import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { Order } from '../../../../../../domain';
import { OptionView } from './OptionView';

type Option = Order['items'][number]['options'][number];

interface OptionGroupViewModel {
  option: Option;
  isHideCategory?: boolean;
  isHideBorder?: boolean;
  optionGroups?: OptionGroupViewModel[];
}

export const OptionGroupView = (
  { option, isHideCategory, isHideBorder, optionGroups }: OptionGroupViewModel
): ReactElement =>
  <>
    <OptionView option={option} isHideCategory={isHideCategory} isHideBorder={isHideBorder} />
    {optionGroups?.map((optionGroup, i) => (
      <View style={styles.childView} key={`optionGroups-item-${optionGroup.option.id || i}`}>
        <OptionGroupView
          option={optionGroup.option}
          isHideCategory={optionGroup.isHideCategory}
          isHideBorder={optionGroup.isHideBorder}
          optionGroups={optionGroup.optionGroups} />
      </View>
    ))}
  </>;

const styles = StyleSheet.create({
  childView: {
    marginLeft: 24
  }
});
