import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class HttpServerError extends Error {
  readonly config: AxiosRequestConfig | undefined;
  readonly response: AxiosResponse;

  constructor(config: AxiosRequestConfig | undefined, response: AxiosResponse, message?: string) {
    const method = response.config?.method?.toUpperCase();
    const url = `${response.config?.baseURL}${response.config?.url}`;

    super(message ?? `Received http server error response: ${response.status} ${method} ${url}`);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    this.config = config;
    this.response = response;
  }
}
