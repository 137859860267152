// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createClient } from 'contentful/dist/contentful.browser.js';
import { useCallback } from 'react';
import { NewsItem } from '../../../domain';
import { ReadNewsStore } from '../../../infrastructure';

type UseNews = {
  fetch: (skip?: number, limit?: number) => Promise<NewsItem[]>
  readNewsItem: (newsItem: NewsItem) => Promise<void>
}

export const useNews = (): UseNews => {
  const fetch = useCallback(async (skip?: number, limit = 100): Promise<NewsItem[]> => {
    const client = createClient({
      space: '2ekwy87irslo',
      accessToken: 'ywJtE_XhAnMc1156iIW3UQti0mKvEMYJw4qryXcitDM',
    });

    // お知らせ画面を最初に開いた時刻（初回起動時の時刻を得られる）
    const newsScreenFirstOpenDate = await ReadNewsStore.getNewsScreenFirstOpenDate();

    return client.getEntries({
      content_type: 'news',
      order: '-fields.publishedAt',
      skip: skip,
      limit: limit,
    }).then((entries: { items: unknown[] }) => {
      return Promise.all(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        entries.items.map(async ({ fields: { title, body, publishedAt }, sys: { id } }: any, index: number) => {
          const publishedAtDate = new Date(publishedAt).getTime();
          // 既に読んだお知らせとして記録があるか、初回起動時よりも公開日時が前のお知らせの場合は既読とする。
          const isRead = await ReadNewsStore.isReadByNewsItemId(id)
            || (newsScreenFirstOpenDate && newsScreenFirstOpenDate.getTime() > publishedAt);

          return { id, title, body, publishedAt: publishedAtDate, isRead, isNew: (!isRead && (index == 0)) };
        })
      );
    });
  }, []);

  const readNewsItem = useCallback((newsItem: NewsItem) => ReadNewsStore.read(newsItem), []);

  return { fetch, readNewsItem };
};
