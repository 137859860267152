import firebase from 'firebase/compat/app';
import { useMemo } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Delivery } from '../../../domain';

type Params = {
  accountId: string
  storeId: string
  brandId: string
  orderId: string
}

export const useDeliveries = (params: Params | undefined): [Delivery[] | undefined, boolean, Error | undefined] => {
  const query = useMemo(() => params && firebase.firestore()
    .collection('accounts')
    .doc(params.accountId)
    .collection('stores')
    .doc(params.storeId)
    .collection('brands')
    .doc(params.brandId)
    .collection('deliveries')
    .where('orderId', '==', params.orderId)
  , [params]);

  return useCollectionData<Delivery>(query);
};
