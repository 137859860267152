import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import * as WebBrowser from 'expo-web-browser';
import React, { useCallback, useEffect, useState } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Environment } from '../../Environment';
import { confirm } from '../../infrastructure';
import { Menu, MenuList, NavigationBar, WithScreen } from '../components';
import { Color, Route } from '../constants';
import { useAppContext, useItems, useNews } from '../hooks';

export const MenuScreen = (
  { navigation }: StackScreenProps<Route, 'Menu'>
): React.ReactElement => {
  const [newsCount, setNewsCount] = useState<number>();
  const { logout } = useAppContext();
  const [soldOutItems, soldOutLoading, soldOutItemsError] = useItems({ soldOut: true });
  const { fetch } = useNews();

  useEffect(() => {
    fetch(undefined, 1)
      .then(items => items.filter((item) => item.isNew).length)
      .then(setNewsCount);
  }, [fetch]);

  const onPressClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressMenuList = useCallback((menu: Menu) => {
    switch (menu) {
      case Menu.SOLD_OUT:
        navigation.navigate('Brands');
        break;
      case Menu.BUSINESS_DAY_TIME:
        navigation.navigate('BusinessDayTimes');
        break;
      case Menu.SALES:
        navigation.navigate('Sales');
        break;
      case Menu.HISTORY:
        navigation.navigate('History');
        break;
      case Menu.SETTINGS:
        navigation.navigate('Settings');
        break;
      case Menu.NEWS:
        navigation.navigate('News');
        break;
      case Menu.HELP:
        {
          // eslint-disable-next-line max-len
          const url = 'https://faq.orderly.jp/knowledge/%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%AB%E9%80%A3%E7%B5%A1%E3%81%99%E3%82%8B';
          if (Environment.isNative) {
            // iOS/Androidはシステムのブラウザでヘルプを開くために Linking.openURL を使用する。
            // iOS/Androidで WebBrowser.openBrowserAsync を使用するとアプリ内の
            // ブラウザViewController/Activityで開いてしまうために使用しない。
            Linking.openURL(url);
          } else {
            // Webは別ウィンドウでヘルプを開くために WebBrowser.openBrowserAsync を使用する。
            // Webで Linking.openURL を使用すると同じタブでヘルプが開いてしまうので使用しない。
            WebBrowser.openBrowserAsync(url);
          }
        }
        break;
      case Menu.LOGOUT:
        confirm(
          'ログアウトしますか？',
          undefined,
          { text: 'ログアウト', onPress: () => logout() },
          { text: 'キャンセル', style: 'cancel' },
          { cancelable: true }
        );
        break;
    }
  }, [logout, navigation]);

  return <WithScreen loading={soldOutLoading} error={soldOutItemsError}>
    <SafeAreaView style={styles.screen}>
      <StatusBar style="dark" />
      <NavigationBar title="メニュー" onPressClose={onPressClose} isForMenu={true} />
      <MenuList soldOutCount={soldOutItems?.length} newsCount={newsCount} onPress={onPressMenuList} />
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
});
