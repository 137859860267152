import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { Clickable } from '../../Clickable';
import { Menu } from './MenuList';

interface Props {
  menu: Menu;
  badge?: React.ReactElement;
  showDisclosureIndicator?: boolean;
  danger?: boolean;

  onPressMenu: (menu: Menu) => void
}

export const MenuListItem = (
  { menu, badge, showDisclosureIndicator, danger, onPressMenu }: Props
): React.ReactElement => {
  const onPress = useCallback(() => {
    onPressMenu(menu);
  }, [onPressMenu, menu]);

  return <Clickable name="menu_tap" id={menu} onPress={onPress}>
    <View style={styles.view}>
      <AppText style={[styles.title, danger && styles.titleRed]}>{menu}</AppText>
      <View style={styles.badge}>{badge}</View>
      <If condition={showDisclosureIndicator}>
        <Image
          source={require('../../../assets/icon/arrow_right.png')}
          style={styles.disclosureIndicator}
          resizeMode="contain"/>
      </If>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingVertical: 48,
    backgroundColor: Color.white,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
  },
  title: {
    flex: 1,
    fontSize: 18,
    color: Color.gray100,
  },
  titleRed: {
    color: Color.red,
  },
  badge: {
    marginHorizontal: 8,
  },
  disclosureIndicator: {
    width: 24,
    height: 24,
  },
});
