import firebase from 'firebase/compat/app';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Store } from '../../../domain';

interface Params {
  accountId: string
  storeId: string
}

export const useStore = (params: Params | undefined): [Store | undefined, boolean, Error | undefined] => {
  const query = params
    ? firebase.firestore()
      .collection('accounts')
      .doc(params.accountId)
      .collection('stores')
      .doc(params.storeId)
      .withConverter(converter)
    : undefined;

  return useDocumentData<Store>(query);
};

const converter: firebase.firestore.FirestoreDataConverter<Store> = {
  toFirestore: store => store,
  fromFirestore: (snapshot, options) => ({ ...snapshot.data(options), id: snapshot.id }) as Store
};
