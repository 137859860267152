import { Printer } from '../../../domain';
import { CamerarollPrinter } from '../../../domain/entities/Printer/CamerarollPrinter';
import { sleep } from '../../../utils';
import { PrinterDiscoverer } from './';

export class CamerarollPrinterDiscoverer implements PrinterDiscoverer {
  start(f: (printer: Printer) => void): Promise<void> {
    return sleep(500)
      .then(() => f(new CamerarollPrinter()));
  }

  stop(): Promise<void> {
    return Promise.resolve();
  }
}
