export const resourceNames = {
  Account: 'アカウント',
  Brand: 'ブランド',
  Identity: 'IDまたはパスワード',
  Order: '注文',
  Platform: 'プラットフォーム',
  Store: '店舗',
} as const;

export const localizedResourceName = (entityName: ResourceName): string =>
  resourceNames[entityName];

export type ResourceName = keyof typeof resourceNames;
