import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { OrderPlatformSuspension } from '../../../domain';

interface Params {
  accountId: string
  storeId: string
  now: Date
}

export const useOrderPlatformSuspensions = (
  params: Params | undefined
): [OrderPlatformSuspension[] | undefined, boolean, Error | undefined] =>
  useCollectionData<OrderPlatformSuspension>(params
    && firebase
      .firestore()
      .collectionGroup('orderPlatformSuspensions')
      .where('accountId', '==', params.accountId)
      .where('storeId', '==', params.storeId)
      .where('suspendUntil', '>', params.now)
      .where('invalidatedTime', '==', null)
  );
