import { ApiRequest } from '../../../client';
import { base } from '../../base';
import { InAppNotification } from '../../entities';

type Request = {
  id: string
}

type Response = InAppNotification

export const getInAppNotification = (token: string, { id }: Request): ApiRequest<Response> =>
  ({ ...base(token), method: 'GET', url: `/api/in_app_notifications/${id}` });
