import { CommonNode, documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Block, BLOCKS, Document, Inline, INLINES } from '@contentful/rich-text-types';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { WebView } from 'react-native-webview';
import { NewsItem } from '../../domain';
import { Environment } from '../../Environment';
import { NavigationBar, WithScreen } from '../components';
import { Color, Route } from '../constants';
import { useNews } from '../hooks';

export const NewsItemScreen = (
  { navigation, route }: StackScreenProps<Route, 'NewsItem'>
): React.ReactElement => {
  const { newsItem } = route.params;
  const news = useNews();
  const ref = useRef<WebView>(null);

  useEffect(() => {
    // 既読にする
    news.readNewsItem(newsItem);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressClose = useCallback(() => {
    navigation.popToTop();
    navigation.goBack();
  }, [navigation]);

  const html = (newsItem: NewsItem) => {
    const timeToString = (time: number) => {
      const date = new Date(time);
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    };

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
          const url = node?.data?.target?.fields?.file?.url;
          const title = node?.data?.target?.fields?.title;
          const width = node?.data?.target?.fields?.file?.details?.image?.width;
          const height = node.data?.target?.fields?.file?.details?.image?.height;
          return `
            <img
              ${url ? `src="https://${url}"` : ''}
              ${title ? `title="${title}"` : ''}
              ${width ? `width="${width}"` : ''}
              ${height ? `height="${height}"` : ''}
              />
          `;
        },
        // WebではWebView.onNavigationStateChangeが反応しないため、
        // aタグの属性に target="_blank" をつけている。
        // これにより、Webの場合はリンクをタップした際に別ウィンドウで開く。
        [INLINES.HYPERLINK]: (node: Block | Inline, next: { (nodes: CommonNode[]): string }) => {
          return `<a href="${node.data.uri}" target="_blank">${next(node.content)}</a>`;
        },
      },
    };

    return `
      <html>
      <head>
        <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0">
        <link rel="stylesheet" href="https://fonts.googleapis.com/earlyaccess/notosansjp.css">
        <style type="text/css">
          body { font-family: 'Noto Sans JP', sans-serif; }
          img { max-width: 100%; height: auto; }
          .wrapper { padding: 16px; }
          #title { font-size: 2em; font-weight: bold; color: ${Color.gray100}; }
          #publishedAt { margin-top: 24px; color: ${Color.gray50}; }
          #body { margin-top: 16px; }
        </style>
      </head>
      <body>
        <div class="wrapper">
          <div id="title">${newsItem.title}</div>
          <div id="publishedAt">${timeToString(newsItem.publishedAt)}</div>
          <div id="body">${documentToHtmlString(newsItem.body as Document, options)}</div >
        </div>
      </body>
      </html>
    `;
  };

  const htmlResult = useMemo(() => html(newsItem), [newsItem]);

  const onNavigationStateChange = useCallback(async (newNavState: { url?: string; }) => {
    if (!Environment.isNative) return;

    const { url } = newNavState;
    if (!url) return;

    if ((url.startsWith('http://') || url.startsWith('https://')) && await Linking.canOpenURL(url)) {
      ref.current?.stopLoading();
      Linking.openURL(url);
    }
  }, []);

  return <WithScreen loading={false} error={undefined}>
    <SafeAreaView style={styles.screen}>
      <NavigationBar title={newsItem.title} onPressBack={onPressBack} onPressClose={onPressClose} isForMenu={true} />
      <WebView
        ref={ref}
        style={styles.webview}
        originWhitelist={['*']}
        source={{ html: htmlResult }}
        onNavigationStateChange={onNavigationStateChange}
      />
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
  webview: {
    flex: 1,
  },
});
