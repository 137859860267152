import { isDevice } from 'expo-device';
import * as Updates from 'expo-updates';
import Constants, { AppOwnership } from 'expo-constants';
import { Platform } from 'react-native';

export interface Environment {
  /**
   * Application environment
   * Generally, this may be used to determine which databases to connect or API host etc...
   */
  projectEnvironment: 'production' | 'staging' | 'development' | 'local';

  /**
   * Running mode
   * If it's production, it should be performance optimized, show logs only really needed, notify domain.errors, etc...
   * If it's debug, it should show much logs to help devs, etc...
   */
  runningMode: 'production' | 'debug' | 'test';

  platform:
    | {
      type: 'ios' | 'android'
      runningType: 'bare' | 'ota'
      build: {
        version: string
        number: string
        revision: string | undefined
      }
      isSimulator: boolean
    }
    | { type: 'web', build: undefined } // TODO: Set build information properly
    | { type: 'expo-go', build: undefined }

  /**
   * App is running on native app or not
   * Expo Go don't have native feature, so this will be false
   */
  isNative: boolean

  dependencies: {
    expo: {
      projectId: 'orderly' | 'orderlydev' | 'orderlydev2'
    }
    firebase: {
      projectId: 'foodstudio-prod' | 'foodstudio-stg' | 'foodstudio-dev2'
    }
    sentry: {
      dsn: string
    }
  }
}

const environment = (): Environment['projectEnvironment'] => {
  if (process.env.NODE_ENV === 'test') return 'local';

  switch (Constants.expoConfig?.slug) {
    case 'orderly': return 'production';
    case 'orderlydev': return 'staging';
    default: return 'development';
  }
};

const runningMode = (): Environment['runningMode'] => {
  switch (process.env.NODE_ENV) {
    case 'production': return 'production';
    case 'test': return 'test';
    default: return 'debug';
  }
};

const platform = (): Environment['platform'] => {
  if (Constants.appOwnership === AppOwnership.Expo) return { type: 'expo-go', build: undefined };
  if (Platform.OS === 'web') return { type: 'web', build: undefined };

  const version = Constants.expoConfig?.version ?? '1.0.0';
  const revisionId = Updates.updateId
    ? `${version}-${Updates.updateId.slice(0, 4)}`
    : undefined;

  switch (Platform.OS) {
    case 'android':
      return {
        type: 'android',
        runningType: revisionId ? 'ota' : 'bare',
        build: {
          version: version,
          number: String(Constants.expoConfig?.android?.versionCode ?? 1),
          revision: revisionId
        },
        isSimulator: !isDevice
      };
    case 'ios':
      return {
        type: 'ios',
        runningType: revisionId ? 'ota' : 'bare',
        build: {
          version: version,
          number: Constants.expoConfig?.ios?.buildNumber ?? '1',
          revision: revisionId
        },
        isSimulator: !isDevice
      };
    default:
      throw new Error('Unknown Platform');
  }
};

export const Environment: Environment = {
  projectEnvironment: environment(),
  runningMode: runningMode(),
  platform: platform(),
  isNative: Constants.appOwnership !== AppOwnership.Expo && Platform.OS !== 'web',
  dependencies: {
    expo: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      projectId: Constants.expoConfig?.slug ?? 'orderlydev2' as any
    },
    firebase: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      projectId: Constants.expoConfig?.web?.config?.firebase?.projectId ?? 'foodstudio-dev2' as any
    },
    sentry: { dsn: Constants.expoConfig?.web?.config?.sentry?.dsn }
  }
};
