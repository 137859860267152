import AsyncStorage from '@react-native-async-storage/async-storage';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Constants from 'expo-constants';
import { setNotificationHandler } from 'expo-notifications';
import { preventAutoHideAsync } from 'expo-splash-screen';
import { LocaleConfig } from 'react-native-calendars';
import { getApps, initializeApp } from 'firebase/app';
import { getReactNativePersistence, initializeAuth } from 'firebase/auth/react-native';
import firebase from 'firebase/compat/app';
import { initializeFirestore } from 'firebase/firestore';
import { logger } from 'react-native-logs';
import { enableScreens } from 'react-native-screens';
import { Environment } from './Environment';

import 'dayjs/locale/ja';
import 'expo-asset';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'react-native-gesture-handler';

preventAutoHideAsync()
  .catch(error => { logger.createLogger().info(error.message); });

setNotificationHandler({
  handleNotification: () => Promise.resolve({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  })
});

if (Environment.runningMode === 'debug')
  logger.createLogger().info(`🌎 Environment ${JSON.stringify(Environment, undefined, 2)}`);

if (!getApps().length) {
  const firestoreSetting = Environment.platform.type === 'web' && Environment.projectEnvironment === 'staging'
    ? { experimentalForceLongPolling: true, ignoreUndefinedProperties: true, merge: true }
    : { experimentalAutoDetectLongPolling: true, ignoreUndefinedProperties: true, merge: true };

  // TODO: Delete v8 syntax
  firebase.initializeApp(Constants.expoConfig?.web?.config?.firebase ?? {});
  firebase.firestore().settings(firestoreSetting);

  const app = initializeApp(Constants.expoConfig?.web?.config?.firebase ?? {});
  initializeAuth(app, { persistence: getReactNativePersistence(AsyncStorage) });
  initializeFirestore(app, { ignoreUndefinedProperties: true });

  // TODO: Separate this as Local AppEnv
  // if (!Device.isDevice) {
  //   firebase.firestore().useEmulator('localhost', 8080);
  //   firebase.app().functions('asia-northeast1').useEmulator('localhost', 5001);
  //   firebase.auth().useEmulator('http://localhost:9099');
  // }
}

// パフォーマンス改善のため https://reactnavigation.org/docs/5.x/react-native-screens/#setup-when-you-are-using-expo
enableScreens();

dayjs.locale('ja');
dayjs.extend(relativeTime);

LocaleConfig.locales.jp = {
  monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
};
LocaleConfig.defaultLocale = 'jp';
