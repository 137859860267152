import { BusinessTime, RegularBusinessDayTime } from '../../../../../../domain';

export type RegularBusinessDayTimeFormAction = {
  type: 'switch'
  payload: {
    id: string
    availavility: boolean
  }
} | {
  type: 'addTimes'
  payload: {
    id: string
  }
} | {
  type: 'removeTimes'
  payload: {
    id: string,
    timeInputsIndex: number
  }
} | {
  type: 'changeTimes'
  payload: {
    id: string
    timeInputsIndex: number,
    businessTime: BusinessTime
  }
}

export const regularBusinessDayTimeFormReducer = (
  state: RegularBusinessDayTime[],
  action: RegularBusinessDayTimeFormAction
): RegularBusinessDayTime[] => {
  const toBeUpdatedBusinessDayTime = state.find(businessDayTime => businessDayTime.id === action.payload.id);
  if (!toBeUpdatedBusinessDayTime) throw Error();

  switch (action.type) {
    case 'switch':
      return [
        ...state.filter(businessDayTime => businessDayTime.id !== action.payload.id),
        {
          ...toBeUpdatedBusinessDayTime,
          businessTimes: action.payload.availavility ? [{ startTime: '', endTime: '' }]
            : []
        }
      ];
    case 'addTimes':
      return [
        ...state.filter(businessDayTime => businessDayTime.id !== action.payload.id),
        {
          ...toBeUpdatedBusinessDayTime,
          businessTimes: [
            ...toBeUpdatedBusinessDayTime.businessTimes,
            { startTime: '', endTime: '' }
          ]
        }
      ];
    case 'removeTimes':
      return [
        ...state.filter(businessDayTime => businessDayTime.id !== action.payload.id),
        {
          ...toBeUpdatedBusinessDayTime,
          businessTimes: [
            ...toBeUpdatedBusinessDayTime.businessTimes
              .filter((_, index) => index != action.payload.timeInputsIndex),
          ]
        }
      ];
    case 'changeTimes':
      return [
        ...state.filter(businessDayTime => businessDayTime.id !== action.payload.id),
        {
          ...toBeUpdatedBusinessDayTime,
          businessTimes: [
            ...toBeUpdatedBusinessDayTime.businessTimes
              .map((businessTime, index) => index === action.payload.timeInputsIndex
                ? action.payload.businessTime
                : businessTime
              )
          ]
        }
      ];
    default:
      return state;
  }
};
