import { HasLocalizedDescription } from '../core/HasLocalizedDescription';
import { LocalizableRfc7807Compliant } from '../core/Rfc7807Compliant';

export class CallableError extends Error implements HasLocalizedDescription {
  readonly code: string;
  readonly status: number;
  readonly detail: string;
  readonly localizedTitle: string;
  readonly localizedDetail: string

  constructor(details: LocalizableRfc7807Compliant) {
    const message = 'The callable has returned an error.';
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    this.code = details.type;
    this.status = details.status;
    this.localizedTitle = details.localizedTitle;
    this.detail = details.detail;
    this.localizedDetail = details.localizedDetail;
  }
}
