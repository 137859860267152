import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

export type Diff<T> = {
  before?: T;
  after?: T;
  type: firebase.firestore.DocumentChangeType;
};

export const useCollectionDiffData = <T extends { id: string }>(
  query: firebase.firestore.Query | null | undefined,
  onChanged: (diff: Diff<T>[]) => void
): [T[], boolean, Error | undefined] => {
  const [snapshot, setSnapshot] = useState<firebase.firestore.QuerySnapshot>();
  const [isInitial, setIsInitial] = useState(true);
  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const converter = (snapshot: firebase.firestore.QueryDocumentSnapshot) =>
    ({ id: snapshot.id, ...snapshot.data() }) as T;

  useEffect(() => {
    if (!query) return;

    return query
      .onSnapshot(snapshot => {
        setLoading(false);
        setSnapshot(snapshot);
      }, error => {
        setLoading(false);
        setError(error);
      });
  }, [query]);

  useEffect(() => {
    if (!snapshot) return;

    if (!isInitial) {
      const changes = snapshot.docChanges()
        .map(({ type, doc, newIndex }) => ({
          before: items?.find(item => item.id === doc.id),
          after: newIndex > -1 ? converter(doc) : undefined,
          type,
        }));
      onChanged(changes);
    }

    setItems(snapshot.docs.map(converter));
    setIsInitial(false);
  }, [snapshot]);

  return [items, loading, error];
};