import firebase from 'firebase/compat/app';
import { DocumentData } from 'firebase/firestore';
import { Environment } from '../../Environment';
import { logger } from '../logger';
import { AppUpdateCheckTimeStore } from './AppUpdateCheckTimeStore';

export const AppUpdateResults = {
  /** アプリを更新する必要がある */
  RequiredUpdate: 'RequiredUpdate',
  /** アプリの更新を推奨している */
  RecommendedUpdate: 'RecommendedUpdate',
  /** アプリの更新がない */
  NoUpdate: 'NoUpdate',
  /** 前回のチェックから時間が経ってないので更新があるかをチェックせず */
  Wait: 'Wait',
  /** このプラットフォームではアプリの更新をサポートしていない(iOS/Android以外の場合) */
  NotSupported: 'NotSupported',
  /** サーバにデータない */
  NoData: 'NoData',
  /** 何かエラーが起こった */
  Error: 'Error',
} as const;

export type AppUpdateResult = typeof AppUpdateResults[keyof typeof AppUpdateResults];

type Config = {
  value: number;
};

/**
 * アプリのアップデートがあるかを返す
 *
 * Firestoreにアプリの最新版があるかを確認し、
 */
export const checkAppUpdate = async (): Promise<AppUpdateResult> => {
  if (!Environment.isNative) {
    return AppUpdateResults.NotSupported;
  }

  const lastAppUpdateCheckTime = await AppUpdateCheckTimeStore.get();
  // 最後にアプリの更新があるかを確認してから24時間経っていない場合
  if (lastAppUpdateCheckTime && lastAppUpdateCheckTime + (60 * 60 * 24 * 1000) > Date.now()) {
    return AppUpdateResults.Wait;
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const DeviceInfo = require('react-native-device-info');
  const buildNumber = parseInt(DeviceInfo.getBuildNumber());
  // ビルドナンバーが取得できなかった場合(通常はあり得ないはず)
  if (!buildNumber) {
    return AppUpdateResults.Error;
  }

  try {
    const requiredBuildNumber = await getAppRequiredBuildNumber();

    if (requiredBuildNumber && requiredBuildNumber > buildNumber) {
      return AppUpdateResults.RequiredUpdate;
    }

    const recommendedBuildNumber = await getAppRecommendedBuildNumber();
    if (recommendedBuildNumber && recommendedBuildNumber > buildNumber) {
      await AppUpdateCheckTimeStore.set(); // アプリの更新があるかを次の24時間は確認しない
      return AppUpdateResults.RecommendedUpdate;
    }

    // サーバからデータが取得できなかった場合
    if (!requiredBuildNumber || !recommendedBuildNumber) {
      return AppUpdateResults.NoData;
    }

    await AppUpdateCheckTimeStore.set(); // アプリの更新があるかを次の24時間は確認しない
    return AppUpdateResults.NoUpdate;
  } catch (e) {
    logger.error(e as Error);
    return AppUpdateResults.Error;
  }
};

const getAppRequiredBuildNumber = async (): Promise<number | undefined> => getData('app_required_build_number');

const getAppRecommendedBuildNumber = async (): Promise<number | undefined> => getData('app_recommended_build_number');

const getData = async (docPath: string): Promise<number | undefined> => {
  const doc = await firebase.firestore()
    .collection('configs')
    .doc(docPath)
    .withConverter(converter)
    .get();
  if (doc.exists) {
    return doc.data()?.value;
  } else {
    return undefined;
  }
};

const converter = {
  toFirestore(config: Config): DocumentData {
    return { value: config.value };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Config {
    const data = snapshot.data(options);

    if (!(data.value && typeof data.value === 'number')){
      throw new Error('invalid data');
    }

    return { value: data.value };
  },
};
