import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native';
import { OrderWithDelivery, Printer } from '../../domain';
import { alert, logger } from '../../infrastructure';
import { concatPromises } from '../../utils';
import { CancelReceiptView, If, ReceiptView } from '../components';
import { composeDisposers, OrderEvent, OrderReceiptEvent, useAppContext, useEventBus } from '../hooks';
import { CapturedImage, captureImages } from '../utils';

export type OrderReceiptType = 'OrderReceipt' | 'CancelReceiptIfCanceled';

interface Task {
  orderWithDelivery: OrderWithDelivery
  orderReceiptType: OrderReceiptType
}

type Props = {
  children: ReactNode
}

export const WithOrderReceiptPrinter = ({ children }: Props): ReactElement => {
  const [taskQueue, setTaskQueue] = useState<Task[]>([]);
  const [task, setTask] = useState<Task>();
  const [ready, setReady] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);

  const [, subscribe] = useEventBus();
  const { brands, connectedPrinters } = useAppContext();

  const orderWithDelivery = task?.orderWithDelivery;
  const brand = brands?.find(brand => brand.id == orderWithDelivery?.order.brandId);
  const hasCanceled = task?.orderReceiptType == 'CancelReceiptIfCanceled'
    && orderWithDelivery?.order.status == 'CANCELED';

  const printFunc = useCallback((orderWithDelivery: OrderWithDelivery, printReceiptType?: OrderReceiptType) => {
    logger.info('Print orders', {
      orderId: orderWithDelivery.order.id,
      orderStatus: orderWithDelivery.order.status,
      printers: connectedPrinters?.map(printer => `${printer.modelName} ${printer.identifier}`)
    });
    setTaskQueue(prev => [...prev, { orderWithDelivery, orderReceiptType: printReceiptType ?? 'OrderReceipt' }]);
  }, [connectedPrinters]);

  useEffect(() => {
    return composeDisposers([
      subscribe<OrderEvent>('order_accepted', ({ orderWithDelivery }) => {
        printFunc(orderWithDelivery, 'OrderReceipt');
      }),
      subscribe<OrderEvent>('scheduled_order_accepted', ({ orderWithDelivery }) => {
        printFunc(orderWithDelivery, 'OrderReceipt');
      }),
      subscribe<OrderEvent>('order_canceled', ({ orderWithDelivery }) => {
        printFunc(orderWithDelivery, 'CancelReceiptIfCanceled');
      }),
      subscribe<OrderReceiptEvent>('order_receipt_requested', ({ orderWithDelivery }) => {
        printFunc(orderWithDelivery, 'OrderReceipt');
      })
    ]);
  }, [printFunc, subscribe]);

  useEffect(() => {
    if (task || taskQueue.length === 0) return;

    setTask(taskQueue[0]);
    setTaskQueue(taskQueue.slice(1));
  }, [taskQueue, task]);

  useEffect(() => {
    if (!ready) return;

    if (!connectedPrinters) {
      setTask(undefined);
      setReady(false);
      return;
    }

    Promise.resolve(connectedPrinters)
      .then(printers => printers.map(printer => ({
        width: printer.supportedImageWidth,
        reverse: printer.shouldPrintUpSideDown
      })))
      .then(imageSpecs => captureImages(scrollViewRef, imageSpecs))
      .then(allImages => connectedPrinters.map(printer => () => printImage(allImages, printer)))
      .then(concatPromises)
      .then(() => {
        logger.info('Printed orders', {
          orderId: task?.orderWithDelivery.order.id,
          orderStatus: task?.orderWithDelivery.order.status,
          printers: connectedPrinters.map(printer => `${printer.modelName} ${printer.identifier}`)
        });
      })
      .catch(error => {
        logger.error(error as Error);
        const message = errorMessage(error);
        alert(
          '印刷に失敗しました。',
          `注文番号 ${task?.orderWithDelivery.order.displayId} は印刷出来ませんでした。${message ? `[${message}]` : ''}`,
          { text: '閉じる' },
        );
      })
      .finally(() => { setTask(undefined); });

    setReady(false);
  }, [ready]);

  const onLayout = useCallback(() => {
    if (!task) return;
    setReady(true);
  }, [task]);

  return <>
    <ScrollView ref={scrollViewRef} style={{ position: 'absolute', width: 864 }} collapsable={false}>
      {task && orderWithDelivery?.order
        ? <>
          <If condition={hasCanceled}>
            <CancelReceiptView {...{ orderWithDelivery, brand, onLayout }} />
          </If>
          <If condition={!hasCanceled}>
            <ReceiptView {...{ orderWithDelivery, brand, onLayout }} />
          </If>
        </>
        : <></>
      }
    </ScrollView>
    {children}
  </>;
};

const printImage = (allImages: CapturedImage[], printer: Printer) =>
  Promise.resolve(allImages)
    .then(allImages => allImages.find(({ width, reverse }) =>
      width === printer.supportedImageWidth && reverse === printer.shouldPrintUpSideDown
    ))
    .then(capturedImage => capturedImage?.image.base64)
    .then(base64 => {
      if (!base64) {
        logger.error(new Error('CapturedImage.image.base64 is not defined'));
        return Promise.resolve();
      }

      return printer.print(base64);
    });

const errorMessage = (error: unknown) => {
  if (!(error instanceof Error)) return '';

  switch (error?.message) {
    case 'Device not found.':
    case 'Error: Failed to connect to the printer.':
      return 'プリンタが見つかりませんでした。プリンタとの接続を確認してください。';
    case 'Device has error.':
      return 'プリンタ上でエラーが発生しました。プリンタの状態や接続状況を確認してください。';
    case 'Print timed out.':
      return '印刷がタイムアウトしました。プリンタの状況や接続状況を確認してください。';
    case 'The device is in use by another host.':
    case 'The device is in use by another process.':
      return 'プリンタが別のデバイスによって使用中です。プリンタの状況や接続状況を確認してください。';
    default:
      return error?.message ?? '';
  }
};
