import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NewsItem } from '../../domain';
import { NavigationBar, NewsList, WithScreen } from '../components';
import { Color, Route } from '../constants';
import { useNews } from '../hooks';

export const NewsScreen = ({ navigation }: StackScreenProps<Route, 'News'>): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const { fetch } = useNews();

  useEffect(() => {
    setLoading(true);
    fetch()
      .then((items) => { setNewsItems(items); })
      .catch((error) => { setError(error); })
      .finally(() => { setLoading(false); });
  }, [fetch]);

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressClose = useCallback(() => {
    navigation.popToTop();
    navigation.goBack();
  }, [navigation]);

  const onPressNewsItem = useCallback((newsItem: NewsItem) => {
    navigation.navigate('NewsItem', { newsItem });
  }, [navigation]);

  return <WithScreen {...{ loading, error }}>
    <SafeAreaView style={styles.screen}>
      <NavigationBar title="お知らせ" onPressBack={onPressBack} onPressClose={onPressClose} isForMenu={true} />
      <NewsList newsItems={newsItems} onPressNewsItem={onPressNewsItem}/>
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
});
