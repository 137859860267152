import React, { useCallback, useMemo } from 'react';
import { FlatList, ImageURISource, StyleSheet, View } from 'react-native';
import {
  BusinessDayTime,
  Integration,
  PlatformType,
  platformTypeSortPriority,
  SECTION_TYPE,
  Store
} from '../../../../domain';
import { PlatformTypeDecorator } from '../../../decorators';
import { useAppContext } from '../../../hooks';
import { AppText } from '../../AppText';
import { BusinessDayTimeListItem } from './BusinessDayTimeListItem';

type Props = {
  brandId: string
  businessDayTimes: BusinessDayTime[] | undefined
}

type Item = {
  store: Store | undefined
  brandId: string
  brandName: string
  businessDayTimes: BusinessDayTime[]
  integrations: Integration[] | undefined
  platform: {
    type: PlatformType
    name: string
    icon: ImageURISource
  }
}

export const BusinessDayTimesList = ({ brandId, businessDayTimes }: Props): React.ReactElement => {
  const { store, platforms, integrations } = useAppContext();

  const data = useMemo(() => platforms
    ?.filter(platform => platform.brandId === brandId)
    .filter(({ type }) =>
      integrations?.find(({ platformType }) => platformType === type)?.features.businessDayTimes.fetch.supported
    )
    .sort((a, b) => platformTypeSortPriority(a.type) - platformTypeSortPriority(b.type))
    .map(platform => ({
      store,
      brandId,
      brandName: platform.name,
      platform: {
        type: platform.type as PlatformType,
        name: new PlatformTypeDecorator(platform.type, integrations).name(),
        icon: new PlatformTypeDecorator(platform.type, integrations).icon(),
      },
      businessDayTimes: businessDayTimes?.filter(businessDayTime => businessDayTime.brandId === platform?.brandId)
        .filter(businessDayTime => businessDayTime.platformType === platform.type)
        .sort((a,b) => SECTION_TYPE.indexOf(a.type) - SECTION_TYPE.indexOf(b.type)) ?? [],
      integrations
    })), [platforms, brandId, integrations, store, businessDayTimes]);

  const renderItem = useCallback(({ item }: { item: Item }) => <BusinessDayTimeListItem {...item} />, []);
  const ItemSeparatorComponent = useCallback(
    () => <View style={{ height: 0, marginVertical: 12, width: '100%' }} />,
    []
  );

  const keyExtractor = useCallback(
    ({ brandId, platform: { type } }: Item) => `business-day-times-list-${brandId}-${type}`,
    []
  );

  return !data || data.length === 0
    ? <View style={styles.view}>
      <AppText style={styles.unavailableText}>変更可能なプラットフォームがありません。</AppText>
    </View>
    : <FlatList
      {...{ data, renderItem, ItemSeparatorComponent, keyExtractor }}
      contentContainerStyle={styles.container}
    />;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  container: {
    paddingVertical: 32,
    paddingLeft: 32,
    paddingRight: 56
  },
  unavailableText: {
    fontSize: 18,
    marginStart: 'auto',
    marginEnd: 'auto',
    marginTop: 48,
  },
});
