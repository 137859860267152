import { HasLocalizedDescription } from './HasLocalizedDescription';

export type Rfc7807Compliant = {
  type: string
  status: number
  title: string
  detail: string
};

export type LocalizableRfc7807Compliant = HasLocalizedDescription & Rfc7807Compliant;

export const isLocalizableRfc7807Compliant = (error: unknown): error is LocalizableRfc7807Compliant =>
  typeof error === 'object'
  && !!error
  && 'type' in error
  && 'status' in error
  && 'title' in error
  && 'localizedTitle' in error
  && 'detail' in error
  && 'localizedDetail' in error;
