import { PlatformRelation, PlatformType, SuspensionReason } from '../../../domain';
import { Callable, useCallable } from '../core/useCallable';

interface Request {
  orderPlatformRelation: PlatformRelation<PlatformType>
  minutes: number | null
  suspensionReason: SuspensionReason
}

type Response = void

export const useCreateOrderPlatformSuspension = (): Callable<Request, Response> =>
  useCallable<Request, Response>('onCallCreateOrderPlatformSuspension');
