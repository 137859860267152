import React, { useCallback, useMemo } from 'react';
import { SectionList, StyleSheet } from 'react-native';
import { Printer } from '../../../../domain';
import { useAppContext } from '../../../hooks';
import { PrinterListEmptyItem } from './PrinterListEmptyItem';
import { PrinterListItem, PrinterListItemPositionInTheList } from './PrinterListItem';
import { PrinterListSectionHeader } from './PrinterListSectionHeader';
import { PrinterListSwitchItem } from './PrinterListSwitchItem';

interface Props {
  foundPrinters: Printer[];
  discovering: boolean;
  onPressConnectPrinter: (printer: Printer) => void;
  onPressDisconnectPrinter: (printer: Printer) => void;
  onChangeDiscovering: (discover: boolean) => void;
}
type ListItem =
  | { type: 'switch' }
  | { type: 'empty' }
  | { type: 'connected', printer: Printer, position: PrinterListItemPositionInTheList }
  | { type: 'discovered', printer: Printer, position: PrinterListItemPositionInTheList };

export const PrinterList = (props: Props): React.ReactElement => {
  const { foundPrinters, discovering, onPressConnectPrinter, onPressDisconnectPrinter, onChangeDiscovering } = props;
  const { connectedPrinters } = useAppContext();

  const sections = useMemo(() =>
    buildSections(foundPrinters, connectedPrinters, discovering)
  , [connectedPrinters, discovering, foundPrinters]);

  const renderItem = useCallback(({ item } : { item: ListItem }) => {
    switch (item.type) {
      case 'switch':
        return <PrinterListSwitchItem value={discovering} onValueChange={onChangeDiscovering} />;
      case 'empty':
        return <PrinterListEmptyItem />;
      case 'connected':
        return <PrinterListItem
          printer={item.printer}
          checked={true}
          buttonTitle={'解除'}
          onPressButton={onPressDisconnectPrinter}
          positionInTheList={item.position} />;
      case 'discovered':
        return <PrinterListItem
          printer={item.printer}
          checked={false}
          buttonTitle={'接続'}
          onPressButton={onPressConnectPrinter}
          positionInTheList={item.position} />;
    }
  }, [discovering, onChangeDiscovering, onPressConnectPrinter, onPressDisconnectPrinter]);

  const renderSectionHeader = useCallback(({ section: { title } }: { section: { title?: string } }) =>
    title ? <PrinterListSectionHeader title={title} /> : <></>
  , []);

  const keyExtractor = useCallback((item: ListItem, index: number) =>
    `PrinterList-PrinterListItem_${item.type}-${index}`
  , []);

  return <SectionList {...{
    keyExtractor,
    sections,
    renderSectionHeader,
    renderItem,
    stickySectionHeadersEnabled: false,
    contentContainerStyle: styles.container,
  }}/>;
};

const buildSections = (
  foundPrinters: Printer[],
  connectedPrinters: Printer[],
  discover: boolean
): { title?: string, data: ListItem[] }[] => {
  const unconnectedPrinters = foundPrinters.filter(printer => !connectedPrinters.some(v => v.isEqual(printer)));

  return [
    { title: undefined, data: [{ type: 'switch' as const }] },
    ...discover
      ? [{
        title: '接続中のプリンター',
        data: connectedPrinters.length > 0
          ? connectedPrinters.map((printer: Printer, index: number) => ({
            type: 'connected' as const,
            printer,
            position: itemPosition(index, connectedPrinters.length)
          }))
          : [{ type: 'empty' as const }]
      }]
      : [],
    ...discover && unconnectedPrinters.length > 0
      ? [{
        title: 'すべてのプリンター',
        data: unconnectedPrinters.map((printer: Printer, index: number) => ({
          type: 'discovered' as const,
          printer,
          position: itemPosition(index, unconnectedPrinters.length)
        }))
      }]
      : []
  ];
};

const itemPosition = (indexInList: number, listLength: number): PrinterListItemPositionInTheList => {
  if (listLength == 1) return 'single';
  if (indexInList == 0) return 'top';
  if (indexInList + 1 >= listLength) return 'bottom';

  return  'middle';
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 32,
    paddingLeft: 32,
    paddingRight: 56,
  },
});
