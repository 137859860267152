import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { WithScreen } from '../components';
import { Color } from '../constants';

export const SplashScreen = (): React.ReactElement =>
  <WithScreen loading={false} error={undefined}>
    <View style={styles.screen}>
      <Image source={require('../assets/splash.png')} resizeMode="contain" style={styles.image} />
    </View>
  </WithScreen>;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.dark,
    width: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});
