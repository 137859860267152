import { StyleSheet } from 'react-native';
import { Color } from '../../../../constants';

export const styles = StyleSheet.create({
  view: {
    width: 384
  },
  info: {
    padding: 32,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
  },
  delivery: {
    flex: 1,
  },
  deliveryContainer: {
    padding: 32,
  },
  iconAndOrderId: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 130,
    marginTop: 32,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 24,
  },
  orderIds: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 256,
  },
  orderId: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -4
  },
  orderIdIcon: {
    width: 16,
    height: 16,
  },
  orderIdText: {
    marginLeft: 6,
    color: Color.gray100,
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 21
  },
  brandText: {
    marginTop: 8,
    fontSize: 14,
    fontWeight: '500',
    color: Color.gray100,
  },
  posId: {
    height: 32,
    marginTop: 13,
    paddingHorizontal: 8,
    borderColor: Color.gray10,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
  },
  posIdIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
  posIdText: {
    fontSize: 14,
    fontWeight: '500',
    color: Color.gray100,
  },
  orderUuid: {
    minHeight: 31,
    marginTop: 5,
    paddingHorizontal: 8,
    borderColor: Color.gray10,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
  },
  orderUuidText: {
    fontSize: 14,
    fontWeight: '500',
    color: Color.gray100,
  },
  confirm: {
    marginTop: 20,
  },
  confirmButton: {
    minHeight: 64,
    backgroundColor: Color.brand,
    borderRadius: 4,
    justifyContent: 'center',
    padding: 8,
  },
  confirmText: {
    fontSize: 20,
    color: Color.white,
    textAlign: 'center',
  },
  print: {
    marginRight: 8,
  },
  support: {
    flex: 1,
  },
});
