import { DiscoveredPrinter, Models, PrinterDiscoverer } from '@orderlyjp/react-native-epson-epos';
import { PrinterDiscoverer as IPrinterDiscoverer } from '../';
import { Printer } from '../../../domain';
import { EposPrinter } from '../../../domain/entities/Printer/EposPrinter';
import { logger } from '../../logger';

export class EpsonPrinterDiscoverer implements IPrinterDiscoverer {
  private manager: PrinterDiscoverer | null = null;

  start(f: (printer: Printer) => void): Promise<void> {
    const manager = new PrinterDiscoverer((_, printer: DiscoveredPrinter) => {
      f(new EposPrinter(printer.target, printer.deviceName, Models.TM_T88)); // とりあえずは TM-T88 シリーズのプリンタと決め打ちする
    });

    this.manager = manager;
    return manager.start()
      .catch(error => {
        logger.error(error as Error);
        return Promise.reject(error);
      })
      .catch(() => manager.stop().finally(() => { this.manager = null; }))
      .then();
  }

  stop(): Promise<void> {
    return this.manager?.stop().finally(() => { this.manager = null; }).then() ?? Promise.resolve();
  }
}
