import React, { ForwardedRef, useCallback, useMemo } from 'react';
import { Image, SectionList, SectionListData, StyleSheet, View, ViewToken } from 'react-native';
import { Integration, Item, PlatformType } from '../../../../domain';
import { Color } from '../../../constants';
import { PlatformTypeDecorator } from '../../../decorators';
import { useAppContext } from '../../../hooks';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { Clickable } from '../../Clickable';
import { ItemListItem } from './ItemListItem';

export interface ItemListProps {
  sections: SectionItem[];
  showPlatform: boolean;
  selectedPlatform: PlatformType | undefined;
  emptyText: string;
  extraView?: (item: Item) => React.ReactNode;
  onChangeViewableSection?: (section: SectionItem[]) => void;
  onPressOptionCategory?: (category: Category) => void;
  onScrollBeginDrag?: () => void;
}

export type Category = { name: string, id: string, single: boolean };

export interface SectionItem {
  platformType?: PlatformType;
  sectionTitle?: string;
  category: Category;
  index: number;
  data: Item[];
}

export const ItemList = React.forwardRef((props: ItemListProps, ref: ForwardedRef<SectionList>): React.ReactElement => {
  const { integrations } = useAppContext();

  /* eslint-disable react/prop-types */
  const {
    sections,
    showPlatform,
    selectedPlatform,
    emptyText,
    extraView,
    onPressOptionCategory,
    onChangeViewableSection,
    onScrollBeginDrag,
  } = props;
  /* eslint-enable react/prop-types */

  const selectedIntegration = integrations?.find(integration => integration.platformType === selectedPlatform);

  const renderSectionHeader = useCallback(({ section }: { section: SectionListData<Item> }) =>
    <SectionHeader {...{ section, showPlatform, integrations, onPressOptionCategory }}/>
  , [integrations, showPlatform, onPressOptionCategory]);

  const renderSectionFooter = useCallback(() => <View style={styles.sectionFooter}/>, []);

  const renderItem = useCallback(({ item }: { item: Item }) =>
    <ItemListItem key={`item-list-item-${item.id}`} item={item} extraView={extraView && extraView(item)} />
  , [extraView]);

  const keyExtractor = useCallback((item: Item, index: number) => `ItemListItem-${item.id}-${index}`, []);

  const onViewableItemsChanged = useCallback(({ viewableItems }: { viewableItems: ViewToken[]}) => {
    onChangeViewableSection?.(viewableItems.map(item => item.section));
  }, [onChangeViewableSection]);

  const supportsFeature = !selectedIntegration || selectedIntegration?.features.menus.fetch;

  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  const onScrollToIndexFailed = useCallback(() => {}, []);

  return useMemo(() => (
    <View style={styles.view}>
      <If condition={!supportsFeature}>
        <AppText style={styles.emptyText}>現在商品の売り切れ設定に対応しておりません</AppText>
      </If>
      <If condition={supportsFeature && sections.length === 0 /* eslint-disable-line react/prop-types */}>
        <AppText style={styles.emptyText}>{emptyText}</AppText>
      </If>
      <If condition={supportsFeature && sections.length > 0 /* eslint-disable-line react/prop-types */}>
        <SectionList
          {...{ ref, sections, renderItem, renderSectionHeader, renderSectionFooter, keyExtractor }}
          {...{ onScrollBeginDrag, onViewableItemsChanged }}
          stickySectionHeadersEnabled={false}
          style={styles.listView}
          initialNumToRender={10}
          contentInset={{ bottom: 24 }}
          onScrollToIndexFailed={onScrollToIndexFailed}/>
      </If>
    </View>
  ), [
    ref,
    emptyText,
    sections,
    renderItem,
    renderSectionHeader,
    renderSectionFooter,
    keyExtractor,
    supportsFeature,
    onViewableItemsChanged,
    onScrollBeginDrag,
    onScrollToIndexFailed,
  ]);
});

interface SectionHeaderProps {
  section: SectionListData<Item>
  showPlatform: boolean
  integrations: Integration[] | undefined

  onPressOptionCategory?: (category: Category) => void
}

const SectionHeader = ({ section, showPlatform, integrations, onPressOptionCategory }: SectionHeaderProps) => {
  const onPress = useCallback(() =>
    onPressOptionCategory?.(section.category)
  , [onPressOptionCategory, section.category]);

  return <View>
    <If condition={showPlatform && section.platformType}>
      <View style={styles.platformHeader}>
        <Image
          style={styles.platformHeaderIcon}
          source={new PlatformTypeDecorator(section.platformType, integrations).icon()}
          resizeMode="contain"/>
        <AppText style={styles.platformHeaderText}>
          {new PlatformTypeDecorator(section.platformType, integrations).name()}
        </AppText>
      </View>
    </If>
    <If condition={section.sectionTitle}>
      <View style={styles.sectionHeader}>
        <AppText style={styles.sectionHeaderText}>{section.sectionTitle}</AppText>
      </View>
    </If>
    <View style={styles.subSectionHeader}>
      <AppText numberOfLines={1} style={styles.subSectionHeaderText}>{section.category.name}</AppText>
      <If condition={!section.category.single}>
        <Clickable name={'sold_option_item'} onPress={onPress} style={styles.subSectionHeaderLink}>
          <Image style={styles.subSectionHeaderLinkImage} source={require('../../../assets/icon/info_line.png')}/>
        </Clickable>
      </If>
    </View>
  </View>;
};

ItemList.displayName = 'ItemList';

const styles = StyleSheet.create({
  view: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: Color.light,
  },
  emptyText: {
    width: '100%',
    marginTop: 77,
    fontSize: 21,
    color: Color.gray50,
    textAlign: 'center',
  },
  platformHeader: {
    display: 'flex',
    height: 64,
    paddingHorizontal: 32,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Color.light,
  },
  platformHeaderIcon: {
    height: 28,
    width: 28,
    borderRadius: 14
  },
  platformHeaderText: {
    height: 64,
    paddingLeft: 12,
    fontSize: 17,
    fontWeight: '700',
    color: Color.gray100,
    lineHeight: 64
  },
  sectionHeader: {
    height: 60,
    paddingHorizontal: 32,
    backgroundColor: Color.white,
  },
  sectionHeaderText: {
    height: 60,
    lineHeight: 84,
    fontSize: 20,
    fontWeight: '700',
  },
  subSectionHeader: {
    display: 'flex',
    height: 54,
    paddingTop: 24,
    paddingHorizontal: 32,
    flexDirection: 'row',
    backgroundColor: Color.white,
  },
  subSectionHeaderText: {
    height: 50,
    flex: 1,
    fontSize: 18,
    fontWeight: '700',
  },
  subSectionHeaderLink: {
    paddingHorizontal: 8,
  },
  subSectionHeaderLinkImage: {
    height: 24,
    width: 24
  },
  listView: {
    paddingBottom: 100,
    backgroundColor: Color.light,
  },
  sectionFooter: {
    height: 24,
    backgroundColor: Color.white,
  }
});
