import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { AppState, AppStateStatus, Linking } from 'react-native';
import { Environment } from '../../../Environment';
import { alert, AppUpdateResult, AppUpdateResults, checkAppUpdate, confirm } from '../../../infrastructure';

const Type = {
  Required: 'required',
  Recommended: 'recommended',
} as const;

type Type = typeof Type[keyof typeof Type];

export const useAppUpdate = (): void => {
  const navigation = useNavigation();
  const [showAppUpdateDialog, setShowAppUpdateDialog] = useState(false);

  useEffect(() => {
    const handleAppUpdate = async () => {
      const update = await checkAppUpdate().then(mapUpdateResult);

      if (!update) return;
      if (showAppUpdateDialog) return;
      if (!(await Linking.canOpenURL(update.url))) return;

      setShowAppUpdateDialog(true);

      update.type == Type.Required
        ? showUpdateAlert(update.message, update.url, () => setShowAppUpdateDialog(false))
        : showUpdateRecommendationAlert(update.message, update.url, () => setShowAppUpdateDialog(false));
    };

    const handleAppUpdateWithAppState = async (state: AppStateStatus) =>
      state === 'active' ? handleAppUpdate() : Promise.resolve();

    const subscription = AppState.addEventListener('change', handleAppUpdateWithAppState);
    const callback = navigation.addListener('focus', handleAppUpdate);

    return () => {
      subscription.remove();
      navigation.removeListener('focus', callback);
    };
  }, [navigation, showAppUpdateDialog]);
};

const mapUpdateResult = (result: AppUpdateResult): { url: string, message: string, type: Type, } | undefined => {
  switch (Environment.platform.type) {
    case 'ios': {
      if (result === AppUpdateResults.RequiredUpdate) {
        return {
          url: 'itms-apps://itunes.apple.com/app/id1551630951',
          message: 'App Storeから最新バージョンのアプリを入手してください',
          type: Type.Required,
        };
      } else if (result === AppUpdateResults.RecommendedUpdate) {
        return {
          url: 'itms-apps://itunes.apple.com/app/id1551630951',
          message:  'App Storeに新しいバージョンのアプリがあります',
          type: Type.Recommended,
        };
      }
      break;
    }
    case 'android': {
      if (result === AppUpdateResults.RequiredUpdate) {
        return {
          url: 'market://details?id=jp.orderly.order',
          message: 'Google Playから最新バージョンのアプリを入手してください',
          type: Type.Required,
        };
      } else if (result === AppUpdateResults.RecommendedUpdate) {
        return {
          url: 'market://details?id=jp.orderly.order',
          message: 'Google Playに新しいバージョンのアプリがあります',
          type: Type.Recommended,
        };
      }
      break;
    }
    default:
      return undefined;
  }
};

const showUpdateAlert = (message: string, url: string, onDismiss: () => void) =>
  alert(
    message,
    undefined,
    { text: 'OK', onPress: () => Linking.openURL(url) },
    { onDismiss },
  );

const showUpdateRecommendationAlert = (message: string, url: string, onDismiss: () => void) =>
  confirm(
    message,
    undefined,
    { text: 'OK', onPress: () => Linking.openURL(url) },
    { text: 'キャンセル' },
    { onDismiss },
  );
