import { Picker } from '@react-native-picker/picker';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  CookingTime,
  platformToRelation,
  SUSPENSION_MINUTES,
  SuspensionMinute,
  SuspensionReason,
  suspensionReasonNames,
  SuspensionReasonTypes
} from '../../../domain';
import { AppText, If, Input, PrimaryButton, Radio, WithModal } from '../../components';
import { Color, Route } from '../../constants';
import {
  useAppContext,
  useCreateOrderPlatformSuspension,
  useOrderPlatformSuspensions,
  usePeriodicUpdater
} from '../../hooks';

const screenStates = {
  SPECIFY_STOP_TIME: '停止時間を指定してください' as const,
  SELECT_THE_REASON_FOR_STOP: '停止理由を選択してください' as const
};
const operationTypes = {
  MINUTES_DESIGNATED: '停止時間を指定する' as const,
  UNTIL_NEXT_OPENING: '次の営業時間まで停止' as const
};

export const CreateOrderPlatformSuspensionsScreen = (
  { route, navigation }: StackScreenProps<Route, 'CreateOrderPlatformSuspensions'>
): React.ReactElement => {
  const { platforms } = route.params;
  const { store } = useAppContext();
  const { now } = usePeriodicUpdater(120 * 1000);
  const [screenState, setScreenState] = useState<keyof typeof screenStates>('SPECIFY_STOP_TIME');
  const [operationType, setOperationType] = useState<keyof typeof operationTypes>('MINUTES_DESIGNATED');
  const [suspensionReason, setSuspensionReason] = useState<SuspensionReason>({ type: 'closed_earlier_than_planned' });
  const [minutes, setMinutes] = useState<SuspensionMinute>(5);

  const [orderPlatformSuspensions, loadingOrderPlatformSuspensions, errorOrderPlatformSuspension] =
    useOrderPlatformSuspensions(store && { accountId: store.accountId, storeId: store.id, now: new Date(now) });
  const { call, loading, error } = useCreateOrderPlatformSuspension();

  const confirmedMinutes = operationType === 'UNTIL_NEXT_OPENING' ? 0 : minutes;

  const onPressNextButton = useCallback(() => setScreenState('SELECT_THE_REASON_FOR_STOP'), []);

  const onSubmit = useCallback(() => {
    const targetPlatforms = platforms
      .filter(platform => !orderPlatformSuspensions
        ?.some(suspension => (suspension?.brandId === platform.brandId) && (suspension.platformType === platform.type))
      );

    return Promise.allSettled(targetPlatforms.map(platform => call({
      orderPlatformRelation: platformToRelation(platform),
      minutes: confirmedMinutes,
      suspensionReason
    })))
      .then(results => {
        const hasError = results.some(result => result.status === 'rejected');
        if (hasError) return;
        return navigation.navigate('OrderPlatformSuspensions');
      });
  }, [call, confirmedMinutes, navigation, orderPlatformSuspensions, platforms, suspensionReason]);

  const Header = <>
    <AppText style={styles.title}>{screenStates[screenState] ?? screenStates['SPECIFY_STOP_TIME']}</AppText>
    <View style={styles.divider}/>
  </>;

  const Body = screenState === 'SPECIFY_STOP_TIME'
    ? <View style={styles.specifyStopTimeBody}>
      {Object.keys(operationTypes).map(operationTypeKey => <Radio
        key={`create-order-platform-suspension-operation-${operationTypeKey}`}
        text={operationTypes[operationTypeKey as keyof typeof operationTypes]}
        selected={operationType === operationTypeKey}
        size='extraLarge'
        outline
        onPress={() => setOperationType(operationTypeKey as keyof typeof operationTypes) /* eslint-disable-line react/jsx-no-bind, max-len */}>
        <If condition={operationTypeKey === 'MINUTES_DESIGNATED'}>
          <Picker
            key={'create-order-platform-suspension-picker'}
            enabled={operationTypeKey === 'MINUTES_DESIGNATED'}
            style={styles.timePicker}
            itemStyle={styles.pickerItem}
            selectedValue={minutes.toString()}
            onValueChange={value => setMinutes(Number(value) as CookingTime) /* eslint-disable-line react/jsx-no-bind, max-len */}>
            {SUSPENSION_MINUTES.map(minute => <Picker.Item
              key={`create-order-platform-suspension-picker-item-${minute}`}
              label={`${minute}分`}
              value={minute.toString()} />
            )}
          </Picker>
        </If>
      </Radio>
      )}
    </View>
    : <View style={styles.selectTheReasonForStopBody}>
      {
        SuspensionReasonTypes.map(suspensionReasonType => <Radio
          key={`platforms-bulk-update-receiption-${suspensionReasonType}`}
          text={suspensionReasonNames[suspensionReasonType]}
          selected={suspensionReason?.type === suspensionReasonType}
          size='extraLarge'
          outline
          onPress={() => setSuspensionReason({ type: suspensionReasonType } as SuspensionReason) /* eslint-disable-line react/jsx-no-bind, max-len */}>
          <If condition={suspensionReasonType === 'other'}>
            <View style={styles.inputView}>
              <Input
                theme='light'
                value={suspensionReason.type === 'other' ? suspensionReason.note : ''}
                placeholder='停止理由は必ず記入してください'
                onChange={(value) => setSuspensionReason({ type: 'other', note: value }) /* eslint-disable-line react/jsx-no-bind, max-len */}
                editable={suspensionReason.type === 'other'}
                error={{
                  condition: suspensionReason.type === 'other' && !suspensionReason.note,
                  message: '停止理由は必ず記入してください'
                }} />
            </View>
          </If>
        </Radio>)
      }
    </View>;

  const Footer = screenState === 'SPECIFY_STOP_TIME'
    ? <View style={styles.nextButtonArea}>
      <PrimaryButton onPress={onPressNextButton} title={'次へ'} size='large' />
    </View>
    : <View style={styles.confirmButtonArea}>
      <PrimaryButton disabled={loading} onPress={onSubmit} title={'確定'} size='large' />
    </View>;

  return <WithModal {...{
    Header,
    Body,
    Footer,
    loading: loading || loadingOrderPlatformSuspensions,
    error: error || errorOrderPlatformSuspension
  }} />;
};

const styles = StyleSheet.create({
  title: {
    height: 70,
    paddingHorizontal: 40,
    fontSize: 18,
    lineHeight: 70,
    textAlign: 'center'
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: Color.gray10,
  },
  specifyStopTimeBody: {
    alignSelf: 'center',
    paddingTop: 40,
    paddingHorizontal: 40,
    paddingBottom: 22
  },
  selectTheReasonForStopBody: {
    alignSelf: 'center',
    paddingTop: 40,
    paddingHorizontal: 40
  },
  timePicker: {
    color: Color.gray100,
    fontSize: 14,
    fontWeight: '400',
    width: 376
  },
  pickerItem: {
    height: 96,
    color: Color.gray100,
    fontSize: 14,
    fontWeight: '400',
  },
  inputView: {
    marginTop: 16,
  },
  nextButtonArea: {
    alignItems: 'center',
    paddingBottom: 40
  },
  confirmButtonArea: {
    alignItems: 'center',
    paddingTop: 14,
    paddingBottom: 18
  }
});
