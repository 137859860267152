import { Audio } from 'expo-av';
import { useCallback, useEffect, useState } from 'react';

type Response = [
  () => Promise<void>,
  () => Promise<void>,
  boolean,
  Error | undefined
];

const soundTypes = ['new', 'cancel'] as const;
type SoundType = typeof soundTypes[number];

const sounds = {
  new: require('../../assets/sound/new.mp3'),
  cancel: require('../../assets/sound/cancel.mp3')
} as const;

const soundObjects = {
  new: new Audio.Sound(),
  cancel: new Audio.Sound()
};

export const usePlayer = (soundType: SoundType): Response => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);

  const soundObject = soundObjects[soundType];

  useEffect(() => {
    loadSoundIfNeeded();
  }, []);

  const loadSoundIfNeeded = useCallback(() =>
    soundObject.getStatusAsync()
      .then(status => status.isLoaded
        ? Promise.resolve()
        : soundObject.loadAsync(sounds[soundType], { isLooping: soundType === 'new' }).then<void>()
      )
      .then(() => setLoaded(true))
      .catch(setError)
  , [soundObject, soundType]);

  const play = useCallback(() =>
    loadSoundIfNeeded()
      .then(() => soundType === 'new' ? soundObject.playAsync().then<void>() : soundObject.replayAsync().then<void>())
      .catch(setError)
  , [loadSoundIfNeeded, soundObject, soundType]);

  const stop = useCallback(() =>
    loadSoundIfNeeded()
      .then(() => soundObject.stopAsync().then<void>())
      .catch(setError)
  , [loadSoundIfNeeded, soundObject]);

  return [play, stop, loaded, error];
};
