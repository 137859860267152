import deepEqual from 'fast-deep-equal';
import { logger } from '../../infrastructure';

export const equalProps = (
  debug = false,
  componentName = 'UnknownComponent'
) => <T extends Record<string, unknown>>(prev: T, next: T): boolean => {
  if (debug)
    logger.info(
      `${componentName} ${deepEqual(prev, next) ? 'render() is ignored' : 'render() is performed' }`,
      {
        keys: Object.entries(prev)
          .reduce((acc: string[], [key, value]) => deepEqual(value, (next)[key]) ? acc : [...acc, key], [])
      }
    );

  return deepEqual(prev, next);
};
