import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import {
  Delivery,
  DeliveryEstimation,
  FulfillmentDetailType,
  getOrderFulfillmentDetail,
  getOrderPreparationTime,
  Order
} from '../../../../domain';
import { Color } from '../../../constants';
import { Formatter } from '../../../utils';
import { AppText } from '../../AppText';
import { If } from '../../If';

interface Props {
  order: Order
  delivery: Delivery | undefined
  deliveryEstimation: DeliveryEstimation | undefined
  size: 'small' | 'medium'
}

interface Data {
  text: string
  style: 'text' | 'number'
  color?: 'normal' | 'error'
}

export const FulfillmentView = ({ order, delivery, deliveryEstimation, size }: Props): React.ReactElement => {
  const data = buildData(order, delivery, deliveryEstimation);

  return <ViewComponent {...{ order, data, size }}/>;
};

interface ViewProps {
  order: Order
  data: Data[]
  size: 'small' | 'medium'
}

const ViewComponent = ({ order, data, size }: ViewProps) => {
  const styles = useMemo(() => createStyles(size), [size]);
  const isDelivery = getOrderFulfillmentDetail(order) === FulfillmentDetailType.DELIVERY
    || getOrderFulfillmentDetail(order) === FulfillmentDetailType.INHOUSE_DELIVERY;

  return <View style={styles.row}>
    <If condition={isDelivery}>
      <Image source={require('../../../assets/icon/delivery2.png')} resizeMode='contain' style={styles.deliveryIcon}/>
    </If>
    <AppText style={styles.textView}>
      {data.map(({ text, style, color }, i) => (
        <AppText
          key={`fulfillmentView-text-${order.uuid}-${i}`}
          style={[style === 'text' ? styles.text : styles.numberText, color === 'error' ? styles.redText : undefined]}>
          {text}
        </AppText>
      ))}
    </AppText>
  </View>;
};

const buildData = (
  order: Order,
  delivery: Delivery | undefined,
  deliveryEstimation: DeliveryEstimation | undefined
): Data[] => {
  if (order.status === 'CREATED')
    return order.deliveryTimeType.type === 'scheduled'
      ? [
        { text: `${fulfillmentText(order)}予定：`, style: 'text' },
        { text: `${Formatter.dayAgo(order.deliveryTimeType.time?.toDate() ?? order.preparationTime?.toDate())}`, style: 'text' },
        { text: `${Formatter.time(order.deliveryTimeType.time?.toDate() ?? order.preparationTime?.toDate())}`, style: 'number' }
      ]
      : [
        { text: `${fulfillmentText(order)}：`, style: 'text' },
        { text: '調理時間', style: 'text' },
        { text: `${getOrderPreparationTime(order)}`, style: 'number' },
        { text: '分', style: 'text' }
      ];

  if (order.deliveryMethod === 'pick_up')
    return [{ text: 'テイクアウト', style: 'text' }];

  if (order.deliveryMethod === 'eat_in')
    return [{ text: 'イートイン', style: 'text' }];

  if (!delivery || !deliveryEstimation)
    return [];

  switch (delivery.courierType) {
    case 'deaas':
    case 'platform': {
      const remainingMinute = getRemainingMinute(deliveryEstimation.pickupTime);

      return delivery.status === 'created'
        ? [{ text: '配達：配達員を検索中', style: 'text' }]
        : remainingMinute <= 1
          ? [{ text: '配達：まもなく到着', style: 'text' }]
          : [
            { text: '配達：', style: 'text' },
            { text: `${remainingMinute}`, style: 'number' },
            { text: '分', style: 'text' }
          ];
    }
    case 'inhouse': {
      const remainingMinute = getRemainingMinute(deliveryEstimation.deliveryTime ?? order.preparationTime);
      const isOverdue = remainingMinute < 1;

      return [
        { text: '店舗スタッフ配達：', style: 'text' },
        { text: `${Math.abs(remainingMinute)}`, style: 'number', color: isOverdue ? 'error' : 'normal' },
        { text: '分', style: 'text', color: isOverdue ? 'error' : 'normal' },
        ...(isOverdue ? [{ text: '遅れ', style: 'text', color: 'error' }] as const : [])
      ];
    }
  }
};

const fulfillmentText = (order: Order): string => {
  switch (getOrderFulfillmentDetail(order)) {
    case FulfillmentDetailType.DELIVERY: return '配達';
    case FulfillmentDetailType.EATIN: return 'イートイン';
    case FulfillmentDetailType.PICKUP: return 'テイクアウト';
    case FulfillmentDetailType.INHOUSE_DELIVERY: return '店舗スタッフ配達';
  }
};

const getRemainingMinute = (date: Timestamp): number =>
  dayjs(date.toDate()).diff(dayjs(), 'minute');

const createStyles = (size: Props['size']) =>
  StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    textView: {
      lineHeight: size === 'small' ? 25 : 27
    },
    text: {
      color: Color.gray100,
      fontSize: size === 'small' ? 14 : 16,
      fontWeight: '500',
    },
    numberText: {
      color: Color.gray100,
      fontSize: size === 'small' ? 20 : 22,
      fontWeight: '500',
    },
    redText: {
      color: Color.red
    },
    deliveryIcon: {
      width: size === 'small' ? 20 : 24,
      height: size === 'small' ? 20 : 24,
      marginRight: 5,
    },
  });
