import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NavigationBar, WithScreen } from '../../components';
import { Color, Route } from '../../constants';
import { SettingsView } from './SettingsView';

export const SettingsScreen = ({ navigation }: StackScreenProps<Route, 'Settings'>): React.ReactElement => {
  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressClose = useCallback(() => {
    navigation.popToTop();
    navigation.goBack();
  }, [navigation]);

  return <WithScreen loading={false} error={undefined}>
    <SafeAreaView style={styles.screen}>
      <NavigationBar title="設定" isForMenu={true} onPressBack={onPressBack} onPressClose={onPressClose}/>
      <SettingsView />
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
});
