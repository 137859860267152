import React, { useMemo } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { If } from '../If';

interface Props {
  value: string;
  placeholder: string;
  theme: 'dark' | 'light'
  onChange: (value: string) => void;

  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  editable?: boolean
  secureTextEntry?: boolean;
  error?: { condition: boolean, message: string };
}

export const Input = ({ onChange, ...props }: Props): React.ReactElement => {
  const styles = useMemo(() => props.theme === 'dark' ? dark : light, [props.theme]);

  return <>
    <View style={props.error?.condition ? { ...styles.view, ...styles.errorView } : styles.view}>
      <TextInput
        value={props.value}
        secureTextEntry={props.secureTextEntry}
        placeholder={props.placeholder}
        placeholderTextColor={styles.placeHolderText.color}
        autoCapitalize={props.autoCapitalize}
        editable={props.editable}
        onChangeText={onChange}
        style={styles.text} />
    </View>
    <If condition={props.error?.condition}>
      <AppText style={styles.errorText}>{props.error?.message}</AppText>
    </If>
  </>;
};

const dark = StyleSheet.create({
  view: {
    width: 336,
    height: 60,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: Color.gray80,
    backgroundColor: Color.gray90,
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorView: {
    borderColor: Color.red300
  },
  text: {
    width: '100%',
    height: 48,
    fontSize: 16,
    color: Color.white,
    paddingHorizontal: 16,
    paddingVertical: 0,
    fontFamily: 'NotoSansJP_400Regular',
  },
  placeHolderText: {
    color: Color.gray50
  },
  errorText: {
    color: Color.red500,
    fontFamily: 'NotoSansJP_400Regular',
    fontSize: 14,
    marginTop: 10
  },
});

const light = StyleSheet.create({
  view: {
    width: 376,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: Color.gray5,
    backgroundColor: Color.white,
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorView: {
    borderColor: Color.red300
  },
  text: {
    width: '100%',
    height: 48,
    fontSize: 16,
    color: Color.gray100,
    paddingHorizontal: 16,
    paddingVertical: 0,
    fontFamily: 'NotoSansJP_400Regular',
  },
  placeHolderText: {
    color: Color.gray30
  },
  errorText: {
    color: Color.red500,
    fontFamily: 'NotoSansJP_400Regular',
    fontSize: 14,
    marginTop: 2,
  },
});
