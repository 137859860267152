export enum Status {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  READY = 'READY',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  UNKNOWN = 'UNKNOWN',
}
