import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { deliveryToRelation, orderToRelation } from '../../domain';
import { NavigationBar, Support, SupportList, WithModal } from '../components';
import { Route } from '../constants';
import { useAppContext, useDeliveries, useDeliveryEstimations } from '../hooks';

export const SupportScreen = (
  { navigation, route }: StackScreenProps<Route, 'Support'>
): React.ReactElement => {
  const { integrations } = useAppContext();
  const { order, onComplete } = route.params;
  const [deliveries, loadingDeliveries] = useDeliveries(orderToRelation(order));
  const [deliveryEstimations, loadingDeliveryEstimations] = useDeliveryEstimations(
    deliveries?.[0]
      ? deliveryToRelation(deliveries[0])
      : undefined
  );
  const delivery = deliveries?.[0];
  const deliveryEstimation = deliveryEstimations?.[0];
  const orderWithDelivery = { order, delivery, deliveryEstimation };

  const onPressSupport = useCallback((support: Support) => {
    switch (support) {
      case Support.DELAY:
        navigation.goBack();
        navigation.navigate('Delay', { order, onComplete });
        break;
      case Support.CONTACT:
        navigation.goBack();
        navigation.navigate('Contact', { order, onComplete });
        break;
      case Support.CANCEL:
        navigation.goBack();
        navigation.navigate('Cancel', { order, onComplete });
        break;
    }
  }, [navigation, onComplete, order]);

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const Header = <NavigationBar title="サポート" isForMenu={true} onPressBack={onPressBack}/>;
  const Body = <View style={styles.body}>
    <SupportList {...{ orderWithDelivery, integrations, onPressSupport }}/>
  </View>;

  return <WithModal {...{ Header, Body, loading: loadingDeliveries || loadingDeliveryEstimations, error: undefined }}/>;
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    borderRadius: 12
  }
});
