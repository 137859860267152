import {
  DiscoveredPrinter,
  Ditherings,
  getPrinterPortName,
  Printer as NativePrinter,
  PrinterModels,
  PrinterPortTypes
} from '@orderlyjp/react-native-sii';
import { Printer } from './';

export class SiiPrinter implements Printer {
  readonly identifier: string;
  readonly maker = 'Sii' as const
  readonly modelName: string;
  readonly supportedImageWidth: number = 576;
  readonly shouldPrintUpSideDown = true;
  readonly shouldSelectPrinterModelManually = false;

  private printer: NativePrinter | null = null;
  private readonly printerModel: PrinterModels;
  private readonly portType: PrinterPortTypes;
  private readonly ipAddress: string | undefined;
  private readonly macAddress: string | undefined;
  private readonly bluetoothAddress: string | undefined;

  static load(dictionary: { [key: string]: unknown }): Printer | null {
    if (!dictionary) return null;
    if (dictionary.device_type !== 'sii') return null;

    const { printerModel, portType, name, ipAddress, macAddress, bluetoothAddress } = dictionary;

    return new SiiPrinter(
      {
        portType: portType as PrinterPortTypes,
        name: name as string,
        ipAddress: ipAddress as string | null,
        macAddress: macAddress as string | null,
        bluetoothAddress: bluetoothAddress as string | null
      },
      printerModel as PrinterModels,
    );
  }

  constructor(printer: DiscoveredPrinter, printerModel: PrinterModels) {
    this.printerModel = printerModel;
    this.portType = printer.portType;
    this.ipAddress = printer.ipAddress ?? undefined;
    this.macAddress = printer.macAddress ?? undefined;
    this.bluetoothAddress = printer.bluetoothAddress ?? undefined;
    this.modelName = printer.name ?? '';
    this.identifier = [
      getPrinterPortName(printer.portType),
      [printer.ipAddress, printer.bluetoothAddress].filter((v) => !!v).join(', ')
    ].filter((v) => !!v).join(' - ');
  }

  print(image: string): Promise<void> {
    return new NativePrinter(this.portType, this.printerModel, this.modelName, this.ipAddress, this.bluetoothAddress)
      .printImage(image, Ditherings.DISABLE);
  }

  dispose(): Promise<void> {
    return this.printer?.dispose() ?? Promise.resolve();
  }

  toJSON(): { [key: string]: unknown; } {
    return {
      device_type: 'sii',
      printerModel: this.printerModel,
      portType: this.portType,
      name: this.modelName,
      ipAddress: this.ipAddress,
      macAddress: this.macAddress,
      bluetoothAddress: this.bluetoothAddress,
    };
  }

  isEqual(other: unknown): boolean {
    return other instanceof SiiPrinter
      && this.portType === other.portType
      && this.modelName === other.modelName
      && this.ipAddress === other.ipAddress
      && this.macAddress === other.macAddress
      && this.bluetoothAddress === other.bluetoothAddress;
  }
}
