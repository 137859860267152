import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface Props {
  title: string;
  size: 'large' | 'medium'
  disabled?: boolean;
  color?: string;
  onPress: () => void;
}

export const PrimaryButton = (props: Props): React.ReactElement => {
  const color = () => {
    if (props.disabled) {
      return Color.gray20;
    } else {
      return props.color ? props.color : Color.brand;
    }
  };

  const styles = useMemo(() => props.size === 'large'? large : medium, [props.size]);

  return <Clickable name="button_tap" id={props.title} onPress={props.onPress} disabled={props.disabled}>
    <View style={[styles.view, { backgroundColor: color() }]}>
      <AppText style={styles.title}>{props.title}</AppText>
    </View>
  </Clickable>;
};

const large = StyleSheet.create({
  view: {
    width: 296,
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  title: {
    fontSize: 18,
    color: Color.white,
  },
});

const medium = StyleSheet.create({
  view: {
    width: 62,
    height: 36,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  title: {
    fontSize: 14,
    color: Color.white,
  },
});
