import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Integration, PlatformType } from '../../../../domain';
import { AppText, Clickable, If, } from '../../../components';
import { Color } from '../../../constants';
import { PlatformTypeDecorator } from '../../../decorators';
import { useAppContext } from '../../../hooks';

export interface HeaderViewProps {
  platformTypes: PlatformType[];
  selectedPlatform?: PlatformType;
  selectedSection: 'items' | 'options';
  isSearching: boolean;
  onPressSingle?: () => void;
  onPressOption?: () => void;
  onPressPlatform: (platformType: PlatformType) => void;
}

export const HeaderView = (props: HeaderViewProps): React.ReactElement => {
  const { integrations } = useAppContext();
  const { platformTypes, isSearching, selectedPlatform, selectedSection } = props;
  const { onPressSingle, onPressOption, onPressPlatform } = props;

  const isActive = (platformType: PlatformType, index: number) =>
    selectedPlatform ? platformType === selectedPlatform : index === 0;

  return <View style={styles.view}>
    <If condition={!isSearching}>
      <View style={styles.bar}>
        <View style={styles.platformList}>
          {platformTypes?.map((platformType, index) =>
            <PlatformItem
              key={`HeaderView-PlatformItem-${platformType}`}
              {...{ platformType, isActive: isActive(platformType, index), integrations, onPressPlatform }}/>
          )}
        </View>
        <View style={styles.buttons}>
          <Clickable
            name="sold_settngs_tab"
            id="item"
            style={selectedSection === 'items' ? [styles.item, styles.itemSelected] : styles.item}
            onPress={onPressSingle}>
            <AppText style={selectedSection === 'items' ? styles.titleActive : styles.titleInactive}>商品</AppText>
          </Clickable>
          <Clickable
            name="sold_settngs_tab"
            id="option"
            style={selectedSection === 'options' ? [styles.item, styles.itemSelected] : styles.item}
            onPress={onPressOption}>
            <AppText style={selectedSection === 'options' ? styles.titleActive : styles.titleInactive}>オプション</AppText>
          </Clickable>
        </View>
      </View>
    </If>
  </View>;
};

type PlatformItemProps = {
  platformType: PlatformType
  isActive: boolean
  integrations: Integration[] | undefined

  onPressPlatform: (platformType: PlatformType) => void
};

const PlatformItem = ({ platformType, isActive, integrations, onPressPlatform }: PlatformItemProps) => {
  const decorator = new PlatformTypeDecorator(platformType, integrations);
  const onPress = useCallback(() => {
    onPressPlatform?.(platformType);
  }, [onPressPlatform, platformType]);

  return <Clickable
    key={`platformItem_${platformType}`}
    name="sold_platform_tab"
    id={platformType}
    onPress={onPress}
    style={[styles.platformItem, isActive && styles.platformItemActive]}>
    <Image source={decorator.icon()} resizeMode={'contain'} style={styles.platformImage}/>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    display: 'flex',
    minHeight: 10,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 2,
  },
  bar: {
    display: 'flex',
    height: 73,
    paddingHorizontal: 56,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Color.white,
  },
  platformList: {
    display: 'flex',
    height: 74,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  platformItem: {
    height: 74,
    width: 74,
    paddingHorizontal: 18,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
  platformItemActive: {
    borderBottomWidth: 3,
    borderBottomColor: Color.dark,
  },
  platformImage: {
    height: 36,
    width: 36,
    marginTop: 22,
    borderRadius: 18,
  },
  buttons: {
    display: 'flex',
    width: 256,
    height: 32,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  titleActive: {
    fontSize: 17,
    fontWeight: '500',
    color: Color.gray100,
  },
  titleInactive: {
    fontSize: 17,
    color: Color.gray50,
  },
  item: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 128,
    height: 40,
    paddingTop: 2,
    borderRadius: 6,
  },
  itemSelected: {
    backgroundColor: Color.gray5,
  },
});
