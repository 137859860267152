import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Order, OrderWithDelivery } from '../../domain';
import { Environment } from '../../Environment';
import { AppText, If, OrderDetailView, Clickable, WithScreen, } from '../components';
import { Color, Route } from '../constants';
import { useAppContext, useEventBus, useOrderOperation, useOrderUpdate } from '../hooks';

export const OrderDetailScreen = ({ navigation, route }: StackScreenProps<Route, 'OrderDetail'>): React.ReactElement => {
  const { orderId, order, title, shouldNotUpdateOrderRead } = route.params;

  const { brands, platforms, activeOrders, connectedPrinters } = useAppContext();
  const [dispatch] = useEventBus();
  const { call: callOrderOperation, loading: loadingOrderOperation, error: errorOrderOperation }  = useOrderOperation();
  const { update: orderUpdate } = useOrderUpdate();

  useEffect(() => {
    const order = activeOrders?.find(order => order.id === orderId);

    if (shouldNotUpdateOrderRead) return;
    if (!order) return;
    if (order.isRead) return;

    orderUpdate({ ...order, isRead: true });
  }, [orderId, shouldNotUpdateOrderRead, activeOrders, orderUpdate]);

  const printOrder = useCallback((orderWithDelivery: OrderWithDelivery) => {
    dispatch({ type: 'order_receipt_requested', value: { receiptType: 'OrderReceipt', orderWithDelivery } });
  }, [dispatch]);

  const onPressConfirm = useCallback((order: Order) => {
    const operation = (() => {
      switch (order.status) {
        case 'CREATED': return { type: 'ACCEPT' as const, cookingTime: null };
        case 'ACCEPTED': return { type: 'READY' as const };
        case 'READY': return { type: 'COMPLETE' as const };
      }
    })();

    if (!operation) return;

    const { acceptanceMethod } = platforms?.find(platform => platform.type === order.platform) ?? {};

    if (operation.type === 'ACCEPT' && !acceptanceMethod?.automated && acceptanceMethod?.cookingTimeCalculation.type === 'manual') {
      navigation.navigate('AcceptWithCookingTime', { order });
      return;
    }

    return callOrderOperation({ operation, orderId: order.id })
      .then(() => navigation.goBack());
  }, [callOrderOperation, navigation, platforms]);

  const onPressSupport = useCallback((order: Order) => {
    navigation.navigate('Support', { order, onComplete: () => navigation.goBack() });
  }, [navigation]);

  const onPressClose = useCallback(() => navigation.goBack(), [navigation]);

  const onPressPrint = useCallback((orderWithDelivery: OrderWithDelivery) => {
    Environment.platform.type === 'expo-go'
      ? connectedPrinters.some(printer => printer.maker === 'Orderly')
        ? printOrder(orderWithDelivery)
        : navigation.navigate('ReceiptPreview', {
          orderWithDelivery,
          brand: brands?.find((brand) => brand.id == orderWithDelivery.order.brandId)
        })
      : printOrder(orderWithDelivery);
  }, [brands, connectedPrinters, navigation, printOrder]);

  return <WithScreen loading={loadingOrderOperation} error={errorOrderOperation}>
    <View style={styles.screen}>
      <View style={styles.header}>
        <If condition={title}><AppText style={styles.headerText}>{title}</AppText></If>
        <Clickable name='order_close' style={styles.close} onPress={onPressClose}>
          <Image source={require('../assets/icon/close_black.png')} style={styles.closeIcon} resizeMode="contain"/>
        </Clickable>
      </View>
      <OrderDetailView {...{ orderId, order, onPressConfirm, onPressSupport, onPressPrint }} />
    </View>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    marginTop: 24,
    backgroundColor: Color.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  header: {
    flexDirection: 'row',
    minHeight: 64,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    minWidth: 120,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    right: 0,
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
});
