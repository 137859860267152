import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';

export const PrinterListEmptyItem = (): React.ReactElement =>
  <View style={styles.view}>
    <AppText style={styles.text}>なし</AppText>
  </View>;

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 104,
    paddingHorizontal: 44,
    paddingVertical: 40,
    backgroundColor: Color.white,
    borderWidth: 1,
    borderColor: Color.gray10,
    borderRadius: 12,
  },
  text: {
    fontSize: 18,
    color: Color.gray100,
  },
});
