import React, { useCallback } from 'react';
import { Image, Modal, StyleSheet, View } from 'react-native';
import { OrdersNotification } from '../../../domain';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface Props {
  notification: OrdersNotification
  onPress: (notification: OrdersNotification) => void;
  onClose: (notification: OrdersNotification) => void;
}

export const NotificationView = ({ notification, ...props }: Props): React.ReactElement => {
  const onPress = useCallback(() => { props.onPress(notification); }, [notification, props]);
  const onClose = useCallback(() => { props.onClose(notification); }, [notification, props]);

  return <Modal animationType='none' visible transparent style={{ width: '100%', height: '100%' }}>
    <Clickable
      name='notification_tap'
      style={[styles.screen, { backgroundColor: notification.color }]}
      onPress={onPress}>
      <AppText style={styles.title}>{notification.message}</AppText>
      <View style={styles.circle}>
        <AppText style={styles.count}>{notification.count}</AppText>
      </View>
      <AppText style={styles.description}>タップして注文内容を確認</AppText>
    </Clickable>
    <Clickable name="cancel_bar_close_tap" style={styles.closeView} onPress={onClose}>
      <Image style={styles.closeIcon} source={require('../../assets/icon/close_white.png')} />
    </Clickable>
  </Modal>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    fontSize: 56,
    fontWeight: 'bold',
    color: Color.white,
    textAlign: 'center',
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 300,
    height: 300,
    borderRadius: 150,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    marginVertical: 56,
  },
  count: {
    lineHeight: 300,
    fontSize: 120,
    fontWeight: 'bold',
    color: Color.white,
    textAlign: 'center',
  },
  description: {
    fontSize: 32,
    fontWeight: 'bold',
    color: Color.white,
    textAlign: 'center',
  },
  closeView: {
    position: 'absolute',
    top: 32,
    end: 12,
    width: 104,
    height: 104,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 32,
    height: 32,
  },
});
