import { useNavigation } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View
} from 'react-native';
import { Color } from '../../constants';
import { useErrorHandler } from '../../hooks';
import { AppText } from '../AppText';
import { If } from '../If';
import { Clickable } from '../Clickable';

type Props = {
  Header?: React.ReactNode
  Body: React.ReactNode
  Footer?: React.ReactNode

  loading: boolean
  error: Error | undefined
};

export const WithModal = ({ Header, Body, Footer, loading, error }: Props): React.ReactElement => {
  const { height: screenHeight } = useWindowDimensions();
  const navigation = useNavigation();
  const [shouldBeFull, setShouldBeFull] = useState(false);
  const [layouted, setLayouted] = useState(false);

  const modalRef = useRef<View>(null);

  useErrorHandler(error);

  const onLayout = useCallback((event: LayoutChangeEvent)  => {
    const { height, width } = event.nativeEvent.layout;
    if (!width || !height || !screenHeight || shouldBeFull) return;

    const isExceeded = height + styles.modalFull.marginVertical * 2 > screenHeight;

    setShouldBeFull(isExceeded);
    setLayouted(true);
  }, [screenHeight, shouldBeFull]);

  const onPress = useCallback(() => navigation.goBack(), [navigation]);

  return <View style={[styles.wrapper, shouldBeFull ? undefined : styles.wrapperContain]}>
    <StatusBar style="light" />
    <View
      style={{
        ...styles.modal,
        ...(shouldBeFull ? styles.modalFull : {}),
        opacity: layouted ? 1 : 0,
        height: shouldBeFull ? screenHeight - styles.modalFull.marginVertical * 2 : undefined
      }}
      ref={modalRef}
      onLayout={onLayout}>
      <If condition={Header}>
        <View style={styles.headerWrapper}>
          {Header}
        </View>
      </If>
      <If condition={shouldBeFull}>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1, overflow: 'hidden' }}>
          {Body}
        </ScrollView>
      </If>
      <If condition={!shouldBeFull}>
        <View>{Body}</View>
      </If>
      <If condition={Footer}>
        <View style={styles.footerWrapper}>
          {Footer}
        </View>
      </If>
      <View style={styles.close}>
        <Clickable
          name="modal_close"
          style={styles.closeButton}
          onPress={onPress}
          useGestureHandlerTouchableOpacity={true}>
          <Image source={require('../../assets/icon/close_white.png')} style={styles.closeIcon} resizeMode="contain"/>
          <AppText style={styles.closeText}>閉じる</AppText>
        </Clickable>
      </View>
    </View>
    <If condition={loading}>
      <View style={styles.indicatorWrapper}>
        <ActivityIndicator style={styles.indicator} size="large" color='#999999' animating={true} hidesWhenStopped />
      </View>
    </If>
  </View>;
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  wrapperContain: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicatorWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  indicator: {
    top: '50%',
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: 12,
  },
  modalFull: {
    marginVertical: 24,
    marginHorizontal: 104,
  },
  headerWrapper: {
    height: 70
  },
  footerWrapper: {
    height: 88
  },
  close: {
    display: 'flex',
    backgroundColor: Color.gray50,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    position: 'absolute',
    top: 24,
    right: -74,
  },
  closeButton: {
    width: 74,
    height: 74,
    justifyContent: 'center',
    alignItems: 'center'
  },
  closeIcon: {
    width: 24,
    height: 24,
    marginBottom: 4,
  },
  closeText: {
    color: 'white',
    fontSize: 14,
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: Color.gray10,
  }
});
