import { Printer } from '../../domain';
import { Environment } from '../../Environment';
import { Storage } from '../Storage';

export class ConnectedPrinterStore {
  private storage = new Storage<Record<string, unknown>[]>();

  save(printers: Printer[]): Promise<void> {
    return this.storage.save('@SELECTED_PRINTERS', printers.map(printer => printer.toJSON()));
  }

  load(): Promise<Printer[]> {
    if (!Environment.isNative)
      return Promise.resolve([]);

    // TODO: Make polymorphic
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { EposPrinter } = require('../../domain/entities/Printer/EposPrinter');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { SiiPrinter } = require('../../domain/entities/Printer/SiiPrinter');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { StarPrinter } = require('../../domain/entities/Printer/StarPrinter');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { CamerarollPrinter } = require('../../domain/entities/Printer/CamerarollPrinter');

    const loadFuncs = [StarPrinter.load, EposPrinter.load, SiiPrinter.load, CamerarollPrinter.load];
    return this.storage.get('@SELECTED_PRINTERS')
      .then(records => records?.flatMap(record => loadFuncs.map(f => f(record))).filter(v => v) ?? []);
  }
}
