import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { getOrderPlatformTypeToDisplay, orderToRelation } from '../../domain';
import { AppText, NavigationBar, PhoneNumberView, WithModal } from '../components';
import { Color, Route } from '../constants';
import { PlatformTypeDecorator } from '../decorators';
import { useAppContext, useDeliveries } from '../hooks';

export const ContactScreen = (
  { navigation, route }: StackScreenProps<Route, 'Contact'>
): React.ReactElement => {
  const { integrations } = useAppContext();
  const { order, onComplete } = route.params;
  const [deliveries, loadingDeliveries] = useDeliveries(orderToRelation(order));
  const delivery = deliveries?.[0];

  const platformDecorator = new PlatformTypeDecorator(getOrderPlatformTypeToDisplay(order), integrations);
  const phoneNumber = platformDecorator.phoneNumber();
  const supportMessage = platformDecorator.supportMessage();

  const onPressBack = useCallback(() => {
    navigation.goBack();
    navigation.navigate('Support', { order, onComplete });
  }, [navigation, onComplete, order]);

  const Header = <NavigationBar title="お客様/配達員に連絡" onPressBack={onPressBack} isForMenu={true} />;
  const Body = <View style={styles.body}>
    <View style={[styles.row, styles.rowBorder]}>
      <View style={styles.titleView}>
        <AppText style={styles.title}>お客様に連絡</AppText>
      </View>
      <AppText style={styles.name}>{order.customer.name}</AppText>
      <PhoneNumberView phoneNumber={order.customer.phoneNumber} />
      {order.customer.phonePinCode
        ? <AppText style={styles.phonePinCode}>{`コード: ${order.customer.phonePinCode}`}</AppText>
        : null}
    </View>
    {delivery?.courier
      ? <View style={[styles.row, styles.rowBorder]}>
        <View style={styles.titleView}>
          <AppText style={styles.title}>配達員に連絡</AppText>
        </View>
        <AppText style={styles.name}>{delivery.courier.name}</AppText>
        <PhoneNumberView phoneNumber={delivery.courier.phoneNumber ?? undefined} />
      </View>
      : null}
    <View style={styles.row}>
      <View style={styles.titleView}>
        <AppText style={styles.title}>{platformDecorator.name()}サポートに連絡</AppText>
      </View>
      {phoneNumber && phoneNumber.length
        ? <PhoneNumberView phoneNumber={phoneNumber} />
        : null}
      {supportMessage && supportMessage.length
        ? <AppText style={styles.title}>{platformDecorator.supportMessage()}</AppText>
        : null}
    </View>
  </View>;

  return <WithModal {...{ Header, Body, loading: loadingDeliveries, error: undefined }} />;
};

const styles = StyleSheet.create({
  body: {
    flex: 1
  },
  row: {
    flexDirection: 'column',
    paddingVertical: 40,
    paddingHorizontal: 56,
  },
  rowBorder: {
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
  },
  titleView: {
    marginBottom: 32,
  },
  title: {
    fontSize: 16,
    color: Color.gray100,
  },
  name: {
    fontSize: 16,
    color: Color.gray100,
    marginBottom: 8,
  },
  phonePinCode: {
    fontSize: 16,
    color: Color.gray100,
    marginBottom: 12
  }
});
