import { StackScreenProps } from '@react-navigation/stack';
import React from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { AppText, WithModal } from '../components';
import { Color, Route } from '../constants';

export const PopupNotificationScreen = (
  { route }: StackScreenProps<Route, 'PopupNotification'>
): React.ReactElement => {
  const { notification } = route.params;
  const Body = <View style={styles.body}>
    <ImageBackground source={require('../assets/background/border.png')} resizeMode='cover' style={styles.border} />
    <View style={styles.content}>
      <AppText style={styles.titleText}>{notification.title}</AppText>
      <AppText style={styles.descriptionText}>{notification.description}</AppText>
      {notification.messages.map((message, i) => (
        <View key={`notification-messages-${i}`} style={styles.messageView}>
          <AppText style={styles.messageText}>{message}</AppText>
        </View>
      ))}
    </View>
    <ImageBackground source={require('../assets/background/border.png')} resizeMode='cover' style={styles.border} />
  </View>;

  return <WithModal {...{ Body, loading: false, error: undefined}} />;
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    overflow: 'hidden',
    justifyContent: 'space-between',
    borderRadius: 12
  },
  content: {
    flex: 1,
    padding: 40,
  },
  titleText: {
    marginBottom: 36,
    color: Color.brand,
    fontSize: 28,
    fontWeight: '700',
    textAlign: 'center'
  },
  descriptionText: {
    marginBottom: 24,
    color: Color.brand,
    fontSize: 16,
    fontWeight: '500'
  },
  messageView: {
    marginBottom: 6,
    padding: 16,
    fontSize: 16,
    fontWeight: '700',
    backgroundColor: Color.gray5,
    borderRadius: 4
  },
  messageText: {
    color: Color.brand,
    fontSize: 16,
    fontWeight: '700'
  },
  border: {
    height: 12
  }
});
