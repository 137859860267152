import { BusinessDayTime } from '../../../domain';
import { Callable, useCallable } from '../core/useCallable';

interface Request {
  businessDayTimes: BusinessDayTime[]
}

type Response = void

export const useBusinessDayTimesOperation = (): Callable<Request, Response> =>
  useCallable<Request, Response>('onCallBusinessDayTimesOperation');
