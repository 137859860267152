import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Order } from '../../../../../../domain';
import { Color } from '../../../../../constants';
import { Formatter } from '../../../../../utils';
import { AppText } from '../../../../AppText';
import { If } from '../../../../If';

type Option = Order['items'][number]['options'][number];

type Props = {
  option: Option;
  isHideCategory?: boolean;
  isHideBorder?: boolean;
}

export const OptionView = ({ option, isHideCategory, isHideBorder }: Props): React.ReactElement =>
  <View style={[styles.view, !isHideBorder && styles.border]}>
    <If condition={option.category && !isHideCategory}>
      <AppText style={styles.category}>{option.category}</AppText>
    </If>
    <View style={styles.row}>
      <View style={styles.titleView}>
        <AppText style={[styles.quantity, option.quantity > 1 && styles.multiple]}>
          {option.quantity}
        </AppText>
        <AppText style={[styles.quantityCross, option.quantity > 1 && styles.multiple]}>×</AppText>
        <AppText style={styles.title}>{option.title}</AppText>
      </View>
      <If condition={option.price}>
        <AppText style={styles.price}>{Formatter.price(option.price ?? 0)}</AppText>
      </If>
    </View>
  </View>;

const styles = StyleSheet.create({
  view: {
    display: 'flex',
    paddingTop: 16,
    paddingBottom: 8,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Color.gray10,
  },
  category: {
    marginBottom: 4,
    fontSize: 12,
    color: Color.gray70,
    lineHeight: 14
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 32
  },
  titleView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
    color: Color.gray100,
    marginRight: 8,
  },
  quantity: {
    fontSize: 18,
    fontWeight: '500',
    color: Color.gray100,
    lineHeight: 22
  },
  quantityCross: {
    marginLeft: -2,
    marginRight: 7,
    fontSize: 12,
    fontWeight: '900',
    lineHeight: 15
  },
  multiple: {
    color: Color.red,
  },
  price: {
    textAlign: 'right',
    fontSize: 18,
    fontWeight: '500',
    color: Color.gray100,
    lineHeight: 20
  },
});
