import React, { Ref, useCallback } from 'react';
import { Image, KeyboardType, StyleSheet, TextInput, View } from 'react-native';
import { Color } from '../../constants';
import { Clickable } from '../Clickable';

interface Props {
  value?: string;
  placeholder: string;
  keyboardType?: KeyboardType;
  secureTextEntry?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onPressCrossButton?: () => void;
}

export const Search = React.forwardRef((props: Props, ref: Ref<TextInput>): React.ReactElement => {
  const { onChange, onFocus, onBlur, onPressCrossButton } = props;

  const onPress = useCallback(() => {
    onPressCrossButton?.();
    onChange?.('');
  }, [onChange, onPressCrossButton]);

  return <View style={styles.view}>
    <Image
      source={require('../../assets/icon/search.png')}
      style={styles.icon}
      resizeMode="contain" />
    <TextInput
      ref={ref}
      style={styles.input}
      placeholder={props.placeholder}
      placeholderTextColor={Color.gray30}
      autoCapitalize="none"
      clearButtonMode="never"
      keyboardType={props.keyboardType}
      returnKeyType="done"
      secureTextEntry={props.secureTextEntry}
      selectionColor={Color.gray100}
      value={props.value}
      testID='Search_TextInput'
      onChangeText={onChange}
      onFocus={onFocus}
      onBlur={onBlur} />
    {props.value ? props.value.length > 0 && (
      <Clickable name={'search_cross_button'} style={styles.crossButton} onPress={onPress}>
        <Image
          source={require('../../assets/icon/cross.png')}
          style={styles.crossIcon}
          resizeMode="contain" />
      </Clickable>
    ) : null}
  </View>;
});

Search.displayName = 'Search';

const styles = StyleSheet.create({
  view: {
    position: 'relative',
    height: 60,
    maxWidth: 640,
    flex: 1,
    borderRadius: 6,
    borderWidth: 2,
    borderColor: Color.gray10,
  },
  icon: {
    position: 'absolute',
    top: 16,
    left: 14,
    width: 24,
    height: 24,
  },
  input: {
    height: 56,
    paddingLeft: 46,
    paddingRight: 10,
    fontSize: 17,
    color: Color.gray100,
    fontFamily: 'NotoSansJP_400Regular',
  },
  crossButton: {
    position: 'absolute',
    top: 18,
    right: 12,
    width: 24,
    height: 24,
  },
  crossIcon: {
    width: 18,
    height: 18,
  }
});
