import { createContext } from 'react';
import { Brand, Integration, Order, Platform, Printer, Store } from '../../domain';

export type AppContextProps = {
  logout: () => void;
  store: Store | undefined;
  brands: Brand[] | undefined;
  platforms: Platform[] | undefined;
  integrations: Integration[] | undefined;
  activeOrders: Order[] | undefined;
  connectedPrinters: Printer[];
};

export const AppContext = createContext<AppContextProps>({
  logout: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  store: undefined,
  brands: undefined,
  platforms: undefined,
  integrations: undefined,
  activeOrders: undefined,
  connectedPrinters: []
});
