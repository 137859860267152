import { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { now, setHourAndMinute, stringToDayjs } from '../../utils';
import { PlatformType } from './PlatformType';

export type BusinessDayTimeReference = {
  id: string
  accountId: string
  storeId: string
  brandId: string
}

type HasTimestamp = {
  createTime: Timestamp
  updateTime: Timestamp
}

export type BusinessTime = {
  startTime: string // ex: 09:30
  endTime: string // ex: 12:30
}

export type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
export const DAYS: Day[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

export type SectionType = 'regular' | 'extra'
export const SECTION_TYPE = ['regular', 'extra'];

export type BusinessDayTimeCommon = {
  disabled: boolean
  platformType: PlatformType
  businessTimes: BusinessTime[] // NOTE: length = 0 means holiday.
} & BusinessDayTimeReference

export type RegularBusinessDayTime = {
  type: 'regular'
  sectionId: string | null
  sectionName: string | null
  day: Day
} & BusinessDayTimeCommon

export type ExtraBusinessDayTime = {
  type: 'extra'
  date: string // ex: 2022-04-01
} & BusinessDayTimeCommon

export type BusinessDayTime = RegularBusinessDayTime | ExtraBusinessDayTime;

export type PersistentBusinessDayTime = BusinessDayTime & HasTimestamp

export const isBusinessDayTimeRegular = (businessDayTime: BusinessDayTime): businessDayTime is RegularBusinessDayTime =>
  businessDayTime.type === 'regular';

export const isBusinessDayTimeExtra = (businessDayTime: BusinessDayTime): businessDayTime is ExtraBusinessDayTime =>
  businessDayTime.type === 'extra';

export const isDuringBusinessDayTime = (businessDayTimes: BusinessDayTime[]): boolean => {
  const current = now();

  return getBusinessDayTimeRangesForDays(businessDayTimes, [0, 1], current)
    .some(([start, end]) =>
      start.isSame(current) ||
      end.isSame(current) ||
      (start.isBefore(current) && end.isAfter(current))
    );
};

export const findNextOpeningDayTime = (businessDayTimes: BusinessDayTime[]): Dayjs | null => {
  const current = now();

  return getBusinessDayTimeRangesForDays(businessDayTimes, [0, 1, 2, 3, 4, 5, 6], current)
    .filter(([startTime]) => startTime.isAfter(current))
    .sort(([startTimeA], [startTimeB]) => compareDayjs(startTimeA, startTimeB))[0]?.[0] ?? null;
};

export const findNextClosingDayTime = (businessDayTimes: BusinessDayTime[]): Dayjs | null => {
  const current = now();

  return getBusinessDayTimeRangesForDays(businessDayTimes, [0, 1, 2, 3, 4, 5, 6], current)
    .filter(([, endTime]) => endTime.isAfter(current))
    .sort(([, endTimeA], [, endTimeB]) => compareDayjs(endTimeA, endTimeB))[0]?.[1] ?? null;
};

const getBusinessDayTimeRangesForDays = (businessDayTimes: BusinessDayTime[], days: number[], current: Dayjs) => {
  const extraBusinessDayTimes = businessDayTimes
    .filter(isBusinessDayTimeExtra)
    .filter(businessDayTime => current.format('YYYY-MM-DD') <= businessDayTime.date);

  const regularBusinessDayTimes = businessDayTimes.filter(isBusinessDayTimeRegular);

  return days
    .map(i => current.add(i, 'day'))
    .map(day =>
      extraBusinessDayTimes.find(businessDayTime => businessDayTime.date === day.format('YYYY-MM-DD'))
      ?? regularBusinessDayTimes.find(businessDayTime => businessDayTime.day === DAYS[day.day() % 7])
    )
    .filter((businessDayTime): businessDayTime is BusinessDayTime => !!businessDayTime)
    .filter(businessDayTime => businessDayTime.businessTimes.length > 0)
    .flatMap(businessDayTime => businessDayTimeToDayjsRanges(businessDayTime, current));
};

const businessDayTimeToDayjsRanges = (businessDayTime: BusinessDayTime, current: Dayjs): [Dayjs, Dayjs][] =>
  businessDayTime.businessTimes
    .map(businessTime => businessTimeToDayjsRange(dateOfBusinessDayTime(businessDayTime, current), businessTime));

const businessTimeToDayjsRange = (date: Dayjs, { startTime, endTime }: BusinessTime): [Dayjs, Dayjs] =>
  [
    setHourAndMinute(date, startTime),
    setHourAndMinute(date, endTime)
      .add(setHourAndMinute(date, startTime).isAfter(setHourAndMinute(date, endTime)) ? 1 : 0, 'd')
  ];

const dateOfBusinessDayTime = (businessDayTime: BusinessDayTime, current: Dayjs): Dayjs =>
  isBusinessDayTimeRegular(businessDayTime)
    ? current.day(current.day() > DAYS.indexOf(businessDayTime.day)
      ? DAYS.indexOf(businessDayTime.day) + 7
      : DAYS.indexOf(businessDayTime.day)
    )
    : stringToDayjs(businessDayTime.date);

const compareDayjs = (a: Dayjs, b: Dayjs) => {
  if (a.isSame(b)) return 0;
  return a.isAfter(b) ? 1 : -1;
};
