import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { OrderDispatch } from '../../../domain';

type Params = {
  accountId: string
  storeId: string
  brandId: string
  orderId: string
}

export const useOrderDispatches = (
  params: Params | undefined
): [OrderDispatch[] | undefined, boolean, Error | undefined] =>
  useCollectionData<OrderDispatch>(
    params
    && firebase.firestore()
      .collection('accounts')
      .doc(params.accountId)
      .collection('stores')
      .doc(params.storeId)
      .collection('brands')
      .doc(params.brandId)
      .collection('orders')
      .doc(params.orderId)
      .collection('orderDispatches'),
    { idField: 'id' }
  );
