import { BusinessTime, ExtraBusinessDayTime, PlatformType } from '../../../../../../domain';

export type ExtraBusinessDayTimeFormAction = {
  type: 'addBusinessDayTimeWithNewSection'
  payload: {
    accountId: string
    storeId: string
    brandId: string
    platformType: PlatformType
    date: string
  }
} | {
  type: 'switch'
  payload: {
    sectionIndex: number
    availability: boolean
  }
} | {
  type: 'addBusinessDayTimeToLocalSection'
  payload: {
    sectionIndex: number
    date: string
  }
} | {
  type: 'removeBusinessDayTimeFromLocalSection'
  payload: {
    sectionIndex: number
    date: string
  }
} | {
  type: 'addTimes'
  payload: {
    sectionIndex: number
  }
} | {
  type: 'removeTimes'
  payload: {
    sectionIndex: number
    timeInputsIndex: number
  }
} | {
  type: 'changeTimes'
  payload: {
    sectionIndex: number
    timeInputsIndex: number
    businessTime: BusinessTime
  }
}

export const extraBusinessDayTimeFormReducer = (
  state: [number , ExtraBusinessDayTime[]][],
  action: ExtraBusinessDayTimeFormAction
): [number , ExtraBusinessDayTime[]][] => {
  if (action.type === 'addBusinessDayTimeWithNewSection')
    return [
      ...state, [
        state.length + 1,
        [{
          ...action.payload,
          businessTimes: [],
          disabled: false,
          type: 'extra',
          id: `${action.payload.brandId}_${action.payload.platformType}_${action.payload.date}_noSectionId`
        }]] as [number, ExtraBusinessDayTime[]]
    ].sort((a, b) => a[0] - b[0]);

  const toBeUpdatedSection = state.find(section => section[0] === action.payload.sectionIndex);
  if (!toBeUpdatedSection) throw Error();

  switch (action.type) {
    case 'switch':
      return [
        ...state.filter(section => section[0] !== action.payload.sectionIndex),
        [
          toBeUpdatedSection[0],
          toBeUpdatedSection[1]
            .map(busienssDayTime => ({
              ...busienssDayTime,
              businessTimes: busienssDayTime.businessTimes.length !== 0 && action.payload.availability
                ? busienssDayTime.businessTimes
                : action.payload.availability ? [{ startTime: '', endTime: '' }] : []
            }))
        ] as [number, ExtraBusinessDayTime[]]
      ].sort((a, b) => a[0] - b[0]);
    case 'addBusinessDayTimeToLocalSection': {
      const commonProps = toBeUpdatedSection[1][0];
      if (!commonProps) throw Error('no commonProps');
      return [
        ...state.filter(section => section[0] !== action.payload.sectionIndex),
        [
          toBeUpdatedSection[0],
          toBeUpdatedSection[1]
            .concat({
              ...commonProps,
              date: action.payload.date,
              disabled: false,
              id: `${commonProps.brandId}_${commonProps.platformType}_${action.payload.date}_noSectionId`
            })
            .filter(businessDayTime => !businessDayTime.disabled)
        ] as [number, ExtraBusinessDayTime[]]
      ].sort((a, b) => a[0] - b[0]);
    }
    case 'removeBusinessDayTimeFromLocalSection' : {
      const { payload: { date } } = action;
      const [firstSection, secondSection] = toBeUpdatedSection;
      return [
        ...state.filter(section => section[0] !== action.payload.sectionIndex),
        [
          firstSection,
          secondSection.filter(businessDayTime => (businessDayTime as ExtraBusinessDayTime).date !== date).length === 0
            ? [{
              ...secondSection.find(businessDayTime => (businessDayTime as ExtraBusinessDayTime).date === date),
              disabled: true
            }]
            : secondSection.filter(businessDayTime => (businessDayTime as ExtraBusinessDayTime).date !== date)
        ] as [number, ExtraBusinessDayTime[]]
      ].sort((a, b) => a[0] - b[0]);
    }
    case 'addTimes':
      return [
        ...state.filter(section => section[0] !== action.payload.sectionIndex),
        [
          toBeUpdatedSection[0],
          toBeUpdatedSection[1].map(businessDayTime => ({
            ...businessDayTime,
            businessTimes: [
              ...businessDayTime.businessTimes,
              { startTime: '', endTime: '' }
            ]
          }))
        ] as [number, ExtraBusinessDayTime[]]
      ].sort((a, b) => a[0] - b[0]);
    case 'removeTimes':
      return [
        ...state.filter(section => section[0] !== action.payload.sectionIndex),
        [
          toBeUpdatedSection[0],
          toBeUpdatedSection[1].map(businessDayTime => ({
            ...businessDayTime,
            businessTimes: [
              ...businessDayTime.businessTimes.filter((_, index) => index !== action.payload.timeInputsIndex)
            ]
          }))
        ] as [number, ExtraBusinessDayTime[]]
      ].sort((a, b) => a[0] - b[0]);
    case 'changeTimes':
      return [
        ...state.filter(section => section[0] !== action.payload.sectionIndex),
        [
          toBeUpdatedSection[0],
          toBeUpdatedSection[1].map(businessDayTime => ({
            ...businessDayTime,
            businessTimes: businessDayTime.businessTimes
              .map((businessTime, index) => index === action.payload.timeInputsIndex
                ? action.payload.businessTime
                : businessTime
              )
          }))
        ] as [number, ExtraBusinessDayTime[]]
      ].sort((a, b) => a[0] - b[0]);
    default:
      throw Error();
  }
};
