import React, { ReactElement } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Order } from '../../../../../domain';
import { Color } from '../../../../constants';
import { AppText } from '../../../AppText';
import { If } from '../../../If';

type Props = { order: Order };

export const ShopCommentView = ({ order }: Props): ReactElement =>
  <If condition={(order.comment?.length ?? 0) > 0}>
    <View style={styles.view}>
      <Image source={require('../../../../assets/icon/comment.png')} resizeMode="contain" style={styles.icon}/>
      <AppText style={styles.comment}>お店へのコメント：{order.comment}</AppText>
    </View>
  </If>;

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    marginTop: 8,
    padding: 8,
    backgroundColor: 'rgba(247, 78, 47, 0.05)',
    borderRadius: 4,
    alignItems: 'center',
  },
  icon: {
    width: 28,
    height: 20,
    marginLeft: 1,
    marginRight: 8,
  },
  comment: {
    fontSize: 16,
    color: Color.red,
    paddingRight: 16,
  },
});
