import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { If } from '../If';
import { Clickable } from '../Clickable';

interface Props {
  title?: string;
  onPressBack?: () => void;
  onPressClose?: () => void;
  children?: React.ReactNode;
  border?: boolean;

  isForMenu: boolean
}

export const NavigationBar = ({ border = true, ...props }: Props): React.ReactElement =>
  <View style={[styles.view, border && styles.border]}>
    {props.onPressBack
      ? <Clickable name="navigation_back" style={styles.backView} onPress={props.onPressBack}>
        <Image source={require('../../assets/icon/arrow_left.png')} style={styles.backIcon} resizeMode="contain" />
        <AppText style={styles.title}>{props.title}</AppText>
      </Clickable>
      : <AppText
        style={[props.isForMenu ? { ...styles.normalTitle } : { ...styles.normalTitle, ...styles.withExtraMargin }]}>
        {props.title}
      </AppText>
    }
    {props.children ? props.children : null}
    <If condition={props.onPressClose}>
      <Clickable name="navigation_close" style={styles.closeView} onPress={props.onPressClose}>
        <Image source={require('../../assets/icon/close_black.png')} style={styles.closeIcon} resizeMode="contain" />
      </Clickable>
    </If>
  </View>;

const styles = StyleSheet.create({
  view: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    zIndex: 9999,
  },
  border: {
    borderBottomColor: Color.gray10,
    borderBottomWidth: 2,
  },
  backView: {
    height: 64,
    minWidth: 44,
    minHeight: 44,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 4,
    paddingHorizontal: 8,
  },
  backIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  title: {
    fontSize: 18,
    color: Color.gray100,
  },
  normalTitle: {
    fontSize: 18,
    color: Color.gray100,
    marginLeft: 16,
  },
  withExtraMargin: {
    marginTop: 2,
    marginLeft: 48
  },
  closeView: {
    height: 64,
    minWidth: 44,
    minHeight: 44,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 32,
    marginRight: -2,
    paddingHorizontal: 8,
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
});
