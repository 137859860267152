import React, { useCallback, useRef, useState } from 'react';
import { default as rn, Image, ScrollView, View } from 'react-native';
import { getOrderPlatformTypeToDisplay, Order, OrderDispatch, OrderWithDelivery } from '../../../../../domain';
import { Environment } from '../../../../../Environment';
import { OrderDecorator, PlatformTypeDecorator } from '../../../../decorators';
import { useAppContext } from '../../../../hooks';
import { AppText } from '../../../AppText';
import { SecondaryButton } from '../../../buttons/SecondaryButton';
import { If } from '../../../If';
import { Clickable } from '../../../Clickable';
import { FulfillmentAndCookView } from '../../FulfillmentAndCookView';
import { SeeMoreButton } from '../SeeMoreButton';
import { CancelReasonView } from './CancelReasonView';
import { CustomerView } from './CustomerView';
import { styles } from './styles';

interface Props {
  orderWithDelivery: OrderWithDelivery;
  orderDispatch: OrderDispatch | undefined;
  onPressConfirm?: (order: Order) => void;
  onPressSupport?: (order: Order) => void;
  onPressPrint?: (orderWithDelivery: OrderWithDelivery) => void;
}

export const RightPane = (props: Props): React.ReactElement => {
  const { orderWithDelivery, orderDispatch } = props;
  const { onPressConfirm, onPressSupport, onPressPrint } = props;
  const { integrations, brands, connectedPrinters } = useAppContext();
  const [visibleContinuousForDelivery, setVisibleContinuousForDelivery] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);

  const { order } = orderWithDelivery;
  const brand = brands?.find(brand => brand.id === order.brandId);
  const confirmText = new OrderDecorator(order).confirmText();
  const shouldShowPrintButton = Environment.platform.type === 'expo-go' || connectedPrinters.length > 0;

  const onPressPrintButton = useCallback(() => {
    onPressPrint?.(orderWithDelivery);
  }, [onPressPrint, orderWithDelivery]);

  const onPress = useCallback(() => onPressSupport?.(order), [onPressSupport, order]);

  const onScroll = useCallback((event: rn.NativeSyntheticEvent<rn.NativeScrollEvent>) => {
    setVisibleContinuousForDelivery(!isCloseToBottom(event.nativeEvent));
  }, []);

  const onLayout = useCallback(() => {
    // この画面を表示したときにInfo欄がスクロール可能な場合に"↓ 続き"を表示するために、
    // この画面を表示したときにスクロールさせることでonLayoutを発火させる。
    scrollViewRef.current?.scrollTo({ y: 1, animated: false });
    scrollViewRef.current?.scrollTo({ y: 0, animated: false });
  }, []);

  const onPressSeeMoreButton = useCallback(() => { scrollViewRef.current?.scrollToEnd(); }, []);

  const onPressConfirmButton = useCallback(() => onPressConfirm?.(order), [onPressConfirm, order]);

  return <View style={styles.view}>
    <View style={styles.info}>
      <FulfillmentAndCookView {...orderWithDelivery} size='medium' />
      <If condition={order.status === 'CANCELED'}>
        <CancelReasonView {...{ order }} />
      </If>
      <View style={styles.iconAndOrderId}>
        <Image
          source={new PlatformTypeDecorator(getOrderPlatformTypeToDisplay(order), integrations).icon()}
          style={styles.icon}
          resizeMode='contain' />
        <View style={styles.orderIds}>
          <View style={styles.orderId}>
            <Image source={require('../../../../assets/icon/order_id.png')} style={styles.orderIdIcon} resizeMode='contain' />
            <AppText style={styles.orderIdText}>{order.displayId}</AppText>
          </View>
          <AppText style={styles.brandText} numberOfLines={2}>{brand?.name ?? ''}</AppText>
          <If condition={orderDispatch?.displayId}>
            <View style={styles.posId}>
              <Image source={require('../../../../assets/icon/pos.png')} style={styles.posIdIcon} resizeMode="contain" />
              <AppText style={styles.posIdText}>POS連携番号：{orderDispatch?.displayId}</AppText>
            </View>
          </If>
          <View style={styles.orderUuid}>
            <AppText numberOfLines={1} ellipsizeMode='tail' style={styles.orderUuidText}>注文ID：{order.uuid}</AppText>
          </View>
        </View>
      </View>
      <If condition={confirmText}>
        <View style={styles.confirm}>
          <Clickable name="button_tap" id={confirmText} onPress={onPressConfirmButton}>
            <View style={styles.confirmButton}>
              <AppText style={styles.confirmText}>{confirmText}</AppText>
            </View>
          </Clickable>
        </View>
      </If>
      <View style={{ flexDirection: 'row', marginTop: 16 }}>
        <If condition={shouldShowPrintButton}>
          <View style={styles.print}>
            <SecondaryButton
              size='large'
              title="印刷"
              icon={require('../../../../assets/icon/print.png')}
              onPress={onPressPrintButton}/>
          </View>
        </If>
        <View style={styles.support}>
          <SecondaryButton size='large' title="注文の管理" onPress={onPress}/>
        </View>
      </View>
    </View>
    <View style={styles.delivery}>
      <ScrollView
        contentContainerStyle={styles.deliveryContainer}
        scrollEventThrottle={100}
        ref={scrollViewRef}
        onLayout={onLayout}
        onScroll={onScroll}>
        <CustomerView orderWithDelivery={orderWithDelivery} />
      </ScrollView>
      <If condition={visibleContinuousForDelivery}>
        <SeeMoreButton onPress={onPressSeeMoreButton} />
      </If>
    </View>
  </View>;
};

const isCloseToBottom = (event: rn.NativeScrollEvent, paddingToBottom = 8) =>
  event.layoutMeasurement.height + event.contentOffset.y >= event.contentSize.height - paddingToBottom;
