import { AcceptanceMethod, DeliveryMethod, PlatformType } from '../../../domain';
import { Callable, useCallable } from '../core/useCallable';

interface AcceptanceMethodOperation {
  type: 'CHANGE_ACCEPTANCE_METHOD'
  acceptanceMethod: AcceptanceMethod
}

interface DeliveryMethodOperation {
  type: 'CHANGE_DELIVERY_METHOD';
  deliveryMethod: DeliveryMethod;
}

interface Request {
  operation: AcceptanceMethodOperation | DeliveryMethodOperation;
  brandId: string;
  platformType: PlatformType;
}

type Response = void

export const usePlatformSettingOperation = (): Callable<Request, Response> =>
  useCallable<Request, Response>('onCallPlatformSettingOperation');
