import { Environment } from '../../../Environment';

export type PrinterSeries = typeof printerSeries[number];

export const printerSeries = (() => {
  if (!Environment.isNative)
    return [];

  return [
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    ...require('./EpsonPrinterSeries').epsonPrinterSerieses,
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    ...require('./StarPrinterSeries').starPrinterSerieses
  ] as const;
})();
