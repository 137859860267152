import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface Props {
  shopClosedCount?: number;
  onPress: () => void;
}

export const ShopButton = (props: Props): React.ReactElement =>
  <Clickable name="button_tap" id='shop_open' onPress={props.onPress}>
    <View style={styles.view}>
      <>
        {props.shopClosedCount
          ? <View style={styles.badge}><AppText style={styles.count}>{props.shopClosedCount}</AppText></View>
          : null
        }
        <Image source={require('../../assets/icon/shop_white.png')} style={styles.icon} resizeMode="contain" />
        <View style={[styles.underLine, { backgroundColor: Color.white }]} />
      </>
    </View>
  </Clickable>;

const styles = StyleSheet.create({
  view: {
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: Color.gray80,
  },
  underLine: {
    width: 62,
    height: 4,
    position: 'absolute',
    bottom: 1,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  icon: {
    width: 28,
    height: 28,
  },
  badge: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 4,
    left: 36,
    zIndex: 2,
    borderRadius: 4,
    backgroundColor: Color.red,
  },
  count: {
    lineHeight: 24,
    fontSize: 14,
    color: Color.white,
  }
});
