import React from 'react';
import { canCancel, canDelay, Integration, OrderWithDelivery } from '../../../../domain';
import { SupportListItem } from './SupportListItem';

export enum Support {
  DELAY = '注文を遅らせる',
  CONTACT = 'お客様/配達員に連絡',
  CANCEL = '注文をキャンセル',
}

interface Props {
  orderWithDelivery: OrderWithDelivery;
  integrations: Integration[] | undefined;
  onPressSupport: (support: Support) => void;
}

export const SupportList = ({ orderWithDelivery, integrations, onPressSupport }: Props): React.ReactElement => {
  const data = [
    ...(canDelay(orderWithDelivery) ? [Support.DELAY] : []),
    Support.CONTACT,
    ...(canCancel(orderWithDelivery, integrations ?? []) ? [Support.CANCEL] : []),
  ];

  return <>
    {data.map(item =>
      <SupportListItem
        key={`SupportList-SupportListItem-${item}`}
        {...{ support: item, onPressSupport }}/>)}
  </>;
};
