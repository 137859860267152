import React, { memo, useCallback, useMemo } from 'react';
import { ActivityIndicator, Image, ImageURISource, StyleSheet, View } from 'react-native';
import {
  Brand,
  Delivery,
  DeliveryEstimation,
  getOrderPlatformTypeToDisplay,
  getOrderTotalItemCount,
  Order,
  orderToRelation
} from '../../../../domain';
import { Environment } from '../../../../Environment';
import { Color } from '../../../constants';
import { OrderDecorator, PlatformTypeDecorator } from '../../../decorators';
import {
  useAppContext,
  useDeliveries,
  useDeliveryEstimations,
  useErrorHandler,
  usePeriodicUpdater
} from '../../../hooks';
import { equalProps, Formatter } from '../../../utils';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { Clickable } from '../../Clickable';
import { FulfillmentAndCookView } from '../../views/FulfillmentAndCookView';

interface Props {
  order: Order;
  loading: boolean;
  onPress: (order: Order) => void;
  onPressButton: (order: Order) => void;
}

export const OrderCardListItem = ({ order, loading, onPress, onPressButton }: Props): React.ReactElement => {
  const { brands, integrations } = useAppContext();
  const [deliveries, loadingDeliveries, errorDeliveries] = useDeliveries(orderToRelation(order));
  const [
    deliveryEstimations,
    loadingDeliveryEstimations,
    errorDeliveryEstimations
  ] = useDeliveryEstimations(orderToRelation(order));

  const { now } = usePeriodicUpdater(60 * 1000);
  useErrorHandler(errorDeliveries || errorDeliveryEstimations);

  const isNew = !order.isRead && !(order.status == 'READY' || order.status == 'COMPLETED');
  const confirmText = new OrderDecorator(order).confirmText();

  const platformIcon = useMemo(
    () => new PlatformTypeDecorator(getOrderPlatformTypeToDisplay(order), integrations).icon()
    , [order, integrations]
  );

  const brand = useMemo(() => brands?.find(brand => brand.id === order.brandId), [brands, order]);
  const delivery = useMemo(() => deliveries?.[0], [deliveries]);
  const deliveryEstimation = useMemo(() => deliveryEstimations?.[0], [deliveryEstimations]);

  return <ViewComponent {...{
    brand,
    order,
    delivery,
    deliveryEstimation,
    isNew,
    platformIcon,
    confirmText,
    now,
    loading,
    loadingDelivery: loadingDeliveries || loadingDeliveryEstimations,
    onPress,
    onPressButton
  }}/>;
};

type ViewProps = {
  brand: Brand | undefined
  order: Order
  delivery: Delivery | undefined
  deliveryEstimation: DeliveryEstimation | undefined

  isNew: boolean
  platformIcon: ImageURISource
  confirmText: string | undefined

  now: number

  loading: boolean
  loadingDelivery: boolean

  onPress: (order: Order) => void
  onPressButton: (order: Order) => void
}

const ViewComponent = memo(function ViewComponent (props: ViewProps) {
  const { brand, order, delivery, deliveryEstimation, isNew, platformIcon, confirmText } = props;
  const { loading, loadingDelivery } = props;
  const { onPress, onPressButton } = props;

  const onPressConfirmButton = useCallback(() => loading || onPressButton(order), [onPressButton, loading, order]);
  const onPressOrder = useCallback(() => onPress(order), [onPress, order]);

  return <Clickable name='card_open' id={order.id} onPress={onPressOrder} style={styles.item}>
    <View style={styles.view}>
      <If condition={isNew}>
        <View style={styles.newTag}>
          <AppText style={styles.newText}>新規</AppText>
        </View>
      </If>
      <View style={styles.row}>
        <Image source={platformIcon} style={styles.icon} resizeMode='contain' />
      </View>
      <View style={styles.orderIdAndName}>
        <View style={styles.orderId}>
          <Image source={require('../../../assets/icon/order_id.png')} style={styles.orderIdIcon} resizeMode='contain' />
          <AppText style={styles.orderIdText}>{order.displayId}</AppText>
        </View>
        <If condition={order.customer.name}>
          <AppText style={styles.nameText} numberOfLines={1}>{order.customer.name}</AppText>
        </If>
      </View>
      <View style={styles.itemCount}>
        <View style={styles.itemCountBox}>
          <AppText style={styles.itemCountText}>{getOrderTotalItemCount(order).toString()}</AppText>
        </View>
      </View>
      <View style={styles.price}>
        <AppText style={styles.priceText}>{Formatter.price(order.price.total)}</AppText>
      </View>
      <View style={styles.brand}>
        <If condition={brand}>
          <AppText style={styles.brandText} numberOfLines={2}>{brand?.name}</AppText>
        </If>
      </View>
      <View style={styles.info}>
        <If condition={!loadingDelivery}>
          <FulfillmentAndCookView {...{ order, delivery, deliveryEstimation }} size='small' />
        </If>
        <If condition={loadingDelivery}>
          <ActivityIndicator style={styles.confirmIndicator} size='small' color='#333333' animating={true} />
        </If>
      </View>
      <If condition={confirmText}>
        <View style={styles.confirm}>
          <Clickable name="button_tap" id={confirmText} disabled={loading} onPress={onPressConfirmButton}>
            <View style={[styles.confirmButton, loading ? styles.confirmButtonWithDisabled : undefined]}>
              <AppText style={styles.confirmText}>{confirmText}</AppText>
            </View>
          </Clickable>
          <If condition={loading}>
            <ActivityIndicator style={styles.confirmIndicator} size='small' color='#333333' animating={true} />
          </If>
        </View>
      </If>
    </View>
  </Clickable>;
}, equalProps());

const styles = StyleSheet.create({
  item: {
    marginVertical: 6,
  },
  view: {
    flex: 1,
    flexDirection: 'row',
    height: 89,
    padding: 16,
    borderRadius: 4,
    backgroundColor: Color.white,
  },
  newTag: {
    position: 'absolute',
    top: 4,
    left: 4,
    zIndex: 9999,
    height: 24,
    paddingHorizontal: 8,
    paddingVertical: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: Color.white,
    borderRadius: 12,
    backgroundColor: Color.blue_light,
  },
  newText: {
    lineHeight: 24,
    color: Color.white,
    fontSize: 14,
    fontWeight: '500',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20
  },
  orderIdAndName: {
    width: 185,
    marginLeft: 12,
    justifyContent: 'center',
  },
  orderId: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderIdIcon: {
    width: 16,
    height: 16,
  },
  orderIdText: {
    marginLeft: 6,
    color: Color.gray100,
    lineHeight: 30,
    fontSize: 20,
    fontWeight: '500',
  },
  nameText: {
    marginTop: 4,
    lineHeight: 30,
    fontSize: 16,
    fontWeight: '500',
    color: Color.gray70,
  },
  itemCount: {
    marginLeft: 12,
    justifyContent: 'center',
  },
  itemCountBox: {
    minWidth: 30,
    height: 30,
    borderWidth: 1,
    borderColor: Color.gray10,
    borderRadius: 4,
  },
  itemCountText: {
    color: Color.gray100,
    lineHeight: 28,
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  price: {
    marginLeft: 8,
    justifyContent: 'center',
  },
  priceText: {
    color: Color.gray100,
    fontSize: 18,
    fontWeight: '500',
  },
  brand: {
    flex: 1,
    marginLeft: 12,
    justifyContent: 'center',
  },
  brandText: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 18,
    color: Color.gray70,
    textAlign: 'right',
  },
  info: {
    marginLeft: 12,
    borderLeftColor: Color.gray10,
    borderLeftWidth: 1,
    paddingLeft: 12,
    justifyContent: 'center',
  },
  confirm: {
    marginLeft: 12,
    justifyContent: 'center',
  },
  confirmButton: {
    width: 96,
    minHeight: Environment.platform.type === 'web' ? 48 : '100%', // Webでは'100%'が効かないので絶対値指定
    backgroundColor: Color.brand,
    borderRadius: 4,
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  confirmButtonWithDisabled: {
    backgroundColor: Color.gray20,
  },
  confirmText: {
    fontSize: 16,
    color: Color.white,
    textAlign: 'center',
  },
  confirmIndicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});
