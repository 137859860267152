import { Timestamp } from 'firebase/firestore';
import { pickObject } from '../../utils';
import { AcceptanceMethod } from './AcceptanceMethod';
import { BrandRelation } from './Brand';
import { DeliveryMethod } from './DeliveryMethod';
import { PlatformType } from './PlatformType';
import { StoreRelation } from './Store';

export type PlatformReference<T extends PlatformType> = StoreRelation & BrandRelation & { type: T, id: T };
export type PlatformRelation<T extends PlatformType> =
  & StoreRelation
  & BrandRelation
  & { platformType: PlatformReference<T>['id'] };

export interface Platform {
  id: PlatformType;
  type: PlatformType;
  accountId: string;
  storeId: string;
  brandId: string;
  disabled?: boolean;
  name: string;
  deliveryMethod: DeliveryMethod | null;
  supportsDeliveryMethod?: boolean;
  acceptanceMethod: AcceptanceMethod
  createTime: Timestamp;
  updateTime: Timestamp;
}

export const platformToReference = (platform: Platform): PlatformReference<PlatformType> =>
  pickObject(platform, ['id', 'accountId', 'storeId', 'brandId', 'type']);

export const platformToRelation = (platform: Platform): PlatformRelation<PlatformType> =>
  ({ ...pickObject(platform, ['accountId', 'storeId', 'brandId']), platformType: platform.type });
