import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Brand, BusinessDayTime } from '../../domain';
import { AppText, BusinessDayTimesList, Clickable, If, NavigationBar, WithScreen } from '../components';
import { Color, Route } from '../constants';
import { useAppContext, useBusinessDayTimes } from '../hooks';

export const BusinessDayTimesScreen = (
  { navigation }: StackScreenProps<Route, 'BusinessDayTimes'>
): React.ReactElement => {
  const { store } = useAppContext();
  const [businessDayTimes, loading, error] = useBusinessDayTimes(
    store && { accountId: store.accountId, storeId: store.id }
  );

  const onPressBack = () => {
    navigation.goBack();
  };

  const onPressClose = () => {
    navigation.popToTop();
    navigation.goBack();
  };

  return <WithScreen loading={loading} error={error}>
    <SafeAreaView style={styles.screen}>
      <NavigationBar {...{ title: '営業時間管理', isForMenu: true, onPressBack, onPressClose }}/>
      <BusinessDayTimesView  {...{ businessDayTimes }} />
    </SafeAreaView>
  </WithScreen>;
};

type ViewProps = {
  businessDayTimes: BusinessDayTime[] | undefined;
};

const BusinessDayTimesView = ({ businessDayTimes }: ViewProps): React.ReactElement => {
  const { brands } = useAppContext();
  const [selectedBrand, setSelectedBrand] = useState<Brand>();

  useEffect(() => {
    if (brands && !selectedBrand) setSelectedBrand(brands[0]);
  }, [brands, selectedBrand]);

  const renderItem = useCallback(({ item }: { item: Brand }) =>
    <BrandItem {...{ brand: item, selected: selectedBrand === item, onPressBrand: setSelectedBrand }}/>
  , [selectedBrand, setSelectedBrand]);

  const ItemSeparatorComponent = useCallback(() => <View style={{ height: 8 }} />, []);

  const keyExtractor = useCallback((brand: Brand) => `business-day-times-view-${brand.id}`, []);

  return <View style={styles.view}>
    <View style={styles.left}>
      <FlatList {...{ data: brands, renderItem, ItemSeparatorComponent, keyExtractor }} style={styles.menu} />
    </View>
    <View style={styles.container}>
      <If condition={selectedBrand}>
        <BusinessDayTimesList {...{ brandId: selectedBrand?.id ?? '', businessDayTimes }} />
      </If>
      <If condition={!brands}>
        <AppText style={styles.unavailableText}>変更可能なブランドがありません。</AppText>
      </If>
    </View>
  </View>;
};

type BrandItemProps = {
  brand: Brand
  selected: boolean

  onPressBrand: (brand: Brand) => void
}

const BrandItem = ({ brand, selected, onPressBrand }: BrandItemProps) => {
  const onPress = useCallback(() => onPressBrand(brand), [brand, onPressBrand]);

  return <Clickable name="settings_tap" id={`businessDayTime_${brand.id}`} onPress={onPress}>
    <View style={[styles.menuItem, selected && styles.menuItemSelected]}>
      <AppText style={[styles.menuItemText, selected && styles.menuItemTextSelected]} numberOfLines={2}>
        {brand.name}
      </AppText>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  left: {
    borderRightWidth: 1,
    borderRightColor: Color.gray10,
    width: 264
  },
  menu: {
    paddingVertical: 32,
    paddingRight: 32,
    paddingLeft: 56,
  },
  menuItem: {
    alignSelf: 'stretch',
    display: 'flex',
    padding: 16
  },
  menuItemSelected: {
    backgroundColor: Color.brand,
    borderRadius: 6,
  },
  menuItemText: {
    color: Color.black,
    fontSize: 16,
    fontWeight: '400',
  },
  menuItemTextSelected: {
    color: Color.white,
    fontWeight: '500',
  },
  platform: {
    minHeight: 88,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
  unavailableText: {
    fontSize: 18,
    marginStart: 'auto',
    marginEnd: 'auto',
    marginTop: 48,
  },
  view: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Color.gray5,
    width: '100%',
  },
});
