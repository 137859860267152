import { User } from 'firebase/auth';
import { Environment } from '../../Environment';
import { Adapter, ConsoleAdapter, FirebaseAdapter, SentryAdapter } from './adapters';

export class Logger {
  adapters: Adapter[];
  currentScreen: string;

  constructor(adapters: Adapter[]) {
    this.adapters = adapters;
    this.currentScreen = 'Launch';
  }

  set(user: User | undefined, accountId: string | undefined, storeId: string | undefined): void {
    Promise.all(this.adapters.map((adapter) => adapter.set(user, accountId, storeId)));
  }

  screen(name: string): void {
    Promise.all(this.adapters.map((adapter) => adapter.screen(name)));
  }

  action(name: string, id?: string): void {
    Promise.all(this.adapters.map((adapter) => adapter.action(name, id)));
  }

  info(name: string, params: Record<string, unknown> | undefined): void {
    Promise.all(this.adapters.map((adapter) => adapter.info(name, params)));
  }

  error(error: Error): void {
    Promise.all(this.adapters.map((adapter) => adapter.error(error)));
  }
}

export const logger = (() => {
  switch (Environment.runningMode) {
    case 'test': return new Logger([]);
    case 'debug': return new Logger([new ConsoleAdapter()]);
    default: return new Logger([new ConsoleAdapter(), new FirebaseAdapter(), new SentryAdapter()]);
  }
})();
