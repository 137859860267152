import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { Brand } from '../../../../domain';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { Clickable } from '../../Clickable';

interface Props {
  brand: Brand
  soldOutCount: number

  onPressBrand: (brand: Brand) => void
}

export const BrandListItem = ({ brand, soldOutCount, onPressBrand }: Props): React.ReactElement => {
  const onPress = useCallback(() => {
    onPressBrand(brand);
  }, [brand, onPressBrand]);

  return <Clickable name="brand_tap" id={brand.id} style={{ zIndex: 999 }} onPress={onPress}>
    <View style={styles.view}>
      <View style={styles.main}>
        <AppText style={styles.title} numberOfLines={2}>{brand.name}</AppText>
      </View>
      <If condition={soldOutCount && soldOutCount > 0}>
        <View style={styles.soldOut}><AppText style={styles.soldOutText}>売り切れ({soldOutCount})</AppText></View>
      </If>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 136,
    paddingHorizontal: 24,
    backgroundColor: Color.white,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
  },
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  title: {
    fontSize: 18,
    color: Color.gray100,
  },
  soldOut: {
    backgroundColor: Color.red,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
  soldOutText: {
    fontSize: 14,
    color: Color.white,
  },
});
