import React, { useCallback, useMemo } from 'react';
import { FlatList, Image, ImageURISource, StyleSheet, View } from 'react-native';
import {
  BusinessDayTime,
  Integration,
  isBusinessDayTimeExtra,
  isBusinessDayTimeRegular,
  PlatformType,
  RegularBusinessDayTime,
  Store
} from '../../../../domain';
import { groupBy } from '../../../../utils';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { ExtraSection } from './parts/extraBusinessDayTime';
import { RegularSection } from './parts/regularBusinessDayTime';

interface ItemProps {
  store: Store | undefined
  brandId: string
  brandName: string
  platform: {
    type: PlatformType
    name: string
    icon: ImageURISource
  },
  businessDayTimes: BusinessDayTime[]
  integrations: Integration[] | undefined
}

type Item = [string | null, RegularBusinessDayTime[]];

export const BusinessDayTimeListItem = (
  { store, brandId, brandName, platform, businessDayTimes, integrations }: ItemProps
): React.ReactElement => {
  const data = useMemo(() =>
    groupBy((businessDayTimes as RegularBusinessDayTime[])
      .filter(isBusinessDayTimeRegular), businessDayTime => businessDayTime.sectionId)
  , [businessDayTimes]);

  const renderItem = useCallback(({ item: [, businessDayTimes] }: { item: Item }) => {
    const sectionName = businessDayTimes[0]?.sectionName ?? null;
    return <RegularSection {...{ sectionName, businessDayTimes, integrations }}/>;
  }, [integrations]);

  const ItemSeparatorComponent = useCallback(() =>
    <View style={{ width: '100%', height: 1, backgroundColor: Color.gray10, marginTop: 24, marginBottom: 31 }} />
  , []);

  const keyExtractor = useCallback(([key]: Item) => `business-day-time-list-item-${key}`, []);
  const listKey = `business-day-time-list-item-${brandId}-${platform.type}`;

  const supportsExtraBusinessDayTime = integrations
    ?.find(({ platformType }) => platformType === businessDayTimes[0]?.platformType)
    ?.features.businessDayTimes.extraBusinessDayTime.supported;

  return <View style={styles.card}>
    <Header brandName={brandName} icon={platform.icon} platformName={platform.name}/>
    <View style={styles.itemWrapper}>
      <FlatList {...{ data, renderItem, ItemSeparatorComponent, keyExtractor, listKey }} />
    </View>
    <If condition={supportsExtraBusinessDayTime}>
      {ItemSeparatorComponent()}
      <ExtraSection
        {...{
          store,
          brandId,
          businessDayTimes: businessDayTimes.filter(isBusinessDayTimeExtra),
          platformType: platform.type,
          integrations
        }}
      />
    </If>
  </View>;
};

interface HeaderProps {
  brandName: string
  icon: ImageURISource
  platformName: string,
}

const Header = ({ brandName, icon, platformName  }: HeaderProps) =>
  <View style={styles.header}>
    <Image source={icon} style={styles.icon} resizeMode="contain"/>
    <View style={styles.headerTextWrapper}>
      <AppText style={styles.headerMainText}>{platformName}</AppText>
      <AppText style={styles.headerSubText}>{brandName}</AppText>
    </View>
  </View>;

const styles = StyleSheet.create({
  card: {
    alignItems: 'flex-start',
    backgroundColor: Color.white,
    borderColor: Color.gray10,
    borderRadius: 12,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
    width: '100%'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 96,
    paddingHorizontal: 32,
  },
  headerTextWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  headerMainText: {
    color: Color.gray100,
    fontSize: 14,
  },
  headerSubText: {
    color: Color.gray50,
    fontSize: 12,
    fontWeight: '400'
  },
  icon: {
    borderRadius: 16,
    height: 32,
    marginEnd: 9,
    width: 32,
  },
  itemWrapper: {
    width: '100%'
  }
});
