import React, { ReactElement } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Order } from '../../../../../domain';
import { Color } from '../../../../constants';
import { AppText } from '../../../AppText';

type Props = { order: Order };

export const CutleryView = ({ order }: Props): ReactElement => {
  return order.cutlery !== null
    ? <View style={styles.view}>
      <Image source={require('../../../../assets/icon/cutlery.png')} resizeMode="contain" style={styles.icon}/>
      {order.cutlery
        ? <AppText style={styles.comment}>使い捨て製品：<AppText style={styles.cutlery}>利用する</AppText></AppText>
        : <AppText style={styles.comment}>使い捨て製品：利用しない</AppText>
      }
    </View>
    : <View style={styles.view} />;
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    marginBottom: 3,
    marginRight: 8,
  },
  comment: {
    marginBottom: 3,
    fontSize: 16,
    color: Color.gray100,
  },
  cutlery: {
    fontWeight: '700',
  },
});
