import { StackScreenProps } from '@react-navigation/stack';
import moji from 'moji';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Keyboard, SectionList, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Item, ItemSoldOutStatus, platformToRelation, PlatformType } from '../../../domain';
import { Category, ItemList, ItemSegmentedControl, SectionItem, WithScreen } from '../../components';
import { Color, Route } from '../../constants';
import { useAppContext, useBrandItems, useImportItems, useItemOperation } from '../../hooks';
import { HeaderView, NavigationView } from './components';

export const SoldOutScreen = (
  { navigation, route: { params: { brand } } }: StackScreenProps<Route, 'SoldOut'>
): React.ReactElement => {
  const { store, platforms } = useAppContext();
  const [items, loadingItems, errorItems] = useBrandItems(store, brand);

  const [selectedSection, setSelectedSection] = useState<'items' | 'options'>('items');
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformType | undefined>(undefined);
  const [focusingSearch, setFocusingSearch] = useState(false);
  const [search, setSearch] = useState<string>();
  const [soldOutFilter, setSoldOutFilter] = useState(false);
  const [loadingItem, setLoadingItem]= useState<Record<string, boolean>>({});

  const { call: callItemOperation, error: errorOperation } = useItemOperation();
  const importItems = useImportItems();
  const sectionListRef = React.createRef<SectionList>();

  const platformTypes = useMemo(() => platforms
    ?.filter(p => p.brandId === brand.id)
    .map(p => p.type)
    ?? []
  , [brand.id, platforms]);

  const itemsByPlatform = useMemo(() => platformTypes
    .reduce((acc: { platformType: PlatformType, items: Item[] }[], value) =>
      acc.concat({ platformType: value, items: items?.filter(item => item.platformType === value) ?? [] })
    , [])
    ?? []
  , [items, platformTypes]);

  const allItems = useMemo(() => itemsByPlatform
    .flatMap(brandItem => brandItem.items.filter(item => platformTypes.includes(item.platformType)))
    .reduce((acc: Item[], item) => acc.find(i => i.id === item.id) ? acc : [...acc, item], [])
    ?? []
  , [itemsByPlatform, platformTypes]);

  const soldOutCount = allItems.filter(item => item.soldOutStatus !== 'sale').length;

  const isSearching = !!(focusingSearch || (search && search.length > 0));
  const sectionItems = useMemo(() => isSearching
    ? itemsByPlatform
      .flatMap(({ items }) => items.filter(item => platformTypes.includes(item.platformType)))
      .filter(item => matchIgnoringHiraganaAndKatakana(search, item))
      ?? []
    : itemsByPlatform
      .find(({ platformType }) => platformType === (selectedPlatform ?? platformTypes[0]))
      ?.items
      .filter(item => platformTypes.includes(item.platformType))
      .filter(item => (soldOutFilter ? item.soldOutStatus !== 'sale' : true))
      ?? itemsByPlatform[0]?.items
        .filter(item => platformTypes.includes(item.platformType))
        .filter(item => (soldOutFilter ? item.soldOutStatus !== 'sale' : true))
      ?? []
  , [isSearching, itemsByPlatform, platformTypes, search, selectedPlatform, soldOutFilter]);

  const { sections, optionsIndex } = useMemo(() =>
    buildSections(sectionItems, platformTypes)
  , [platformTypes, sectionItems]);

  useEffect(() => {
    if (!selectedPlatform) return;

    const platform = platforms?.find(platform => platform.brandId === brand.id && platform.type === selectedPlatform);

    if (!platform) return;

    importItems.call(platformToRelation(platform));
  }, [brand.id, platforms, selectedPlatform]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressClose = useCallback(() => {
    navigation.popToTop();
    navigation.goBack();
  }, [navigation]);

  const onChangeSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const onPressSegmentedControl = useCallback((item: Item, soldOutStatus: ItemSoldOutStatus) => {
    setLoadingItem(loadingItem => ({ ...loadingItem, [item.masterId || item.id]: true }));

    if (!item.masterId) return;

    const params = soldOutStatus === 'sale'
      ? { type: 'SALE' as const }
      : soldOutStatus === 'sold_out'
        ? { type: 'SOLDOUT' as const }
        : { type: 'SUSPEND' as const, brandId: brand.id, itemId: item.id, platformType: item.platformType };

    callItemOperation({ operation: params, brandId: brand.id, masterItemIds: [item.masterId] })
      .finally(() => { setLoadingItem(loadingItem => ({ ...loadingItem, [item.masterId || item.id]: false })); });
  }, [brand.id, callItemOperation]);

  const onPressSingle = useCallback(() => {
    sectionListRef.current?.scrollToLocation({ sectionIndex: 0, itemIndex: 0 });
  }, [sectionListRef]);

  const onPressOption = useCallback(() => {
    sectionListRef.current?.scrollToLocation({ sectionIndex: optionsIndex, itemIndex: 0 });
  }, [optionsIndex, sectionListRef]);

  const onPressPlatform = useCallback((platformType: PlatformType) => {
    setSelectedPlatform(platformType);
  }, []);

  const onPressSoldOutFilter = useCallback(() => {
    setSoldOutFilter(soldOutFilter => !soldOutFilter);
  }, []);

  const onPressOptionCategory = useCallback(({ name, id }: Category) => {
    navigation.navigate(
      'OptionCategory',
      { optionCategoryName: name, items: allItems.filter(item => item.optionCategoryIds?.includes(id)) }
    );
  }, [allItems, navigation]);

  const onChangeFocus = useCallback((focus: boolean) => {
    setFocusingSearch(focus);
  }, []);

  const onChangeViewableSection = useCallback((sectionItems: SectionItem[]) => {
    const sectionIndexMin = Math.min(...sectionItems.map(item => item.index || 0));
    const sectionIndexMax = Math.max(...sectionItems.map(item => item.index || 0));

    if (optionsIndex !== -1 &&
      (sectionIndexMin >= optionsIndex || Math.max(...sections.map((section) => section.index)) === sectionIndexMax)
    ) {
      setSelectedSection('options');
    } else {
      setSelectedSection('items');
    }
  }, [optionsIndex, sections]);

  const onScrollBeginDrag = useCallback(() => {
    if (search && search.length > 0) Keyboard.dismiss();
  }, [search]);

  const extraView = useCallback((item: Item) =>
    <ItemSegmentedControl
      itemId={item.id}
      state={item.soldOutStatus ?? 'sale'}
      disabled={!!loadingItem[item.masterId || item.id]}
      onPress={soldOutStatus => { onPressSegmentedControl(item, soldOutStatus); } /* eslint-disable-line react/jsx-no-bind, max-len */} />
  , [loadingItem, onPressSegmentedControl]);

  return <WithScreen loading={loadingItems} error={errorItems || errorOperation}>
    <SafeAreaView style={styles.screen}>
      <NavigationView
        {...{ brand, search, isSearching, soldOutCount, soldOutFilter }}
        {...{ onChangeSearch, onChangeFocus, onPressBack, onPressClose, onPressSoldOutFilter }} />
      <HeaderView
        platformTypes={platformTypes || []}
        {...{ selectedPlatform, selectedSection, isSearching, onPressSingle, onPressOption, onPressPlatform }} />
      <ItemList
        ref={sectionListRef}
        sections={sections}
        showPlatform={isSearching}
        selectedPlatform={selectedPlatform ?? platformTypes[0]}
        emptyText={search ? '一致する検索結果はありません' : '商品がありません'}
        extraView={extraView}
        {...{ onPressOptionCategory, onChangeViewableSection, onScrollBeginDrag }} />
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
});

const buildSections = (allItems: Item[], platformTypes: PlatformType[]) => {
  const categories = extractCategories(allItems);

  const sections = platformTypes
    .flatMap(platformType =>
      categories
        .map(mapItemsToSection(allItems, platformType))
        .filter(sections => sections.data.length > 0)
        .sort(compareSingle)
    );

  const firstIndexesOfPlatform = platformTypes
    .map(platformType => sections.findIndex(section => section.platformType === platformType));
  const firstIndexesOfOptionCategory = platformTypes
    .map(platformType =>
      sections.findIndex(section => section.platformType === platformType && !section.category.single)
    );

  return {
    sections: sections.map((section, index) => ({
      ...section,
      platformType: firstIndexesOfPlatform.includes(index) ? section.platformType : undefined,
      sectionTitle: firstIndexesOfPlatform.includes(index)
        ? '商品'
        : firstIndexesOfOptionCategory.includes(index)
          ? 'オプション'
          : undefined,
      index
    })),
    optionsIndex: firstIndexesOfOptionCategory.find((e) => e > 0) || 0
  };
};

const extractCategories = (items: Item[]): Category[] =>
  Object.values(
    items
      .map(item => item.categories?.map((name, i) => ({ id: item.categoryIds?.[i] ?? '', name, single: false })) ?? [])
      .reduce((acc, categories) => ({ // make unique by id
        ...acc,
        ...categories.reduce((acc2, category) => ({ ...acc2, [category.id]: category }), {})
      }), {})
  );

const mapItemsToSection = (allItems: Item[], platformType: PlatformType) => (category: Category) => {
  const data = allItems.filter(item => item.platformType === platformType && item.categories?.includes(category.name));
  const single = data.every(item => item.single);
  return { platformType, category: { ...category, single }, data };
};

const compareSingle = (l: { category: Category }, r: { category: Category }) => {
  if (l.category.single && !r.category.single) return -1;
  if (!l.category.single && r.category.single) return 1;

  return 0;
};

const matchIgnoringHiraganaAndKatakana = (search: string | undefined, item: Item): boolean =>
  !search || moji(item.title).convert('HG', 'KK').toString().indexOf(moji(search).convert('HG', 'KK').toString()) > -1;
