import React, { useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface Props {
  text: string;
  selected: boolean;
  size: 'extraLarge' | 'large' | 'medium'
  outline: boolean
  onPress: () => void;

  children?: React.ReactElement;
}

export const Radio = (props: Props): React.ReactElement => {
  const styles = useMemo(() =>({ extraLarge, large, medium })[props.size], [props.size]);

  return <Clickable
    name="radio_select"
    id={`${props.text}_${props.selected}`}
    onPress={props.onPress}
    style={[props.outline? styles.viewWithOutline: styles.viewWithoutOutline,
      props.selected && (props.outline? styles.viewSelectedWithOutline : styles.viewSelectedWithoutOutline)]}>
    <View style={styles.buttonFrame}>
      <Image
        source={props.selected ? require('../../assets/button/radio_on.png') : require('../../assets/button/radio_off.png')}
        style={styles.icon}
        resizeMode="contain"/>
      <AppText style={styles.text}>{props.text}</AppText>
    </View>
    {props.children}
  </Clickable>;
};

const extraLarge = StyleSheet.create({
  viewWithOutline: {
    minHeight: 72,
    marginBottom: 16,
    paddingVertical: 22.5,
    paddingLeft: 22.5,
    flexDirection: 'column',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Color.gray10,
    justifyContent: 'flex-start',
    width: 428
  },
  viewWithoutOutline: {
    minHeight: 72,
    marginBottom: 16,
    padding: 24,
    flexDirection: 'column',
  },
  viewSelectedWithOutline: {
    borderColor: Color.gray80,
  },
  viewSelectedWithoutOutline: {
    borderColor: 'transparent',
  },
  buttonFrame: {
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  icon: {
    width: 24,
    height: 24,
  },
  text: {
    fontSize: 16,
    color: Color.gray100,
    lineHeight: 24,
    marginLeft: 14,
  }
});

const large = StyleSheet.create({
  viewWithOutline: {
    minHeight: 70,
    marginBottom: 16,
    paddingHorizontal: 24,
    flexDirection: 'column',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Color.gray10,
  },
  viewWithoutOutline: {
    minHeight: 70,
    marginBottom: 16,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center'
  },
  viewSelectedWithOutline: {
    borderColor: Color.gray80,
  },
  viewSelectedWithoutOutline: {
    borderColor: 'transparent',
  },
  buttonFrame: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  icon: {
    width: 24,
    height: 24,
  },
  text: {
    fontSize: 16,
    color: Color.gray100,
    lineHeight: 24,
    marginLeft: 14,
    paddingVertical: 18,
  },
});

const medium = StyleSheet.create({
  viewWithOutline: {
    minHeight: 24,
    minWidth: 96,
    paddingHorizontal: 0,
    flexDirection: 'column',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Color.gray10,
  },
  viewWithoutOutline: {
    minHeight: 24,
    minWidth: 96,
    paddingHorizontal: 0,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: 'transparent',
  },
  viewSelectedWithOutline: {
    borderColor: Color.gray80,
  },
  viewSelectedWithoutOutline: {
    borderColor: 'transparent',
  },
  buttonFrame: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  icon: {
    width: 24,
    height: 24,
  },
  text: {
    fontSize: 16,
    color: Color.gray100,
    lineHeight: 24,
    marginLeft: 10,
    paddingVertical: 18,
  },
});
