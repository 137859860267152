import React, { useCallback, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Integration, PlatformType } from '../../../domain';
import { Color } from '../../constants';
import { PlatformTypeDecorator } from '../../decorators';
import { useAppContext } from '../../hooks';
import { AppText } from '../AppText';
import { If } from '../If';
import { Clickable } from '../Clickable';

interface Props {
  platforms: PlatformType[];
  selectedPlatform?: PlatformType;
  onPressPlatform: (platform?: PlatformType) => void;
}

export const PlatformDropdown = ({ platforms, selectedPlatform, onPressPlatform }: Props): React.ReactElement => {
  const { integrations } = useAppContext();
  const [visible, setVisible] = useState(false);

  const onPress = useCallback(() => {
    setVisible(visible => !visible);
  }, []);

  const onDeselect = useCallback(() => {
    onPressPlatform(undefined);
    setVisible(false);
  }, [onPressPlatform]);

  const onSelect = useCallback((platform: PlatformType) => {
    onPressPlatform(platform);
    setVisible(false);
  }, [onPressPlatform]);

  return <>
    <Clickable name="platform_dropdown_open" onPress={onPress}>
      <View style={styles.view}>
        {selectedPlatform ? <View style={styles.badge} /> : null}
        <AppText style={styles.title}>
          {selectedPlatform
            ? new PlatformTypeDecorator(selectedPlatform, integrations).name()
            : 'すべて'}
        </AppText>
        <Image
          source={
            visible
              ? require('../../assets/icon/cursor_up_white.png')
              : require('../../assets/icon/cursor_down_white.png')
          }
          style={styles.cursor}
          resizeMode="contain"
        />
      </View>
    </Clickable>
    <If condition={visible}>
      <View style={selectionStyles.view}>
        <Clickable
          name="platform_dropdown_select"
          id='all'
          useGestureHandlerTouchableOpacity={true}
          onPress={onDeselect}>
          <View style={selectionStyles.platform}>
            <AppText style={selectionStyles.title} numberOfLines={1}>
              すべて
            </AppText>
          </View>
        </Clickable>
        {platforms.map(platformType =>
          <PlatformItem
            key={`PlatformDropdown-PlatformItem-${platformType}`}
            {...{ platformType, integrations, onSelect }} />
        )}
      </View>
    </If>
  </>;
};

type PlatformItemProps = {
  platformType: PlatformType
  integrations: Integration[] | undefined
  onSelect: (platformType: PlatformType) => void
}

const PlatformItem = ({ platformType, onSelect, integrations }: PlatformItemProps) => {
  const onPress = useCallback(() => onSelect(platformType), [platformType, onSelect]);

  return <Clickable
    name="platform_dropdown_select"
    id={platformType}
    useGestureHandlerTouchableOpacity={true}
    onPress={onPress}>
    <View style={selectionStyles.platform}>
      <Image
        source={new PlatformTypeDecorator(platformType, integrations).icon()}
        style={styles.icon}
        resizeMode="contain"
      />
      <AppText style={selectionStyles.title} numberOfLines={1}>
        {new PlatformTypeDecorator(platformType, integrations).name()}
      </AppText>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 2,
    borderWidth: 4,
    borderRadius: 4,
    borderColor: Color.red,
  },
  title: {
    fontSize: 14,
    color: Color.white,
  },
  cursor: {
    width: 16,
    height: 16,
    marginLeft: 6,
    borderRadius: 2,
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: 8,
    borderRadius: 20
  },
});

const selectionStyles = StyleSheet.create({
  view: {
    width: 240,
    position: 'absolute',
    top: 32,
    right: 80,
    borderRadius: 6,
    paddingBottom: 24,
    paddingHorizontal: 24,
    backgroundColor: Color.white,
    shadowColor: Color.gray100,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 16,
    shadowOpacity: 0.2,
    zIndex: 100
  },
  platform: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
  },
  title: {
    fontSize: 16,
    color: Color.gray100,
  },
});
