import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Brand } from '../../../domain';

interface Params {
  accountId: string
}

export const useBrands = (params: Params | undefined): [Brand[] | undefined, boolean, Error | undefined] =>
  useCollectionData<Brand>(
    params
    && firebase.firestore()
      .collection('accounts')
      .doc(params.accountId)
      .collection('brands')
      .where('disabled', '==', false),
    { idField: 'id' }
  );
