import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { PermissionsAndroid } from 'react-native';
import { Printer } from '../../../domain';
import { Environment } from '../../../Environment';
import { alert, confirm, } from '../../../infrastructure';
import { PrinterList, WithScreen } from '../../components';
import { Route } from '../../constants';
import { useAppContext, useEventBus, usePrinterDiscoverer } from '../../hooks';

/** 選択できるプリンタの最大数 */
const MAX_SELECTED_PRINTER_COUNT = 2;

export const SettingsPrintersView = (): React.ReactElement => {
  const navigation = useNavigation<NavigationProp<Route>>();
  const [publish] = useEventBus();
  const { connectedPrinters } = useAppContext();
  const { foundPrinters, startDiscovering, stopDiscovering, discovering, loading, error } = usePrinterDiscoverer();

  useEffect(() => {
    if (Environment.platform.type !== 'android') return;

    PermissionsAndroid.requestMultiple([
      PermissionsAndroid.PERMISSIONS.BLUETOOTH_SCAN,
      PermissionsAndroid.PERMISSIONS.BLUETOOTH_CONNECT,
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION
    ] as any); // eslint-disable-line @typescript-eslint/no-explicit-any
  }, []);

  const onPressConnectPrinter = useCallback((printer: Printer) => {
    if (connectedPrinters.length >= MAX_SELECTED_PRINTER_COUNT) {
      alert(
        '接続可能なプリンターの数が上限に達しています。接続中のプリンターを1つ解除してください。',
        undefined,
        { text: '閉じる' }
      );
      return;
    }

    printer.shouldSelectPrinterModelManually
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ? navigation.navigate('PrinterModel', { printer: printer as any })
      : publish({ type: 'printer_added', value: { printer } });
  }, [navigation, connectedPrinters, publish]);

  const onPressDisconnectPrinter = useCallback((printer: Printer) => {
    confirm(
      '接続中のプリンターを解除しますか？',
      `${printer.modelName}\n${printer.identifier}`,
      {
        text: '解除する',
        onPress: () => { publish({ type: 'printer_removed', value: { printer } }); },
      },
      { text: 'キャンセル', style: 'cancel' },
      { cancelable: true }
    );
  }, [publish]);

  const onChangeDiscovering = useCallback((discovering: boolean) =>
    discovering ? startDiscovering() : stopDiscovering()
  , [startDiscovering, stopDiscovering]);

  return <WithScreen {...{ loading, error }}>
    <PrinterList
      {...{ foundPrinters, discovering, onPressConnectPrinter, onPressDisconnectPrinter, onChangeDiscovering }} />
  </WithScreen>;
};
