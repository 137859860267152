import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { Color } from '../../../../constants';
import { AppText } from '../../../AppText';
import { Clickable } from '../../../Clickable';

interface Props {
  onPress?: () => void
}

export const SeeMoreButton = ({ onPress }: Props): ReactElement =>
  <View style={styles.view}>
    <Clickable name='continuous_tap' onPress={onPress}>
      <AppText style={styles.text}>↓ 続き</AppText>
    </Clickable>
  </View>;

const styles = StyleSheet.create({
  view: {
    position: 'absolute',
    left: 'auto',
    right: 'auto',
    bottom: 32,
    minWidth: 66,
    minHeight: 28,
    backgroundColor: Color.gray80,
    padding: 2,
    borderRadius: 33,
    alignItems: 'center',
    alignSelf: 'center',
  },
  text: {
    color: Color.white,
    fontSize: 14,
    fontWeight: '500',
  },
});

