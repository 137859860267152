import { useCallback, useEffect, useState } from 'react';
import { Printer } from '../../../domain';
import { ConnectedPrinterStore, logger } from '../../../infrastructure';
import { composeDisposers, PrinterEvent, PrintersEvent, useEventBus } from './useEventBus';

type Response = {
  connectedPrinters: Printer[]
}

export const useConnectedPrinters = (): Response => {
  const [, subscribe] = useEventBus();
  const [connectedPrinters, setConnectedPrinters] = useState<Printer[]>();

  const addConnectedPrinter = useCallback((printer: Printer) => {
    const printers = [...connectedPrinters ?? [], printer]
      .filter((x, i, array) => array.findIndex(y => y.isEqual(x)) === i);
    setConnectedPrinters(printers);
  }, [connectedPrinters]);

  const removeConnectedPrinter = useCallback((printer: Printer) => {
    printer.dispose();
    setConnectedPrinters(previous => previous?.filter(value => !value.isEqual(printer)) ?? []);
  }, []);

  const removeAllTheConnectedPrinters = useCallback(() => {
    connectedPrinters?.forEach(printer => printer.dispose());
    setConnectedPrinters([]);
  }, [connectedPrinters]);

  useEffect(() => {
    new ConnectedPrinterStore().load()
      .then(setConnectedPrinters)
      .catch(e => { logger.error(e); });
  }, []);

  useEffect(() => {
    if (!connectedPrinters) return;

    new ConnectedPrinterStore().save(connectedPrinters)
      .catch(e => { logger.error(e); });
  }, [connectedPrinters]);

  useEffect(() => {
    return composeDisposers([
      subscribe<PrinterEvent>('printer_added', ({ printer }) => {
        addConnectedPrinter(printer);
      }),
      subscribe<PrinterEvent>('printer_removed', ({ printer }) => {
        removeConnectedPrinter(printer);
      }),
      subscribe<PrintersEvent>('printer_cleared', () => {
        removeAllTheConnectedPrinters();
      })
    ]);
  }, [addConnectedPrinter, removeAllTheConnectedPrinters, removeConnectedPrinter, subscribe]);

  return { connectedPrinters: connectedPrinters ?? [] };
};
