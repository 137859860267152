import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Brand, Item, Platform } from '../../../../domain';
import { Color } from '../../../constants';
import { BrandListItem } from './BrandListItem';

interface Props {
  brands: Brand[];
  platforms: Platform[];
  soldOutItems: Item[];

  onPressBrand: (brand: Brand) => void;
}

export const BrandList = ({ brands, platforms, soldOutItems, onPressBrand }: Props): React.ReactElement => {
  const calculateSoldOutItemCountByBrandId = (brand: Brand) => {
    const activePlatformTypesByBrandId = Array.from(new Set(platforms
      .filter(platform => platform.brandId === brand.id)
      .filter(platform => !platform.disabled)
      .map(platform => platform.type)));

    return soldOutItems
      .filter(item => item.brandId === brand.id)
      .filter(item => activePlatformTypesByBrandId.includes(item.platformType))
      .length;
  };

  return <ScrollView style={styles.view}>
    {brands.map(brand =>
      <BrandListItem
        key={`BrandList-BrandListItem-${brand.id}`}
        {...{ brand, soldOutCount: calculateSoldOutItemCountByBrandId(brand), onPressBrand }} />
    )}
  </ScrollView>;
};

const styles = StyleSheet.create({
  view: {
    backgroundColor: Color.gray5,
  },
});
