import { Picker } from '@react-native-picker/picker';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import {
  buildAcceptanceMethod,
  CalculationMethod,
  calculationMethodsFor,
  COOKING_TIMES,
  CookingTime,
  localizedCalculationMethod,
  mapAcceptanceMethodToCalculationMethod,
  mapAcceptanceMethodToCookingTime, platformToRelation
} from '../../../domain';
import { AppText, If, Radio, Clickable, WithModal } from '../../components';
import { Color, Route } from '../../constants';
import { useAppContext, useGetMaxCookingTime, usePlatformSettingOperation } from '../../hooks';

export const AcceptanceMethodScreen = ({ navigation, route }: StackScreenProps<Route, 'AcceptanceMethod'>): React.ReactElement => {
  const { brandId, platformType } = route.params;
  const { platforms, integrations } = useAppContext();

  const [selectedCookingTime, setSelectedCookingTime] = useState<CookingTime | undefined>();
  const [selectedCalculationMethod, setSelectedCalculationMethod] = useState<CalculationMethod | undefined>();
  const [maxCookingMinute, setMaxCookingMinute] = useState<number>();

  const { call, loading: loading1, error: error1 } = usePlatformSettingOperation();
  const { call: getMaxCookingTime, loading: loading2, error: error2 } = useGetMaxCookingTime();

  const cookingTimes = useMemo(() =>
    maxCookingMinute ? COOKING_TIMES.filter(time => time <= maxCookingMinute) : []
  , [maxCookingMinute]);

  const currentAcceptanceMethod = useMemo(() => platforms
    ?.find(platform => platform.brandId === brandId && platform.type === platformType)
    ?.acceptanceMethod
  , [platforms, brandId, platformType]);

  const currentCookingTime = useMemo(() => currentAcceptanceMethod
    ? mapAcceptanceMethodToCookingTime(currentAcceptanceMethod) ?? 30
    : 30
  , [currentAcceptanceMethod]);

  const currentCalculationMethod = useMemo(() => currentAcceptanceMethod
    ? mapAcceptanceMethodToCalculationMethod(currentAcceptanceMethod)
    : 'automatic-automatic'
  , [currentAcceptanceMethod]);

  const calculationMethods = useMemo(() =>
    calculationMethodsFor(integrations?.find(integration => integration.platformType === platformType))
  , [integrations, platformType]);

  useEffect(() => {
    const platform = platforms?.find(platform => brandId === platform.brandId && platformType === platform.type);
    if (!platform) return;

    getMaxCookingTime(platformToRelation(platform))
      .then(({ maxPreparationMinute }) => setMaxCookingMinute(maxPreparationMinute));
  }, [platforms, getMaxCookingTime, brandId, platformType]);

  const onPress = useCallback(() => {
    if (!selectedCookingTime && !selectedCalculationMethod) return navigation.goBack();

    const acceptanceMethod = buildAcceptanceMethod(
      selectedCookingTime ?? currentCookingTime,
      selectedCalculationMethod ?? currentCalculationMethod
    );
    const operation = { type: 'CHANGE_ACCEPTANCE_METHOD' as const, acceptanceMethod };

    call({ operation, brandId, platformType })
      .then(() => navigation.goBack());
  }, [
    navigation,
    brandId,
    platformType,
    call,
    selectedCookingTime,
    selectedCalculationMethod,
    currentCookingTime,
    currentCalculationMethod
  ]);

  const onValueChange = useCallback((value: string) => setSelectedCookingTime(Number(value) as CookingTime), []);

  const Header = <>
    <AppText style={styles.title}>受注・調理時間設定を選択してください</AppText>
    <View style={styles.border}/>
  </>;

  const Body = <View style={styles.body}>
    <View style={{ flex: 1 }}>
      <ScrollView>
        {calculationMethods.map(calculationMethod =>
          <CalculationMethodItem
            key={`brands-${brandId}-platforms-${platformType}-calculationMethods-${calculationMethod}`}
            {...{
              calculationMethod,
              selected: calculationMethod === (selectedCalculationMethod ?? currentCalculationMethod),
              onPressCalculationMethod: setSelectedCalculationMethod
            }} />
        )}
      </ScrollView>
      <If condition={['automatic-fixed', 'manual-fixed'].includes(selectedCalculationMethod ?? currentCalculationMethod)}>
        <View style={styles.border2} />
        <View style={styles.cookingTimeSelector}>
          <AppText>調理時間を指定してください</AppText>
          <Picker
            style={styles.cookingTimePicker}
            enabled={['automatic-fixed', 'manual-fixed'].includes(selectedCalculationMethod ?? currentCalculationMethod)}
            itemStyle={styles.pickerItem}
            selectedValue={(selectedCookingTime ?? currentCookingTime).toString()}
            onValueChange={onValueChange}>
            {cookingTimes
              .map(minute => ({ label: `${minute}分`, value: minute.toString() }))
              .map(({ label, value }) => <Picker.Item
                {...{ label, value }}
                key={`acceptance-method-setting-list-item-${brandId}-platforms-${platformType}-minutes-${value}`} />
              )}
          </Picker>
        </View>
      </If>
    </View>
    <Clickable name="close_modal" onPress={onPress} style={styles.changeButton}>
      <AppText style={styles.changeButtonText}>変更</AppText>
    </Clickable>
  </View>;

  return <WithModal {...{ Header, Body, loading: loading1 || loading2, error: error1 || error2 }} />;
};

type CalculationMethodItemProps = {
  calculationMethod: CalculationMethod
  selected: boolean
  onPressCalculationMethod: (calculationMethod: CalculationMethod) => void
};

const CalculationMethodItem = (
  { calculationMethod, selected, onPressCalculationMethod }: CalculationMethodItemProps
) => {
  const onPress = useCallback(() =>
    onPressCalculationMethod(calculationMethod)
  , [calculationMethod, onPressCalculationMethod]);

  return <Radio {...{
    text: localizedCalculationMethod(calculationMethod),
    selected,
    size: 'large',
    outline: true,
    onPress
  }}/>;
};

const styles = StyleSheet.create({
  body: {
    justifyContent: 'space-between',
    alignSelf: 'center',
    height: 500,
    width: 500,
    padding: 40,
  },
  title: {
    height: 70,
    paddingHorizontal: 40,
    fontSize: 18,
    lineHeight: 70,
    textAlign: 'center'
  },
  border: {
    height: 2,
    width: '100%',
    backgroundColor: Color.gray10,
  },
  border2: {
    height: 1,
    width: '100%',
    backgroundColor: Color.gray10,
  },
  cookingTimeSelector: {
    height: 48,
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cookingTimePicker: {
    width: 120,
    marginEnd: 8,
    color: Color.gray100,
    fontSize: 14,
    fontWeight: '400',
  },
  pickerItem: {
    height: 96,
    color: Color.gray100,
    fontSize: 14,
    fontWeight: '400',
  },
  changeButton: {
    width: 296,
    height: 56,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#372E61',
    borderRadius: 4
  },
  changeButtonText: {
    color: Color.white,
    fontSize: 18,
  },
});

