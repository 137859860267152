import deepEqual from 'fast-deep-equal';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { deliveryToRelation, Order, OrderDispatch, orderToRelation, OrderWithDelivery } from '../../../../domain';
import { pickObject } from '../../../../utils';
import { Color } from '../../../constants';
import { OrderDecorator } from '../../../decorators';
import { useAppContext, useDeliveries, useDeliveryEstimations, useOrderDispatches } from '../../../hooks';
import { equalProps } from '../../../utils';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { LeftPane } from './LeftPane';
import { RightPane } from './RightPane';

interface Props {
  orderId: string
  order: Order | undefined
  onPressConfirm: ((order: Order) => void) | undefined;
  onPressSupport: ((order: Order) => void) | undefined;
  onPressPrint: ((orderWithDelivery: OrderWithDelivery) => void) | undefined;
}

export const OrderDetailView = (
  { orderId, order: propsOrder, onPressConfirm, onPressSupport, onPressPrint }: Props
): React.ReactElement => {
  const { activeOrders } = useAppContext();
  const [order, setOrder] = useState<Order | undefined>();
  const [orderDispatches] = useOrderDispatches(
    order && { ...pickObject(order, ['accountId', 'storeId', 'brandId']), orderId: order.id }
  );

  const [deliveries] = useDeliveries(order ? orderToRelation(order) : undefined);
  const [deliveryEstimations] = useDeliveryEstimations(deliveries?.[0] ? deliveryToRelation(deliveries[0]) : undefined);

  const orderDispatch = useMemo(() => orderDispatches?.[0], [orderDispatches]);
  const orderWithDelivery = useMemo(() => order
    ? { order, delivery: deliveries?.[0], deliveryEstimation: deliveryEstimations?.[0] }
    : undefined
  , [order, deliveries, deliveryEstimations]);

  useEffect(() => {
    propsOrder && setOrder(propsOrder);
  }, [propsOrder]);

  useEffect(() => {
    const newOrder = activeOrders?.find(order => order.id === orderId);
    if (newOrder && !deepEqual(newOrder, order))
      setOrder(newOrder);
  }, [orderId, activeOrders, order]);

  return orderWithDelivery
    ? <ViewComponent {...{ orderWithDelivery, orderDispatch, onPressConfirm, onPressSupport, onPressPrint }}/>
    : <ActivityIndicator style={styles.indicator} size='large' color='#999999' animating={true} hidesWhenStopped/>;
};

type ViewProps = {
  orderWithDelivery: OrderWithDelivery
  orderDispatch: OrderDispatch | undefined
  onPressConfirm: ((order: Order) => void) | undefined;
  onPressSupport: ((order: Order) => void) | undefined;
  onPressPrint: ((orderWithDelivery: OrderWithDelivery) => void) | undefined;
};

const ViewComponent = memo(function ViewComponent (
  { orderWithDelivery, orderDispatch, onPressConfirm, onPressSupport, onPressPrint }: ViewProps
) {
  return <>
    <If condition={orderDispatch?.execution?.type === 'failed'}>
      <View style={styles.posErrorView}>
        <AppText style={styles.posErrorText}>
          【POS送信エラー】POS連携されていないメニューがあります。注文内容をPOSに入力してください。
        </AppText>
      </View>
    </If>
    <If condition={orderWithDelivery.order.deliveryTimeType.type === 'scheduled'}>
      <View style={styles.scheduledView}>
        <AppText style={styles.scheduledText}>{scheduledText(orderWithDelivery.order)}</AppText>
      </View>
    </If>
    <View style={styles.view}>
      <LeftPane {...{ order: orderWithDelivery.order }}/>
      <RightPane {...{ orderWithDelivery, orderDispatch, onPressConfirm, onPressSupport, onPressPrint }} />
    </View>
  </>;
}, equalProps());

const scheduledText = (order: Order): string =>
  order.deliveryTimeType.type === 'scheduled'
    ? `配達予定は${new OrderDecorator(order).scheduledTimeText('relative')}です。調理開始時刻になるとアラートが表示されます。`
    : '';

const styles = StyleSheet.create({
  view: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
  },
  posErrorView: {
    padding: 12,
    backgroundColor: Color.red,
  },
  posErrorText: {
    color: Color.white,
    fontSize: 14,
    fontWeight: '500',
  },
  scheduledView: {
    padding: 12,
    backgroundColor: Color.purple100,
  },
  scheduledText: {
    color: Color.blue,
    fontSize: 14,
    fontWeight: '500',
  },
  indicator: {
    position: 'absolute',
    top: '50%',
    right: '50%'
  },
});
