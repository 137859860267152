import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { Formatter } from '../../utils';
import { AppText } from '../AppText';
import { If } from '../If';
import { Clickable } from '../Clickable';
import { Calendar, DateData } from 'react-native-calendars';

interface Props {
  selected: Dayjs;
  selectable: { min: Dayjs, max: Dayjs }

  onPressDate: (date: Dayjs) => void;
}

export const CalendarDropdown = ({ selected, selectable: { min, max }, onPressDate }: Props): React.ReactElement => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [visible, setVisible] = useState(false);

  //TODO: Allow multiple dates to be selected.
  const markedDates = useMemo(() => ({
    [selected.format('YYYY-MM-DD')] : {
      selected: true,
      selectedTextColor: Color.white,
      selectedColor: Color.blue_light
    }
  }), [selected]);

  const onPressDropdown = useCallback(() => {
    onPressDate(selected);
    setVisible(visible => !visible);
  }, [selected, onPressDate]);

  const onDayPress = useCallback(({ dateString }: DateData) => {
    onPressDate(dayjs(dateString));
    setVisible(false);
  },[onPressDate]);

  const onPressArrowLeft = useCallback(() => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  }, [currentDate]);

  const onPressArrowRight = useCallback(() => {
    setCurrentDate(currentDate.add(1, 'month'));
  }, [currentDate]);

  const renderLeftArrow = useCallback((direction: 'left' | 'right') =>
    direction === 'left'
      ? <Image
        source={require('../../assets/icon/select_arrow_left.png')}
        style={calendarsStyles.icon}
        resizeMode="contain" />
      : <View style={calendarsStyles.spacing} />
  , []);

  const renderRightArrow = useCallback((direction: 'left' | 'right') =>
    direction === 'right'
      ? <Image
        source={require('../../assets/icon/select_arrow_right.png')}
        style={calendarsStyles.icon}
        resizeMode="contain" />
      : <View style={calendarsStyles.spacing} />
  , []);

  return <>
    <Clickable name="calendar_dropdown_open" onPress={onPressDropdown}>
      <View style={styles.view}>
        <AppText style={styles.title}>{Formatter.day(selected.toDate())}</AppText>
        <View style={styles.iconView}>
          <Image
            source={visible ? require('../../assets/icon/cursor_up.png') : require('../../assets/icon/cursor_down.png')}
            style={styles.icon}
            resizeMode="contain" />
        </View>
      </View>
    </Clickable>
    <If condition={visible}>
      <View style={calendarsStyles.view}>
        <View style={calendarsStyles.container}>
          <Calendar
            disableArrowRight
            hideExtraDays
            initialDate={currentDate.subtract(1, 'month').format('YYYY-MM-DD')}
            minDate={min.format('YYYY-MM-DD')}
            maxDate={max.format('YYYY-MM-DD')}
            renderArrow={renderLeftArrow}
            onDayPress={onDayPress}
            theme={theme}
            onPressArrowLeft={onPressArrowLeft}
            markedDates={markedDates}
            testID='left_calendar' />
          <Calendar
            disableArrowLeft
            hideExtraDays
            initialDate={currentDate.format('YYYY-MM-DD')}
            minDate={min.format('YYYY-MM-DD')}
            maxDate={max.format('YYYY-MM-DD')}
            renderArrow={renderRightArrow}
            theme={theme}
            onDayPress={onDayPress}
            onPressArrowRight={onPressArrowRight}
            markedDates={markedDates}
            testID='right_calendar' />
        </View>
      </View>
    </If>
  </>;
};

const styles = StyleSheet.create({
  view: {
    minWidth: 268,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 6,
    borderColor: Color.gray10,
    borderWidth: 2,
    overflow: 'visible',
  },
  title: {
    fontSize: 16,
    color: Color.gray100,
    marginLeft: 6,
  },
  iconView: {
    width: 40,
    height: 40,
    borderRadius: 6,
    backgroundColor: Color.gray5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 16,
    height: 16,
  }
});

const calendarsStyles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    width: 728,
    position: 'absolute',
    top: 60,
    right: 0,
    borderRadius: 6,
    paddingVertical: 8,
    backgroundColor: Color.white,
    shadowColor: Color.gray100,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 16,
    shadowOpacity: 0.2,
    zIndex: Platform.OS === 'android' ? 10 : 0,
    elevation: Platform.OS === 'android' ? 10 : 0,
  },
  container: {
    flexDirection: 'row',
  },
  spacing: {
    width: 40,
    height: 40
  },
  icon: {
    width: 40,
    height: 40
  },
});

const theme = {
  'stylesheet.calendar.header': {
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 0,
    },
    arrow: {
      paddingVertical: 10,
    },
    monthText: {
      color: Color.gray100,
      fontSize: 16,
      fontWeight: '500',
      paddingTop: 5
    },
    week: {
      marginTop: 0,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    dayHeader: {
      color: Color.gray100,
      fontSize: 12,
      fontWeight: '400',
      marginTop: 2,
      marginBottom: 7
    }
  },
  'stylesheet.calendar.main': {
    container: {
      backgroundColor: Color.white,
      paddingHorizontal: 12
    },
    monthView: {
      borderColor: Color.white,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
    },
    dayContainer: {
      backgroundColor: 'transparent',
      borderColor: Color.white,
      borderTopWidth: 1,
      borderRightWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontWeight: '400',
      height: 48,
      width: 48,
    },
    week: {
      marginTop: 0,
      marginBottom: 0,
      flexDirection: 'row',
      justifyContent: 'space-around'
    },
  },
  'stylesheet.day.basic': {
    selected: { borderRadius: 0, height: 48, width: 48, paddingVertical: 8, }
  }
} as any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
