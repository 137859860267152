import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { BusinessDayTime, ExtraBusinessDayTime, isBusinessDayTimeExtra } from '../../../../../../domain';
import { Color } from '../../../../../constants';
import { decorateBusinessTime, decorateDayOrDate, decorateSectionName } from '../../../../../decorators';
import { AppText } from '../../../../AppText';
import { OutlineButton } from '../../../../buttons/OutlineButton';

interface SectionViewProps {
  businessDayTimes: BusinessDayTime[]
  onPressEdit: () => void
}

export const ExtraViews = ({ businessDayTimes, onPressEdit }: SectionViewProps): React.ReactElement => {
  const data = useMemo(() => businessDayTimes
    .filter(isBusinessDayTimeExtra)
    .sort((a, b) => a.date === b.date ? 0 : a.date > b.date ? 1 : -1)
  , [businessDayTimes]);

  const renderItem = useCallback(({ item }: { item: BusinessDayTime }) =>
    <View style={styles.businessDayTimeTextWrapper}>
      <AppText style={styles.businessDayTimeText}>{decorateDayOrDate(item)}</AppText>
      <AppText style={styles.businessDayTimeText}>{decorateBusinessTime(item)}</AppText>
    </View>, []);

  const keyExtractor = useCallback((businessDayTime: BusinessDayTime) =>
    `extra-views-${businessDayTime.platformType}-${(businessDayTime as ExtraBusinessDayTime).date}`
  , []);

  return <>
    <View style={styles.title}>
      <AppText style={styles.sectionName}>{decorateSectionName('extra' as const, null)}</AppText>
      <OutlineButton size='medium' title='編集' onPress={onPressEdit} />
    </View>
    <FlatList {...{ data, renderItem, keyExtractor }} />
  </>;
};

const styles = StyleSheet.create({
  businessDayTimeTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: 26,
    textAlignVertical: 'top'
  },
  sectionName: {
    color: Color.gray100,
    fontSize: 16,
    marginTop: -3
  },
  businessDayTimeText: {
    fontSize: 14,
    marginRight: 32,
    color: Color.gray70
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    justifyContent: 'space-between',
    marginBottom: -3
  },
});
