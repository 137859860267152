import { Storage } from '../Storage';

/** アプリの更新があるかをチェックした時刻を保存(永続化)と復元をする */
export class AppUpdateCheckTimeStore {
    static appUpdateCheckTimeCache: number | undefined | null = undefined;

    private constructor() {
      // do nothing.
    }

    static async get(): Promise<number | null> {
      if (this.appUpdateCheckTimeCache !== undefined) return this.appUpdateCheckTimeCache;

      const storage = new Storage<number>();
      const appUpdateCheckTimeCache = await storage.get('@APP_UPDATE_CHECK_TIME');
      if (appUpdateCheckTimeCache !== undefined) {
        this.appUpdateCheckTimeCache = appUpdateCheckTimeCache;
        return appUpdateCheckTimeCache;
      } else {
        this.appUpdateCheckTimeCache = null;
        return null;
      }
    }

    static async set(): Promise<void> {
      const storage = new Storage<number>();
      const now = Date.now();
      await storage.save('@APP_UPDATE_CHECK_TIME', now);
      this.appUpdateCheckTimeCache = now;
    }
}
