import React, { ReactNode } from 'react';
import { Linking, StyleSheet, TextProps } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface Props {
  phoneNumber: string | undefined
}

export class PhoneNumberView extends React.Component<Props & TextProps> {
  render() : ReactNode {
    const { phoneNumber, ...textProps } = this.props;

    return <Clickable name="tap_phone_number" id={phoneNumber} onPress={this.onPress}>
      <AppText {...textProps} style={[this.styles.number, this.props.style]}>{phoneNumber}</AppText>
    </Clickable>;
  }

  styles = StyleSheet.create({
    number: {
      fontSize: 16,
      fontWeight: '500',
      color: Color.gray100,
    }
  });

  onPress = (): void => {
    Linking.openURL(`tel:${this.props.phoneNumber}`);
  };
}
