import { ApiRequest } from '../../../client';
import { base } from '../../base';

type Request = {
  deviceId: 'ios' | 'android'
  token: string
}

type Response = {
  status: number
  result: 'ok'
}

export const createDeviceToken = (token: string, { deviceId, ...data }: Request): ApiRequest<Response> =>
  ({ ...base(token), method: 'POST', url: `/api/devices/${deviceId}/device_tokens`, data });
