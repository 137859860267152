import dayjs from 'dayjs';
import { Day } from '../../domain';

const fromNow = (date: Date): string =>
  dayjs(date).fromNow();

const month = (date: Date): string =>
  formatDate(date, 'YYYY-M');

const monthYear = (date: Date): string =>
  formatDate(date, 'M月 YYYY');

const dateAndTime = (date: Date): string =>
  formatDate(date, 'M/D HH:mm');

const day = (date: Date): string =>
  formatDate(date, 'YYYY年MM月DD日(ddd)');

const dayTime = (date: Date): string =>
  formatDate(date, 'YYYY年MM月DD日 HH:mm');

const dayJP = (day: Day): string => ({
  monday: '月曜日',
  tuesday: '火曜日',
  wednesday: '水曜日',
  thursday: '木曜日',
  friday: '金曜日',
  saturday: '土曜日',
  sunday: '日曜日'
})[day];

const dayKey = (date: Date): string =>
  formatDate(date, 'YYYY-MM-DD');

const week = (date: Date): string =>
  formatDate(date, 'M月DD日(ddd)');

const time = (date: Date): string =>
  formatDate(date, 'HH:mm');

const dayTimeAgo = (date: Date): string =>
  dayjs(date).isSame(dayjs(), 'day')
    ? formatDate(date, '今日 HH:mm')
    : formatDate(date, 'M月DD日 HH:mm');

const dayAgo = (date: Date): string =>
  dayjs(date).isSame(dayjs(), 'day')
    ? formatDate(date, '今日')
    : formatDate(date, 'M月DD日');

const price = (price: number): string =>
  `￥${String(Math.floor(price)).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;

const formatDate = (date: Date, format: string) =>
  dayjs(date).format(format);

export const Formatter = {
  fromNow,
  month,
  monthYear,
  dateAndTime,
  day,
  dayTime,
  dayJP,
  dayKey,
  week,
  time,
  dayTimeAgo,
  dayAgo,
  price
};
