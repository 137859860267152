import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { ListRenderItemInfo, StyleSheet, View } from 'react-native';
import { FlatList, Switch } from 'react-native-gesture-handler';
import { BusinessDayTime, BusinessTime, DAYS, RegularBusinessDayTime } from '../../../../../../domain';
import { Environment } from '../../../../../../Environment';
import { alert, logger } from '../../../../../../infrastructure';
import { pickObject } from '../../../../../../utils';
import { Color } from '../../../../../constants';
import { decorateDayOrDate, decorateSectionName } from '../../../../../decorators';
import { useAppContext, useBusinessDayTimesOperation } from '../../../../../hooks';
import { AppText } from '../../../../AppText';
import { IconTextButton } from '../../../../buttons/IconTextButton';
import { OutlineButton } from '../../../../buttons/OutlineButton';
import { PrimaryButton } from '../../../../buttons/PrimaryButton';
import { If } from '../../../../If';
import { TimeInputs } from '../elements';
import {
  RegularBusinessDayTimeFormAction,
  regularBusinessDayTimeFormReducer
} from './regularBusinessDayTimeFormReducer';

interface Props {
  sectionName: string | null
  businessDayTimes: RegularBusinessDayTime[]

  onFinishEditing: () => void
}

export const RegularForms = ({ sectionName, businessDayTimes, onFinishEditing }: Props): React.ReactElement => {
  const { integrations } = useAppContext();
  const [state, dispatch] = useReducer(regularBusinessDayTimeFormReducer, businessDayTimes);
  const callable = useBusinessDayTimesOperation();

  const data = useMemo(() =>
    state.sort((a, b) => DAYS.indexOf(a.day) - DAYS.indexOf(b.day))
  , [state]);

  const supportsUnlimitedRegularTimeFrames = useMemo(() => integrations
    ?.find(({ platformType }) => platformType === businessDayTimes[0]?.platformType)
    ?.features.businessDayTimes.unlimitedRegularTimeFrames.supported
  , [businessDayTimes, integrations]);

  const keyExtractor = useCallback((businessDayTime: RegularBusinessDayTime) =>
    `regular-forms-${businessDayTime.id}`
  , []);

  const onPressSave = useCallback(() =>
    callable.call({ businessDayTimes: state.map(omitTimeStamps) })
      .then(() => onFinishEditing())
  , [callable, onFinishEditing, state]);

  useEffect(() => {
    if (!callable.error) return;

    alert('エラーが発生しました。再度お試しください。', callable.error.message, { text: '閉じる' }, { cancelable: false });
    logger.error(callable.error);
  }, [callable.error]);

  const renderItem = useCallback(({ item: businessDayTime }: { item: RegularBusinessDayTime }) =>
    <RegularForm {...{ businessDayTime, dispatch, supportsUnlimitedRegularTimeFrames }}/>
  , [supportsUnlimitedRegularTimeFrames]);

  return <>
    <View style={styles.title}>
      <AppText style={styles.sectionName}>{decorateSectionName('regular' as const, sectionName)}</AppText>
      <View style={styles.buttonGroup}>
        <OutlineButton disabled={callable.loading} size='medium' title='キャンセル' onPress={onFinishEditing} />
        <View style={styles.buttonSeparator} />
        <PrimaryButton disabled={callable.loading} size='medium' title='保存' onPress={onPressSave} />
      </View>
    </View>
    <View><FlatList {...{ data, renderItem, keyExtractor }} /></View>
  </>;
};

interface RegularFormProps {
  businessDayTime: BusinessDayTime
  dispatch: React.Dispatch<RegularBusinessDayTimeFormAction>
  supportsUnlimitedRegularTimeFrames: boolean | undefined
}

const RegularForm = ({ businessDayTime, dispatch, supportsUnlimitedRegularTimeFrames }: RegularFormProps) => {
  const renderItem = useCallback(({ item, index }: ListRenderItemInfo<BusinessTime>) =>
    <TimeInputs
      businessDayTimeId={businessDayTime.id}
      businessTime={item}
      dispatch={dispatch}
      sectionIndex={0}
      timeInputsIndex={index} />
  , [businessDayTime.id, dispatch]);

  const keyExtractor = useCallback(({ startTime, endTime }: BusinessTime) =>
    `regular-forms-business-day-time-${businessDayTime.id}-${startTime}-${endTime}`
  , [businessDayTime.id]);

  const onValueChange = useCallback((value: boolean) =>
    dispatch({ type: 'switch', payload: { id: businessDayTime.id, availavility: value } })
  , [businessDayTime.id, dispatch]);

  const onPressAddTimes = useCallback(() =>
    dispatch({ type: 'addTimes', payload: { id: businessDayTime.id } })
  , [businessDayTime.id, dispatch]);

  return <View style={styles.regularForm}>
    <View style={styles.dayView}>
      <AppText>{decorateDayOrDate(businessDayTime)}</AppText>
    </View>
    <View style={styles.toggleWrapper}>
      <View style={styles.regularFormToggle}>
        <Switch
          testID={`switch-businessDayTime-${businessDayTime.id}`}
          trackColor={{ false: Color.gray30, true: Color.brand }}
          onValueChange={onValueChange}
          value={businessDayTime.businessTimes.length !== 0}
        />
      </View>
      <AppText style={styles.regularFormToggleText}>
        <If condition={businessDayTime.businessTimes.length === 0}>定休日</If>
        <If condition={businessDayTime.businessTimes.length !== 0}>営業日</If>
      </AppText>
    </View>
    <View style={styles.timeInputss}>
      <If condition={businessDayTime.businessTimes.length > 0}>
        <FlatList
          data={businessDayTime.businessTimes}
          renderItem={renderItem}
          keyExtractor={keyExtractor} />
        <If condition={businessDayTime.businessTimes.length < 2 || supportsUnlimitedRegularTimeFrames}>
          <IconTextButton
            title='時間追加'
            icon={require('../../../../../assets/icon/add.png')}
            onPress={onPressAddTimes}
          />
        </If>
      </If>
    </View>
  </View>;
};

//Note: Prevent overwriting of persistentBusinessDayTime timestamps
const omitTimeStamps = (businessDayTime: RegularBusinessDayTime) => ({
  ...pickObject(businessDayTime, ['id', 'accountId', 'storeId', 'brandId', 'platformType']),
  ...pickObject(businessDayTime, ['sectionId', 'sectionName', 'day', 'disabled', 'businessTimes']),
  type: 'regular' as const
});

const styles = StyleSheet.create({
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row'
  },
  buttonSeparator: {
    paddingHorizontal: 6
  },
  dayView: {
    paddingBottom: 11,
    paddingLeft: 12,
    paddingRight: 225,
    paddingTop: 10,
  },
  timeInputss: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    marginBottom: -2
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    justifyContent: 'space-between',
    marginBottom: -3
  },
  regularForm: {
    borderLeftColor: Color.gray5,
    borderLeftWidth: 4,
    borderRadius: 4,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 24,
    width: '100%'
  },
  sectionName: {
    color: Color.gray100,
    fontSize: 16,
    marginTop: -4
  },
  toggleWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  regularFormToggle: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: Environment.platform.type === 'android' ? 0 : Environment.platform.type === 'ios' ? 4 : 8
  },
  regularFormToggleText: {
    alignSelf: 'flex-start',
    paddingTop: 9,
    paddingLeft: 8,
    paddingRight: 24
  },
});
