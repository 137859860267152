import { StackScreenProps } from '@react-navigation/stack';
import { hideAsync } from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { customerSupportNotification, isOrderAccepted, isOrderReady, Order } from '../../domain';
import { Environment } from '../../Environment';
import { logger } from '../../infrastructure';
import { DashboardBar, DashboardBarButton, DashboardBarState, OrderCardList, WithScreen } from '../components';
import { Color, Route } from '../constants';
import { useAppContext, useAppUpdate, useOrderOperation, usePopupNotifications } from '../hooks';
import { equalProps } from '../utils';

export const DashboardScreen = (
  { navigation }: StackScreenProps<Route, 'Dashboard'>
): React.ReactElement => {
  const { platforms, activeOrders } = useAppContext();
  const [barState, setBarState] = useState<DashboardBarState>(DashboardBarState.ALL);
  const [loadingOrderIds, setLoadingOrderIds] = useState<string[]>([]);
  const [orderIds, setOrderIds] = useState<string[]>([]);

  const { call: callOrderOperation, error: errorOperation } = useOrderOperation();

  useAppUpdate();
  usePopupNotifications(customerSupportNotification);

  const orders = useMemo(() => {
    switch (barState) {
      case DashboardBarState.ALL: return activeOrders ?? [];
      case DashboardBarState.ACCEPTED:return activeOrders?.filter(isOrderAccepted) ?? [];
      case DashboardBarState.READY: return activeOrders?.filter(isOrderReady) ?? [];
    }
  }, [activeOrders, barState]);

  useEffect(() => {
    setOrderIds(orders.map(({ id }) => id));
  }, [orders]);

  const onLayout = useCallback(() => {
    hideAsync()
      .catch(error => { logger.info('error', error.message); });
  }, []);

  const onPressBarButton = useCallback((button: DashboardBarButton) => {
    switch (button) {
      case DashboardBarButton.MENU:
        return navigation.navigate('Menu');
      case DashboardBarButton.SHOP:
        return navigation.navigate('OrderPlatformSuspensions');
    }
  }, [navigation]);

  const onPressOrder = useCallback((order: Order) => {
    if (Environment.isNative && orderIds.length > 1) {
      navigation.navigate('OrderDetails', {
        orderIds,
        initialOrderId: order.id,
        title: undefined,
        shouldNotUpdateOrderRead: undefined
      });
    } else {
      navigation.navigate('OrderDetail', {
        orderId: order.id,
        order: undefined,
        title: undefined,
        shouldNotUpdateOrderRead: undefined
      });
    }
  }, [navigation, orderIds]);

  const onPressOrderConfirm = useCallback((order: Order) => {
    const operation = (() => {
      switch (order.status) {
        case 'CREATED': return { type: 'ACCEPT' as const, cookingTime: null };
        case 'ACCEPTED': return { type: 'READY' as const };
        case 'READY': return { type: 'COMPLETE' as const };
      }
    })();

    if (!operation) return;

    const { acceptanceMethod } = platforms?.find(platform => platform.type === order.platform) ?? {};

    if (operation.type === 'ACCEPT' && !acceptanceMethod?.automated && acceptanceMethod?.cookingTimeCalculation.type === 'manual') {
      navigation.navigate('AcceptWithCookingTime', { order });
      return;
    }

    setLoadingOrderIds(loadingOrderIds => [...loadingOrderIds.filter(id => id !== order.id), order.id]);

    return callOrderOperation({ operation, orderId: order.id })
      .finally(() => setLoadingOrderIds(loadingOrders => loadingOrders.filter(id => id !== order.id)));
  }, [callOrderOperation, platforms, navigation]);

  const onChangeBarState = useCallback((state: DashboardBarState) => { setBarState(state); }, []);

  return <DashboardScreenView {...{
    barState,
    orders,
    loadingOrderIds,
    onLayout,
    onChangeBarState,
    onPressBarButton,
    onPressOrder,
    onPressOrderConfirm,
    sectioned: barState === DashboardBarState.ALL,
    error: errorOperation
  }}/>;
};

// TODO: move this presentational component to different file
interface ViewProps {
  orders: Order[];
  loadingOrderIds: string[];
  barState: DashboardBarState;
  sectioned: boolean;

  error: Error | undefined;

  onChangeBarState: (state: DashboardBarState) => void;
  onLayout: () => void;
  onPressBarButton: (button: DashboardBarButton) => void;
  onPressOrder: (order: Order) => void;
  onPressOrderConfirm: (order: Order) => void;
}

const DashboardScreenView = memo(function DashboardScreenView (props: ViewProps) {
  const { orders, loadingOrderIds, barState, sectioned, error } = props;
  const { onChangeBarState, onLayout, onPressBarButton, onPressOrder, onPressOrderConfirm } = props;

  return <WithScreen loading={false} error={error}>
    <SafeAreaView style={{ width: '100%', height: '100%', backgroundColor: Color.dark }} onLayout={onLayout}>
      <StatusBar style='light' />
      <DashboardBar {...{ state: barState, onChangeBarState, onPressBarButton }} />
      <OrderCardList {...{ orders, loadingOrderIds, sectioned, onPressOrder, onPressOrderConfirm }} />
    </SafeAreaView>
  </WithScreen>;
}, equalProps());
