import {
  NotoSansJP_100Thin,
  NotoSansJP_300Light,
  NotoSansJP_400Regular,
  NotoSansJP_500Medium,
  NotoSansJP_700Bold,
  NotoSansJP_900Black,
  useFonts,
} from '@expo-google-fonts/noto-sans-jp';
import { ActionSheetProvider, connectActionSheet } from '@expo/react-native-action-sheet';
import { CommonActions, NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import { useKeepAwake } from 'expo-keep-awake';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  CanceledOrderNotification,
  isAcceptanceAutomated,
  Notification,
  Notifications,
  Order,
  OrdersNotification,
} from './domain';
import { Environment } from './Environment';
import { logger, ReadNewsStore } from './infrastructure';
import { AppText, If, OrderNotificationView, WithOrderReceiptPrinter } from './presentation/components';
import { Color, Route } from './presentation/constants';
import { AppContext } from './presentation/contexts';
import {
  composeDisposers,
  OrderEvent,
  useConnectedPrinters,
  useErrorHandler,
  useEventBus,
  useLoginState, useNetworkState,
  useOrderNotification,
  useOTAUpdater,
  usePlayer,
  usePushNotification,
} from './presentation/hooks';
import { RootNavigator } from './presentation/navigators';

const fonts = {
  NotoSansJP_100Thin,
  NotoSansJP_300Light,
  NotoSansJP_400Regular,
  NotoSansJP_500Medium,
  NotoSansJP_700Bold,
  NotoSansJP_900Black,
};

function App() {
  const [notifications, setNotifications] = useState(new Notifications());
  const [routeName, setRouteName]= useState<string>();

  const navigationRef = useRef<NavigationContainerRef<Route>>(null);

  const [, subscribe] = useEventBus();
  const { store, brands, platforms, integrations, loggedIn, logout, loading, error: errorLogin } = useLoginState();
  const { activeOrders } = useOrderNotification({ store });

  const [isNetworkConnected] = useNetworkState();
  const { connectedPrinters } = useConnectedPrinters();
  const [playNew, stopNew, loadedNew, errorNew] = usePlayer('new');
  const [playCancel,, loadedCancel, errorCancel] = usePlayer('cancel');
  const [fontsLoaded, errorFonts] = useFonts(fonts);

  useKeepAwake();
  useOTAUpdater();
  usePushNotification();
  useErrorHandler(errorLogin || errorFonts || errorNew || errorCancel);

  useEffect(() => {
    ReadNewsStore.initialize();
  }, []);

  useEffect(() => {
    return composeDisposers([
      subscribe<OrderEvent>('order_created', ({ orderWithDelivery: { order } }) => {
        if (isAcceptanceAutomated(order, platforms ?? [])) return;

        playNew();
        setNotifications(notifications => notifications.addNewOrder(order).clone());
      }),
      subscribe<OrderEvent>('order_accepted', ({ orderWithDelivery }) => {
        if (!isAcceptanceAutomated(orderWithDelivery.order, platforms ?? [])) return;

        playNew();
        setNotifications(notifications => notifications.addNewOrder(orderWithDelivery.order).clone());
      }),
      subscribe<OrderEvent>('order_canceled', ({ orderWithDelivery }) => {
        playCancel();
        setNotifications(notifications => {
          const updatedNotifications = notifications.addCanceledOrder(orderWithDelivery.order).clone();
          if (!updatedNotifications.hasAnyOrdersNotification) stopNew();
          return updatedNotifications.clone();
        });
      }),
      subscribe<OrderEvent>('scheduled_order_created', ({ orderWithDelivery: { order } }) => {
        playNew();
        setNotifications(notifications => notifications.addScheduledOrder(order).clone());
      }),
      subscribe<OrderEvent>('scheduled_order_accepted', ({ orderWithDelivery }) => {
        playNew();
        setNotifications(notifications => notifications.addPrepareOrder(orderWithDelivery.order).clone());
      })
    ]);
  }, [platforms, playCancel, playNew, stopNew, subscribe]);

  useEffect(() => {
    if (!fontsLoaded || loading || !loadedNew || !loadedCancel) return;

    navigationRef.current?.dispatch(CommonActions.reset({ index: 0, routes: [{ name: loggedIn ? 'Main' : 'Login' }] }));
  }, [fontsLoaded, loading, loggedIn, loadedNew, loadedCancel]);

  const onNavigationReady = useCallback(() => {
    setRouteName(navigationRef?.current?.getCurrentRoute()?.name ?? '');
  }, [navigationRef]);

  const onNavigationStateChange = useCallback(() => {
    const currentRouteName = navigationRef?.current?.getCurrentRoute()?.name ?? '';
    if (routeName !== currentRouteName) logger.screen(currentRouteName);
    setRouteName(currentRouteName);
  }, [routeName, navigationRef]);

  const removeNotification = useCallback((notification: Notification) => {
    setNotifications(notifications => {
      const updatedNotifications = notifications.removeNotification(notification);
      if (!updatedNotifications.hasAnyOrdersNotification) stopNew();
      return updatedNotifications.clone();
    });
  }, [stopNew]);

  const onPressNotification = useCallback((notification: Notification) => {
    navigateToOrderScreen(navigationRef.current, notification);
    removeNotification(notification);
  }, [removeNotification]);

  const onCloseNotification = useCallback((notification: Notification) => {
    removeNotification(notification);
  }, [removeNotification]);

  const appContext = useMemo(() => ({
    logout,
    store,
    brands,
    platforms,
    integrations,
    activeOrders,
    connectedPrinters
  }), [activeOrders, brands, connectedPrinters, integrations, logout, platforms, store]);

  return <ActionSheetProvider>
    <NavigationContainer ref={navigationRef} onReady={onNavigationReady} onStateChange={onNavigationStateChange}>
      <StatusBar style='auto' />
      <AppContext.Provider value={appContext}>
        <WithOrderReceiptPrinter>
          <RootNavigator />
          <OrderNotificationView {...{
            notifications,
            fullScreen: navigationRef.current?.getCurrentRoute()?.name === 'Dashboard',
            onPress: onPressNotification,
            onClose: onCloseNotification
          }}/>
          <If condition={!isNetworkConnected}>
            <View style={styles.connectWrapper}>
              <AppText style={styles.connect}>ネットワークに接続されていません</AppText>
            </View>
          </If>
        </WithOrderReceiptPrinter>
      </AppContext.Provider>
    </NavigationContainer>
  </ActionSheetProvider>;
}

export default connectActionSheet(App);

const styles = StyleSheet.create({
  connectWrapper: {
    width: '100%',
    height: 88,
    position: 'absolute',
    bottom: 0,
    backgroundColor: Color.red,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,
  },
  connect: {
    fontSize: 18,
    fontWeight: '400',
    color: Color.white,
  },
});

const navigateToOrderScreen = (navigationRef: NavigationContainerRef<Route> | null, notification: Notification) => {
  if (notification instanceof CanceledOrderNotification)
    navigationRef?.navigate('OrderDetail', buildOrderDetailScreenParams(notification.order.id));

  if (notification instanceof OrdersNotification) {
    const { orders, message } = notification;

    if (orders.length === 0) return;

    Environment.isNative
      ? navigationRef?.navigate('OrderDetails', buildOrderDetailsScreenParams(orders, message))
      : navigationRef?.navigate('OrderDetail', buildOrderDetailScreenParams(orders.reverse()[0]?.id ?? ''));
  }
};

const buildOrderDetailsScreenParams = (orders: Order[], title: string): Route['OrderDetails'] => ({
  orderIds: orders.map(({ id }) => id),
  initialOrderId: orders[0]?.id,
  title,
  shouldNotUpdateOrderRead: undefined
});

const buildOrderDetailScreenParams = (orderId: string): Route['OrderDetail'] => ({
  orderId,
  order: undefined,
  title: undefined,
  shouldNotUpdateOrderRead: undefined
});
