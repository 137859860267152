import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { pickObject } from '../../utils';
import { GeolocationAddress } from './Address';
import { HasId } from './core/HasId';
import { Delivery } from './Delivery';
import { DeliveryEstimation } from './DeliveryEstimation';
import { Platform, PlatformRelation } from './Platform';
import { PlatformType } from './PlatformType';

export type OrderStatus = typeof orderStatuses[number];

export const orderStatuses = [
  'CREATED',
  'ACCEPTED',
  'DENIED',
  'READY',
  'COMPLETED',
  'CANCELED',
  'ITEM_CHANGE_REQUESTED',
  'UNKNOWN'
];

export type CanceledReason =
  | 'customer_canceled'
  | 'store_canceled'
  | 'driver_canceled'
  | 'platform_canceled'
  | 'unknown';

export type StatusReason = CanceledReason;

export enum Interaction {
  LEAVE_AT_DOOR = 'leave_at_door',
  DOOR_TO_DOOR = 'door_to_door',
  CURBSIDE = 'curbside',
}

// TODO: Migrate property name to platformType and remove this?
type OrderPlatformRelation = Omit<PlatformRelation<PlatformType>, 'platformType'>
  & { platform: PlatformRelation<PlatformType>['platformType'] };

export type OrderReference = HasId & OrderPlatformRelation;
export type OrderRelation = { orderId: OrderReference['id'] } & OrderPlatformRelation;

export interface OrderWithDelivery {
  order: Order
  delivery: Delivery | undefined
  deliveryEstimation: DeliveryEstimation | undefined
}

export interface Order {
  id: string;
  accountId: string;
  storeId: string;
  brandId: string;
  platform: PlatformType;

  uuid: string;
  displayId: string;

  isRead: boolean;

  // ---------------------------------------------------
  // Status
  status: OrderStatus;
  statusReason: StatusReason | null;
  rawStatusReason: string | null;
  statuses: {
    caller: string;
    status: OrderStatus;
    statusReason: StatusReason | null;
    rawStatusReason: string | null;
    updatedAt: Timestamp;
  }[] | null;

  // ---------------------------------------------------
  // Order content
  comment: string;
  cutlery: boolean | null;
  customer: OrderCustomer;

  items: OrderItem[];
  price: OrderPrice;

  // ---------------------------------------------------
  // How to deliver
  deliveryMethod: 'eat_in' | 'pick_up' | 'delivery';

  inHouseDelivery: {
    /**
     * @deprecated Use Address properties
     */
    title: string;

    address: string;

    comment: string | null;
    interaction: Interaction;
    cash: number | null;
  } & GeolocationAddress | null;

  // ---------------------------------------------------
  deliveryTimeType:
    | { type: 'immediate' }
    | { type: 'scheduled', time: Timestamp | undefined }

  /**
   * @deprecated
   * TODO: Use deliveryTimeType
   */
  scheduled: boolean;

  /**
   * @deprecated
   * TODO: This needs to be a Status
   */
  scheduledAccepted: boolean;

  // ---------------------------------------------------
  // Times
  // TODO: Refactor
  //   - Needs both of Expected and Actual time?
  //   - Split type definition by deliveryMethod
  //      - Ex: If the delivery method is pickup, deliveryTime doesn't make any sense
  //      - Ex: If the delivery method is inhouse, preparationTime / pickupTime doesn't make any sense
  //   - Use same word for 'READY' ( should correspond to preparationTime
  preparationDelayed: boolean;

  preparationTime: Timestamp;

  createTime: Timestamp;
}

export interface OrderCustomer {
  name: string;
  phoneNumber: string;
  uuid: string | null;
  phonePinCode: string | null;
  orderCount: number | null;
}

export interface OrderItem {
  uuid: string | null;
  itemChangeRequestId: string | null;
  title: string;
  price: number;
  quantity: number;
  comment: string | null;
  options: OrderOption[];
}

export interface OrderOption {
  id: string | null;
  uuid: string | null;
  parentId: string | null;
  title: string;
  category: string | null;
  comment: string | null;
  price: number | null;
  quantity: number;
}

export type OrderItemOrOption = OrderItem | OrderOption

export interface OrderPrice {
  subtotal: number;                    // 小計            / sum(Item.price) + sum(Option.price) 配送料金を含まず
  total: number;                       // 合計            / subtotal + sum(promotions) + adjustment
  promotions: number[] | null;         // 割引金額         / 負数
  adjustment: number | null;           // 調整額           / 現状 Uber のみに項目ありだが、未使用？
  delivery: number | null;             // 配送料金         / deliveryPromotion 適用前の金額。 total, subtotal とは関連なし
  deliveryPromotions: number[] | null; // 配送料金への割引
}

/**
 * @deprecated
 * TODO: Use integration info
 */
export const platformTypeSortPriority = (platformType: PlatformType): number =>
  ({
    [PlatformType.BOTEATS]: 0,
    [PlatformType.UBEREATS]: 1,
    [PlatformType.DEMAECAN]: 2,
    [PlatformType.DEMAECAN_OFFICIAL]: 2,
    [PlatformType.MENU]: 5,
    [PlatformType.MENU_OFFICIAL]: 5,
    [PlatformType.SUKESAN]: Number.MAX_VALUE,
    [PlatformType.WOLT]: 6,
    [PlatformType.WOLT_OFFICIAL]: 6,
  })[platformType] ?? Number.MAX_VALUE;

/**
 * 配達方法の詳細。
 *
 * {@link Fulfillment}は{@link Fulfillment.PICKUP}と{@link Fulfillment.DELIVERY}のみだが、
 * {@link FulfillmentDetailType}は{@link FulfillmentDetailType.PICKUP}、
 * {@link FulfillmentDetailType.DELIVERY}、{@link FulfillmentDetailType.INHOUSE_DELIVERY}になる。
 */
export const FulfillmentDetailType = {
  EATIN: 'eat_in',
  PICKUP: 'pick_up',
  DELIVERY: 'delivery',
  INHOUSE_DELIVERY: 'inhouse_delivery',
} as const;
export type FulfillmentDetailType = typeof FulfillmentDetailType[keyof typeof FulfillmentDetailType];

/**
 * アイテムの総数。
 * アイテムごとの個数を足し合わせた数を返す。
 */
export const getOrderTotalItemCount = (order: Pick<Order, 'items'>): number =>
  order.items.length > 0
    ? order.items
      .map(item => item.quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    : 0;

/**
 * 調理にかかる時間[分]
 */
export const getOrderPreparationTime = (order: Pick<Order, 'preparationTime' | 'createTime'>): number | undefined =>
  dayjs(order.preparationTime.toDate()).diff(dayjs(order.createTime.toDate()), 'minute');

/**
 * 配達方法
 */
export const getOrderFulfillmentDetail = (order: Order): FulfillmentDetailType => {
  switch (order.deliveryMethod) {
    case 'pick_up':
      return FulfillmentDetailType.PICKUP;
    case 'eat_in':
      return FulfillmentDetailType.EATIN;
    case 'delivery':
      if (order.inHouseDelivery) {
        return FulfillmentDetailType.INHOUSE_DELIVERY;
      } else {
        return FulfillmentDetailType.DELIVERY;
      }
  }
};

export const getOrderPlatformTypeToDisplay = (order: Pick<Order, 'platform' | 'createTime'>): PlatformType =>
  order.platform === PlatformType.BOTEATS
    ? randomPlatform(order.createTime)
    : order.platform;

export const isOrderCreated = (order: Order): boolean =>
  order.status === 'CREATED' && (order.deliveryTimeType.type !== 'scheduled' || !order.scheduledAccepted);

export const isOrderAccepted = (order: Order): boolean =>
  order.status === 'ACCEPTED';

export const isOrderReady = (order: Order): boolean =>
  order.status === 'READY';

export const isOrderScheduled = (order: Order): boolean =>
  order.status === 'CREATED' && (order.deliveryTimeType.type === 'scheduled' && order.scheduledAccepted);

export const isAcceptanceAutomated = (order: Order, platforms: Platform[]): boolean =>
  platforms
    .find(platform => platform.brandId === order.brandId && platform.type === order.platform)
    ?.acceptanceMethod
    .automated
    ?? true;

export const orderToReference = (order: Order): OrderReference =>
  pickObject(order, ['id', 'accountId', 'storeId', 'brandId', 'platform']);

export const orderToRelation = (order: Order): OrderRelation => ({
  ...pickObject(order, ['accountId', 'storeId', 'brandId', 'platform']),
  orderId: order.id
});

const randomPlatform = (timestamp: Timestamp): PlatformType => {
  // TODO: Use integration info
  const ignored = [PlatformType.BOTEATS, PlatformType.DEMAECAN, PlatformType.DEMAECAN_OFFICIAL, PlatformType.SUKESAN];
  const platforms = Object.values(PlatformType).filter(p => !ignored.includes(p));
  return platforms[(timestamp.toDate().getMinutes() + timestamp.toDate().getSeconds()) % platforms.length]
    ?? PlatformType.UBEREATS;
};
