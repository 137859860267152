import { Alert, AlertButton, AlertOptions } from 'react-native';
import { Environment } from '../Environment';

export const alert = (
  title: string,
  message?: string,
  button?: AlertButton,
  options?: AlertOptions,
): void => {
  if (Environment.platform.type === 'ios' || Environment.platform.type === 'android') {
    return Alert.alert(title, message, button ? [button] : undefined, options);
  } else {
    window.alert([title, message].filter(item => item).join('\n\n'));
    button?.onPress && button.onPress();
  }
};

export const confirm = (
  title: string,
  message?: string,
  okButton?: AlertButton,
  cancelButton?: AlertButton,
  options?: AlertOptions,
): void => {
  if (Environment.isNative || Environment.platform.type === 'expo-go') {
    const buttons: AlertButton[] = [];
    okButton && buttons.push(okButton);
    cancelButton && buttons.push(cancelButton);
    return Alert.alert(title, message, buttons, options);
  } else {
    const result = window.confirm([title, message].filter(item => item).join('\n\n'));
    if (result) {
      okButton?.onPress && okButton.onPress();
    } else {
      cancelButton?.onPress && cancelButton.onPress();
    }
  }
};
