import React, { useMemo } from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

export interface OutlineButtonProps {
  title: string;
  size: 'large' | 'medium'
  disabled?: boolean;
  icon?: ImageSourcePropType;
  onPress: () => void;
}

export const OutlineButton = (props: OutlineButtonProps): React.ReactElement => {
  const styles = useMemo(() => props.size === 'large' ? large : medium, [props.size]);

  return <Clickable disabled={props.disabled} name="button_tap" id={props.title} onPress={props.onPress}>
    <View style={styles.view}>
      {props.icon ? <Image source={props.icon} style={styles.icon} resizeMode="contain" /> : null}
      <AppText style={styles.title}>{props.title}</AppText>
    </View>
  </Clickable>;
};

const large = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    borderRadius: 4,
    borderColor: Color.gray10,
    borderWidth: 1,
    paddingHorizontal: 16,
  },
  icon: {
    width: 17,
    height: 17,
    marginRight: 6,
  },
  title: {
    fontSize: 14,
    color: Color.gray100,
    lineHeight: 34
  },
});

const medium = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    borderColor: Color.gray10,
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
    maxWidth: 120,
  },
  icon: {
    width: 0,
    height: 0,
  },
  title: {
    fontSize: 14,
    color: Color.gray100,
  },
});
