import { DeliveryMethod, Integration, Platform } from '../../domain';
import { PlatformTypeDecorator } from './PlatformTypeDecorator';

export class PlatformDecorator {
  private platform: Platform
  private integrations: Integration[] | undefined;

  constructor(platform: Platform, integrations: Integration[] | undefined) {
    this.platform = platform;
    this.integrations = integrations;
  }

  deliveryMethodText(): string {
    const name = new PlatformTypeDecorator(this.platform.type, this.integrations).name();

    switch (this.platform.deliveryMethod) {
      case DeliveryMethod.PLATFORM:
        return `${name}の配達員のみを利用する`;
      case DeliveryMethod.INHOUSE:
        return '店舗スタッフが配達する';
      case DeliveryMethod.FALLBACK:
        return `店舗スタッフと${name}の配達員を併用する`;
      default:
        return `${name}の配達員のみを利用する`;
    }
  }
}
