import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import { OrderSales } from '../../../domain';
import { useAppContext } from '../contexts/useAppContext';

export const useOrderSales = (orderIds: string[]): [OrderSales[], boolean, Error | undefined] => {
  const { store } = useAppContext();
  const [orderSales, setOrderSales]= useState<OrderSales[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (!store) return;

    Promise.resolve(orderIds)
      .then(orderIds => orderIds.map(orderId => getOrderSales(store.accountId, store.id, orderId)))
      .then(promises => Promise.all(promises))
      .then(records => {
        setOrderSales(records.filter(orderSales => !!orderSales));
        setLoaded(true);
        setError(undefined);
      })
      .catch(error => setError(error));
  }, [store, orderIds]);

  return [orderSales, loaded, error];
};

const getOrderSales = (accountId: string, storeId: string, orderId: string) =>
  firebase
    .firestore()
    .collectionGroup('sales')
    .where('accountId', '==', accountId)
    .where('storeId', '==', storeId)
    .where('orderId', '==', orderId)
    .get()
    .then(snapshot => snapshot.docs[0]?.data() as OrderSales);
