export const Color = {
  brand: '#372E61',
  dark: '#0B081A',
  light: '#F2F2F7',
  surface: '#3C3948',
  black: '#000000',
  white: '#FFFFFF',
  red: '#F74E2F',
  red5: '#FFF6F5',
  red25: '#FFF5F6',
  red300: '#E97770',
  red500: '#F74E2F',
  red_alpha: 'rgba(247, 78, 47, 0.05)',
  blue: '#4321CE',
  blue_light: '#28AAF3',
  yellow: '#f7f5ea',
  purple: '#995AE3',
  purple100: '#CEC2FF',
  purple50: '#DFCCFF',
  purple10: '#E2DBFF',
  gray100: '#0B081A',
  gray90: '#232131',
  gray80: '#3C3948',
  gray70: '#54525F',
  gray50: '#6F6D78',
  gray40: '#8F8E94',
  gray30: '#A7A7AA',
  gray20: '#CECDD1',
  gray10: '#E4E3E8',
  gray5: '#F2F2F7',
};
