import { StackScreenProps } from '@react-navigation/stack';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { CalendarDropdown, If, NavigationBar, WithScreen } from '../../components';
import { Color, Route } from '../../constants';
import { useAppContext, useOrders, useOrderSales } from '../../hooks';
import { SalesCardView } from './SalesCardView';

export const SalesScreen = ({ navigation }: StackScreenProps<Route, 'Sales'>): React.ReactElement => {
  const { store, brands } = useAppContext();

  const [selectable] = useState({ min: dayjs(store?.createTime.seconds), max: dayjs() });
  const [selected, setSelected] = useState(dayjs());

  const [orders, { loaded, loadingMore }, error] = useOrders({
    date: selected.toDate(),
    status: ['COMPLETED', 'CANCELED'],
    limit: 10000
  });

  const orderIds = useMemo(() => orders.map(order => order.id), [orders]);
  const [orderSales, loadedSales, errorSales] = useOrderSales(orderIds);

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressClose = useCallback(() => {
    navigation.popToTop();
    navigation.goBack();
  }, [navigation]);

  const onPressDate = useCallback((date: Dayjs) => {
    setSelected(date);
  }, []);

  return <WithScreen loading={!loaded || loadingMore || !loadedSales} error={error || errorSales}>
    <SafeAreaView style={styles.screen}>
      <NavigationBar title="売り上げ" isForMenu={true} onPressBack={onPressBack} onPressClose={onPressClose}>
        <CalendarDropdown {...{ selected, selectable, onPressDate }} />
      </NavigationBar>
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
        <View style={styles.component}>
          <If condition={brands && brands.length > 1}>
            <SalesCardView {...{ brand: 'all', orders, orderSales }}/>
          </If>
        </View>
        <View style={styles.component}>
          {brands?.map(brand => <SalesCardView key={brand.id} {...{ brand, orders: orders ?? [], orderSales }}/>)}
        </View>
      </ScrollView>
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
  scrollView: {
    flex: 1,
    width: '100%',
    backgroundColor: Color.gray5,
  },
  container: {
    paddingVertical: 32,

  },
  component: {
    marginLeft: 56,
    marginRight: 56,
  },
  orderRecord: {
    height: 120,
    backgroundColor: Color.white,
    marginLeft: 56,
    marginRight: 56,
    justifyContent: 'center',
  },
  orderRecordText: {
    fontSize: 18,
  },
  icon: {
    width: 16,
    height: 16,
    position: 'absolute',
    right: 0,
  },
});
