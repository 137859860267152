import { pickObject } from '../../utils';
import { HasId } from './core/HasId';
import { DeliveryPlatformType } from './DeliveryPlatformType';
import { StoreRelation } from './Store';

export type DeliveryPlatformReference = StoreRelation & HasId;
export type DeliveryPlatformRelation = StoreRelation & { deliveryPlatformId: DeliveryPlatformReference['id'] };

interface DeliveryPlatformCommon<P extends DeliveryPlatformType> extends DeliveryPlatformReference {
  type: P
  identityIds: string[]
}


export type AnyCarryDeliveryPlatform = DeliveryPlatformCommon<'anycarry'>;
export type DeaaSDeliveryPlatform = AnyCarryDeliveryPlatform;

export type BotEatsDeliveryPlatform = DeliveryPlatformCommon<'boteats'>;
export type DemaecanDeliveryPlatform = DeliveryPlatformCommon<'demaecan'>;
export type MenuDeliveryPlatform = DeliveryPlatformCommon<'menu'>;
export type UberEatsDeliveryPlatform = DeliveryPlatformCommon<'ubereats'>;
export type WoltDeliveryPlatform = DeliveryPlatformCommon<'wolt'>;
export type DefaultDeliveryPlatform =
  | BotEatsDeliveryPlatform
  | DemaecanDeliveryPlatform
  | MenuDeliveryPlatform
  | UberEatsDeliveryPlatform
  | WoltDeliveryPlatform

export type DeliveryPlatform =
  | DeaaSDeliveryPlatform
  | DefaultDeliveryPlatform

export const deliveryPlatformToReference = (platform: DeliveryPlatform): DeliveryPlatformReference =>
  pickObject(platform, ['id', 'accountId', 'storeId', 'type']);

export const deliveryPlatformToRelation = (platform: DeliveryPlatform): DeliveryPlatformRelation =>
  ({ ...pickObject(platform, ['accountId', 'storeId']), deliveryPlatformId: platform.id });
