import { NavigationProp, useNavigation } from '@react-navigation/native';
import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { PlatformType } from '../../../domain';
import { AcceptanceMethodSettingList, AppText, WithScreen } from '../../components';
import { Route } from '../../constants';
import { useAppContext } from '../../hooks';

export const SettingsAcceptanceMethodView = (): React.ReactElement => {
  const { brands, platforms } = useAppContext();
  const navigation = useNavigation<NavigationProp<Route>>();

  const data = brands
    ?.map(brand => ({ brand, platforms: platforms?.filter(platform => platform.brandId === brand.id) ?? [] }))
    .filter(({ platforms }) => platforms.length > 0)
    ?? [];

  const onPress = (brandId: string, platformType: PlatformType) => {
    navigation.navigate('AcceptanceMethod', { brandId, platformType });
  };

  return <WithScreen loading={false} error={undefined}>
    {data.length > 0
      ? <AcceptanceMethodSettingList {...{ data, onPress }} />
      : <ScrollView style={styles.view}>
        <AppText style={styles.unavailableText}>変更可能なプラットフォームがありません。</AppText>
      </ScrollView>}
  </WithScreen>;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  unavailableText: {
    fontSize: 18,
    marginStart: 'auto',
    marginEnd: 'auto',
    marginTop: 48,
  },
});
