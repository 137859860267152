import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Brand } from '../../domain';
import { BrandList, NavigationBar, WithScreen } from '../components';
import { Color, Route } from '../constants';
import { useAppContext, useItems } from '../hooks';

export const BrandsScreen = (
  { navigation }: StackScreenProps<Route, 'Brands'>
): React.ReactElement => {
  const { brands, platforms } = useAppContext();
  const [soldOutItems, soldOutLoading, soldOutItemsError] = useItems({ soldOut: true });

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressClose = useCallback(() => {
    navigation.popToTop();
    navigation.goBack();
  }, [navigation]);

  const onPressBrand = useCallback((brand: Brand) => {
    navigation.navigate('SoldOut', { brand });
  }, [navigation]);

  return <WithScreen loading={soldOutLoading} error={soldOutItemsError}>
    <SafeAreaView style={styles.screen}>
      <NavigationBar title="商品の売り切れ設定" onPressBack={onPressBack} onPressClose={onPressClose} isForMenu={true} />
      <BrandList
        {...{ brands: brands ?? [], soldOutItems: soldOutItems ?? [], platforms: platforms ?? [], onPressBrand }}/>
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
});
