import { useEffect, useState } from 'react';

type Response = {
  now: number;
}

export const usePeriodicUpdater = (periodSeconds = 5 * 1000): Response => {
  const [now, setNow] = useState(new Date().getTime());

  useEffect(() => {
    const id = setInterval(() => {
      setNow(new Date().getTime());
    }, periodSeconds);
    return () => clearInterval(id);
  }, [periodSeconds]);

  return { now };
};
