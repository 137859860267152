import React, { useCallback, useMemo, useRef, useState } from 'react';
import { default as rn, ScrollView, View } from 'react-native';
import { Order } from '../../../../../domain';
import { If } from '../../../If';
import { SeeMoreButton } from '../SeeMoreButton';
import { CutleryView } from './CutleryView';
import { ItemCountView } from './ItemCountView';
import { ItemView } from './ItemView';
import { PriceView } from './PriceView';
import { ShopCommentView } from './ShopCommentView';
import { styles } from './styles';

interface Props {
  order: Order;
}

export const LeftPane = ({ order }: Props): React.ReactElement => {
  const [visibleContinuousForItems, setVisibleContinuousForItems] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);

  const data = useMemo(() => ({
    items: order.items
      .map(item => ({
        item: item,
        optionGroups: item.options
          .filter((option) => !option.parentId)
          .map((option, index, array) => ({
            // オプション_1階層
            option: option,
            isHideCategory: index !== 0 && array[index - 1]?.category === option.category,
            isHideBorder:
              (index === array.length - 1 &&
                item.options.filter((child) => option.id && option.id === child.parentId).length === 0) ||
              (index !== array.length - 1 && array[index + 1]?.category === option.category),
            optionGroups: item.options
              .filter((child) => option.id && option.id === child.parentId)
              .map((child, childIndex, childArray) => ({
                // オプション_2階層
                option: child,
                isHideCategory: childIndex !== 0 && childArray[childIndex - 1]?.category === child.category,
                isHideBorder:
                  (index === array.length - 1 &&
                    childIndex === childArray.length - 1 &&
                    item.options.filter((grandChild) => child.id === grandChild.parentId).length === 0) ||
                  (childIndex !== childArray.length - 1 && childArray[childIndex + 1]?.category === child.category),
                optionGroups: item.options
                  .filter((grand) => grand.parentId === child.id)
                  .map((grand, grandIndex, grandArray) => ({
                    // オプション_3階層
                    option: grand,
                    isHideCategory: grandIndex !== 0 && grandArray[grandIndex - 1]?.category === grand.category,
                    isHideBorder:
                      (index === array.length - 1 && childIndex === childArray.length - 1) ||
                      (grandIndex !== grandArray.length - 1 && grandArray[grandIndex + 1]?.category !== grand.category),
                  }))
              }))
          }))
      }))
  }), [order.items]);

  const onScroll = useCallback((event: rn.NativeSyntheticEvent<rn.NativeScrollEvent>) => {
    setVisibleContinuousForItems(!isCloseToBottom(event.nativeEvent));
  }, []);

  const onLayout = useCallback(() => {
    // この画面を表示したときにItem欄がスクロール可能な場合に"↓ 続き"を表示するために、
    // この画面を表示したときにスクロールさせることでonLayoutを発火させる。
    scrollViewRef.current?.scrollTo({ y: 1, animated: false });
    scrollViewRef.current?.scrollTo({ y: 0, animated: false });
  }, [scrollViewRef]);

  const onPress = useCallback(() => {
    scrollViewRef.current?.scrollToEnd();
  }, [scrollViewRef]);

  return <View style={styles.view}>
    <ScrollView
      contentContainerStyle={styles.itemsContainer}
      scrollEventThrottle={100}
      ref={scrollViewRef}
      onLayout={onLayout}
      onScroll={onScroll}>
      <View style={styles.header}>
        <CutleryView {...{ order }} />
        <ItemCountView {...{ order }} />
      </View>
      <ShopCommentView {...{ order }} />
      {data.items.map((item, index) =>
        <ItemView item={item.item} optionGroups={item.optionGroups} key={`orderDetail-item-${index}`} />
      )}
      <PriceView {...{ order }} />
    </ScrollView>
    <If condition={visibleContinuousForItems}>
      <SeeMoreButton onPress={onPress} />
    </If>
  </View>;
};

const isCloseToBottom = (event: rn.NativeScrollEvent, paddingToBottom = 8) =>
  event.layoutMeasurement.height + event.contentOffset.y >= event.contentSize.height - paddingToBottom;
