import { Order } from '../Order';
import { CanceledOrderNotification } from './CanceledOrderNotification';
import { Notification } from './Notification';
import { OrdersNotification, OrdersNotificationType } from './OrdersNotification';

export class Notifications {
  constructor(
    newOrders?: OrdersNotification,
    scheduledOrders?: OrdersNotification,
    prepareOrders?: OrdersNotification,
    cancelOrders?: CanceledOrderNotification[]
  ) {
    this.newOrders = newOrders ?? new OrdersNotification(OrdersNotificationType.New);
    this.scheduledOrders = scheduledOrders ?? new OrdersNotification(OrdersNotificationType.Scheduled);
    this.prepareOrders = prepareOrders ?? new OrdersNotification(OrdersNotificationType.Prepare);
    this._canceledOrders = cancelOrders ?? [];
  }

  readonly newOrders = new OrdersNotification(OrdersNotificationType.New);
  readonly scheduledOrders = new OrdersNotification(OrdersNotificationType.Scheduled);
  readonly prepareOrders = new OrdersNotification(OrdersNotificationType.Prepare);

  private _canceledOrders: CanceledOrderNotification[] = [];

  get canceledOrders(): readonly CanceledOrderNotification[] {
    return this._canceledOrders;
  }

  get hasAny(): boolean {
    return this.hasAnyOrdersNotification || this.hasAnyCanceledOrderNotification;
  }

  get hasAnyOrdersNotification(): boolean {
    return this.newOrders.count > 0 || this.scheduledOrders.count > 0 || this.prepareOrders.count > 0;
  }

  get hasAnyCanceledOrderNotification(): boolean {
    return this.canceledOrders.length > 0;
  }

  get all(): readonly Notification[] {
    let result: Notification[] = [];
    if (this.newOrders.count > 0) {
      result.push(this.newOrders);
    }
    if (this.scheduledOrders.count > 0) {
      result.push(this.scheduledOrders);
    }
    if (this.prepareOrders.count > 0) {
      result.push(this.prepareOrders);
    }
    if (this.canceledOrders.length > 0) {
      result = result.concat(this.canceledOrders);
    }
    return result;
  }

  clone(): Notifications {
    return new Notifications(this.newOrders, this.scheduledOrders, this.prepareOrders, this._canceledOrders);
  }

  addNewOrder(order: Order): Notifications {
    this.newOrders.addOrder(order);
    this.scheduledOrders.removeOrder(order);
    this.prepareOrders.removeOrder(order);
    this.removeCanceledOrder(order);
    return this;
  }

  removeNewOrders(orders: Order[]): Notifications {
    this.newOrders.removeOrders(orders);
    return this;
  }

  clearNewOrders(): Notifications {
    this.newOrders.clearOrder();
    return this;
  }

  addScheduledOrder(order: Order): Notifications {
    this.scheduledOrders.addOrder(order);
    this.newOrders.removeOrder(order);
    this.prepareOrders.removeOrder(order);
    this.removeCanceledOrder(order);
    return this;
  }

  removeScheduledOrders(orders: Order[]): Notifications {
    this.scheduledOrders.removeOrders(orders);
    return this;
  }

  clearScheduledOrders(): Notifications {
    this.scheduledOrders.clearOrder();
    return this;
  }

  addPrepareOrder(order: Order): Notifications {
    this.prepareOrders.addOrder(order);
    this.newOrders.removeOrder(order);
    this.scheduledOrders.removeOrder(order);
    this.removeCanceledOrder(order);
    return this;
  }

  removePrepareOrders(orders: Order[]): Notifications {
    this.prepareOrders.removeOrders(orders);
    return this;
  }

  clearPrepareOrders(): Notifications {
    this.prepareOrders.clearOrder();
    return this;
  }

  addCanceledOrder(order: Order): Notifications {
    this.removeCanceledOrder(order);
    this._canceledOrders.push(new CanceledOrderNotification(order));
    this.newOrders.removeOrder(order);
    this.scheduledOrders.removeOrder(order);
    this.prepareOrders.removeOrder(order);
    return this;
  }

  removeCanceledOrder(order: Order): Notifications {
    this._canceledOrders = this._canceledOrders.filter((n) => n.order.id !== order.id);
    return this;
  }

  removeNotification(notification: Notification): Notifications {
    if (notification instanceof OrdersNotification) {
      switch (notification.type) {
        case OrdersNotificationType.New:
          this.removeNewOrders(notification.orders);
          break;
        case OrdersNotificationType.Scheduled:
          this.removeScheduledOrders(notification.orders);
          break;
        case OrdersNotificationType.Prepare:
          this.removePrepareOrders(notification.orders);
          break;
      }
    } else if (notification instanceof CanceledOrderNotification) {
      this.removeCanceledOrder(notification.order);
    }
    return this;
  }
}
