import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { OrderWithDelivery } from '../../../../../domain';
import { Color } from '../../../../constants';
import { OrderDecorator } from '../../../../decorators';
import { AppText } from '../../../AppText';
import { If } from '../../../If';
import { PhoneNumberView } from '../../PhoneNumberView';

type Props = { orderWithDelivery: OrderWithDelivery };

export const CustomerView = ({ orderWithDelivery }: Props): React.ReactElement => {
  const { order, delivery } = orderWithDelivery;

  const decorator = new OrderDecorator(order);

  return <>
    <If condition={delivery?.courier}>
      <View style={styles.delivery}>
        <AppText style={styles.title}>配達員</AppText>
        <View>
          <AppText style={styles.name}>{delivery?.courier?.name}</AppText>
          <View style={styles.phoneNumber}>
            <Image source={require('../../../../assets/icon/tel.png')} style={styles.phoneNumberIcon} resizeMode='contain' />
            <PhoneNumberView style={styles.phoneNumberText} phoneNumber={delivery?.courier?.phoneNumber?? undefined}/>
          </View>
        </View>
      </View>
    </If>
    <View style={styles.customer}>
      <AppText style={styles.title}>注文者</AppText>
      <View style={{ justifyContent: 'flex-start' }}>
        <AppText style={styles.name}>{order.customer.name}</AppText>
        <If condition={(order.customer.orderCount ?? 0) > 0}>
          <View style={styles.orderCount}>
            <AppText style={styles.orderCountText}>{order.customer.orderCount}回目の注文</AppText>
          </View>
        </If>
        <If condition={order.customer?.phoneNumber}>
          <View>
            <View style={styles.phoneNumber}>
              <Image source={require('../../../../assets/icon/tel.png')} style={styles.phoneNumberIcon} resizeMode='contain' />
              <PhoneNumberView style={styles.phoneNumberText} phoneNumber={order.customer?.phoneNumber}/>
            </View>
            <If condition={order.customer?.phonePinCode}>
              <AppText style={styles.phonePinCodeText}>コード：{order.customer?.phonePinCode}</AppText>
            </If>
          </View>
        </If>
      </View>
    </View>
    <If condition={order.inHouseDelivery}>
      <View style={styles.inhouseDelivery}>
        <AppText style={styles.inhouseDeliveryTitle}>配達情報</AppText>
        <View style={{ flex: 1 }}>
          <View style={styles.address}>
            <Image source={require('../../../../assets/icon/pin.png')} style={styles.addressIcon} resizeMode='contain' />
            <AppText style={styles.addressText}>{order.inHouseDelivery?.address}</AppText>
          </View>
          <If condition={(order.inHouseDelivery?.interaction || order.inHouseDelivery?.comment?.length)}>
            <View style={styles.deliveryInfoComment}>
              <Image
                source={require('../../../../assets/icon/delivery.png')}
                style={styles.deliveryInfoCommentIcon}
                resizeMode='contain' />
              <AppText style={styles.deliveryInfoCommentText}>
                配達時の注意事項：{'\n'}
                <If condition={decorator.interactionText()?.length}>
                  <AppText style={styles.deliveryInfoInteractionText}>{decorator.interactionText()}{'\n'}</AppText>
                </If>
                <If condition={order.inHouseDelivery?.comment?.length}>{order.inHouseDelivery?.comment}</If>
              </AppText>
            </View>
          </If>
          <If condition={order.inHouseDelivery?.cash}>
            <View style={styles.deliveryInfoPayment}>
              <Image
                source={require('../../../../assets/icon/payment_method.png')}
                style={styles.deliveryInfoPaymentIcon}
                resizeMode='contain' />
              <AppText style={styles.deliveryInfoPaymentText}>お支払い方法：現金</AppText>
            </View>
          </If>
        </View>
      </View>
    </If>
  </>;
};

const styles = StyleSheet.create({
  delivery: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  customer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 40,
  },
  inhouseDelivery: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 40,
  },
  title: {
    alignSelf: 'flex-start',
    marginRight: 46,
    fontSize: 14,
    fontWeight: '500',
    color: Color.gray70,
    lineHeight: 17
  },
  name: {
    fontSize: 16,
    fontWeight: '500',
    color: Color.gray70,
    lineHeight: 20
  },
  orderCount: {
    alignSelf: 'flex-start',
    minHeight: 32,
    marginTop: 8,
    marginBottom: 4,
    paddingHorizontal: 12,
    borderRadius: 18,
    backgroundColor: Color.gray5,
  },
  orderCountText: {
    fontSize: 16,
    fontWeight: '500',
    color: Color.gray70,
    lineHeight: 32
  },
  phoneNumber: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
  },
  phoneNumberIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    tintColor: Color.gray50,
  },
  phoneNumberText: {
    fontSize: 14,
    color: Color.gray70,
    lineHeight: 18
  },
  phonePinCodeText: {
    fontSize: 14,
    color: Color.gray70,
    marginLeft: 22,
  },
  inhouseDeliveryTitle: {
    alignSelf: 'flex-start',
    fontSize: 14,
    fontWeight: '500',
    color: Color.gray70,
    marginRight: 30,
  },
  address: {
    flex: 1,
    flexDirection: 'row',
  },
  addressIcon: {
    width: 16,
    height: 16,
    marginTop: 2,
    marginRight: 8,
    tintColor: Color.gray50,
  },
  addressText: {
    flex: 1,
    color: Color.gray50,
    fontSize: 14,
  },
  deliveryInfoComment: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  },
  deliveryInfoCommentIcon: {
    width: 16,
    height: 16,
    marginTop: 2,
    marginRight: 8,
    tintColor: Color.gray50,
  },
  deliveryInfoCommentText: {
    flex: 1,
    color: Color.gray50,
    fontSize: 14,
  },
  deliveryInfoInteractionText: {
    flex: 1,
    color: Color.gray50,
    fontSize: 18,
    fontWeight: '500',
  },
  deliveryInfoPayment: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
  },
  deliveryInfoPaymentIcon: {
    width: 16,
    height: 16,
    marginTop: 2,
    marginRight: 8,
    tintColor: Color.gray50,
  },
  deliveryInfoPaymentText: {
    flex: 1,
    color: Color.gray50,
    fontSize: 14,
  },
});
