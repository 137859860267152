import { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { Environment } from '../../../../../Environment';

type RequestInterceptor = [
  (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  (error: AxiosError) => Promise<unknown> | undefined
];

export const responseLogInterceptor = (name: string): RequestInterceptor => [
  response => {
    logResponse(name, response);
    return response;
  },
  error => {
    logError(name, error);
    return Promise.reject(error);
  },
];

const logResponse = (name: string, response: AxiosResponse) => {
  const startTime = (response?.config as { metadata?: { startTime?: number } })?.metadata?.startTime;
  const duration = startTime ? dayjs().valueOf() - startTime : 0;

  if (Environment.runningMode === 'production') return;

  console.info(`${name} API Response`, {
    method: response.config.method,
    url: `${response.config?.baseURL}${response.config?.url}`,
    status: response?.status,
    data: response?.data,
    duration: `${duration}msec`
  });
};

const logError = (name: string, error: AxiosError) => {
  const startTime = (error.response?.config as { metadata?: { startTime?: number } })?.metadata?.startTime;
  const duration = startTime ? dayjs().valueOf() - startTime : 0;

  if (Environment.runningMode === 'production') return;

  console.error(`${name} API Error`, {
    method: error.config?.method,
    url: `${error.config?.baseURL}${error.config?.url}`,
    status: error.response?.status,
    data: error.response?.data,
    duration: `${duration}msec`
  });
};
