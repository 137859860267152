import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { useAppContext, useNews, useOrderPlatformSuspensions, usePeriodicUpdater } from '../../hooks';
import { AppText } from '../AppText';
import { IconButton } from '../buttons/IconButton';
import { ShopButton } from '../buttons/ShopButton';
import { If } from '../If';
import { Clickable } from '../Clickable';

export enum DashboardBarState {
  ALL = '注文一覧',
  ACCEPTED = '調理中',
  READY = '準備完了',
}

export enum DashboardBarButton {
  MENU,
  SHOP,
}

interface Props {
  state: DashboardBarState;
  onChangeBarState: (state: DashboardBarState) => void;
  onPressBarButton: (button: DashboardBarButton) => void;
}

export const DashboardBar = ({ state, onChangeBarState, onPressBarButton }: Props): React.ReactElement => {
  const { store } = useAppContext();
  const [hasNewNews, setHasNewNews] = useState(false);
  const { now } = usePeriodicUpdater(120 * 1000);

  const { fetch } = useNews();
  const [orderPlatformSuspensions] = useOrderPlatformSuspensions(
    store && { accountId: store.accountId, storeId: store.id, now: new Date(now) }
  );

  const shopClosedCount = Array.from(new Set(orderPlatformSuspensions?.map(({ brandId }) => brandId) ?? [])).length;

  useEffect(() => {
    fetch(undefined, 1)
      .then(items => items.some((item) => item.isNew))
      .then(setHasNewNews);
  }, [fetch]);

  const onPressMenu = useCallback(() => onPressBarButton(DashboardBarButton.MENU), [onPressBarButton]);
  const onPressShop = useCallback(() => onPressBarButton(DashboardBarButton.SHOP), [onPressBarButton]);

  return <View style={styles.view}>
    <IconButton icon={require('../../assets/icon/menu.png')} id="menu_open" onPress={onPressMenu}>
      <If condition={hasNewNews}>
        <View style={styles.menuBadge}><AppText style={styles.menuBadgeText}>N</AppText></View>
      </If>
    </IconButton>
    <View style={styles.menu}>
      <MenuItem{...{
        type: DashboardBarState.ALL,
        selected: state === DashboardBarState.ALL,
        onPress: onChangeBarState
      }} />
      <MenuItem{...{
        type: DashboardBarState.ACCEPTED,
        selected: state === DashboardBarState.ACCEPTED,
        onPress: onChangeBarState
      }} />
      <MenuItem{...{
        type: DashboardBarState.READY,
        selected: state === DashboardBarState.READY,
        onPress: onChangeBarState
      }} />
    </View>
    <ShopButton shopClosedCount={shopClosedCount} onPress={onPressShop}/>
  </View>;
};

type MenuItemProps = {
  type: DashboardBarState
  selected: boolean
  onPress: (state: DashboardBarState) => void
};

const MenuItem = ({ type, selected, onPress }: MenuItemProps) => {
  const onPressDashboardFilter = useCallback(() => onPress(type), [type, onPress]);

  return <Clickable
    name="dashboard_filter"
    id={type}
    style={styles.itemCountainer}
    onPress={onPressDashboardFilter}>
    <View style={StyleSheet.flatten(selected ? [styles.item, styles.itemSelected] : [styles.item])}>
      <AppText
        style={StyleSheet.flatten(selected ? [styles.title, styles.titleSelected] : [styles.title])}>
        {type}
      </AppText>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    width: '100%',
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Color.dark,
    paddingHorizontal: 8,
  },
  menu: {
    width: 564,
    flexDirection: 'row',
  },
  menuBadge: {
    position: 'absolute',
    top: 4,
    start: 4,
    paddingVertical: 4,
    paddingHorizontal: 6,
    backgroundColor: Color.brand,
    borderRadius: 4,
  },
  menuBadgeText: {
    top: 0,
    left: 0,
    fontSize: 12,
    color: Color.white,
  },
  itemCountainer: {
    flex: 1,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 68,
    height: 64,
    marginHorizontal: 60,
  },
  itemSelected: {
    borderBottomColor: Color.white,
    borderBottomWidth: 2,
  },
  title: {
    fontSize: 16,
    color: Color.gray40,
    textAlign: 'center',
  },
  titleSelected: {
    fontWeight: 'bold',
    color: Color.white,
  },
});
