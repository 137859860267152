import React, { useCallback } from 'react';
import { FlatList, NativeScrollEvent, NativeSyntheticEvent, StyleSheet, View } from 'react-native';
import { Order, OrderSales, PlatformType } from '../../../../domain';
import { Color } from '../../../constants';
import { useAppContext } from '../../../hooks';
import { AppText } from '../../AppText';
import { PlatformDropdown } from '../../dropdowns/PlatformDropdown';
import { OrderListItem } from './OrderListItem';

interface Props {
  orders: Order[] | undefined;
  orderSales: OrderSales[];
  selectedPlatform?: PlatformType;
  onPressOrder: (order: Order) => void;
  onReachedBottom: () => void;
  onPressPlatform: (platform?: PlatformType) => void;
}

export const OrderList = (
  { orders, orderSales, selectedPlatform, onPressOrder, onReachedBottom, onPressPlatform }: Props
): React.ReactElement => {
  const { brands } = useAppContext();

  const data = orders
    ?.filter((i) => selectedPlatform ? i.platform === selectedPlatform : true)
    .sort((a, b) => b.createTime.toMillis() - a.createTime.toMillis())
    .map(order => ({ order, orderSales: orderSales.find(o => o.orderId === order.id) }))
    ?? [];

  const HeaderView = useCallback(() =>
    <View style={headerStyles.view}>
      <View style={{width: '20.5%', paddingStart: 56}}>
        <AppText style={headerStyles.title}>ステータス</AppText>
      </View>
      <View style={{width: '12%', alignItems: 'center'}}>
        <PlatformDropdown
          platforms={
            Object.values(PlatformType)
              // TODO: Use integration info
              .filter(platform => platform !== PlatformType.BOTEATS && orders?.map(o => o.platform).includes(platform))
          }
          selectedPlatform={selectedPlatform}
          onPressPlatform={onPressPlatform}
        />
      </View>
      <View style={{width: '14%', paddingStart: 8}}>
        <AppText style={headerStyles.title}>注文番号</AppText>
      </View>
      <View style={{width: '14%', paddingStart: 8}}>
        <AppText style={headerStyles.title}>注文者</AppText>
      </View>
      <View style={{flex: 1, paddingStart: 8}}>
        <AppText style={headerStyles.title}>ブランド</AppText>
      </View>
      <View style={{width: '12.5%', paddingStart: 8}}>
        <AppText style={headerStyles.title}>合計</AppText>
      </View>
    </View>
  , [onPressPlatform, orders, selectedPlatform]);

  const renderItem = useCallback(({ item }: { item: { order: Order, orderSales?: OrderSales } }) =>
    <OrderListItem {...{ ...item, brands, onPressOrder }} />
  , [brands, onPressOrder]);

  const keyExtractor = useCallback(({ order }: { order: Order }) =>
    `OrderList-OrderItem-${order.uuid}`
  , []);

  const onScroll = useCallback(({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { layoutMeasurement, contentOffset, contentSize } = nativeEvent;
    const isBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - 50;
    if (isBottom) onReachedBottom();
  }, [onReachedBottom]);

  return <FlatList
    data={data}
    renderItem={renderItem}
    ListHeaderComponent={HeaderView}
    stickyHeaderIndices={[0]}
    keyExtractor={keyExtractor}
    style={styles.view}
    onScroll={onScroll}/>;
};

const styles = StyleSheet.create({
  view: {},
});

const headerStyles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    paddingVertical: 16,
    backgroundColor: Color.gray100,
  },
  title: {
    fontSize: 14,
    color: Color.white,
  },
});
