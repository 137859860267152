import { Timestamp } from 'firebase/firestore';
import { pickObject } from '../../utils';
import { HasId } from './core/HasId';
import { PlatformRelation } from './Platform';
import { PlatformType } from './PlatformType';

// TODO: Generalize
export type SuspensionUserType = 'store' | 'automation' | 'import';

export const SuspensionReasonTypes = [
  'closed_earlier_than_planned',
  'problem_in_restaurant',
  'out_of_stack',
  'no_courier_in_restaurant',
  'other'
] as const;

export type SuspensionReason =
  | { type: Exclude<typeof SuspensionReasonTypes[number], 'other'> }
  | { type: 'other', note: string };

export const suspensionReasonNames = {
  closed_earlier_than_planned: '予定よりも早く閉店した',
  problem_in_restaurant: 'レストランでの問題',
  out_of_stack: '品切れ',
  no_courier_in_restaurant: '対応できる配達スタッフが自店舗にいない',
  other: 'その他'
} as const;

export type OrderPlatformSuspensionReference = PlatformRelation<PlatformType> & HasId;

export interface OrderPlatformSuspension extends OrderPlatformSuspensionReference {
  suspendUntil: Timestamp;
  suspendedBy: SuspensionUserType
  suspensionReason: SuspensionReason
  invalidatedBy: SuspensionUserType | null
  invalidatedTime: Timestamp | null
  createTime: Timestamp
  updateTime: Timestamp
}

export const orderPlatformSuspensionToReference = (
  orderPlatformSuspension: OrderPlatformSuspension
): OrderPlatformSuspensionReference =>
  pickObject(orderPlatformSuspension, ['id', 'accountId', 'brandId', 'storeId', 'platformType']);

export const SUSPENSION_MINUTES = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60] as const;
export type SuspensionMinute = typeof SUSPENSION_MINUTES[number];

export const suspensionStatus = {
  notSuspended: '受付中' as const,
  suspended: '停止中' as const
};
export type SuspensionStatus = keyof typeof suspensionStatus;
