import { HasLocalizedDescription } from '../core/HasLocalizedDescription';

export class UnknownError extends Error implements HasLocalizedDescription {
  readonly code = 'unknown-error';

  readonly detail = 'The request cannot be processed due to unknown reasons. Try again after a while or please contact us.';
  readonly localizedTitle = '不明なエラーが起きました。';
  readonly localizedDetail = '不明なエラーが発生しました。時間をおいてもう一度試すか、それでも解決しない場合はサポートにご連絡ください。';
  readonly params: Record<string, unknown>;

  constructor(params: Record<string, unknown> = {}) {
    const message = 'An unknown error has occurred.';
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    this.params = params;
  }
}
