import { Interaction, Order } from '../../domain';
import { Formatter } from '../utils';

export class OrderDecorator {
  private order: Order;

  constructor(order: Order) {
    this.order = order;
  }

  confirmText(): string | undefined {
    switch (this.order.status) {
      case 'CREATED':
        return this.order.deliveryTimeType.type === 'scheduled' && this.order.scheduledAccepted ? undefined : '受注する';
      case 'ACCEPTED':
        return this.order.inHouseDelivery ? '配達を開始' : '調理完了';
      case 'READY':
        return this.order.deliveryMethod === 'pick_up'
          ? 'ピックアップ完了'
          : this.order.deliveryMethod === 'eat_in'
            ? '提供完了'
            : undefined;
      default:
        return undefined;
    }
  }

  customerNameText(): string | undefined {
    return this.order.customer.name
      ? `${this.order.customer.name} 様`
      : undefined;
  }

  fulfillmentText(): string {
    if (this.order.deliveryMethod === 'pick_up') return 'テイクアウト';
    if (this.order.deliveryMethod === 'eat_in') return 'イートイン';

    return this.order.inHouseDelivery ? '店舗スタッフ' : '配達';
  }

  interactionText(): string | undefined {
    switch (this.order.inHouseDelivery?.interaction) {
      case Interaction.LEAVE_AT_DOOR:
        return '玄関先に置く';
      case Interaction.DOOR_TO_DOOR:
        return '玄関先で受け取る';
      case Interaction.CURBSIDE:
        return '外で受け取る';
      default:
        return undefined;
    }
  }

  scheduledTimeText(format: 'relative' | 'absolute'): string {
    if (this.order.deliveryTimeType.type !== 'scheduled') return '';
    const date = this.order.deliveryTimeType.time?.toDate() ?? this.order.preparationTime.toDate();
    return format === 'relative' ? Formatter.dayTimeAgo(date) : Formatter.dayTime(date);
  }

  statusText(): string | undefined {
    if (this.order.status === 'ACCEPTED') {
      return '調理中';
    } else if (this.order.status === 'READY') {
      return '準備完了';
    } else if (this.order.status === 'CREATED' && this.order.deliveryTimeType.type === 'scheduled') {
      return '予約注文';
    } else if (this.order.status === 'CREATED' && this.order.deliveryTimeType.type !== 'scheduled') {
      return '受注待ち';
    } else {
      return undefined;
    }
  }
}
