import React, { useCallback, useMemo, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Brand, Order, OrderSales, PlatformType, platformTypeSortPriority } from '../../../domain';
import { AppText, Clickable } from '../../components';
import { Color } from '../../constants';
import { PlatformTypeDecorator } from '../../decorators';
import { useAppContext } from '../../hooks';
import { Formatter } from '../../utils';

interface Props {
  brand: 'all' | Brand;
  orders: Order[];
  orderSales: OrderSales[];
}

export const SalesCardView = ({ brand, orders, orderSales }: Props): React.ReactElement => {
  const { platforms } = useAppContext();
  const [visible, setVisible] = useState(false);

  const brandOrders = useMemo(
    () => orders.filter(order => brand === 'all' || order.brandId === brand.id),
    [brand, orders]
  );

  const sales = useMemo(() => brandOrders
    .map(order => order.price.total)
    .reduce((prev, current) => prev + current, 0)
  , [brandOrders]);

  const totalSales = useMemo(() => brandOrders
    .filter(order => brand === 'all' || order.brandId === brand.id)
    .map(order => orderSales.find(sales => sales.orderId === order.id)?.sales ?? order.price.subtotal)
    .reduce((acc, current) => acc + current, 0)
  , [brand, brandOrders, orderSales]);

  const shouldShowAllBrands = brand === 'all';

  const platformTypes = useMemo(() => platforms
    ?.filter(platform => shouldShowAllBrands || platform.brandId === (brand as Brand).id)
    .map(platform => platform.type)
    ?? []
  , [brand, platforms, shouldShowAllBrands]);

  const platformTypesToShow = useMemo(() =>
    Array.from(new Set([...brandOrders.map(({ platform }) => platform), ...platformTypes]))
      .sort((a, b) => platformTypeSortPriority(a) - platformTypeSortPriority(b))
  , [brandOrders, platformTypes]);

  const onPress = useCallback(() => setVisible(visible => !visible), []);

  return <View style={styles.screen}>
    <Clickable name="sales_open" id={shouldShowAllBrands ? 'all' : (brand as Brand).name} onPress={onPress}>
      <View style={styles.view}>
        <View style={styles.colBrand}>
          {shouldShowAllBrands
            ? <AppText style={styles.textBrand}>すべてのブランド</AppText>
            : <View style={styles.viewIconTitle}>
              <AppText style={styles.textBrand}>{(brand as Brand).name}</AppText>
            </View>
          }
        </View>
        <View style={styles.rightComponent}>
          <View style={styles.colItem}>
            <AppText style={styles.textCol}>合計</AppText>
            <AppText style={styles.textValue}>{Formatter.price(sales)}</AppText>
          </View>
          <View style={styles.colItem}>
            <AppText style={styles.textCol}>小計</AppText>
            <AppText style={styles.textValue}>{Formatter.price(totalSales)}</AppText>
          </View>
          <View style={styles.colItem}>
            <AppText style={styles.textCol}>注文数</AppText>
            <AppText style={styles.textValue}>{brandOrders.length}</AppText>
          </View>
          <View style={styles.colItem}>
            <AppText style={styles.textCol}>平均単価</AppText>
            <AppText style={styles.textValue}>
              {brandOrders.length > 0 ? Formatter.price(sales / brandOrders.length) : Formatter.price(0)}
            </AppText>
          </View>
          <View style={styles.colAccordion}>
            {visible
              ? <View style={styles.iconCircleOpen}>
                <Image
                  source={require('@/assets/icon/cursor_up.png')}
                  style={styles.iconCursor}
                  resizeMode="contain" />
              </View>
              : <View style={styles.iconCircle}>
                <Image
                  source={require('@/assets/icon/cursor_down.png')}
                  style={styles.iconCursor}
                  resizeMode="contain" />
              </View>
            }
          </View>
        </View>
      </View>
    </Clickable>
    {visible
      ? platformTypesToShow.map(platformType =>
        <PlatformTypeView{...{ platformType, orderSales }} orders={brandOrders} key={platformType} />
      )
      : null}
  </View>;
};

interface PlatformTypeViewProps {
  platformType: PlatformType
  orders: Order[]
  orderSales: OrderSales[]
}

const PlatformTypeView = ({ platformType, orders, orderSales }: PlatformTypeViewProps) => {
  const { integrations } = useAppContext();
  const platformOrders = useMemo(() => orders.filter(order => order.platform === platformType), [orders, platformType]);

  const sales = useMemo(() => platformOrders
    .map(order => order.price.total)
    .reduce((prev, current) => prev + current, 0)
  , [platformOrders]);

  const totalSales = useMemo(() => platformOrders
    .map(order => (orderSales.find(sales => sales.orderId === order.id)?.sales ?? order.price.subtotal))
    .reduce((acc, current) => acc + current, 0)
  , [orderSales, platformOrders]);

  const decorator = new PlatformTypeDecorator(platformType, integrations);

  return <View style={platformStyles.view}>
    <View style={platformStyles.colPlatformType}>
      <View style={platformStyles.viewIconTitle}>
        <Image source={decorator.icon()} style={platformStyles.icon} resizeMode="contain" />
        <AppText style={platformStyles.textPlatform}>{decorator.name()}</AppText>
      </View>
    </View>
    <View style={platformStyles.rightComponent}>
      <View style={platformStyles.colItem}>
        <AppText style={platformStyles.textValue}>{Formatter.price(sales)}</AppText>
      </View>
      <View style={platformStyles.colItem}>
        <AppText style={platformStyles.textValue}>{Formatter.price(totalSales)}</AppText>
      </View>
      <View style={platformStyles.colItem}>
        <AppText style={platformStyles.textValue}>{platformOrders?.length ?? 0}</AppText>
      </View>
      <View style={platformStyles.colItem}>
        <AppText style={platformStyles.textValue}>
          {platformOrders.length > 0 ? Formatter.price(sales / platformOrders.length) : Formatter.price(0)}
        </AppText>
      </View>
    </View>
  </View>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    borderWidth: 1,
    borderColor: Color.gray10,
    borderRadius: 12,
    marginBottom: 16,
    minHeight: 'auto'
  },
  view: {
    flexDirection: 'row',
    height: 120,
  },
  rightComponent: {
    width: '60%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textValue: {
    fontSize: 16,
  },
  viewIconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  colItem: {
    flexGrow: 1,
    paddingHorizontal: 4,
    justifyContent: 'center',
  },
  textCol: {
    marginBottom: 16,
    fontSize: 14,
    color: Color.gray50,
  },
  textBrand: {
    width: 192,
    fontSize: 14,
  },
  colBrand: {
    flex: 1,
    marginHorizontal: 32,
    justifyContent: 'center',
  },
  colAccordion: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 32,
  },
  iconCursor: {
    width: 14,
    height: 14,
  },
  iconCircle: {
    width: 32,
    height: 32,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: Color.gray10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCircleOpen: {
    width: 32,
    height: 32,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: Color.gray10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.gray5,
  },
});

const platformStyles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    height: 88,
    padding: 24,
    backgroundColor: Color.gray5,
    borderRadius: 6,
    marginHorizontal: 24,
    marginBottom: 24,
  },
  rightComponent: {
    width: '60%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textValue: {
    fontSize: 16,
  },
  viewIconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 16,
    borderRadius: 16
  },
  colPlatformType: {
    flex: 1,
    justifyContent: 'center',
  },
  textPlatform: {
    fontSize: 14,
  },
  colItem: {
    flexGrow: 1,
    paddingHorizontal: 4,
    justifyContent: 'center',
  },
});
