import { useCallback, useEffect, useState } from 'react';
import { Printer } from '../../../domain';
import { discoverPrinters, logger, stopPrinterDiscovery } from '../../../infrastructure';
import { useAppContext } from '../contexts/useAppContext';
import { useEventBus } from './useEventBus';

type Response = {
  startDiscovering: () => void
  stopDiscovering: () => void
  foundPrinters: Printer[]
  discovering: boolean
  loading: boolean
  error: Error | undefined
};


export const usePrinterDiscoverer = (): Response => {
  const { connectedPrinters } = useAppContext();
  const [discovering, setDiscovering] = useState(connectedPrinters.length > 0);
  const [foundPrinters, setFoundPrinters] = useState<Printer[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [publish] = useEventBus();

  useEffect(() => {
    if (!discovering) return;

    setLoading(true);
    setError(undefined);
    discoverPrinters(printer => {
      setLoading(false);
      setFoundPrinters(prev => [...prev, printer].filter((x, i, arr) => arr.findIndex(y => y.isEqual(x)) === i));
    })
      .catch(error => {
        setDiscovering(false);
        setLoading(false);
        setError(Error('プリンタの検索が開始できませんでした'));
        logger.error(error);
      });

    return () => {
      stopPrinterDiscovery().catch(error => { logger.error(error); });
    };
  }, [discovering]);

  useEffect(() => {
    if (discovering) return;

    setLoading(false);
    setFoundPrinters([]);
    publish({ type: 'printer_cleared', value: undefined });

    stopPrinterDiscovery().catch(error => { logger.error(error); });
  }, [discovering, publish]);

  useEffect(() => {
    if (!loading) return;

    const id = setTimeout(() => { setLoading(false); }, 1000 * 3);
    return () => clearTimeout(id);
  }, [loading]);

  const startDiscovering = useCallback(() => {
    setDiscovering(true);
  }, []);

  const stopDiscovering = useCallback(() => {
    setDiscovering(false);
  }, []);

  return { startDiscovering, stopDiscovering, foundPrinters, discovering, loading, error };
};
