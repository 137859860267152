import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Item } from '../../../domain';
import { useAppContext } from '../contexts/useAppContext';

interface Params {
  soldOut: boolean;
}

export const useItems = (params: Partial<Params> = {}): [Item[] | undefined, boolean, Error | undefined] => {
  const { store } = useAppContext();

  let query = firebase
    .firestore()
    .collectionGroup('items')
    .where('accountId', '==', store?.accountId)
    .where('storeId', '==', store?.id)
    .where('archived', '==', false);

  if (params.soldOut !== undefined) {
    query = params.soldOut ? query.where('soldOutStatus', '!=', 'sale') : query.where('soldOutStatus', '==', 'sale');
  }

  return useCollectionData<Item>(query, { idField: 'id' });
};
