/* eslint-disable react/prop-types */
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, FlatList, Image, LayoutChangeEvent, View, ViewToken } from 'react-native';
import { Order, OrderWithDelivery } from '../../../domain';
import { Environment } from '../../../Environment';
import { AppText, If, OrderDetailView, Clickable, WithScreen, } from '../../components';
import { Route } from '../../constants';
import { OrderDecorator } from '../../decorators';
import { useAppContext, useEventBus, useOrderOperation, useOrderUpdate } from '../../hooks';
import { styles } from './styles';

export const OrderDetailsScreen = (props: StackScreenProps<Route, 'OrderDetails'>): React.ReactElement => {
  const { navigation, route } = props;
  const { orderIds, title, initialOrderId, shouldNotUpdateOrderRead } = route.params;
  const { brands, platforms, activeOrders } = useAppContext();

  const initialPage = initialOrderId
    ? orderIds.indexOf(initialOrderId) === -1
      ? 0
      : orderIds.indexOf(initialOrderId)
    : 0;

  const [printLoading, setPrintLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [currentTitle, setCurrentTitle] = useState<string | undefined>();
  const [componentSize, setComponentSize] = useState<{ width: number, height: number }>();

  const readOrderIdsRef = useRef<Set<string>>(new Set());
  const flatListRef = useRef<FlatList>(null);

  const [dispatch] = useEventBus();
  const { call: callOrderOperation, loading: loadingOrderOperation, error: errorOrderOperation } = useOrderOperation();
  const { update: callOrderUpdate } = useOrderUpdate();

  const screenWidth = componentSize?.width ?? Dimensions.get('window').width;
  const hasPreviousOrderPage = currentPage > 0;
  const hasNextOrderPage = currentPage < orderIds.length - 1;

  const order = useMemo(() =>
    activeOrders?.find(order => order.id === orderIds[currentPage])
  , [activeOrders, currentPage, orderIds]);

  useEffect(() => {
    const currentTitle = title ?? (order ? new OrderDecorator(order).statusText() : '');
    setCurrentTitle(currentTitle);
  }, [order, orderIds, title]);

  useEffect(() => {
    if (shouldNotUpdateOrderRead) return;
    if (!order || order.isRead || readOrderIdsRef.current.has(order.id)) return;

    readOrderIdsRef.current.add(order.id);
    callOrderUpdate({ ...order, isRead: true })
      .catch(() => {
        // isReadの更新に失敗した場合、 updateReadOrderIds から取り除く
        readOrderIdsRef.current.delete(order.id);
      });
  }, [shouldNotUpdateOrderRead, order, callOrderUpdate]);

  const onPressGoBack = useCallback(() => navigation.goBack(), [navigation]);

  const onPressPrevious = useCallback(() => {
    if (!hasPreviousOrderPage) return;

    flatListRef.current?.scrollToIndex({index: currentPage - 1});
    setCurrentPage(currentPage => currentPage - 1);
  }, [currentPage, hasPreviousOrderPage]);

  const onPressNext = useCallback(() => {
    if (!hasNextOrderPage) return;

    flatListRef.current?.scrollToIndex({index: currentPage + 1});
    setCurrentPage(currentPage => currentPage + 1);
  }, [currentPage, hasNextOrderPage]);

  const onPressConfirm = useCallback((order: Order) => {
    const operation = (() => {
      switch (order.status) {
        case 'CREATED': return { type: 'ACCEPT' as const, cookingTime: null };
        case 'ACCEPTED': return { type: 'READY' as const };
        case 'READY': return { type: 'COMPLETE' as const };
      }
    })();

    if (!operation) return;

    const { acceptanceMethod } = platforms?.find(platform => platform.type === order.platform) ?? {};

    const isManualAcceptance = operation.type === 'ACCEPT'
      && !acceptanceMethod?.automated
      && acceptanceMethod?.cookingTimeCalculation.type === 'manual';

    if (isManualAcceptance) {
      navigation.navigate('AcceptWithCookingTime', { order });
      return;
    }

    return callOrderOperation({ operation, orderId: order.id })
      .then(() => navigation.goBack());
  }, [platforms, callOrderOperation, navigation]);

  const onPressSupport = useCallback((order: Order) =>
    navigation.navigate('Support', { order, onComplete: () => navigation.goBack() })
  , [navigation]);

  const onPressPrint = useCallback((orderWithDelivery: OrderWithDelivery) => {
    const printOrder = (orderWithDelivery: OrderWithDelivery) => {
      setPrintLoading(true);
      setTimeout(() => {
        setPrintLoading(false);
      }, 500);
      dispatch({ type: 'order_receipt_requested', value: { receiptType: 'OrderReceipt', orderWithDelivery } });
    };

    Environment.platform.type === 'expo-go'
      ? navigation.navigate('ReceiptPreview', {
        orderWithDelivery,
        brand: brands?.find((brand) => brand.id == orderWithDelivery.order.brandId)
      })
      : printOrder(orderWithDelivery);
  }, [navigation, brands, dispatch]);

  const renderItem = useCallback(({ item }: { item: string }) =>
    <View style={{ width: screenWidth, height: '100%', position: 'relative' }}>
      <OrderDetailView {...{ orderId: item, order: undefined, onPressConfirm, onPressSupport, onPressPrint }} />
    </View>
  , [screenWidth, onPressConfirm, onPressSupport, onPressPrint]);

  const keyExtractor = useCallback((item: string, i: number) => `orderDetails-orderDetail-${item}-${i}`, []);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;
    setComponentSize({ width, height });
  }, []);

  const onViewableItemsChanged = useCallback((info: { viewableItems: Array<ViewToken>; changed: Array<ViewToken> }) => {
    const index = info.viewableItems[0]?.index;
    if (index) setCurrentPage(index);
  }, []);

  const onScrollToIndexFailed = useCallback(() => {
    // scrollToIndexに失敗した場合には少し時間をおいて再度スクロールする。
    const id = setTimeout(() => {
      flatListRef.current?.scrollToIndex({ index: currentPage, animated: false });
    }, 300);

    return () => { clearTimeout(id); };
  }, [currentPage]);

  const getItemLayout = useCallback((_: ArrayLike<string> | null | undefined, index: number) => ({
    length: screenWidth,
    offset: screenWidth * index,
    index
  }), [screenWidth]);

  return <WithScreen loading={printLoading} error={errorOrderOperation}>
    <View style={styles.screen}>
      <View style={styles.header}>
        <View style={styles.headerPrevious}>
          <If condition={hasPreviousOrderPage}>
            <Clickable name='order_previous_page' onPress={onPressPrevious}>
              <View style={styles.pageButtonBackground}>
                <Image
                  source={require('../../assets/icon/arrow_previous_page.png')}
                  style={styles.pageButton}
                  resizeMode="contain" />
              </View>
            </Clickable>
          </If>
        </View>
        <If condition={currentTitle}>
          <AppText style={styles.headerText}>{currentTitle} {currentPage + 1} / {orderIds.length}</AppText>
        </If>
        <View style={styles.headerNext}>
          <If condition={hasNextOrderPage}>
            <Clickable name='order_next_page' onPress={onPressNext}>
              <View style={styles.pageButtonBackground}>
                <Image
                  source={require('../../assets/icon/arrow_next_page.png')}
                  style={styles.pageButton}
                  resizeMode="contain" />
              </View>
            </Clickable>
          </If>
        </View>
        <Clickable name='order_close' style={styles.close} onPress={onPressGoBack}>
          <Image source={require('../../assets/icon/close_black.png')} style={styles.closeIcon} resizeMode="contain" />
        </Clickable>
      </View>
      <View style={{flex: 1}}>
        <FlatList {...{
          ref: flatListRef,
          windowSize: 5,
          maxToRenderPerBatch: 3,
          initialNumToRender: 1,
          initialScrollIndex: initialPage,
          removeClippedSubviews: true,
          scrollEnabled: !loadingOrderOperation,
          horizontal: true,
          pagingEnabled: true,
          showsHorizontalScrollIndicator: false,
          data: orderIds,
          keyExtractor,
          renderItem,
          getItemLayout,
          onLayout,
          onScrollToIndexFailed,
          onViewableItemsChanged,
        }}/>
      </View>
    </View>
  </WithScreen>;
};
