import firebase from 'firebase/compat/app';
import { useCallback, useState } from 'react';
import { CallableError, HasLocalizedDescription, isLocalizableRfc7807Compliant, UnknownError } from '../../../domain';

export type Callable<Request, Response> = {
  loading: boolean,
  error: (HasLocalizedDescription & Error) | undefined,
  call: (params: Request) => Promise<Response>
};

export const useCallable = <Request, Response>(name: string): Callable<Request, Response> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<HasLocalizedDescription & Error>();

  const call = useCallback((params: Request) => {
    setLoading(true);

    return firebase.app().functions('asia-northeast1').httpsCallable(name)(params)
      .then(result => result.data)
      .catch(error => { setError(mapError(error)); })
      .finally(() => { setLoading(false); });
  }, [name, setLoading, setError]);

  return { loading, error, call };
};

const mapError = (error: unknown): HasLocalizedDescription & Error => {
  if (error instanceof Error && hasDetails(error) && isLocalizableRfc7807Compliant(error.details))
    return new CallableError(error.details);

  if (error instanceof Error)
    return { ...error, localizedTitle: error.message, localizedDetail: '' };

  return new UnknownError();
};

const hasDetails = (error: unknown): error is { details: unknown } =>
  typeof error === 'object' && !!error && 'details' in error;
