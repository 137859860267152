import { StackScreenProps } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NavigationBar, ReceiptView } from '../components';
import { Color, Route } from '../constants';

export const ReceiptPreviewScreen = (
  { navigation, route: { params: { brand, orderWithDelivery } } }: StackScreenProps<Route, 'ReceiptPreview'>
): React.ReactElement =>
  <SafeAreaView style={styles.screen}>
    <StatusBar style="dark" />
    <NavigationBar {...{ title: 'プレビュー', isForMenu: true, onPressBack: () => { navigation.goBack(); } }}/>
    <View style={styles.view}>
      {/* <CancelReceiptView {...{ brand, order }} /> */}
      <ReceiptView {...{ brand, orderWithDelivery }} />
    </View>
  </SafeAreaView>;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
    alignItems: 'center'
  },
  view: {
    width: 864 + 80,
    height: '100%'
  }
});
