import React, { useCallback, useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import useInterval from 'use-interval';
import { Order } from '../../../../../domain';
import { Color } from '../../../../constants';
import { PlatformTypeDecorator } from '../../../../decorators';
import { useAppContext } from '../../../../hooks';
import { AppText } from '../../../AppText';
import { Clickable } from '../../../Clickable';

interface Props {
  order: Order;
  onPress?: (order: Order) => void;
  onPressClose?: (order: Order) => void;
  onAutoClose?: (order: Order) => void;
}

export const OrderCancelMessage = ({ order, onPress, onPressClose, onAutoClose }: Props): React.ReactElement => {
  const { brands, integrations } = useAppContext();

  useInterval(() => {
    onAutoClose?.(order);
  }, 1000 * 60 * 5);

  const brandName = useMemo(() => {
    const brandName = brands?.filter(brand => brand.id == order.brandId)[0]?.name;
    return brandName && brandName?.length > 30 ? brandName.slice(0, 30) + '…' : brandName;
  }, [brands, order.brandId]);

  const onPressOrder = useCallback(() => onPress?.(order), [onPress, order]);
  const onPressCloseButton = useCallback(() => onPressClose?.(order), [onPressClose, order]);

  return <Clickable name="cancel_bar_tap" id={order.displayId} style={styles.view} onPress={onPressOrder}>
    <View style={styles.textView}>
      <Image
        source={require('../../../../assets/icon/alert.png')}
        style={styles.alertIcon}
        resizeMode="contain" />
      <AppText style={styles.text}>
        {order.displayId}（{brandName} - {new PlatformTypeDecorator(order.platform, integrations).name()}）の注文をキャンセルしました
      </AppText>
    </View>
    <AppText style={styles.subText}>タップして注文内容を確認</AppText>
    <Clickable name="cancel_bar_close_tap" style={styles.closeView} onPress={onPressCloseButton}>
      <Image style={styles.closeIcon} source={require('../../../../assets/icon/close_white.png')} />
    </Clickable>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    padding: 24,
    flexDirection: 'column',
    width: '100%',
    height: 118,
    justifyContent: 'space-between',
    backgroundColor: Color.red,
    marginBottom: 4,
  },
  textView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertIcon: {
    width: 32,
    height: 32,
    marginEnd: 16,
  },
  text: {
    flex: 1,
    fontSize: 20,
    fontWeight: '500',
    color: Color.white,
  },
  subText: {
    fontSize: 14,
    fontWeight: '400',
    marginStart: 48,
    color: Color.white,
  },
  closeView: {
    position: 'absolute',
    top: 8,
    end: 8,
    width: 52,
    height: 52,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
});
