import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { LoginScreen, SplashScreen } from '../screens';
import { MainNavigator } from './MainNavigator';

const Stack = createStackNavigator();

export const RootNavigator = (): React.ReactElement =>
  <Stack.Navigator screenOptions={{ presentation: 'transparentModal' }}>
    <Stack.Screen name="Splash" component={SplashScreen} options={{ headerShown: false, animationEnabled: false }}/>
    <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false, animationEnabled: false }}/>
    <Stack.Screen name="Main" component={MainNavigator} options={{ headerShown: false, animationEnabled: false }}/>
  </Stack.Navigator>;
