import { StyleSheet } from 'react-native';
import { Color } from '../../../../constants';

export const styles = StyleSheet.create({
  view: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: Color.gray10,
  },
  itemsContainer: {
    padding: 32,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 18,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1
  }
});
