import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { getOrderTotalItemCount, Order } from '../../../../../domain';
import { Color } from '../../../../constants';
import { Formatter } from '../../../../utils';
import { AppText } from '../../../AppText';

type Props = { order: Order };

export const ItemCountView = ({ order }: Props): ReactElement =>
  <View style={styles.view}>
    <View style={styles.itemCountBox}>
      <AppText style={styles.itemCountText}>{getOrderTotalItemCount(order).toString()}点</AppText>
    </View>
    <AppText style={styles.priceLabelText}>合計金額</AppText>
    <AppText style={styles.priceText}>{Formatter.price(order.price.total)}</AppText>
  </View>;

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 28
  },
  itemCountBox: {
    minWidth: 28,
    height: 28,
    paddingHorizontal: 5,
    borderWidth: 1,
    borderColor: Color.gray10,
    borderRadius: 4,
    marginBottom: 2
  },
  itemCountText: {
    color: Color.gray100,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  priceLabelText: {
    color: Color.gray100,
    fontSize: 18,
    fontWeight: '500',
    marginLeft: 16,
    lineHeight: 20
  },
  priceText: {
    color: Color.gray100,
    fontSize: 18,
    fontWeight: '500',
    marginLeft: 14,
    lineHeight: 20
  },
});
