import { ApiRequest } from '../../../client';
import { base } from '../../base';
import { PushNotification } from '../../entities';

type Request = {
  id: string
}

type Response = PushNotification;

export const getPushNotification = (token: string, { id } : Request): ApiRequest<Response> =>
  ({ ...base(token), method: 'GET', url: `/api/push_notifications/${id}`  });
