export type PopupNotification = {
  id: string
  title: string
  description: string
  messages: string[]
  expireTime: string
}

export const customerSupportNotification = {
  id: '64acfbce-8a20-4302-95eb-b76d7b5aadc5',
  title: 'カスタマーサポート設置のお知らせ',
  description: '日頃よりOrderlyのご利用誠にありがとうございます。お客様のさらなる利便性の向上を目的として、カスタマーサポートを設置致しました。',
  messages: [
    `
■お問い合わせ
TEL：050-5835-2541
受付：10:00 ~ 22:00（年中無休）

詳しくは左上アイコンからメニュー画面を開き、お知らせページから内容をご確認ください。    
    `.trim()
  ],
  expireTime: '2022-01-14T23:59:59.000+09:00'
};
