import AsyncStorage from '@react-native-async-storage/async-storage';
import { parse, stringify } from 'telejson';

export class Storage<T> {
  save(key: string, value: T): Promise<void> {
    return AsyncStorage.setItem(key, stringify(value));
  }

  get(key: string): Promise<T | undefined> {
    return AsyncStorage.getItem(key)
      .then(jsonValue => jsonValue ? parse(jsonValue) : undefined);
  }

  remove(key: string): Promise<void> {
    return AsyncStorage.removeItem(key);
  }
}
