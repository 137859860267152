import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Image } from 'react-native';
import { Route } from '../constants';
import {
  AcceptWithCookingTimeScreen,
  BulkUpdateOrderPlatformSuspensionsScreen,
  CancelScreen,
  ContactScreen,
  CreateOrderPlatformSuspensionsScreen,
  DashboardScreen,
  DelayScreen,
  DeleteOrderPlatformSuspensionScreen,
  OrderDetailScreen,
  OrderDetailsScreen,
  OrderPlatformSuspensionScreen,
  PopupNotificationScreen,
  ReceiptPreviewScreen,
  SupportScreen,
} from '../screens';
import { MenuNavigator } from './MenuNavigator';

const Stack = createStackNavigator<Route>();

export const MainNavigator = (): React.ReactElement => (
  <Stack.Navigator screenOptions={{ headerBackImage: HeaderBackImage, presentation: 'transparentModal' }}>
    <Stack.Screen name="Dashboard" component={DashboardScreen} options={optionForScreen} />
    <Stack.Screen name="OrderDetail" component={OrderDetailScreen} options={optionForModalScreen} />
    <Stack.Screen name="OrderDetails" component={OrderDetailsScreen} options={optionForModalScreen} />
    <Stack.Screen name="AcceptWithCookingTime" component={AcceptWithCookingTimeScreen} options={optionForModalScreen} />
    <Stack.Screen name="Menu" component={MenuNavigator} options={optionForScreen} />
    <Stack.Screen name="OrderPlatformSuspensions" component={OrderPlatformSuspensionScreen} options={optionForScreen} />
    <Stack.Screen
      name="BulkUpdateOrderPlatformSuspensions"
      component={BulkUpdateOrderPlatformSuspensionsScreen}
      options={optionForModalScreen}/>
    <Stack.Screen
      name="CreateOrderPlatformSuspensions"
      component={CreateOrderPlatformSuspensionsScreen}
      options={optionForModalScreen} />
    <Stack.Screen
      name="DeleteOrderPlatformSuspension"
      component={DeleteOrderPlatformSuspensionScreen}
      options={optionForModalScreen} />
    <Stack.Screen name="Support" component={SupportScreen} options={optionForModalScreen} />
    <Stack.Screen name="Delay" component={DelayScreen} options={optionForModalScreen} />
    <Stack.Screen name="Cancel" component={CancelScreen} options={optionForModalScreen} />
    <Stack.Screen name="Contact" component={ContactScreen} options={optionForModalScreen} />
    <Stack.Screen name="ReceiptPreview" component={ReceiptPreviewScreen} options={optionForScreen} />
    <Stack.Screen name="PopupNotification" component={PopupNotificationScreen} options={optionForModalScreen} />
  </Stack.Navigator>
);

const optionForScreen = {
  headerShown: false,
  animationEnabled: false
};

const optionForModalScreen = {
  headerShown: false,
  cardStyle: { backgroundColor: 'transparent', opacity: 1 },
  animationEnabled: false,
};

const HeaderBackImage = () =>
  <Image
    source={require('../assets/icon/arrow_left.png')}
    style={{ width: 18, height: 18, marginLeft: 12, marginRight: 30 }} />;
