import { Printer } from '../../../domain';
import { Environment } from '../../../Environment';
import { logger } from '../../logger';
import { CamerarollPrinterDiscoverer } from './CamerarollPrinterDiscoverer';

export interface PrinterDiscoverer {
  start: (f: (printer: Printer) => void) => Promise<void>
  stop: () => Promise<void>
}

export const discoverPrinters = (f: (printer: Printer) => void): Promise<void> =>
  stopPrinterDiscovery()
    .then(() => getPrinterDiscoverers().map(discoverer => discoverer.start(f)))
    .then(promises => Promise.allSettled(promises))
    .then();

export const stopPrinterDiscovery = (): Promise<void> =>
  Promise.resolve(getPrinterDiscoverers())
    .then(printerDiscoverers => printerDiscoverers.map(discoverer => discoverer.stop()))
    .then(promises => Promise.allSettled(promises))
    .catch(error => { logger.error(error); })
    .then();

let printerDiscoverers: PrinterDiscoverer[] | undefined = undefined;

const getPrinterDiscoverers = (): PrinterDiscoverer[] => {
  if (printerDiscoverers)
    return printerDiscoverers;

  const nativeDiscoverers = () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { EpsonPrinterDiscoverer } = require('./EpsonPrinterDiscoverer');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { SiiPrinterDiscoverer } = require('./SiiPrinterDiscoverer');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { StarPrinterDiscoverer } = require('./StarPrinterDiscoverer');

    return [new EpsonPrinterDiscoverer(), new SiiPrinterDiscoverer(), new StarPrinterDiscoverer()];
  };

  printerDiscoverers = [
    ...(
      Environment.platform.type !== 'web' && Environment.projectEnvironment !== 'production'
        ? [new CamerarollPrinterDiscoverer()]
        : []
    ),
    ...(Environment.isNative ? nativeDiscoverers() : [])
  ];

  return printerDiscoverers;
};
