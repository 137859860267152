import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { RegularBusinessDayTime } from '../../../../../../domain';
import { If } from '../../../../If';
import { RegularForms } from './RegularForms';
import { RegularViews } from './RegularViews';

interface Props {
  sectionName: string | null
  businessDayTimes: RegularBusinessDayTime[]
}

export const RegularSection = ({ sectionName, businessDayTimes } : Props): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false);

  const onFinishEditing = useCallback(() => setIsEditing(false), []);
  const onPressEdit = useCallback(() => setIsEditing(true), []);

  return <View style={styles.section}>
    <If condition={isEditing}>
      <RegularForms  {...{ sectionName, businessDayTimes, onFinishEditing }} />
    </If>
    <If condition={!isEditing}>
      <RegularViews {...{ sectionName, businessDayTimes, onPressEdit }} />
    </If>
  </View>;
};

const styles = StyleSheet.create({
  section: {
    width: '100%',
    paddingHorizontal: 32
  }
});
