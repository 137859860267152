import { HasLocalizedDescription } from '../core/HasLocalizedDescription';

export class NetworkError extends Error implements HasLocalizedDescription {
  readonly code = 'network-error';

  readonly detail = 'Error has occurred due to network reasons. Try again after a while or please contact us';
  readonly localizedTitle = 'ネットワークエラー';
  readonly localizedDetail = [
    'インターネットに接続されていない等の通信上の問題によりエラーが発生しました。',
    'Wi-Fiやモバイル回線の設定やアプリの再起動を行い、時間をおいてから再度お試しください。',
    'それでも解決しない場合、サポートまでご連絡ください。'
  ].join();

  readonly params: Record<string, unknown>;

  constructor(params: Record<string, unknown> = {}) {
    const message = 'A network error has occurred.';
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    this.params = params;
  }
}
