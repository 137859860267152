import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';

interface Props {
  checked: boolean;
  color: 'default' | 'red'
}

export const CheckBox = (props: Props): React.ReactElement => {
  const source = props.color === 'default'
    ? require('../../assets/icon/check_white.png')
    : require('../../assets/icon/check_red.png');

  return <>
    {props.checked
      ? <Image source={source} style={[styles.check]} resizeMode="contain"/>
      : <View style={styles.view} />
    }
  </>;
};

const styles = StyleSheet.create({
  view: {
    width: 18,
    height: 18,
    borderWidth: 3,
    borderRadius: 2,
    borderColor: Color.gray10,
  },
  check: {
    width: 18,
    height: 18,
  },
});
