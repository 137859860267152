import { OrderWithDelivery, Printer } from '../../../domain';
import { OrderReceiptType } from '../../components';

export type OrderEvent = {
  type: 'order_created' | 'order_accepted' | 'order_canceled' | 'scheduled_order_created' | 'scheduled_order_accepted'
  value: { orderWithDelivery: OrderWithDelivery }
};

export type OrderReceiptEvent = {
  type: 'order_receipt_requested'
  value: { receiptType: OrderReceiptType, orderWithDelivery: OrderWithDelivery }
};

export type PrinterEvent = {
  type: 'printer_added' | 'printer_removed'
  value: { printer: Printer }
}

export type PrintersEvent = {
  type: 'printer_cleared'
  value: undefined
}

export type Event = OrderEvent | OrderReceiptEvent | PrinterEvent | PrintersEvent

type Listener<T> = (event: T) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let subscribers: [Event['type'], Listener<any>][] = [];

export const useEventBus = (): [typeof publish, typeof subscribe] =>
  [publish, subscribe];

export const composeDisposers = (disposers: (() => void)[]) => (): void => {
  disposers.forEach(disposer => disposer());
};

const subscribe = <T extends Event>(eventType: T['type'], listener: Listener<T['value']>) => {
  subscribers = [...subscribers, [eventType, listener]];

  return () => {
    subscribers = subscribers.filter(subscriber => subscriber[1] !== listener);
  };
};

const publish = (event: Event): void => {
  subscribers.forEach(([eventType, listener]) => {
    if (eventType !== event.type) return;

    listener(event.value);
  });
};
