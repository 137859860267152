import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, SafeAreaView, StyleSheet, View } from 'react-native';
import { Brand, BusinessDayTime, OrderPlatformSuspension, Platform } from '../../../domain';
import { groupBy } from '../../../utils';
import {
  AppText,
  If,
  NavigationBar,
  OrderPlatformSuspensionList,
  Clickable,
  WithScreen
} from '../../components';
import { Color, Route } from '../../constants';
import { useAppContext, useBusinessDayTimes, useOrderPlatformSuspensions, usePeriodicUpdater } from '../../hooks';

export const OrderPlatformSuspensionScreen = (
  { navigation }: StackScreenProps<Route, 'OrderPlatformSuspensions'>
): React.ReactElement => {
  const { store, brands, integrations, platforms } = useAppContext();
  const { now } = usePeriodicUpdater(60 * 1000);
  const [businessDayTimes, loadingBusinessDayTimes, errorBusinessDayTimes] =
    useBusinessDayTimes(store && { accountId: store.accountId, storeId: store.id });
  const [orderPlatformSuspensions, loadingOrderPlatformSuspensions, errorOrderPlatformSuspension] =
    useOrderPlatformSuspensions(store && { accountId: store.accountId, storeId: store.id, now: new Date(now) });
  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>();
  const onSelectBrand = (brand: Brand) => setSelectedBrand(brand);

  const onPressClose = useCallback(() => navigation.goBack(), [navigation]);

  const onPressBulkUpdate = useCallback((brandId: string) => {
    navigation.navigate('BulkUpdateOrderPlatformSuspensions', { brandId });
  }, [navigation]);

  const onPressCreateOrderPlatformSuspension = useCallback((platform: Platform) => {
    navigation.navigate('CreateOrderPlatformSuspensions', { platforms: [platform] });
  }, [navigation]);

  const onPressDeleteOrderPlatformSuspension = useCallback((platform: Platform) => {
    navigation.navigate('DeleteOrderPlatformSuspension', { platform });
  }, [navigation]);

  return <WithScreen
    loading={loadingBusinessDayTimes || loadingOrderPlatformSuspensions}
    error={errorBusinessDayTimes || errorOrderPlatformSuspension}
  >
    <SafeAreaView style={styles.screen}>
      <NavigationBar {...{ title: '新規注文の受付設定', onPressClose, isForMenu: false }}/>
      <OrderPlatformSuspensionView {...{
        selectedBrand,
        brands,
        businessDayTimes,
        orderPlatformSuspensions,
        integrations,
        platforms,
        onSelectBrand,
        onPressBulkUpdate,
        onPressCreateOrderPlatformSuspension,
        onPressDeleteOrderPlatformSuspension
      }}/>
    </SafeAreaView>
  </WithScreen>;
};

type ViewProps = {
  selectedBrand: Brand | undefined
  brands: Brand[] | undefined
  businessDayTimes: BusinessDayTime[] | undefined
  orderPlatformSuspensions: OrderPlatformSuspension[] | undefined

  onSelectBrand: (brand: Brand) => void;
  onPressBulkUpdate: (brandId: string) => void;
  onPressCreateOrderPlatformSuspension: (platform: Platform) => void;
  onPressDeleteOrderPlatformSuspension: (platform: Platform) => void;
}

const OrderPlatformSuspensionView = (props: ViewProps): React.ReactElement => {
  const {
    selectedBrand,
    brands,
    businessDayTimes,
    orderPlatformSuspensions,
    onSelectBrand,
    onPressBulkUpdate,
    onPressCreateOrderPlatformSuspension,
    onPressDeleteOrderPlatformSuspension
  } = props;

  useEffect(() => {
    if (brands && !selectedBrand && brands[0]) onSelectBrand(brands[0]);
  }, [brands, onSelectBrand, selectedBrand]);

  const renderItem = useCallback(({ item }: { item: Brand }) =>
    <BrandItem
      key={`OrderPlatformSuspensionView-BrandItem-${item.id}`}
      {...{ brand: item, orderPlatformSuspensions, selected: selectedBrand === item, onSelectBrand }}/>
  , [onSelectBrand, orderPlatformSuspensions, selectedBrand]);

  const ItemSeparatorComponent = useCallback(() => <View style={{ height: 10 }} />, []);

  const keyExtractor = useCallback((brand: Brand) => `order-platform-suspension-view-${brand.id}`, []);

  return <View style={styles.view}>
    <View style={styles.left}>
      <FlatList {...{ data: brands, renderItem, ItemSeparatorComponent, keyExtractor }} style={styles.menu} />
    </View>
    <View style={styles.container}>
      <OrderPlatformSuspensionList {...{
        brand: selectedBrand,
        businessDayTimes,
        orderPlatformSuspensions,
        onPressBulkUpdate,
        onPressCreateOrderPlatformSuspension,
        onPressDeleteOrderPlatformSuspension
      }}/>
    </View>
  </View>;
};

type BrandItemProps = {
  brand: Brand
  orderPlatformSuspensions: OrderPlatformSuspension[] | undefined
  selected: boolean

  onSelectBrand: (brand: Brand) => void
}

const BrandItem = (
  { brand, orderPlatformSuspensions, selected, onSelectBrand }: BrandItemProps
) => {
  const onPress = useCallback(() => onSelectBrand(brand), [brand, onSelectBrand]);

  const count = orderPlatformSuspensions && groupBy(orderPlatformSuspensions
    .sort((a, b) => a.createTime.toMillis() - b.createTime.toMillis())
    .filter(suspension => suspension.brandId === brand.id)
  , suspension => suspension.platformType).length;

  return <Clickable name="settings_tap" id={`orderPlatformSuspension_${brand.id}`} onPress={onPress}>
    <View style={[styles.menuItem, selected && styles.menuItemSelected]}>
      <View style={styles.menuItemTextView}>
        <AppText style={[styles.menuItemText, selected && styles.menuItemTextSelected]} numberOfLines={2}>
          {brand.name}
        </AppText>
      </View>
      <If condition={count}>
        <View style={styles.menuItemBadgeView}>
          <View style={styles.badge}>
            <AppText style={styles.badgeText}>{count}</AppText>
          </View>
        </View>
      </If>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  badge: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: Color.red,
  },
  badgeText: {
    fontSize: 14,
    color: Color.white,
  },
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
  view: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Color.gray5,
    width: '100%',
  },
  left: {
    borderRightWidth: 1,
    borderRightColor: Color.gray10,
    width: 264
  },
  menu: {
    paddingVertical: 32,
    paddingRight: 32,
    paddingLeft: 56,
  },
  menuItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 22,
    paddingLeft: 18,
    paddingRight: 22
  },
  menuItemSelected: {
    backgroundColor: Color.brand,
    borderRadius: 6,
  },
  menuItemTextView: {
    width: 116
  },
  menuItemText: {
    color: Color.black,
    fontSize: 14,
    fontWeight: '400',
  },
  menuItemTextSelected: {
    color: Color.white,
    fontWeight: '500',
  },
  menuItemBadgeView: {
    paddingLeft:4
  },
  container: {
    flex: 1,
    paddingTop: 32,
    paddingLeft: 32,
    paddingRight: 56
  }
});
