export const groupBy = <K, V>(array: readonly V[], getKey: (current: V) => K): [K, V[]][] => {
  const map = array.reduce((map, current) => {
    const key = getKey(current);
    const list = map.get(key);
    return list ? map.set(key, [...list, current]) : map.set(key, [current]);
  }, new Map<K, V[]>());

  return Array.from(map);
};

export const concatPromises = <T>(fs: (() => Promise<T>)[]): Promise<T[]> =>
  fs.reduce(
    (acc: Promise<T[]>, f) => acc.then(result => f().then(result2 => [...result, result2])),
    Promise.resolve([])
  );

export const sleep = (timer: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, timer));

/**
 * @param object object to filter
 * @param keys keys to filter object
 * @return filtered object by specified keys
 */
export const pickObject = <T, K extends keyof T>(object: T, keys: K[]): Pick<T, K> =>
  Object.assign(
    {},
    ...keys
      .filter(key => object && Object.prototype.hasOwnProperty.call(object, key))
      .map(key => ({ [key]: object[key] }))
  );

export const debug = (name: string) => <T>(value: T): T => {
  console.log(name, value);
  return value;
};

export * from './dateTime';
