import React, { useCallback } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { NewsItem } from '../../../../domain';
import { Color } from '../../../constants';
import { NewsListItem } from './NewsListItem';

interface Props {
  newsItems: NewsItem[];
  onPressNewsItem: (newsItem: NewsItem) => void;
}

export const NewsList = ({ newsItems, onPressNewsItem }: Props): React.ReactElement => {
  const keyExtractor = useCallback((item: NewsItem) => `NewsList-NewsItem-${item.id}`, []);

  const renderItem = useCallback(({ item }: { item: NewsItem }) =>
    <NewsListItem {...{ newsItem: item, onPressNewsItem }} />
  , [onPressNewsItem]);

  return <FlatList style={styles.view} data={newsItems} keyExtractor={keyExtractor} renderItem={renderItem}/>;
};

const styles = StyleSheet.create({
  view: {
    backgroundColor: Color.white,
  },
});
