import firebase from 'firebase/compat/app';
import { useMemo } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { DeliveryEstimation } from '../../../domain';

type Params = {
  accountId: string
  storeId: string
  brandId: string
  orderId: string
}

export const useDeliveryEstimations = (
  params: Params | undefined
): [DeliveryEstimation[] | undefined, boolean, Error | undefined] => {
  const query = useMemo(() => params && firebase.firestore()
    .collectionGroup('deliveryEstimations')
    .where('accountId', '==', params.accountId)
    .where('storeId', '==', params.storeId)
    .where('brandId', '==', params.brandId)
    .where('orderId', '==', params.orderId)
  , [params]);

  return useCollectionData<DeliveryEstimation>(query);
};
