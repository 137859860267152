export enum PlatformType {
  BOTEATS = 'boteats',
  DEMAECAN = 'demaecan',
  DEMAECAN_OFFICIAL = 'demaecanOfficial',
  MENU = 'menu',
  MENU_OFFICIAL = 'menuOfficial',
  SUKESAN = 'sukesan',
  UBEREATS = 'ubereats',
  WOLT = 'wolt',
  WOLT_OFFICIAL = 'woltOfficial'
}
