import { ImageURISource } from 'react-native';
import { Integration, PlatformType } from '../../domain';

/**
 * @deprecated
 */
export class PlatformTypeDecorator {
  readonly platformType: PlatformType;
  private integration: Integration | undefined;

  constructor(platformType: PlatformType, integrations: Integration[] | undefined) {
    this.platformType = platformType;
    this.integration = integrations?.find(integration => this.platformType === integration.platformType);
  }

  name(): string {
    return this.integration?.meta.name ?? '不明なプラットフォーム';
  }

  icon(): ImageURISource {
    return this.integration
      ? { uri: this.integration?.meta.iconUrl }
      : { uri: 'https://foodstudio-prod.web.app/images/platform/ubereats.png' };
  }

  phoneNumber(): string | undefined {
    return this.integration?.meta.support.phoneNumber ?? undefined;
  }

  supportMessage(): string | undefined {
    return this.integration?.meta.support.message ?? undefined;
  }
}
