import { Delivery, DeliveryEstimation } from '../../domain';
import { Formatter } from '../utils';

export class DeliveryDecorator {
  private delivery: Delivery
  private deliveryEstimaiton: DeliveryEstimation

  constructor(delivery: Delivery, deliveryEstimation: DeliveryEstimation) {
    this.delivery = delivery;
    this.deliveryEstimaiton = deliveryEstimation;
  }

  fulfillmentTimeText(): string {
    return (this.delivery.courierType === 'inhouse' || this.delivery.courierType === 'deaas')
      && this.deliveryEstimaiton.deliveryTime
      ? `配達時間：${Formatter.dayTime(this.deliveryEstimaiton.deliveryTime.toDate())}`
      : `調理期限：${Formatter.dayTime(this.deliveryEstimaiton.pickupTime.toDate())}`;
  }
}
