export type HasLocalizedDescription = {
  localizedTitle: string
  localizedDetail: string
};

export const hasLocalizedDescription = (error: unknown): error is HasLocalizedDescription =>
  typeof error === 'object'
  && !!error
  && 'localizedTitle' in error
  && 'localizedDetail' in error;
