import dayjs from 'dayjs';
import deepEqual from 'fast-deep-equal';
import firebase from 'firebase/compat/app';
import { useEffect, useMemo, useState } from 'react';
import { Delivery, DeliveryEstimation, HasId, Order, OrderWithDelivery, Store } from '../../../domain';
import { useCollectionDiffData } from '../core/useCollectionDiffData';
import { useEventBus } from './useEventBus';

type Props = {
  store: Store | undefined;
}

type Response = {
  activeOrders: Order[];
  loading: boolean;
  error: Error | undefined;
}

export const useOrderNotification = ({ store }: Props): Response => {
  const [createdOrderIds, setCreatedOrderIds] = useState<Set<string>>(new Set());
  const [createdScheduledOrderIds, setCreatedScheduledOrderIds] = useState<Set<string>>(new Set());
  const [acceptedOrderIds, setAcceptedOrderIds] = useState<Set<string>>(new Set());
  const [scheduledOrderIds, setScheduledOrderIds] = useState<Set<string>>(new Set());
  const [canceledOrderIds, setCanceledOrderIds] = useState<Set<string>>(new Set());
  const [orders, setOrders] = useState<Order[]>([]);

  const [publish] = useEventBus();

  const createdQuery = useMemo(() =>
    store?.id
      ? firebase.firestore()
        .collectionGroup('orders')
        .where('storeId', '==', store.id)
        .where('status', 'in', ['CREATED', 'ACCEPTED', 'READY'])
        .orderBy('createTime', 'desc')
      : undefined
  , [store?.id]);

  const canceledQuery = useMemo(() =>
    store?.id
      ? firebase.firestore()
        .collectionGroup('orders')
        .where('storeId', '==', store.id)
        .where('status', '==', 'CANCELED')
        .where('updateTime', '>=', dayjs().subtract(1, 'hours').toDate())
        .orderBy('updateTime', 'desc')
      : undefined
  , [store?.id]);

  const [activeOrders, activeLoading, activeError] = useCollectionDiffData<Order>(createdQuery, changes => {
    changes.forEach(({ type, before, after}) => {
      const addedCreatedOrder = type === 'added' && after?.status === 'CREATED';
      const addedAcceptedOrder = type === 'added' && after?.status === 'ACCEPTED';
      const modifiedOrderToAccepted = type === 'modified' && before?.status === 'CREATED' && after?.status === 'ACCEPTED';

      if (!after) return;

      if (after.deliveryTimeType.type === 'scheduled') {
        if (addedCreatedOrder)
          return doExactOnce(
            { order: after, delivery: undefined, deliveryEstimation: undefined },
            createdScheduledOrderIds,
            setCreatedScheduledOrderIds,
            (orderWithDelivery: OrderWithDelivery) =>
              publish({ type: 'scheduled_order_created', value: { orderWithDelivery }})
          );

        if (modifiedOrderToAccepted)
          return Promise.resolve(getDeliveryInfo(after))
            .then(([delivery, deliveryEstimation]) => doExactOnce(
              { order: after, delivery, deliveryEstimation },
              scheduledOrderIds,
              setScheduledOrderIds,
              (orderWithDelivery: OrderWithDelivery) =>
                publish({ type: 'scheduled_order_accepted', value: { orderWithDelivery }})
            ));
      } else {
        if (addedCreatedOrder)
          return doExactOnce(
            { order: after, delivery: undefined, deliveryEstimation: undefined },
            createdOrderIds,
            setCreatedOrderIds,
            (orderWithDelivery: OrderWithDelivery) => publish({ type: 'order_created', value: { orderWithDelivery }})
          );
        if (addedAcceptedOrder || modifiedOrderToAccepted)
          return Promise.resolve(getDeliveryInfo(after))
            .then(([delivery, deliveryEstimation]) => doExactOnce(
              { order: after, delivery, deliveryEstimation },
              acceptedOrderIds,
              setAcceptedOrderIds,
              (orderWithDelivery: OrderWithDelivery) => publish({ type: 'order_accepted', value: { orderWithDelivery }})
            ));
      }
    });
  });

  const [, canceledLoading, canceledError] = useCollectionDiffData<Order>(canceledQuery, changes => {
    changes.forEach(({ type, after}) => {
      if (type === 'added' && after && dayjs(after.preparationTime.toDate()).diff(dayjs().subtract(12, 'hours'), 'seconds') > 0) {
        Promise.resolve(getDeliveryInfo(after))
          .then(([delivery, deliveryEstimation]) => doExactOnce(
            { order: after, delivery, deliveryEstimation },
            canceledOrderIds,
            setCanceledOrderIds,
            (orderWithDelivery: OrderWithDelivery) => publish({ type: 'order_canceled', value: { orderWithDelivery }})
          ));
      }
    });
  });

  useEffect(() => {
    const newOrders = activeOrders
      .map(order => ({ ...order, deliveryTime: undefined, pickupTime: undefined, updateTime: undefined }));

    if (deepEqual(orders, newOrders)) return;

    setOrders(newOrders);
  }, [orders, activeOrders]);

  return {
    activeOrders: orders,
    loading: activeLoading || canceledLoading,
    error: activeError ?? canceledError
  };
};

const doExactOnce = (
  orderWithDelivery: OrderWithDelivery,
  set: Set<string>,
  setNewSet: (set: Set<string>) => void,
  fn: (order: OrderWithDelivery) => void
) => {
  if (set.has(orderWithDelivery.order.id)) return;

  fn(orderWithDelivery);
  setNewSet(set.add(orderWithDelivery.order.id));
};

const getDeliveryInfo = async(
  { accountId, storeId, brandId, id }: Order
): Promise<[Delivery | undefined, DeliveryEstimation | undefined]> => {
  const idConverter = <T extends HasId>(): firebase.firestore.FirestoreDataConverter<T> => ({
    toFirestore(document): firebase.firestore.DocumentData {
      return document;
    },
    fromFirestore(snapshot, options): T {
      return { ...snapshot.data(options), id: snapshot.id } as T;
    },
  });

  const deliveryQuery = await firebase.firestore()
    .collection('accounts')
    .doc(accountId)
    .collection('stores')
    .doc(storeId)
    .collection('brands')
    .doc(brandId)
    .collection('deliveries')
    .where('orderId', '==', id)
    .withConverter(idConverter<Delivery>())
    .get();
  const delivery = deliveryQuery.docs[0]?.data();

  if (!delivery) return [undefined, undefined];

  const deliveryEstimationQuery = await firebase.firestore()
    .collection('accounts')
    .doc(accountId)
    .collection('stores')
    .doc(storeId)
    .collection('brands')
    .doc(brandId)
    .collection('deliveries')
    .doc(delivery.id)
    .collection('deliveryEstimations')
    .where('deliveryId', '==', delivery.id)
    .withConverter(idConverter<DeliveryEstimation>())
    .get();
  const deliveryEstimation = deliveryEstimationQuery.docs[0]?.data();

  return [delivery, deliveryEstimation];
};
