import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { printerSeries, PrinterSeries } from '../../domain';
import { NavigationBar, PrinterModelList, WithModal } from '../components';
import { Route } from '../constants';
import { useEventBus } from '../hooks';

export const PrinterModelScreen = (
  { navigation, route }: StackScreenProps<Route, 'PrinterModel'>
): React.ReactElement => {
  const { printer } = route.params;
  const [publish] = useEventBus();

  const onPressConfirm = useCallback((printerSeries: PrinterSeries) => {
    publish({ type: 'printer_added', value: { printer: printer.copy(printerSeries.nativeValue as never) } });
    navigation.goBack();
  }, [publish, printer, navigation]);

  const printerSerieses = (() => {
    switch (printer.maker) {
      case 'EPSON':
        return printerSeries.filter(({ type }) => type === 'epson');
      case 'Star':
        return printerSeries.filter(({ type }) => type === 'star');
    }
  })();

  const Header = <NavigationBar title="プリンターの機種を選択してください" isForMenu={true} />;
  const Body = <View style={styles.screen}>
    <PrinterModelList {...{ printerSerieses, onPressConfirm }} />
  </View>;

  return <WithModal {...{ Header, Body, loading: false, error: undefined }} />;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
});
