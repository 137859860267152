import dayjs from 'dayjs';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { Environment } from '../../../../../../Environment';
import { Color } from '../../../../../constants';
import { AppText } from '../../../../AppText';
import { If } from '../../../../If';
import { Clickable } from '../../../../Clickable';
import { ExtraBusinessDayTimeFormAction } from '../extraBusinessDayTime';

type MultiDatePickerProps = {
  sectionIndex: number
  datesWithAvailability: { date: string, disabled: boolean }[]
  dispatch: React.Dispatch<ExtraBusinessDayTimeFormAction>
}

export const MultiDatePicker = (props: MultiDatePickerProps): React.ReactElement =>
  <View style={styles.wrapper}>
    <If condition={Environment.platform.type === 'web'}>
      <DatePickerForWeb {...props} />
    </If>
    <If condition={Environment.platform.type !== 'web'}>
      <DatePickerForNative {...props} />
    </If>
  </View>;

const DatePickerForWeb = ({ sectionIndex, datesWithAvailability, dispatch }: MultiDatePickerProps) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const showPicker = useCallback(() => {
    inputRef.current?.showPicker();
    setDatePickerVisibility(true);
  }, []);

  const onBlur = useCallback(() => setDatePickerVisibility(false), []);

  interface DateInputForWebProps {
    minimumDate: Date
    value?: string
  }

  const DateInputForWeb = ({ minimumDate, value }: DateInputForWebProps): ReactElement => {
    const onChange = useCallback(() => {
      dispatch({
        type: 'addBusinessDayTimeToLocalSection',
        payload: {
          sectionIndex: sectionIndex,
          date: inputRef.current?.value ?? ''
        }
      });
      setDatePickerVisibility(false);
    }, []);

    return <input
      id={`dateInputForWeb-datePicker-${sectionIndex}`}
      value={value}
      style={{ border: 'none', outline: 'none' }}
      type='date'
      min={dayjs(minimumDate).format('YYYY-MM-DD')}
      pattern='d{4}-d{2}-d{2}'
      ref={inputRef}
      onChange={onChange}
      onBlur={onBlur} />;
  };

  const onPressDate = useCallback((date: string) =>
    dispatch({
      type: 'removeBusinessDayTimeFromLocalSection',
      payload: { sectionIndex, date }
    })
  , [dispatch, sectionIndex]);

  return <>
    <If condition={datesWithAvailability[0]?.disabled}>
      <Clickable id={`dateInputForWeb-selectDate-${sectionIndex}`} name='selectDate_tap' onPress={showPicker}>
        <AppText style={styles.text}>日にちを選択</AppText>
      </Clickable>
      <View style={styles.hiddenDatePickerView}>
        {DateInputForWeb({ minimumDate: new Date()})}
      </View>
    </If>
    <If condition={!datesWithAvailability[0]?.disabled}>
      {/* Note: Using FlatList renders unnecessary components and reduces the touchable area */}
      {datesWithAvailability.map(dateWithAvailability =>
        <DateTag
          key={`multiDatePicker-${sectionIndex}-date-${dateWithAvailability.date}`}
          id={dateWithAvailability.date}
          value={dateWithAvailability.date}
          onPressDate={onPressDate} />
      )}
      <View style={styles.hiddenDatePickerView}>
        {DateInputForWeb({ minimumDate: new Date()})}
      </View>
    </If>
    <View style={styles.dropDown}>
      <Clickable
        id={`dateInputForWeb-cursor-${sectionIndex}`}
        name={`dateInputForWeb-cursor-${sectionIndex}`}
        onPress={showPicker}
        onBlur={onBlur}
        style={styles.toucheableArea}>
        {isDatePickerVisible
          ? <Image
            source={require('../../../../../assets/icon/cursor_up.png')}
            style={styles.arrowIcon}
            resizeMode="contain" />
          : <Image
            source={require('../../../../../assets/icon/cursor_down.png')}
            style={styles.arrowIcon}
            resizeMode="contain" />
        }
      </Clickable>
    </View>
  </>;
};

const DatePickerForNative = ({ sectionIndex, datesWithAvailability, dispatch }: MultiDatePickerProps) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const handleDatePickerVisibility = useCallback(() => setDatePickerVisibility(value => !value), []);

  const onPressConfirm = useCallback((selectedDate: Date) => {
    dispatch({
      type: 'addBusinessDayTimeToLocalSection',
      payload: { sectionIndex: sectionIndex, date: dayjs(selectedDate).format('YYYY-MM-DD') }
    });
    setDatePickerVisibility(false);
  }, [dispatch, sectionIndex]);

  const onPressRemove = useCallback((date: string) => {
    dispatch({ type: 'removeBusinessDayTimeFromLocalSection', payload: { sectionIndex, date }});
  }, [dispatch, sectionIndex]);

  return <>
    <If condition={datesWithAvailability[0]?.disabled}>
      <Clickable
        id={`dateInputForNative-selectDate-${sectionIndex}`}
        name='selectDate_tap'
        onPress={handleDatePickerVisibility}>
        <AppText style={styles.text}>日にちを選択</AppText>
      </Clickable>
    </If>
    <If condition={!datesWithAvailability[0]?.disabled}>
      {/* Note: Using FlatList renders unnecessary components and reduces the touchable area */}
      {datesWithAvailability.map(dateWithAvailability =>
        <DateTag
          key={`multiDatePicker-${sectionIndex}-date-${dateWithAvailability.date}`}
          id={dateWithAvailability.date}
          onPressDate={onPressRemove}
          value={dateWithAvailability.date} />
      )}
    </If>
    <View style={styles.dropDown}>
      <Clickable
        id={`dateInputForNative-cursor-${sectionIndex}`}
        name={`dateInputForNative-cursor-${sectionIndex}`}
        onPress={handleDatePickerVisibility}
        style={styles.toucheableArea} >
        <Image
          source={require('../../../../../assets/icon/cursor_down.png')}
          style={styles.arrowIcon}
          resizeMode="contain" />
      </Clickable>
    </View>
    <DateTimePickerModal
      testID={`MultiDatePicker-DateTimePickerModal-${sectionIndex}`}
      isVisible={isDatePickerVisible}
      mode="date"
      onConfirm={onPressConfirm}
      onCancel={handleDatePickerVisibility} />
  </>;
};

type DateTagProps = {
  id: string,
  value: string
  onPressDate: (value: string) => void
}

const DateTag = ({ id, value, onPressDate }: DateTagProps): React.ReactElement => {
  const onPress = useCallback(() => {
    onPressDate(value);
  }, [onPressDate, value]);

  return <Clickable id={id} name='tag_tap' onPress={onPress}>
    <View style={styles.tag}>
      <AppText style={styles.badgeText}>{value.replace(/-/g, '/')}</AppText>
      <Image source={require('../../../../../assets/icon/close_black.png')} resizeMode="contain" style={styles.icon}/>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  arrowIcon: {
    position: 'absolute',
    width: 16,
    height: 16,
    right: 16,
    top: 10
  },
  badgeText: {
    height: 16,
    fontSize: 13,
    color: Color.gray100,
  },
  dropDown: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 48,
  },
  hiddenDatePickerView: {
    width: 0,
    height: 32,
    top: 16,
  },
  icon: {
    width: 16,
    height: 16,
    marginHorizontal: 4,
    marginVertical: 3
  },
  tag: {
    backgroundColor: Color.gray5,
    borderRadius: 4,
    flex: 1,
    flexDirection: 'row',
    height: 32,
    marginVertical: 8,
    marginLeft: 8,
    paddingLeft: 8,
    paddingVertical: 4,
    width: 110,
  },
  text: {
    fontSize: 16,
    marginLeft: 12,
    marginVertical: 2
  },
  toucheableArea: {
    height: 32,
    width: '100%',
  },
  wrapper: {
    minWidth: 592,
    height: 48,
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Color.gray10,
  },
});
