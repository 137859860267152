import React, { useCallback, useMemo } from 'react';
import { FlatList, SectionList, StyleSheet, View } from 'react-native';
import { isOrderAccepted, isOrderCreated, isOrderReady, isOrderScheduled, Order, Platform } from '../../../../domain';
import { Color } from '../../../constants';
import { useAppContext } from '../../../hooks';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { OrderCardListItem } from './OrderCardListItem';

interface Props {
  orders: Order[];
  sectioned?: boolean;
  loadingOrderIds: string[];
  onPressOrder: (order: Order) => void;
  onPressOrderConfirm: (order: Order) => void;
}

export const OrderCardList = (props: Props): React.ReactElement => {
  const { sectioned, orders, loadingOrderIds, onPressOrder, onPressOrderConfirm } = props;
  const { platforms } = useAppContext();

  const createdOrders = useMemo(() => orders.filter(isOrderCreated), [orders]);
  const acceptedOrders = useMemo(() => orders.filter(isOrderAccepted), [orders]);
  const readyOrders = useMemo(() => orders.filter(isOrderReady), [orders]);
  const scheduledOrders = useMemo(() => orders.filter(isOrderScheduled), [orders]);

  const sections = useMemo(
    () => buildSections(platforms ?? [], createdOrders, acceptedOrders, readyOrders, scheduledOrders),
    [platforms, createdOrders, acceptedOrders, readyOrders, scheduledOrders]
  );

  const renderItem = useCallback(({ item }: { item: Order }) =>
    <OrderCardListItem
      order={item}
      loading={loadingOrderIds.some(id => item.id === id)}
      onPress={onPressOrder}
      onPressButton={onPressOrderConfirm}/>
  , [loadingOrderIds, onPressOrder, onPressOrderConfirm]);

  return <ViewComponent {...{ sectioned, sections, orders, renderItem }} />;
};

interface ViewProps {
  sectioned: boolean | undefined
  sections: {
    title: string
    data: Order[]
    dataName: string
  }[]
  orders: Order[]
  renderItem: (item: { item: Order }) => React.ReactElement
}

const ViewComponent = ({ sectioned, sections, orders, renderItem }: ViewProps) => {
  const keyExtractor = useCallback((order: Order) => `orderCardListItem-${order.uuid}`, []);

  const getItemLayout = useCallback((_: unknown, index: number) => ({ length: 89, offset: 89 * index, index }), []);

  const renderSectionHeader = useCallback(({ section: { title } }: { section: { title: string } }) => (
    <View style={styles.header}>
      <AppText style={styles.headerText}>{title}</AppText>
      <If condition={title.indexOf('予約注文') !== -1}>
        <AppText style={styles.headerSubText}>調理開始時刻になるとアラートが表示され、調理中に移動します。</AppText>
      </If>
    </View>
  ), []);

  return sectioned
    ? <SectionList
      sections={sections}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      keyExtractor={keyExtractor}
      stickySectionHeadersEnabled={false}
      style={styles.view}
      contentContainerStyle={styles.container}
      windowSize={8}
      getItemLayout={getItemLayout}
      removeClippedSubviews={true}
      maxToRenderPerBatch={5} />
    : <FlatList
      data={orders}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      style={styles.view}
      contentContainerStyle={styles.containerNoSectioned}
      windowSize={8}
      getItemLayout={getItemLayout}
      removeClippedSubviews={true}
      maxToRenderPerBatch={5} />;
};

const buildSections = (
  platforms: Platform[],
  createdOrders: Order[],
  acceptedOrders: Order[],
  readyOrders: Order[],
  scheduledOrders: Order[]
) =>
  [
    platforms.some(platform => !platform.acceptanceMethod.automated)
      ? {
        title: `受注待ち (${createdOrders
          .filter(order => platforms
            .filter(platform => !platform.acceptanceMethod.automated)
            .some(platform => platform.brandId == order.brandId && platform.type === order.platform)).length})`,
        data: createdOrders
          .filter(order => platforms
            .filter(platform => !platform.acceptanceMethod.automated)
            .some(platform => platform.brandId == order.brandId && platform.type === order.platform)
          ),
        dataName: '受注待ち'
      }
      : undefined,
    {
      title: `調理中 (${acceptedOrders.length})`,
      data: acceptedOrders,
      dataName: '調理中',
    },
    {
      title: `準備完了 (${readyOrders.length})`,
      data: readyOrders,
      dataName: '準備完了',
    },
    {
      title: `予約注文 (${scheduledOrders.length})`,
      data: scheduledOrders,
      dataName: '予約注文',
    },
  ]
    .filter((section): section is { title: string, data: Order[], dataName: string } => !!section);

const styles = StyleSheet.create({
  view: {
    backgroundColor: Color.dark,
  },
  container: {
    paddingTop: 6,
    paddingBottom: 30,
    paddingHorizontal: 16,
  },
  containerNoSectioned: {
    paddingTop: 32,
    paddingBottom: 30,
    paddingHorizontal: 16,
  },
  header: {
    paddingTop: 26,
    paddingBottom: 14,
  },
  headerText: {
    color: Color.white,
    fontSize: 18,
    fontWeight: '500',
  },
  headerSubText: {
    fontSize: 16,
    color: Color.gray30,
    paddingTop: 8,
    paddingBottom: 14,
  },
});
