import { useEffect } from 'react';
import { hasLocalizedDescription, UnknownError } from '../../../domain';
import { alert, logger } from '../../../infrastructure';

export const useErrorHandler = (error: Error | undefined): void => {
  useEffect(() => {
    if (!error) return;

    logger.error(error);

    const { localizedTitle, localizedDetail } = mapError(error);
    alert(localizedTitle, localizedDetail, { text: 'OK' }, { cancelable: false });

  }, [error]);
};

const mapError = (error: Error) =>
  hasLocalizedDescription(error) ? error : new UnknownError();
