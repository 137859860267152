import { getNetworkStateAsync } from 'expo-network';
import { useEffect, useRef, useState } from 'react';
import { logger } from '../../../infrastructure';

export const useNetworkState = (): [boolean] => {
  const isNetworkConnectedRef= useRef(true);
  const [isNetworkConnected, setIsNetworkConnected] = useState(true);

  useEffect(() => {
    const id = setInterval(() => {
      getNetworkStateAsync()
        .then(({ isInternetReachable, ...rest }) => {
          if (!!isInternetReachable === isNetworkConnectedRef.current) return;
          logger.info('Network state changed', { isInternetReachable, ...rest });

          isNetworkConnectedRef.current = !!isInternetReachable;
          setIsNetworkConnected(!!isInternetReachable);
        });
    }, 5 * 1000);
    return () => clearInterval(id);
  }, []);

  return [isNetworkConnected];
};
