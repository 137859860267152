import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Order, OrderWithDelivery } from '../../../../domain';
import { Color } from '../../../constants';
import { DeliveryDecorator, OrderDecorator } from '../../../decorators';
import { Formatter } from '../../../utils';
import { AppText } from '../../AppText';
import { If } from '../../If';

type Props = {
  orderWithDelivery: OrderWithDelivery
};

type Item = Order['items'][number];
type Option = Order['items'][number]['options'][number];

interface OptionGroupViewModel {
  option: Option;
  isHideCategory?: boolean;
  isHideBorder?: boolean;
  optionGroups?: OptionGroupViewModel[];
}

interface ItemsViewModel {
  items: {
    item: Item;
    optionGroups: OptionGroupViewModel[];
  }[];
}

export const OrderView = ({ orderWithDelivery }: Props): React.ReactElement => {
  const { order, delivery, deliveryEstimation } = orderWithDelivery;
  const orderDecorator = new OrderDecorator(order);
  const deliveryDecorator = delivery && deliveryEstimation && new DeliveryDecorator(delivery, deliveryEstimation);

  return <>
    <AppText style={styles.orderTimeText}>{`注文時間：${Formatter.dayTime(order.createTime.toDate())}`}</AppText>
    <If condition={order.deliveryTimeType.type === 'scheduled'}>
      <AppText style={styles.scheduledTimeText}>{`予約時間：${orderDecorator.scheduledTimeText('absolute')}`}</AppText>
    </If>
    <AppText style={styles.fulfillmentTimeText}>{deliveryDecorator?.fulfillmentTimeText()}</AppText>
    <If condition={orderDecorator.customerNameText}>
      <AppText style={styles.orderNameText}>{orderDecorator.customerNameText()}</AppText>
    </If>
    <If condition={order.inHouseDelivery}>
      <View style={styles.deliveryInfoPhoneNumberAndPinCode}>
        <AppText style={styles.deliveryInfoText}>{order.customer.phoneNumber}</AppText>
        <If condition={order.customer.phonePinCode}>
          <AppText style={styles.deliveryInfoText}>PIN：{order.customer.phonePinCode}</AppText>
        </If>
      </View>
      <View style={{marginTop : 16}} />
      <AppText style={styles.deliveryInfoText}>{order.inHouseDelivery?.title}</AppText>
      <AppText style={styles.deliveryInfoText}>{order.inHouseDelivery?.address}</AppText>
      <View style={{marginTop : 16}} />
      <If condition={orderDecorator.interactionText()?.length}>
        <AppText style={styles.deliveryInfoText}>受け渡し方法：{orderDecorator.interactionText()}</AppText>
      </If>
      <If condition={order.inHouseDelivery?.comment?.length}>
        <View style={styles.deliveryInfoComment}>
          <AppText style={styles.deliveryInfoCommentText}>{order.inHouseDelivery?.comment}</AppText>
        </View>
      </If>
      <If condition={order.inHouseDelivery?.cash}>
        <View style={styles.deliveryInfoPayment}>
          <AppText style={styles.deliveryInfoPaymentText}>お支払い：{Formatter.price(order.price.total)}（現金）</AppText>
        </View>
      </If>
    </If>
    <View style={[styles.border, { marginTop: 20 }]} />
    <View style={{ height: 48 }} />
    <If condition={order.cutlery !== null}>
      <View style={styles.cutlery}>
        <AppText style={styles.cutleryText}>使い捨て製品：利用{order.cutlery ? 'する' : 'しない'}</AppText>
      </View>
    </If>
    <If condition={order.comment?.length}>
      <View style={styles.comment}>
        <AppText style={styles.commentText}>{order.comment}</AppText>
      </View>
    </If>
    <View style={styles.items}>
      {itemViewModel(order).items.map((item, index) => <ItemView item={item} key={`receipt-item-${index}`} />)}
    </View>
    <View style={styles.border} />
  </>;
};

const itemViewModel = (order: Order): ItemsViewModel => ({
  items: order.items.map((item) => ({
    item: item,
    optionGroups: item.options
      .filter((option) => !option.parentId)
      .map((option, index, array) => ({
        // オプション_1階層
        option: option,
        isHideCategory: index !== 0 && array[index - 1]?.category === option.category,
        isHideBorder:
          (index === array.length - 1 &&
            item.options.filter((child) => option.id && option.id === child.parentId).length === 0) ||
          (index !== array.length - 1 && array[index + 1]?.category === option.category),
        optionGroups: item.options
          .filter((child) => option.id && option.id === child.parentId)
          .map((child, childIndex, childArray) => ({
            // オプション_2階層
            option: child,
            isHideCategory: childIndex !== 0 && childArray[childIndex - 1]?.category === child.category,
            isHideBorder:
              (index === array.length - 1 &&
                childIndex === childArray.length - 1 &&
                item.options.filter((grandChild) => child.id === grandChild.parentId).length === 0) ||
              (childIndex !== childArray.length - 1 && childArray[childIndex + 1]?.category === child.category),
            optionGroups: item.options
              .filter((grand) => grand.parentId === child.id)
              .map((grand, grandIndex, grandArray) => ({
                // オプション_3階層
                option: grand,
                isHideCategory: grandIndex !== 0 && grandArray[grandIndex - 1]?.category === grand.category,
                isHideBorder:
                  (index === array.length - 1 && childIndex === childArray.length - 1) ||
                  (grandIndex !== grandArray.length - 1 && grandArray[grandIndex + 1]?.category !== grand.category),
              })),
          })),
      })),
  })),
});

const ItemView = ({ item }: { item: ItemsViewModel['items'][number] }) =>
  <View style={styles.itemView}>
    <AppText style={styles.itemNameText}>
      <AppText>{item.item.quantity}x</AppText> <AppText>{item.item.title}</AppText>
    </AppText>
    {item.optionGroups.map((optionGroup, i) => (
      <View style={styles.optionView} key={`receiptView-optionGroups-${optionGroup.option.id}-${i}`}>
        <OptionGroupView
          option={optionGroup.option}
          isHideCategory={optionGroup.isHideCategory}
          isHideBorder={optionGroup.isHideBorder}
          optionGroups={optionGroup.optionGroups}
        />
      </View>
    ))}
    <If condition={item.item.comment?.length}>
      <View style={styles.itemComment}>
        <AppText style={styles.itemCommentText}>{item.item.comment}</AppText>
      </View>
    </If>
  </View>;

const OptionGroupView = ({ option, optionGroups, isHideCategory }: OptionGroupViewModel) =>
  <>
    <View style={[styles.optionView, isHideCategory && styles.childView]}>
      <If condition={option.category && !isHideCategory}>
        <AppText style={styles.category}>({option.category})</AppText>
      </If>
      <AppText style={styles.optionQuantityAndoptionTitleText}>
        <AppText>{option.quantity}x</AppText>
        {' '}
        <AppText>{option.title}</AppText>
      </AppText>
    </View>
    {optionGroups?.map((optionGroup, i) => (
      <View style={{ marginLeft: 24 }} key={`optionGroups-item-${optionGroup.option.id || i}`}>
        <OptionGroupView
          option={optionGroup.option}
          isHideCategory={optionGroup.isHideCategory}
          isHideBorder={optionGroup.isHideBorder}
          optionGroups={optionGroup.optionGroups}
        />
      </View>
    ))}
  </>;

const styles = StyleSheet.create({
  items: {
    paddingTop: 36,
  },
  itemView: {
    paddingBottom: 36,
  },
  optionView: {
    marginLeft: 50,
    marginTop: 12,
  },
  orderTimeText: {
    fontSize: 36,
    fontWeight: '300',
  },
  fulfillmentTimeText: {
    fontSize: 36,
    fontWeight: '300',
    marginTop: 8,
  },
  scheduledTimeText: {
    fontSize: 36,
    fontWeight: '300',
    marginTop: 8,
  },
  orderNameText: {
    fontSize: 36,
    fontWeight: '300',
    marginTop: 8,
  },
  deliveryInfoText: {
    fontSize: 36,
    fontWeight: '300',
    marginTop: 8,
  },
  deliveryInfoPhoneNumberAndPinCode: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  deliveryInfoComment: {
    borderWidth: 2,
    borderColor: Color.black,
    borderStyle: 'dashed',
    padding: 8,
    marginTop: 36,
  },
  deliveryInfoCommentText: {
    fontSize: 36,
    fontWeight: '300',
  },
  deliveryInfoPayment: {
    padding: 8,
    backgroundColor: Color.black,
    marginTop: 36,
  },
  deliveryInfoPaymentText: {
    fontSize: 48,
    fontWeight: '700',
    color: Color.white,
  },
  itemNameText: {
    fontSize: 52,
    fontWeight: '700',
  },
  optionChildView: {
    marginLeft: 116,
  },
  childView: {
    marginTop: 4,
  },
  category: {
    fontSize: 42,
    fontWeight: '400',
    paddingBottom: 8,
  },
  optionQuantityAndoptionTitleText: {
    fontSize: 52,
    fontWeight: '700',
  },
  cutlery: {
    padding: 8,
    backgroundColor: Color.black,
    marginBottom: 20,
  },
  cutleryText: {
    fontSize: 48,
    fontWeight: '700',
    color: Color.white,
  },
  comment: {
    borderWidth: 2,
    borderColor: Color.black,
    borderStyle: 'dashed',
    padding: 8,
  },
  commentText: {
    fontSize: 48,
    fontWeight: '400',
  },
  itemComment: {
    borderWidth: 2,
    borderColor: Color.black,
    borderStyle: 'dashed',
    padding: 8,
    marginTop: 32,
  },
  itemCommentText: {
    fontSize: 48,
    fontWeight: '400',
  },
  border: {
    width: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
  },
});
