import { BusinessDayTime, ExtraBusinessDayTime, RegularBusinessDayTime, SectionType } from '../../domain';

export const decorateBusinessTime = (businessDayTime: BusinessDayTime): string => {
  if (businessDayTime.businessTimes.length === 0) return businessDayTime.type === 'regular' ? '定休日' : '臨時休業日';

  return businessDayTime.businessTimes
    .map(businessTime => `${businessTime.startTime} - ${businessTime.endTime}`)
    .join(', ');
};

export const decorateDayOrDate = (businessDayTime: BusinessDayTime): string =>
  businessDayTime.type === 'regular'? day((businessDayTime as RegularBusinessDayTime).day)
    : date((businessDayTime as ExtraBusinessDayTime).date);

const day = (day: string) =>
  ({ monday: '月曜日', tuesday: '火曜日', wednesday: '水曜日', thursday: '木曜日', friday: '金曜日', saturday: '土曜日', sunday: '日曜日'})[day] ?? '不明な曜日';

const date = (date: string) => date.replace(/-/g, '/');

export const decorateSectionName = (sectionType: SectionType, sectionName: string | null): string => {
  if (sectionType === 'regular') return sectionName? `営業時間(${sectionName})` : '営業時間';
  return '臨時営業時間';
};
