import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, TouchableOpacityProps, TouchableWithoutFeedbackProps, } from 'react-native';
import { Environment } from '../../Environment';
import { logger } from '../../infrastructure';

interface Props extends TouchableOpacityProps, TouchableWithoutFeedbackProps {
  name: string;
  testID?: string;
  id?: string;
  disabled?: boolean;
  /**
   * Androidかつ、これがtrueの場合はreact-native-gesture-handler.TouchableOpacityを返し、
   * それ以外の場合はreact-native.TouchhableOpacityを返す。
   *
   * 参照
   * https://github.com/facebook/react-native/issues/27232#issuecomment-819347427
   *
   * Androidで自身のViewの外側にあるreact-native.TouchhableOpacityはタッチしても反応しない。
   * ドロップダウンの子要素がこれに該当し、このままではドロップダウンが機能しないため
   * 自身のViewの外側でもタッチすれば反応するreact-native-gesture-handler.TouchableOpacityを
   * 使用したい。
   * よってここをtrueにすることでreact-native-gesture-handler.TouchableOpacityが返る。
   *
   * 通常は何も指定しないかfalseを指定し、ドロップダウンの子要素のようにAndroidでタッチが機能しない場合にのみ
   * trueを指定すること。
   */
  useGestureHandlerTouchableOpacity?: boolean;
  children: React.ReactNode;
}

export const Clickable = (props: Props): React.ReactElement => {
  const { id, name, testID, useGestureHandlerTouchableOpacity, onPress } = props;

  const onPressTouchableOpacity = useCallback((event: GestureResponderEvent) => {
    logger.action(name, id);
    onPress?.(event);
  }, [id, name, onPress]);

  const TouchableOpacity = useMemo(() => {
    if (useGestureHandlerTouchableOpacity && Environment.platform.type === 'android') {
      // AndroidでViewの外のreact-native.TouchableOpacityが反応しないのを回避
      // https://github.com/facebook/react-native/issues/27232#issuecomment-819347427
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require('react-native-gesture-handler').TouchableOpacity;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require('react-native').TouchableOpacity;
    }
  }, [useGestureHandlerTouchableOpacity]);

  return <TouchableOpacity {...{
    ...props,
    testID: testID ?? id ?? name,
    onPress: onPressTouchableOpacity,
    activeOpacity: props.activeOpacity ?? 0.6
  }} />;
};
