import {
  StarConnectionSettings,
  StarPrinter as NativePrinter,
  StarPrinterModel,
  StarXpandCommand
} from 'react-native-star-io10';
import { Printer } from './';

const defaultModelName = 'Star Printer';

export class StarPrinter implements Printer {
  readonly identifier: string;
  readonly maker = 'Star' as const
  readonly modelName: string;
  readonly supportedImageWidth: number;
  readonly shouldPrintUpSideDown: boolean;
  readonly shouldSelectPrinterModelManually: boolean;

  private readonly printerSeries: StarPrinterModel | undefined;
  private readonly printer: NativePrinter;

  static load(dictionary: { [key: string]: unknown }): Printer | null {
    if (!dictionary) return null;
    if (dictionary.device_type !== 'star') return null;

    const { connection_settings, model_name, printer_series } = dictionary;

    if (!connection_settings) return null;

    return new StarPrinter(
      new NativePrinter(connection_settings as StarConnectionSettings),
      model_name as StarPrinterModel,
      printer_series as StarPrinterModel
    );
  }

  constructor(printer: NativePrinter, modelName?: StarPrinterModel, printerSeries?: StarPrinterModel) {
    this.printer = printer;
    const model = printer.information?.model;

    this.identifier = `${printer.connectionSettings.interfaceType} - ${printer.connectionSettings.identifier}`;
    this.modelName = (model && getModelName(model)) ?? modelName ?? defaultModelName;
    this.printerSeries = getPrinterSeries(model, printerSeries, modelName);
    this.supportedImageWidth = supportedImageWidth(this.printerSeries);
    this.shouldPrintUpSideDown = shouldPrintUpSideDown(this.printerSeries);
    this.shouldSelectPrinterModelManually = !this.printerSeries;
  }

  async print(image: string): Promise<void> {
    this.printer.openTimeout = 10000;
    this.printer.printTimeout = 60000;
    this.printer.getStatusTimeout = 10000;

    try {
      const printerBuilder = new StarXpandCommand.PrinterBuilder()
        .actionPrintImage(new StarXpandCommand.Printer.ImageParameter(image, this.supportedImageWidth))
        .actionCut(StarXpandCommand.Printer.CutType.Full);
      const documentBuilder = new StarXpandCommand.DocumentBuilder().addPrinter(printerBuilder);
      const commands = await new StarXpandCommand.StarXpandCommandBuilder().addDocument(documentBuilder).getCommands();

      await this.printer.open();
      await this.printer.print(commands);
    } finally {
      await this.printer.close();
    }
  }

  dispose(): Promise<void> {
    return this.printer.dispose();
  }

  toJSON(): { [key: string]: unknown } {
    return {
      device_type: 'star',
      connection_settings: this.printer.connectionSettings,
      model_name: this.modelName,
      printer_series: this.printerSeries,
    };
  }

  isEqual(other: unknown): boolean {
    return other instanceof StarPrinter
      && this.printer.connectionSettings.identifier === other.printer.connectionSettings.identifier
      && this.printer.connectionSettings.interfaceType === other.printer.connectionSettings.interfaceType;
  }

  copy(printerSeries: StarPrinterModel): StarPrinter {
    return new StarPrinter(this.printer, printerSeries);
  }
}

const getModelName = (model: StarPrinterModel): string => {
  if (!model) return defaultModelName;
  if (model === StarPrinterModel.Unknown) return defaultModelName;

  return model.toString();
};

const getPrinterSeries = (model?: StarPrinterModel, modelName?: StarPrinterModel, printerSeries?: StarPrinterModel) => {
  // まずは機種名がSDKから取得できているならそれをprinterSeriesに設定する
  if (model && model !== StarPrinterModel.Unknown) return model;

  // プリンタの機種名が指定されている場合はそれをprinterSeriesに設定する
  if (printerSeries && printerSeries !== StarPrinterModel.Unknown) return printerSeries;

  // modelNameからprinterSeriesを推測する（過去の互換性対応。modelNameにmodelを入れていたので推測可能）
  if (modelName && Object.values(StarPrinterModel).includes(modelName) && modelName !== StarPrinterModel.Unknown)
    return modelName;

  return undefined;
};

const supportedImageWidth = (printerSeries: StarPrinterModel | undefined): number => ({
  [StarPrinterModel.mPOP]: 384,
  [StarPrinterModel.mC_Print2]: 384,
  [StarPrinterModel.SM_L200]: 384,
  [StarPrinterModel.SM_S210i]: 384,
  [StarPrinterModel.SM_S230i]: 384,
  [StarPrinterModel.SK1_2xx]: 384,
  [StarPrinterModel.TSP650II]: 576,
  [StarPrinterModel.TSP700II]: 576,
  [StarPrinterModel.TSP800II]: 576,
  [StarPrinterModel.TSP100IIIW]: 576,
  [StarPrinterModel.TSP100IIILAN]: 576,
  [StarPrinterModel.TSP100IIIBI]: 576,
  [StarPrinterModel.TSP100IIIU]: 576,
  [StarPrinterModel.TSP100IV]: 576,
  [StarPrinterModel.mC_Print3]: 576,
  [StarPrinterModel.SM_T300]: 576,
  [StarPrinterModel.SM_T300i]: 576,
  [StarPrinterModel.SM_L300]: 576,
  [StarPrinterModel.BSC10]: 576,
  [StarPrinterModel.TSP043]: 576,
  [StarPrinterModel.SP700]: 576,
  [StarPrinterModel.TUP500]: 576,
  [StarPrinterModel.SK1_3xx]: 576,
  [StarPrinterModel.SM_T400i]: 832,
  [StarPrinterModel.Unknown]: 576
})[printerSeries ?? StarPrinterModel.Unknown] ?? 576;

const shouldPrintUpSideDown = (printerSeries: StarPrinterModel | undefined): boolean => ({
  [StarPrinterModel.mPOP]: true,
  [StarPrinterModel.mC_Print2]: true,
  [StarPrinterModel.mC_Print3]: true,
  [StarPrinterModel.TSP650II]: false,
  [StarPrinterModel.TSP700II]: false,
  [StarPrinterModel.TSP800II]: false,
  [StarPrinterModel.TSP100IIIW]: false,
  [StarPrinterModel.TSP100IIILAN]: false,
  [StarPrinterModel.TSP100IIIBI]: false,
  [StarPrinterModel.TSP100IIIU]: false,
  [StarPrinterModel.TSP100IV]: false,
  [StarPrinterModel.SM_S210i]: false,
  [StarPrinterModel.SM_S230i]: false,
  [StarPrinterModel.SM_T300]: false,
  [StarPrinterModel.SM_T300i]: false,
  [StarPrinterModel.SM_T400i]: false,
  [StarPrinterModel.SM_L200]: false,
  [StarPrinterModel.SM_L300]: false,
  [StarPrinterModel.BSC10]: false,
  [StarPrinterModel.TSP043]: false,
  [StarPrinterModel.SP700]: false,
  [StarPrinterModel.TUP500]: false,
  [StarPrinterModel.SK1_2xx]: false,
  [StarPrinterModel.SK1_3xx]: false,
  [StarPrinterModel.Unknown]: true
})[printerSeries ?? StarPrinterModel.Unknown] ?? true;
