import React, { useCallback } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { Brand } from '../../../../domain';
import { AppText, CheckBox, NavigationBar, Search, Clickable, } from '../../../components';
import { Color } from '../../../constants';

export interface NavigationViewProps {
  brand: Brand;
  search?: string;
  isSearching: boolean;
  soldOutCount: number;
  soldOutFilter: boolean;
  onChangeSearch?: (search: string) => void;
  onChangeFocus?: (focus: boolean) => void;
  onPressBack?: () => void;
  onPressClose?: () => void;
  onPressSoldOutFilter?: () => void;
}

export const NavigationView = ({
  brand,
  search,
  isSearching,
  soldOutCount,
  soldOutFilter,
  onChangeSearch,
  onChangeFocus,
  onPressBack,
  onPressClose,
  onPressSoldOutFilter
}: NavigationViewProps): React.ReactElement => {
  const searchRef = React.createRef<TextInput>();

  const onFocus = useCallback(() => onChangeFocus?.(true), [onChangeFocus]);
  const onBlur = useCallback(() => onChangeFocus?.(false), [onChangeFocus]);

  const onPressCrossButton = useCallback(() => {
    searchRef.current?.clear();
    onChangeSearch?.('');
  }, [onChangeSearch, searchRef]);

  const onPressCancelSearching = useCallback(() => {
    searchRef.current?.clear();
    searchRef.current?.blur();
    onChangeSearch?.('');
  }, [onChangeSearch, searchRef]);

  return <NavigationBar isForMenu={true} onPressBack={onPressBack} onPressClose={onPressClose} border={false}>
    <View style={styles.header}>
      <AppText style={styles.brandText}>{brand.name}</AppText>
      <View style={styles.search}>
        <Search
          ref={searchRef}
          value={search}
          placeholder="商品とオプションを検索"
          onChange={onChangeSearch}
          onFocus={onFocus}
          onBlur={onBlur}
          onPressCrossButton={onPressCrossButton}/>
        {isSearching ? (
          <View style={styles.cancelSearching}>
            <Clickable name={'sold_cancel_searching'} onPress={onPressCancelSearching}>
              <AppText style={styles.cancelSearchingText}>キャンセル</AppText>
            </Clickable>
          </View>
        ) : null}
        {!isSearching ? (
          <Clickable
            style={[styles.soldOutFilter, soldOutFilter && styles.soldOutFilterActive]}
            name={'sold_sold_out_filter'}
            onPress={onPressSoldOutFilter} >
            <CheckBox checked={soldOutFilter} color={'red'}/>
            <AppText style={[styles.soldOutFilterText, soldOutFilter && styles.soldOutFilterTextActive]}>
              {`売り切れのみ表示(${soldOutCount})`}
            </AppText>
          </Clickable>
        ) : null}
      </View>
    </View>
  </NavigationBar>;
};

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    height: 78,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: -8,
  },
  brandText: {
    marginBottom: 6,
    fontSize: 13,
  },
  search: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  cancelSearching: {
    display: 'flex',
    width: 256,
    paddingHorizontal: 32,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cancelSearchingText: {
    height: 78,
    fontSize: 17,
    color: Color.gray50,
    lineHeight: 78,
  },
  soldOutFilter: {
    display: 'flex',
    marginLeft: 16,
    paddingHorizontal: 19,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  soldOutFilterActive: {
    backgroundColor: Color.red_alpha,
    borderRadius: 6,
  },
  soldOutFilterText: {
    paddingHorizontal: 8,
    fontSize: 17,
    color: Color.gray50,
  },
  soldOutFilterTextActive: {
    color: Color.red
  },
});
