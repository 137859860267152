import {
  addNotificationReceivedListener,
  addNotificationResponseReceivedListener,
  addPushTokenListener,
  AndroidImportance,
  DevicePushToken,
  getDevicePushTokenAsync,
  getPermissionsAsync,
  Notification,
  removeNotificationSubscription,
  requestPermissionsAsync,
  setNotificationChannelAsync,
  Subscription
} from 'expo-notifications';
import { getAuth, User } from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Environment } from '../../../Environment';
import { alert, createApiClient, logger, NotificationApi } from '../../../infrastructure';

export const usePushNotification = (): void => {
  const [auth] = useAuthState(getAuth());
  const [deviceToken, setDeviceToken] = useState<DevicePushToken>();
  const [, setNotification] = useState<Notification>();

  const deviceTokenListener = useRef<Subscription>();
  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();

  useEffect(() => {
    setAndroidNotificationChannels()
      .then(() => getDeviceToken())
      .then(setDeviceToken)
      .catch(logger.error);
  }, []);

  useEffect(() => {
    deviceTokenListener.current = addPushTokenListener(setDeviceToken);

    return () => { deviceTokenListener.current && removeNotificationSubscription(deviceTokenListener.current); };
  }, []);

  useEffect(() => {
    notificationListener.current = addNotificationReceivedListener(setNotification);

    return () => { notificationListener.current && removeNotificationSubscription(notificationListener.current); };
  }, []);

  useEffect(() => {
    responseListener.current = addNotificationResponseReceivedListener(() => {
      // TODO: Navigate to URL or do something
    });

    return () => { responseListener.current && removeNotificationSubscription(responseListener.current); };
  }, []);

  useEffect(() => {
    if (!auth) return;
    if (!canUsePushNotification()) return;
    if (deviceToken?.type !== 'ios' && deviceToken?.type !== 'android') return;

    (auth as User).getIdToken()
      .then(token => NotificationApi.createDeviceToken(token, { deviceId: deviceToken.type, token: deviceToken.data }))
      .then(request => createApiClient().request(request));
  }, [auth, deviceToken]);
};

const getDeviceToken = async () => {
  if (!canUsePushNotification()) return;

  const { status } = await getPermissionsAsync()
    .then(result => result.status === 'granted' ? Promise.resolve(result) : requestPermissionsAsync());

  if (status !== 'granted') {
    alert('プッシュ通知が許可されていません。', 'OS設定画面のアプリ設定から、通知の権限を有効にしてください。');
    return;
  }

  return await getDevicePushTokenAsync();
};

const setAndroidNotificationChannels = async () => {
  if (Environment.platform.type !== 'android') return;

  await setNotificationChannelAsync('default', {
    name: 'default',
    importance: AndroidImportance.MAX,
  });
};

const canUsePushNotification = (): boolean =>
  (Environment.platform.type === 'ios' || Environment.platform.type === 'android') && !Environment.platform.isSimulator;

