import dayjs from 'dayjs';
import { Integration, Order, OrderWithDelivery, PlatformType } from '../entities';

/**
 * @deprecated
 */
export const canCancel = (orderWithDelivery: OrderWithDelivery, integrations: Integration[]): boolean => {
  const { order, delivery, deliveryEstimation } = orderWithDelivery;
  const supported = integrations
    .find(integration => integration.platformType === order.platform)
    ?.features.orders.cancel
    ?.supported;

  // TODO: Use integration info from server
  if (order.platform === PlatformType.UBEREATS) {
    if (delivery?.courierType === 'inhouse') return isNotAccepted(order);

    switch (order.deliveryMethod) {
      case 'delivery':
        return ['CREATED', 'ACCEPTED'].includes(order.status)
          && !delivery?.courier
          && dayjs().isBefore(dayjs(deliveryEstimation?.pickupTime.toDate()));
      case 'pick_up':
        return ['CREATED', 'ACCEPTED'].includes(order.status);
      case 'eat_in':
        return ['CREATED', 'ACCEPTED'].includes(order.status);
    }
  }

  return !!supported && isNotAccepted(order);
};

const isNotAccepted = (order: Order) =>
  order.deliveryTimeType.type === 'scheduled'
    ? order.status === 'CREATED' && !order.scheduledAccepted
    : order.status === 'CREATED';
