import { HasLocalizedDescription } from '../core/HasLocalizedDescription';
import { localizedResourceName, ResourceName } from '../ResourceName';

export class ResourceInvalidError extends Error implements HasLocalizedDescription {
  readonly code = 'resource-invalid-error';

  readonly resourceName: string

  readonly detail: string
  readonly localizedTitle: string
  readonly localizedDetail: string

  constructor(resourceName: ResourceName) {
    const message = `${resourceName} is invalid.`;
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    this.resourceName = resourceName;
    this.detail = `${resourceName} is invalid. Ensure ${resourceName} is valid.`;
    const localized = localizedResourceName(resourceName);
    this.localizedTitle = `${localized}が正しくありません。`;
    this.localizedDetail = `${localized}が正しくありません。正しい${localized}であることを確認してください。`;
  }
}
