import { PlatformType } from '../../../domain';
import { Callable, useCallable } from '../core/useCallable';

interface SoldOutOperation {
  type: 'SOLDOUT';
}

interface SaleOperation {
  type: 'SALE';
}

interface SuspendOperation {
  type: 'SUSPEND'
  brandId: string;
  itemId: string;
  platformType: PlatformType;
}

interface Request {
  operation: SoldOutOperation | SaleOperation | SuspendOperation;
  brandId: string;
  masterItemIds: string[];
}

type Response = Record<string, never>;

export const useItemOperation = (): Callable<Request, Response> => useCallable<Request, Response>('onCallItemOperation');
