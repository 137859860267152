import { FlipType, ImageResult, manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { RefObject } from 'react';
import { ScrollView } from 'react-native';
import { captureRef } from 'react-native-view-shot';
import { concatPromises, sleep } from '../../utils';

export type ImageSpec = { width: number, reverse: boolean };
export type CapturedImage = ImageSpec & { image: ImageResult };

export const captureImages = (ref: RefObject<ScrollView>, imageSpecs: ImageSpec[]): Promise<CapturedImage[]> =>
  Promise.resolve(imageSpecs)
    .then(uniqueImageSpecs)
    .then(imageSpecs => imageSpecs.map(imageSpec => () => captureImage(ref, imageSpec)))
    .then(concatPromises);

const captureImage = (ref: RefObject<ScrollView>, { width, reverse }: ImageSpec) =>
  sleep(500)
    .then(() => captureRef(ref, { ...captureOptions, width }))
    .then(uri => manipulateAsync(uri, manipulateOptions(width, reverse), saveOptions))
    .then(image => ({ image, width, reverse }));

const uniqueImageSpecs = (imageSpecs: ImageSpec[]) =>
  imageSpecs
    .reduce((acc: ImageSpec[], s1) => [
      ...acc,
      ...(acc.some(s2 => s1.width === s2.width && s1.reverse === s2.reverse) ? [] : [s1])
    ], []);

const captureOptions = {
  quality: 0,
  format: 'png',
  useRenderInContext: true,
  snapshotContentContainer: true
} as const;

const manipulateOptions = (width: number, reverse: boolean) =>
  [
    { resize: { width } },
    ...(reverse ? [{ flip: FlipType.Vertical }, { flip: FlipType.Horizontal }] : [])
  ];

const saveOptions = { base64: true, compress: 0, format: SaveFormat.PNG };
