import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class HttpAuthenticationError extends Error {
  readonly config: AxiosRequestConfig | undefined;
  readonly response: AxiosResponse;

  constructor(config: AxiosRequestConfig | undefined, response: AxiosResponse) {
    const method = config?.method?.toUpperCase();
    const url = `${config?.baseURL}${response.config?.url}`;

    super(`Received http authentication error response: ${response.statusText} ${method} ${url}`);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    this.config = config;
    this.response = response;
  }
}
