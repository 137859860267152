import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Switch } from 'react-native-gesture-handler';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';

interface Props {
  value: boolean;
  onValueChange: (value: boolean) => void;
}

export const PrinterListSwitchItem = ({ value, onValueChange }: Props): React.ReactElement =>
  <View style={styles.view}>
    <AppText style={styles.text}>プリンター接続</AppText>
    <Switch testID='PrinterListSwitchItem-switch' value={value} onValueChange={onValueChange} />
  </View>;

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 104,
    paddingHorizontal: 44,
    paddingVertical: 32,
    backgroundColor: Color.white,
    borderWidth: 1,
    borderColor: Color.gray10,
    borderRadius: 12,
  },
  text: {
    fontSize: 18,
    color: Color.gray100,
    flex: 1,
  },
});
