import React, { useCallback, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { Environment } from '../../../Environment';
import { AppText, Clickable, } from '../../components';
import { Color } from '../../constants';
import { SettingsAcceptanceMethodView } from './SettingsAcceptanceMethodView';
import { SettingsDeliveryView } from './SettingsDeliveryView';
import { SettingsPrintersView } from './SettingsPrintersView';

type Menu = 'ORDER_AND_COOKING_TIME' | 'DELIVERY' | 'PRINTERS';

const Menus = [
  'ORDER_AND_COOKING_TIME' as const,
  'DELIVERY' as const,
  ...(Environment.platform.type !== 'web' ? ['PRINTERS' as const] : [])
] as const;

const titleForMenu = (menu: Menu) => {
  switch (menu) {
    case 'ORDER_AND_COOKING_TIME': return '受注・調理時間設定';
    case 'DELIVERY': return '配達設定';
    case 'PRINTERS': return 'プリンター設定';
  }
};

const componentForMenu = (menu: Menu) => {
  switch (menu) {
    case 'ORDER_AND_COOKING_TIME': return <SettingsAcceptanceMethodView />;
    case 'DELIVERY': return <SettingsDeliveryView />;
    case 'PRINTERS': return <SettingsPrintersView />;
  }
};

export const SettingsView = (): React.ReactElement => {
  const [selectedMenu, setSelectedMenu] = useState<Menu>(Menus[0]);

  const renderItem = useCallback(({ item }: { item: Menu }) =>
    <SettingItem {...{ menu: item, selected: selectedMenu === item, onPressMenu: setSelectedMenu }} />
  , [selectedMenu, setSelectedMenu]);

  const ItemSeparatorComponent = () => <View style={{ height: 8 }} />;

  const keyExtractor = (menu: Menu) => menu;

  return <View style={styles.view}>
    <View style={styles.left}>
      <FlatList {...{ data: Menus, renderItem, ItemSeparatorComponent, keyExtractor }} style={styles.menu} />
    </View>
    <View style={styles.right}>{componentForMenu(selectedMenu) ?? <></>}</View>
  </View>;
};

type SettingItemProp = {
  menu: Menu
  selected: boolean

  onPressMenu: (menu: Menu) => void
};

const SettingItem = ({ menu, selected, onPressMenu }: SettingItemProp) => {
  const onPress = useCallback(() => onPressMenu(menu), [menu, onPressMenu]);

  return <Clickable name="settings_tap" id={menu} onPress={onPress}>
    <View style={[styles.menuItem, selected && styles.menuItemSelected]}>
      <AppText style={[styles.menuItemText, selected && styles.menuItemTextSelected]}>
        {titleForMenu(menu)}
      </AppText>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Color.gray5,
    width: '100%',
  },
  left: {
    borderRightWidth: 1,
    borderRightColor: Color.gray10,
  },
  right: {
    flex: 1
  },
  menu: {
    paddingVertical: 32,
    paddingRight: 32,
    paddingLeft: 56,
    width: 264,
  },
  menuItem: {
    height: 56,
    paddingHorizontal: 16,
  },
  menuItemSelected: {
    backgroundColor: Color.brand,
    borderRadius: 6,
  },
  menuItemText: {
    color: Color.black,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 56
  },
  menuItemTextSelected: {
    color: Color.white,
    fontWeight: '500',
  },
});
