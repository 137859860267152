import * as Updates from 'expo-updates';
import { useEffect } from 'react';
import { AppState } from 'react-native';
import { Environment } from '../../../Environment';
import { alert, logger } from '../../../infrastructure';

export const useOTAUpdater = (): void => {
  useEffect(() => {
    handleUpdate();
    const subscription = AppState.addEventListener('change', handleUpdate);
    return () => { subscription.remove(); };
  }, []);
};

const handleUpdate = async () => {
  if (!Environment.isNative) return;

  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      alert('アップデートがあります', 'アプリを再起動します', { text: 'OK', onPress: () => Updates.reloadAsync() });
    }
  } catch (e) {
    logger.error(e as Error);
  }
};
