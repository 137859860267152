import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ExtraBusinessDayTime, PlatformType } from '../../../../../../domain';
import { If } from '../../../../If';
import { ExtraForms } from './ExtraForms';
import { ExtraViews } from './ExtraViews';

interface ExtraSectionProps {
  brandId: string
  businessDayTimes: ExtraBusinessDayTime[]
  platformType: PlatformType
}

export const ExtraSection = ({ brandId, businessDayTimes, platformType } : ExtraSectionProps): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false);

  const onFinishEditing = useCallback(() => setIsEditing(false), []);
  const onPressEdit = useCallback(() => setIsEditing(true), []);

  return <View style={styles.section}>
    <If condition={isEditing}>
      <ExtraForms  {...{ brandId, businessDayTimes, platformType, onFinishEditing }} />
    </If>
    <If condition={!isEditing}>
      <ExtraViews {...{ businessDayTimes, onPressEdit }} />
    </If>
  </View>;
};

const styles = StyleSheet.create({
  section: {
    width: '100%',
    paddingHorizontal: 32
  }
});
