import dayjs from 'dayjs';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { BusinessTime } from '../../../../../../domain';
import { Environment } from '../../../../../../Environment';
import { Color } from '../../../../../constants';
import { AppText } from '../../../../AppText';
import { If } from '../../../../If';
import { Clickable } from '../../../../Clickable';
import { ExtraBusinessDayTimeFormAction } from '../extraBusinessDayTime';
import { RegularBusinessDayTimeFormAction } from '../regularBusinessDayTime';

type TimesInputsCommon = {
  businessTime: BusinessTime
  timeInputsIndex: number
}

type RegularFormTimeInputsProps = TimesInputsCommon & {
  businessDayTimeId: string
  dispatch: React.Dispatch<RegularBusinessDayTimeFormAction>
}

type ExtraFormTimeInputsProps = TimesInputsCommon & {
  sectionIndex: number
  dispatch: React.Dispatch<ExtraBusinessDayTimeFormAction>
}

type TimeInputsProps = RegularFormTimeInputsProps | ExtraFormTimeInputsProps

export const TimeInputs = (props : TimeInputsProps): React.ReactElement =>
  <View style={styles.wrapper}>
    <If condition={Environment.platform.type === 'web'}>
      <TimeInputsForWeb {...props}/>
    </If>
    <If condition={Environment.platform.type !== 'web'}>
      <TimeInputsForNative {...props}/>
    </If>
  </View>;

const TimeInputsForWeb = (props: TimeInputsProps): React.ReactElement => {
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const isRegular ='businessDayTimeId' in props;

  const onChangeStartTime = useCallback((e: ChangeEvent<HTMLInputElement>) =>
    isRegular
      ? props.dispatch({
        type: 'changeTimes', payload: {
          id: props.businessDayTimeId,
          timeInputsIndex: props.timeInputsIndex,
          businessTime: { startTime: e.target.value, endTime: props.businessTime.endTime }
        }
      })
      : props.dispatch({
        type: 'changeTimes', payload: {
          sectionIndex: props.sectionIndex,
          timeInputsIndex: props.timeInputsIndex,
          businessTime: { startTime: e.target.value, endTime: props.businessTime.endTime }
        }
      })
  , [isRegular, props]);

  const onChangeEndTime = useCallback((e: ChangeEvent<HTMLInputElement>) =>
    isRegular
      ? props.dispatch({
        type: 'changeTimes', payload: {
          id: props.businessDayTimeId,
          timeInputsIndex: props.timeInputsIndex,
          businessTime: { startTime: props.businessTime.startTime, endTime: e.target.value }
        }
      })
      : props.dispatch({
        type: 'changeTimes', payload: {
          sectionIndex: props.sectionIndex,
          timeInputsIndex: props.timeInputsIndex,
          businessTime: { startTime: props.businessTime.startTime, endTime: e.target.value }
        }
      })
  , [isRegular, props]);

  const onRemoveTime = useCallback(() =>
    isRegular
      ? props.dispatch({
        type: 'removeTimes',
        payload: { id: props.businessDayTimeId, timeInputsIndex: props.timeInputsIndex }
      })
      : props.dispatch({
        type: 'removeTimes',
        payload: { sectionIndex: props.sectionIndex, timeInputsIndex: props.timeInputsIndex }
      })
  , [isRegular, props]);

  return <View style={styles.wrapper}>
    <input
      id={`${props.timeInputsIndex}-startTime`}
      defaultValue={props.businessTime.startTime}
      type='time'
      pattern='mm:hh'
      ref={startTimeRef}
      style={{ marginTop: 6 }}
      onChange={onChangeStartTime} />
    <View style={[styles.hyphen, { marginTop: 6 }]} />
    <input
      id={`${props.timeInputsIndex}-endTime`}
      defaultValue={props.businessTime.endTime}
      type='time'
      pattern='mm:hh'
      ref={endTimeRef}
      style={{ marginTop: 6 }}
      onChange={onChangeEndTime} />
    <Clickable
      id={`timeInputs-${props.timeInputsIndex}`}
      name={'timeInputs_remove'}
      style={{ marginTop: 6 }}
      onPress={onRemoveTime} >
      <Image
        source={require('../../../../../assets/icon/close_black.png')}
        style={styles.icon}
        resizeMode="contain" />
    </Clickable>
  </View>;
};

const TimeInputsForNative =  (props: TimeInputsProps): React.ReactElement => {
  const [isTimePickerVisible, setTimePickerVisibility] = useState(false);
  const [selectedTimeInput, setSelectedTimeInput] = useState<'start' | 'end' | null>(null);

  const handleTimePickerVisibility = useCallback((startOrEnd: 'start' | 'end' | null) => {
    setSelectedTimeInput(startOrEnd);
    setTimePickerVisibility(!isTimePickerVisible);
  }, [isTimePickerVisible]);

  const onPressStart = useCallback(() => { handleTimePickerVisibility('start'); }, [handleTimePickerVisibility]);
  const onPressEnd = useCallback(() => { handleTimePickerVisibility('end'); }, [handleTimePickerVisibility]);

  const onPressRemove = useCallback(() =>
    'businessDayTimeId' in props
      ? props.dispatch(
        { type: 'removeTimes', payload: { id: props.businessDayTimeId, timeInputsIndex: props.timeInputsIndex } }
      )
      : props.dispatch({
        type: 'removeTimes', payload: { sectionIndex: props.sectionIndex, timeInputsIndex: props.timeInputsIndex, }
      })
  , [props]);

  const onConfirm = useCallback((selectedTime: Date) => {
    'businessDayTimeId' in props
      ? props.dispatch({
        type: 'changeTimes',  payload: {
          id: props.businessDayTimeId,
          timeInputsIndex: props.timeInputsIndex,
          businessTime: selectedTimeInput === 'start'
            ? { startTime: dayjs(selectedTime).format('HH:mm'), endTime: props.businessTime.endTime }
            : { startTime: props.businessTime.startTime, endTime: dayjs(selectedTime).format('HH:mm') }}
      })
      : props.dispatch({
        type: 'changeTimes',  payload: {
          sectionIndex: props.sectionIndex,
          timeInputsIndex: props.timeInputsIndex,
          businessTime: selectedTimeInput === 'start'
            ? { startTime: dayjs(selectedTime).format('HH:mm'), endTime: props.businessTime.endTime }
            : { startTime: props.businessTime.startTime, endTime: dayjs(selectedTime).format('HH:mm') }}
      });
    handleTimePickerVisibility(null);
  }, [handleTimePickerVisibility, props, selectedTimeInput]);

  const onCancel = useCallback(() => { handleTimePickerVisibility(null); }, [handleTimePickerVisibility]);

  return <View style={styles.wrapper}>
    <Clickable
      id={`dateInputForNative-selectTime-startTime-${props.timeInputsIndex}`}
      name='selectTime_tap'
      onPress={onPressStart}>
      <View style={styles.nativeTextBox}>
        <AppText style={styles.nativeText}> {props.businessTime.startTime} </AppText>
      </View>
    </Clickable>
    <View style={styles.hyphen} />
    <Clickable
      id={`dateInputForNative-selectTime-endTime-${props.timeInputsIndex}`}
      name='selectTime_tap'
      onPress={onPressEnd}>
      <View style={styles.nativeTextBox}>
        <AppText style={styles.nativeText}> {props.businessTime.endTime} </AppText>
      </View>
    </Clickable>
    <Clickable id={`timeInputs-${props.timeInputsIndex}`} name='timeInput_remove' onPress={onPressRemove}>
      <Image source={require('../../../../../assets/icon/close_black.png')} style={styles.icon} resizeMode="contain" />
    </Clickable>
    <DateTimePickerModal
      testID={`TimeInputs-DateTimePickerModal-${props.timeInputsIndex}`}
      isVisible={isTimePickerVisible}
      mode="time"
      onConfirm={onConfirm}
      onCancel={onCancel} />
  </View>;
};

const styles = StyleSheet.create({
  nativeTextBox: {
    borderColor: Color.gray10,
    borderRadius: 4,
    borderWidth: 1,
    height: 36,
    paddingHorizontal: 6,
    paddingVertical: 6,
    width: 72,
  },
  nativeText: {
    color: Color.gray100,
    fontSize: 14,
  },
  hyphen: {
    backgroundColor: Color.gray70,
    borderRadius: 2,
    height: 2,
    marginHorizontal: 8,
    width: 8,
  },
  icon: {
    width: 16,
    height: 16,
    marginTop: 4,
    marginStart: 13
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 6,
  },
});
