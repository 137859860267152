import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { ItemSoldOutStatus } from '../../../domain';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface Props {
  itemId: string;
  state: ItemSoldOutStatus;
  disabled: boolean;
  onPress: (value: ItemSoldOutStatus) => void;
}

export const ItemSegmentedControl = ({ itemId, state, disabled, onPress }: Props): React.ReactElement => {
  const onPressOnSale = useCallback(() => {
    if (disabled || state === 'sale') return;

    onPress('sale');
  }, [disabled, onPress, state]);

  const onPressSoldOut = useCallback(() => {
    if (disabled || state === 'sold_out') return;

    onPress('sold_out');
  }, [disabled, onPress, state]);

  const onPressSuspended = useCallback(() => {
    if (disabled || state === 'suspended') return;

    onPress('suspended');
  }, [disabled, onPress, state]);

  const stylesForButton = (activeState: ItemSoldOutStatus) => [
    styles.button,
    colorStyles(state, disabled).button,
    state === activeState && colorStyles(state, disabled).active,
  ];

  const stylesForButtonText = (activeState: ItemSoldOutStatus) => [
    styles.buttonText,
    colorStyles(state, disabled).buttonText,
    state === activeState && colorStyles(state, disabled).activeText,
  ];

  return <View style={[styles.view, colorStyles(state, disabled).view]}>
    <Clickable
      name='item_segmented_control_on_sale'
      testID={`item-segmented-control-on-sale-${itemId}`}
      disabled={disabled}
      onPress={onPressOnSale}>
      <View style={stylesForButton('sale')}>
        <AppText style={stylesForButtonText('sale')}>販売中</AppText>
      </View>
    </Clickable>
    <View style={[styles.separator, colorStyles(state, disabled).separator]}/>
    <Clickable
      name='item_segmented_control_sold_out'
      testID={`item-segmented-control-sold-out-${itemId}`}
      disabled={disabled}
      onPress={onPressSoldOut}>
      <View style={stylesForButton('sold_out')}>
        <AppText style={stylesForButtonText('sold_out')}>本日売り切れ</AppText>
      </View>
    </Clickable>
    <View style={[styles.separator, colorStyles(state, disabled).separator]}/>
    <Clickable
      name='item_segmented_control_suspended'
      testID={`item-segmented-control-suspend-${itemId}`}
      disabled={disabled}
      onPress={onPressSuspended}>
      <View style={stylesForButton('suspended')}>
        <AppText style={stylesForButtonText('suspended')}>在庫切れ</AppText>
      </View>
    </Clickable>
  </View>;
};

const styles = StyleSheet.create({
  view: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden',
  },
  button: {
    minWidth: 94,
    minHeight: 38,
    padding: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 14,
  },
  separator: {
    width: 1,
  }
});

const colorStyles = (state: ItemSoldOutStatus, disabled: boolean) => {
  const color = colorFor(state);

  return StyleSheet.create({
    view: {
      borderColor: disabled ? Color.gray20 : color,
    },
    button: {
      backgroundColor: disabled ? Color.white : Color.white
    },
    buttonText: {
      color: disabled ? Color.gray20 : color,
    },
    active: {
      backgroundColor: disabled ? Color.gray20 : color,
    },
    activeText: {
      color: Color.white,
    },
    separator: {
      backgroundColor: disabled ? Color.gray20 : color,
    },
  });
};

const colorFor = (state: ItemSoldOutStatus) => {
  switch (state) {
    case 'sale': return Color.brand;
    case 'sold_out': return Color.red;
    case 'suspended': return Color.red;
  }
};
