import { getNetworkStateAsync, NetworkStateType } from 'expo-network';
import {
  InterfaceType,
  StarDeviceDiscoveryManager,
  StarDeviceDiscoveryManagerFactory,
  StarPrinter as StarNativePrinter
} from 'react-native-star-io10';
import { PrinterDiscoverer } from '../';
import { Printer } from '../../../domain';
import { StarPrinter } from '../../../domain/entities/Printer/StarPrinter';
import { Environment } from '../../../Environment';
import { logger } from '../../logger';

export class StarPrinterDiscoverer implements PrinterDiscoverer {
  private manager: StarDeviceDiscoveryManager | null = null;

  async start(f: (printer: Printer) => void): Promise<void> {
    const manager = await this.isLanConnected()
      .then(shouldSearchLan =>
        [
          InterfaceType.Usb,
          ...(shouldSearchLan ? [InterfaceType.Lan] : []),
          ...(Environment.isNative ? [InterfaceType.Bluetooth] : [])
        ]
      )
      .then(interfaceTypes => StarDeviceDiscoveryManagerFactory.create(interfaceTypes));

    this.manager = manager;

    manager.onPrinterFound = (printer: StarNativePrinter) => { f(new StarPrinter(printer)); };

    await manager.startDiscovery()
      .catch(error => {
        logger.error(error as Error);
        return Promise.reject(error);
      })
      .catch(() => manager.stopDiscovery().finally(() => { this.manager = null; }));
  }

  stop(): Promise<void> {
    return this.manager?.stopDiscovery().finally(() => { this.manager = null; }) ?? Promise.resolve();
  }

  private isLanConnected(): Promise<boolean> {
    return getNetworkStateAsync()
      .then(state =>
        !!state.type
        && [NetworkStateType.WIFI, NetworkStateType.ETHERNET].includes(state.type)
        && !!state.isConnected
      );
  }
}
