import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import { usePaginationData } from 'react-firebase-pagination-hooks';
import { PaginationHook } from 'react-firebase-pagination-hooks/dist/util/usePaginationValue';
import { Order, OrderStatus, Store } from '../../../domain';
import { useAppContext } from '../contexts/useAppContext';

interface Options {
  date?: Date,
  status?: OrderStatus[],
  brandId?: string,
  limit?: number,
}

export const useOrders = (options: Options): PaginationHook<Order> => {
  const { store } = useAppContext();

  return usePaginationData<Order>(store && query(options, store), { idField: 'id', limit: options.limit ?? 25 });
};

const query = (options: Options, store: Store) => {
  let query = firebase
    .firestore()
    .collectionGroup('orders')
    .where('storeId', '==', store?.id)
    .orderBy('createTime', 'desc');

  if (options.date) {
    query = query
      .where('createTime', '>=', dayjs(options.date).startOf('day').toDate())
      .where('createTime', '<=', dayjs(options.date).endOf('day').toDate());
  }

  if (options.status) {
    query = query.where('status', 'in', options.status);
  }

  if (options.brandId) {
    query = query.where('brandId', '==', options.brandId);
  }

  return query;
};
