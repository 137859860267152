import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Brand, OrderWithDelivery } from '../../../../domain';
import { pickObject } from '../../../../utils';
import { Color } from '../../../constants';
import { OrderDecorator, PlatformTypeDecorator } from '../../../decorators';
import { useAppContext, useOrderDispatches } from '../../../hooks';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { OrderView } from './OrderView';
import { SalesView } from './SalesView';

interface Props {
  orderWithDelivery: OrderWithDelivery
  brand: Brand | undefined
  onLayout?: () => void;
}

export const ReceiptView = ({ orderWithDelivery, brand, onLayout }: Props): React.ReactElement => {
  const { integrations } = useAppContext();
  const { order }  = orderWithDelivery;
  const decorator = new OrderDecorator(order);
  const platformDecorator = new PlatformTypeDecorator(order.platform, integrations);
  const [orderDispatches, loadingOrderDispatches] = useOrderDispatches(
    { ...pickObject(order, ['accountId', 'storeId', 'brandId']), orderId: order.id }
  );

  const orderDispatch = orderDispatches?.[0];

  return loadingOrderDispatches
    ? <></>
    : <ScrollView contentContainerStyle={styles.view} testID='ReceiptView_ScrollView' onLayout={onLayout}>
      <If condition={orderDispatch?.execution?.type === 'failed'}>
        <View style={styles.errorView}><AppText style={styles.errorTitle}>POS送信エラー</AppText></View>
      </If>
      <View style={styles.headerOrderId}>
        <AppText style={styles.headerOrderIdText} numberOfLines={1} adjustsFontSizeToFit>
          {platformDecorator.name()} &nbsp; &nbsp; #{order.displayId}
        </AppText>
      </View>
      <If condition={brand}>
        <AppText style={styles.headerBrandText} numberOfLines={1}>{brand?.name}</AppText>
      </If>
      <If condition={orderDispatch?.displayId}>
        <AppText style={styles.headerPosIdText}>店内番号：{orderDispatch?.displayId}</AppText>
      </If>
      <View style={[styles.border, { marginTop: 64 }]} />
      <AppText style={styles.fulfillment}>{decorator.fulfillmentText()}</AppText>
      <OrderView {...{ orderWithDelivery }} />
      <SalesView {...{ order }} />
      <View style={styles.footerView}>
        <AppText style={styles.footerText}>ご注文ありがとうございます！</AppText>
      </View>
    </ScrollView>;
};

const styles = StyleSheet.create({
  view: {
    paddingVertical: 144,
  },
  border: {
    width: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
  },
  errorView: {
    borderColor: Color.black,
    borderTopWidth: 15,
    borderBottomWidth: 15,
    paddingVertical: 40,
    marginBottom: 80,
  },
  errorTitle: {
    fontSize: 80,
    fontWeight: '500',
    textAlign: 'center',
  },
  headerOrderId: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: Color.black,
  },
  headerOrderIdText: {
    fontSize: 64,
    fontWeight: '900',
    color: Color.white,
  },
  headerBrandText: {
    fontSize: 48,
    fontWeight: '400',
    marginTop: 32,
  },
  headerPosIdText: {
    fontSize: 40,
    fontWeight: '400',
    marginTop: 32,
  },
  fulfillment: {
    fontSize: 60,
    fontWeight: '500',
    textAlign: 'center',
    marginVertical: 60,
  },
  footerView: {
    marginTop: 48,
  },
  footerText: {
    fontSize: 40,
    fontWeight: '400',
    textAlign: 'center',
  },
});
