export const PosPlatformTypes = [
  'stesso',
  'ngp'
] as const;

export type PosPlatformType = typeof PosPlatformTypes[number]

export const posPlatformTypeNames = {
  'stesso': 'Stesso',
  'ngp': 'OrderLinkage'
};
