import { User } from 'firebase/auth';
import { Adapter } from '../';
import { Environment } from '../../../../Environment';
import { getAnalytics } from './getAnalytics';

export class FirebaseAdapter implements Adapter {
  currentScreen = 'launch';

  async set(user: User | undefined, accountId: string | undefined, storeId: string | undefined): Promise<void> {
    if (user) {
      await getAnalytics().setUserId(user.uid);
      await getAnalytics().setUserProperties({
        accountId: accountId ?? '',
        storeId: storeId ?? '',
        email: user.email ?? '',
        username: user.displayName ?? '',
        version: Environment.platform.build?.version ?? '',
        revisionId: Environment.platform.build?.revision ?? '',
      });
    } else {
      await getAnalytics().setUserId('');
      await getAnalytics().setUserProperties({});
    }
  }

  async screen(name: string): Promise<void> {
    this.currentScreen = name;
    await getAnalytics().logEvent('screen', { screen: name });
  }

  async action(name: string, id?: string): Promise<void> {
    await getAnalytics().logEvent(name, {
      id,
      screen: this.currentScreen,
    });
  }

  async info(name: string, params: Record<string, unknown> | undefined): Promise<void> {
    await getAnalytics().logEvent('orderly_info', { name, screen: this.currentScreen, ...params });
  }

  async error(error: Error): Promise<void> {
    const { name, message, ...rest } = error;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userInfo = ('userInfo' in rest && (rest as any).userInfo) ? (rest as any).userInfo : {};
    await getAnalytics().logEvent('orderly_error', { name, message, screen: this.currentScreen, ...userInfo, ...rest });
  }
}
