import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { Clickable } from '../../Clickable';
import { Support } from './SupportList';

interface Props {
  support: Support;

  onPressSupport: (support: Support) => void
}

export const SupportListItem = ({ support, onPressSupport }: Props): React.ReactElement => {
  const onPress = useCallback(() => onPressSupport(support), [onPressSupport, support]);

  return <Clickable name="support_tap" id={support} onPress={onPress}>
    <View style={styles.view}>
      <AppText style={styles.title}>{support}</AppText>
      <Image
        source={require('../../../assets/icon/arrow_right.png')}
        style={styles.icon}
        resizeMode="contain"
      />
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 56,
    paddingRight: 56,
    paddingVertical: 32,
    backgroundColor: Color.white,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
  },
  title: {
    fontSize: 18,
  },
  icon: {
    width: 24,
    height: 24,
  },
});
