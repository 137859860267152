import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useErrorHandler } from '../../hooks';

type Props = {
  loading: boolean
  error: Error | undefined
  children: React.ReactNode
};

export const WithScreen = ({ loading, error, children }: Props): React.ReactElement => {
  useErrorHandler(error);

  return <SafeAreaProvider>
    <View style={styles.root}>
      <View style={styles.wrappedComponent}>{children}</View>
      {loading
        ? <View style={styles.indicatorWrapper}>
          <ActivityIndicator style={styles.indicator} size='large' color='#999999' animating={true} hidesWhenStopped/>
        </View>
        : null}
    </View>
  </SafeAreaProvider>;
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrappedComponent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  indicatorWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  indicator: {
    top: '50%',
  }
});
