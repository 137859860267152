import { Color } from '../../../presentation/constants';
import { Order } from '../Order';

/** 新規注文・予約注文・準備中の注文の通知 */
export class OrdersNotification {
  constructor(type: OrdersNotificationType, orders: Order[] = []) {
    this.type = type;
    this._orders = orders;
  }

  get message(): string {
    switch (this.type) {
      case OrdersNotificationType.New:
        return '新規注文';
      case OrdersNotificationType.Scheduled:
        return '予約注文';
      case OrdersNotificationType.Prepare:
        return '予約注文の調理を開始';
    }
  }

  get color(): string {
    switch (this.type) {
      case OrdersNotificationType.New:
        return Color.blue_light;
      case OrdersNotificationType.Scheduled:
        return Color.purple;
      case OrdersNotificationType.Prepare:
        return Color.purple;
    }
  }

  get count(): number {
    return this.orders.length;
  }

  readonly type: OrdersNotificationType;

  get orders(): Order[] {
    return this._orders;
  }

  private _orders: Order[] = [];

  addOrder(order: Order): void {
    this.removeOrder(order);
    this._orders.push(order);
  }

  removeOrder(order: Order): void {
    this._orders = this._orders.filter((o) => o.id !== order.id);
  }

  removeOrders(orders: Order[]): void {
    orders.forEach((o) => this.removeOrder(o));
  }

  clearOrder(): void {
    this._orders = [];
  }
}

export const OrdersNotificationType = {
  New: 'new',
  Scheduled: 'scheduled',
  Prepare: 'prepare',
} as const;
export type OrdersNotificationType = typeof OrdersNotificationType[keyof typeof OrdersNotificationType];
