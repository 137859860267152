import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { BusinessDayTime, DAYS, RegularBusinessDayTime } from '../../../../../../domain';
import { Color } from '../../../../../constants';
import { decorateBusinessTime, decorateDayOrDate, decorateSectionName } from '../../../../../decorators';
import { AppText } from '../../../../AppText';
import { OutlineButton } from '../../../../buttons/OutlineButton';

interface SectionViewProps {
  sectionName: string | null
  businessDayTimes: RegularBusinessDayTime[]
  onPressEdit: () => void
}

export const RegularViews = ({ sectionName, businessDayTimes, onPressEdit }: SectionViewProps): React.ReactElement => {
  const data = useMemo(() =>
    businessDayTimes.sort((a, b) => DAYS.indexOf(a.day) - DAYS.indexOf(b.day))
  , [businessDayTimes]);

  const renderItem = useCallback(({ item }: { item: BusinessDayTime }) =>
    <View style={styles.businessDayTimeTextWrapper}>
      <AppText style={styles.businessDayTimeText}>{decorateDayOrDate(item)}</AppText>
      <AppText style={styles.businessDayTimeText}>{decorateBusinessTime(item)}</AppText>
    </View>, []);

  const keyExtractor = useCallback((businessDayTime: RegularBusinessDayTime) =>
    `regular-views-${businessDayTime.sectionId}-${businessDayTime.day}`
  , []);

  return <>
    <View style={styles.title}>
      <AppText style={styles.sectionName}>{decorateSectionName('regular' as const, sectionName)}</AppText>
      <OutlineButton size='medium' title='編集' onPress={onPressEdit} />
    </View>
    <FlatList {...{ data, renderItem, keyExtractor }} />
  </>;
};

const styles = StyleSheet.create({
  businessDayTimeText: {
    fontSize: 14,
    marginRight: 32,
    color: Color.gray70
  },
  businessDayTimeTextWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    height: 26,
    textAlignVertical: 'top'
  },
  sectionName: {
    color: Color.gray100,
    fontSize: 16,
    marginTop: -3
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    justifyContent: 'space-between',
    marginBottom: -3
  }
});
