import { PlatformRelation, PlatformType } from '../../../domain';
import { Callable, useCallable } from '../core/useCallable';

interface Request {
  orderPlatformRelation: PlatformRelation<PlatformType>
}

type Response = void

export const useDeleteOrderPlatformSuspension = (): Callable<Request, Response> =>
  useCallable<Request, Response>('onCallDeleteOrderPlatformSuspension');
