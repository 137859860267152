import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Brand, getOrderPlatformTypeToDisplay, Order, OrderSales } from '../../../../domain';
import { Color } from '../../../constants';
import { OrderDecorator, PlatformTypeDecorator } from '../../../decorators';
import { useAppContext } from '../../../hooks';
import { Formatter } from '../../../utils';
import { AppText } from '../../AppText';
import { Clickable } from '../../Clickable';

interface Props {
  order: Order
  orderSales?: OrderSales
  brands: Brand[] | undefined

  onPressOrder: (order: Order) => void
}

export const OrderListItem = ({ order, orderSales, brands, onPressOrder }: Props): React.ReactElement => {
  const { integrations } = useAppContext();
  const decorator = new OrderDecorator(order);

  const status = {
    'CREATED': decorator.fulfillmentText(),
    'ACCEPTED': decorator.fulfillmentText(),
    'READY': decorator.fulfillmentText(),
    'COMPLETED': decorator.fulfillmentText(),
    'CANCELED': 'キャンセル',
  }[order.status] ?? 'その他';

  const onPress = useCallback(() => onPressOrder(order), [order, onPressOrder]);

  return <Clickable name="order_tap" id={order.id} onPress={onPress}>
    <View style={styles.view}>
      <View style={styles.colStatus}>
        <View>
          <AppText style={order.status === 'CANCELED' ? styles.canceled : styles.status}>
            {status}
          </AppText>
        </View>
        <View>
          <AppText style={styles.time}>{Formatter.time(order.createTime.toDate())}</AppText>
        </View>
      </View>
      <View style={styles.colPlatformType}>
        <Image
          source={new PlatformTypeDecorator(getOrderPlatformTypeToDisplay(order), integrations).icon()}
          style={styles.icon}
          resizeMode="contain" />
      </View>
      <View style={styles.colOrderNumber}>
        <AppText style={styles.id}>{order.displayId}</AppText>
      </View>
      <View style={styles.colOrderName}>
        <AppText style={styles.name}>{order.customer.name}</AppText>
      </View>
      <View style={styles.colBrand}>
        <View style={{ flexDirection: 'row' }}>
          <AppText style={styles.brand}>{brands?.find((brand) => brand.id === order.brandId)?.name}</AppText>
        </View>
      </View>
      <View style={styles.colTotal}>
        <AppText>{Formatter.price(orderSales?.sales || order.price.subtotal)}</AppText>
      </View>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    paddingVertical: 32,
    borderWidth: 1,
    borderColor: Color.gray10,
    backgroundColor: Color.white,
  },
  colStatus: {
    width: '20.5%',
    paddingStart: 56,
    paddingEnd: 8,
    justifyContent: 'center',
  },
  colPlatformType: {
    width: '12%',
    alignItems: 'center',
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  colOrderNumber: {
    width: '14%',
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  colOrderName: {
    width: '14%',
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  colBrand: {
    flex: 1,
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  colTotal: {
    width: '12.5%',
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  status: {
    fontSize: 16,
    color: Color.gray100,
  },
  canceled: {
    fontSize: 16,
    color: Color.red,
  },
  time: {
    fontSize: 14,
    color: Color.gray100,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20
  },
  id: {
    fontSize: 16,
    color: Color.gray100,
  },
  name: {
    fontSize: 16,
    color: Color.gray100,
  },
  brand: {
    width: 161,
    fontSize: 12,
  },
});
