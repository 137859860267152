import { DiscoveredPrinter, PrinterDiscover, PrinterModels } from '@orderlyjp/react-native-sii';
import { PrinterDiscoverer } from '../';
import { Printer } from '../../../domain';
import { SiiPrinter } from '../../../domain/entities/Printer/SiiPrinter';
import { logger } from '../../logger';

export class SiiPrinterDiscoverer implements PrinterDiscoverer {
  private manager: PrinterDiscover | null = null;

  start(f: (printer: Printer) => void): Promise<void> {
    const manager = new PrinterDiscover((_: PrinterDiscover, printer: DiscoveredPrinter) => {
      // Sii SDK wrongly returns other printers if the printer is connected via Bluetooth.
      if (printer.name?.includes('mC-Print3')) return;
      if (printer.name?.includes('TM-m30II')) return;
      if (!printer.name && !printer.ipAddress && !printer.macAddress && !printer.bluetoothAddress && !printer.portType)
        return;

      f(new SiiPrinter(printer, PrinterModels.RP_F10)); // とりあえずは RP-F10 のプリンタと決め打ちする
    });

    this.manager = manager;

    return manager.start()
      .catch(error => {
        logger.error(error as Error);
        return Promise.reject(error);
      })
      .catch(() => manager.stop().finally(() => { this.manager = null; }));
  }

  stop(): Promise<void> {
    return this.manager?.stop().finally(() => { this.manager = null; }) ?? Promise.resolve();
  }
}
