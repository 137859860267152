import { NewsItem } from '../../domain';
import { Storage } from '../Storage';

/** お知らせを読んだかどうかの保存(永続化)と復元をする */
export class ReadNewsStore {
  static newsScreenFirstOpenTimeCache: number | undefined = undefined;
  static newsScreenLastOpenTimeCache: number | undefined = undefined;

  private constructor() {
    // do nothing.
  }

  /**
   * 初回起動時には全てのお知らせを既読にする必要があるため、
   * 最初にお知らせ一覧を開いた時刻がない場合には現在時刻を記録し、
   * さらに最後にお知らせ一覧を開いた時刻がない場合には現在時刻を記録する。
   */
  static async initialize(): Promise<void> {
    const storage = new Storage<number>();
    const now = Date.now();

    const firstOpenTime = await storage.get('@NEWS_SCREEN_FIRST_OPENED_TIME');
    if (firstOpenTime === undefined) {
      await storage.save('@NEWS_SCREEN_FIRST_OPENED_TIME', now);
      this.newsScreenFirstOpenTimeCache = now;
    }

    const lastOpenTime = await storage.get('@NEWS_SCREEN_LAST_OPENED_TIME');
    if (lastOpenTime === undefined) {
      await storage.save('@NEWS_SCREEN_LAST_OPENED_TIME', now);
      this.newsScreenLastOpenTimeCache = now;
    }
  }

  /** 既読にする */
  static async read(newsItem: NewsItem): Promise<void> {
    await this.readByNewsItemId(newsItem.id);
  }

  /** 既読にする */
  static async readByNewsItemId(newsItemId: string): Promise<void> {
    const storage = new Storage<string[]>();
    const readIdList = await storage.get('@NEWS_READ_ID_LIST') ?? [];
    if (!readIdList.includes(newsItemId)) {
      readIdList.push(newsItemId);
      await storage.save('@NEWS_READ_ID_LIST', readIdList);
    }
  }

  /** 既読かを取得する */
  static async isRead(newsItem: NewsItem): Promise<boolean> {
    return this.isReadByNewsItemId(newsItem.id);
  }

  /** 既読かを取得する */
  static async isReadByNewsItemId(newsItemId: string): Promise<boolean> {
    const storage = new Storage<string[]>();
    const readIdList = await storage.get('@NEWS_READ_ID_LIST') ?? [];
    return readIdList?.some((id) => newsItemId === id) ?? false;
  }

  /**
   * お知らせ一覧画面を最初に開いた時刻を取得する
   */
  static async getNewsScreenFirstOpenDate(): Promise<Date | undefined> {
    if (this.newsScreenFirstOpenTimeCache) {
      return new Date(this.newsScreenFirstOpenTimeCache);
    } else {
      const storage = new Storage<number>();
      const newsScreenFirstOpenTime = await storage.get('@NEWS_SCREEN_FIRST_OPENED_TIME');
      if (newsScreenFirstOpenTime) {
        this.newsScreenFirstOpenTimeCache = newsScreenFirstOpenTime;
        return new Date(newsScreenFirstOpenTime);
      } else {
        return undefined;
      }
    }
  }
}
