import firebase from 'firebase/compat/app';
import { useCallback, useState } from 'react';

type UseDocumentUpdate = {
  loading: boolean
  error: Error | undefined
  update: <T>(documentPath: string, obj: T) => Promise<void>
}

export const useDocumentUpdate = (): UseDocumentUpdate => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const update = useCallback(async <T>(documentPath: string, obj: T) => {
    setLoading(true);

    try {
      await firebase
        .firestore()
        .doc(documentPath)
        .set(
          {
            ...obj,
            updateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          },
          { merge: true },
        );
      setLoading(false);
    } catch (e) {
      setError(e as Error);
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    update,
  };
};
