import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useMemo, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Brand, Order, PlatformType } from '../../domain';
import { BrandDropdown, CalendarDropdown, NavigationBar, OrderList, WithScreen } from '../components';
import { Color, Route } from '../constants';
import { useAppContext, useOrders, useOrderSales } from '../hooks';
import dayjs, { Dayjs } from 'dayjs';

export const HistoryScreen = (
  { navigation }: StackScreenProps<Route, 'History'>
): React.ReactElement => {
  const { store, brands } = useAppContext();

  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformType>();
  const [selectable] = useState({ min: dayjs(store?.createTime.seconds), max: dayjs() });

  const [orders, { loaded, hasMore, loadingMore, loadMore }, error] = useOrders({
    date: selectedDate.toDate(),
    brandId: selectedBrand?.id,
    limit: 10,
  });
  const orderIds = useMemo(() => orders.map(order => order.id), [orders]);
  const [orderSales, loadedSales, errorSales] = useOrderSales(orderIds);

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressClose = useCallback(() => {
    navigation.popToTop();
    navigation.goBack();
  }, [navigation]);

  const onPressBrand = useCallback((brand?: Brand) => {
    setSelectedBrand(brand);
    setSelectedPlatform(undefined);
  }, []);

  const onPressDate = useCallback((date: Dayjs) => {
    setSelectedDate(date);
    setSelectedPlatform(undefined);
  }, []);

  const onPressOrder = useCallback((order: Order) => {
    navigation.navigate('OrderDetail', { orderId: order.id, order, title: '注文履歴', shouldNotUpdateOrderRead: true });
  }, [navigation]);

  const onPressPlatform = useCallback((platform?: PlatformType) => {
    setSelectedPlatform(platform);
  }, []);

  const onReachedBottom = useCallback(() => {
    if (!loadingMore && hasMore) {
      loadMore();
    }
  }, [hasMore, loadMore, loadingMore]);

  const loadingIndicator = loadingMore
    ? <ActivityIndicator style={styles.indicator} size='small' color='#999999' animating hidesWhenStopped />
    : null;

  return <WithScreen loading={!loaded || !loadedSales} error={error || errorSales}>
    <SafeAreaView style={styles.screen}>
      <NavigationBar title="注文履歴" onPressBack={onPressBack} onPressClose={onPressClose} isForMenu={true} >
        <View style={styles.brandDropdown}><BrandDropdown {...{ brands, selectedBrand, onPressBrand }}/></View>
        <View style={styles.calendarDropdown}>
          <CalendarDropdown {...{ selected: selectedDate, selectable, onPressDate }} />
        </View>
      </NavigationBar>
      <OrderList {...{ orders, orderSales, selectedPlatform, onPressOrder, onReachedBottom, onPressPlatform }} />
      {loadingIndicator}
    </SafeAreaView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Color.white,
    width: '100%',
  },
  brandDropdown: {
    flex: 3,
    marginHorizontal: 6,
  },
  calendarDropdown: {
    flex: 2,
    marginHorizontal: 6,
  },
  indicator: {
    padding: 24
  },
});
