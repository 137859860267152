import { ApiRequest } from '../client';
import { NotificationApiHost } from './index';

export type NotificationApiRequestBase =
  Pick<ApiRequest<void>, 'baseURL' | 'headers' | 'apiName' | 'authenticationErrorStatusCodes' | 'mapResponseToError'>;

export const base = (token: string | undefined): NotificationApiRequestBase => ({
  baseURL: `https://${NotificationApiHost}`,
  headers: {
    'Authorization': `Bearer ${token}`,
    'Accept-Encoding': 'gzip, deflate',
    'Content-Type': 'application/json'
  },
  apiName: 'NotificationApi',
  authenticationErrorStatusCodes: [401]
});
