import React, { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { Order } from '../../../../../domain';
import { Color } from '../../../../constants';
import { Formatter } from '../../../../utils';
import { AppText } from '../../../AppText';
import { If } from '../../../If';

export const PriceView = (props: { order: Order }): ReactElement =>
  <View style={styles.view}>
    <View style={styles.row}>
      <AppText style={[styles.title, styles.titleTotal]}>小計</AppText>
      <AppText style={[styles.price, styles.priceTotal]}>
        {Formatter.price(props.order.price.subtotal)}
      </AppText>
    </View>
    <If condition={props.order.price.promotions}>
      {props.order.price.promotions?.map((val, i) =>
        <View style={styles.row} key={`promo-${i}`}>
          <AppText style={styles.title}>プロモーション</AppText>
          <AppText style={styles.price}>{Formatter.price(val)}</AppText>
        </View>
      )}
    </If>
    <View style={[styles.row, styles.total]}>
      <AppText style={[styles.title, styles.titleTotal]}>合計</AppText>
      <AppText style={[styles.price, styles.priceTotal]}>
        {Formatter.price(props.order.price.total)}
      </AppText>
    </View>
    <If condition={props.order.price.delivery}>
      <View style={styles.row}>
        <AppText style={styles.title}>配達料金</AppText>
        <AppText style={styles.price}>{Formatter.price(props.order.price.delivery ?? 0)}</AppText>
      </View>
    </If>
    <If condition={props.order.price.deliveryPromotions}>
      {props.order.price.deliveryPromotions?.map((val, i) =>
        <View style={styles.row} key={`delivery-promo-${i}`}>
          <AppText style={styles.title}>配達料金の値引き</AppText>
          <AppText style={styles.price}>{Formatter.price(val)}</AppText>
        </View>
      )}
    </If>
  </View>;

const styles = StyleSheet.create({
  view: {
    width: 200,
    marginLeft: 'auto',
    marginTop: 40,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  total: {
    paddingBottom: 16,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
  },
  title: {
    fontSize: 14,
    color: Color.gray70,
  },
  titleTotal: {
    fontSize: 18,
    fontWeight: '500',
    color: Color.gray100,
  },
  price: {
    fontSize: 14,
    color: Color.gray70,
  },
  priceTotal: {
    fontSize: 18,
    fontWeight: '500',
    color: Color.gray100,
  },
});
