import dayjs from 'dayjs';
import { OrderWithDelivery, PlatformType } from '../entities';

/**
 * @deprecated
 * TODO: Use integration info
 */
export const canDelay = (orderWithDelivery: OrderWithDelivery): boolean => {
  if (orderWithDelivery.order.platform === PlatformType.UBEREATS)
    return canDelayUberEatsOrder(orderWithDelivery);

  return {
    [PlatformType.BOTEATS]:  true,
    [PlatformType.DEMAECAN]: false,
    [PlatformType.DEMAECAN_OFFICIAL]: false,
    [PlatformType.MENU]: false,
    [PlatformType.MENU_OFFICIAL]: false,
    [PlatformType.SUKESAN]: false,
    [PlatformType.WOLT]: false,
    [PlatformType.WOLT_OFFICIAL]: false,
  }[orderWithDelivery.order.platform];
};

const canDelayUberEatsOrder = ({ order, delivery, deliveryEstimation }: OrderWithDelivery): boolean => {
  if (order.status !== 'CREATED' && order.status !== 'ACCEPTED') return false;
  if (order.preparationDelayed) return false;

  if (order.deliveryTimeType.type === 'scheduled') return false;
  if (delivery?.courierType === 'platform' && delivery.courier) return false;
  if (delivery?.courierType === 'deaas') return false;
  if (order.inHouseDelivery)
    return dayjs(deliveryEstimation?.deliveryTime?.toDate()).diff(dayjs(), 'minute') >= 20;

  return true;
};
