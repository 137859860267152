import { NavigationProp, useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { PopupNotification } from '../../../domain';
import { Storage } from '../../../infrastructure';
import { Route } from '../../constants';

export const usePopupNotifications = (notification: PopupNotification): void => {
  const navigation = useNavigation<NavigationProp<Route>>();

  useEffect(() => {
    if (dayjs(notification.expireTime).diff(dayjs(), 'seconds') < 0)
      return;

    executeOnceById(notification.id, () => {
      navigation.navigate('PopupNotification', { notification });
    });
  }, [navigation, notification]);
};

const executeOnceById = async (id: string, f: () => void) => {
  const storage = new Storage<string[]>();
  await storage.get('@POPUP_NOTIFICATION_READ_ID_LIST')
    .then(async readIdList => {
      if (!(readIdList ?? []).includes(id)) {
        f();
        const newIdList = Array.from(new Set([...(readIdList ?? []), id]));
        await storage.save('@POPUP_NOTIFICATION_READ_ID_LIST', newIdList);
      }
    });
};
