import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Brand, Platform } from '../../../domain';
import { AppText, OutlineButton } from '../../components';
import { Color } from '../../constants';
import { PlatformDecorator, PlatformTypeDecorator } from '../../decorators';
import { useAppContext } from '../../hooks';

interface Props {
  brand: Brand;
  platforms: Platform[];
  onPressChange: (platform: Platform) => void;
}

export const DeliverySettingCardItemView = ({ brand, platforms, onPressChange }: Props): React.ReactElement => {
  const onPress = useCallback(() => {
    // 現状UberEatsのみ配達方法を変更可能なので、変更するプラットフォームは一意に決まる
    if (platforms[0]) onPressChange(platforms[0]);
  }, [onPressChange, platforms]);

  return <View style={styles.view}>
    <AppText style={styles.brandName}>{brand.name}</AppText>
    <View style={styles.platformsView}>
      {platforms.map((platform) =>
        <PlatformItem key={`DeliverySettingCardItemView-PlatformItem-${platform.type}`} {...{ platform, onPress }} />
      )}
    </View>
  </View>;
};

const PlatformItem = ({ platform, onPress }: { platform: Platform; onPress: () => void; }) => {
  const { integrations } = useAppContext();
  const platformTypeDecorator = new PlatformTypeDecorator(platform.type, integrations);
  const deliveryMethodDecorator = new PlatformDecorator(platform, integrations);

  return <View style={platformStyles.view}>
    <View style={platformStyles.colPlatformType}>
      <View style={platformStyles.viewIconTitle}>
        <Image source={platformTypeDecorator.icon()} style={platformStyles.icon} resizeMode="contain" />
        <AppText style={platformStyles.textPlatform}>{platformTypeDecorator.name()}</AppText>
      </View>
    </View>
    <View style={platformStyles.rightComponent}>
      <View style={platformStyles.colItem}>
        <AppText style={platformStyles.textValue}>{deliveryMethodDecorator.deliveryMethodText()}</AppText>
      </View>
      <View style={platformStyles.colItem}>
        <OutlineButton size='large' title="変更" onPress={onPress}/>
      </View>
    </View>
  </View>;
};

const styles = StyleSheet.create({
  view: {
    backgroundColor: Color.white,
    marginBottom: 24,
    paddingHorizontal: 32,
    paddingBottom: 24,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Color.gray10,
  },
  brandName: {
    height: 104,
    fontSize: 16,
    color: Color.gray100,
    fontWeight: '500',
    lineHeight: 104
  },
  platformsView: {
  },
  iconCursor: {
    width: 14,
    height: 14,
  },
  iconCircle: {
    width: 32,
    height: 32,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: Color.gray10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCircleOpen: {
    width: 32,
    height: 32,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: Color.gray10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.gray5,
  },
});

const platformStyles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    height: 88,
  },
  rightComponent: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  textValue: {
    fontSize: 14,
  },
  viewIconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: 16,
    borderRadius: 20,
  },
  colPlatformType: {
    flex: 1,
    justifyContent: 'center',
  },
  textPlatform: {
    fontSize: 16,
    fontWeight: '500',
    marginEnd: 16
  },
  colItem: {
    marginLeft: 16,
    justifyContent: 'center',
  },
});
