import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Brand, Integration, NetworkError, Platform, ResourceInvalidError, Store } from '../../../domain';
import { logger } from '../../../infrastructure';
import { useGetIntegrations } from '../calls/useGetIntegrations';
import { useBrands } from '../collections/useBrands';
import { usePlatforms } from '../collections/usePlatforms';
import { useStore } from '../collections/useStore';

interface LoginState {
  store: Store | undefined
  brands: Brand[] | undefined
  platforms: Platform[] | undefined
  integrations: Integration[] | undefined

  loggedIn: boolean
  logout: () => void

  loading: boolean
  error: Error | undefined
}

interface Claim {
  accountId: string
  storeId: string
}

export const useLoginState = (): LoginState => {
  const [auth, loadingAuth, errorAuth] = useAuthState(getAuth());

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);

  const [claim, setClaim] = useState<Claim>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [integrations, setIntegrations] = useState<Integration[]>();

  const [store, loadingStore, errorStore] = useStore(claim);
  const [brands, loadingBrands, errorBrands] = useBrands(claim);
  const [platforms, loadingPlatforms, errorPlatforms] = usePlatforms(claim);
  const { call: integrationCall, loading: loadingIntegrations, error: errorIntegrations } = useGetIntegrations();

  const brandIds = useMemo(() => platforms?.map(platform => platform.brandId), [platforms]);
  const activeBrands = useMemo(() => brands?.filter(brand => brandIds?.includes(brand.id)), [brands, brandIds]);

  const logout = useCallback(() => getAuth().signOut(), []);

  useEffect(() => {
    if (loadingAuth) return;

    if (!auth) {
      setClaim(undefined);
      setLoading(false);
      return;
    }

    setLoading(true);
    getStoreUserClaims()
      ?.then(claim => {
        if (claim) {
          setClaim(claim as Claim);
        } else {
          setError(new ResourceInvalidError('Account'));
          return logout();
        }
      })
      .catch(error => {
        if (error?.code === 'auth/network-request-failed') {
          setError(new NetworkError());
        } else {
          setError(error);
          return logout();
        }
      })
      .finally(() => setLoading(false));
  }, [auth, loadingAuth, logout]);

  useEffect(() => {
    if (!claim || integrations || loadingIntegrations) return;

    integrationCall().then(integrations => setIntegrations(integrations));
  }, [claim, integrationCall, integrations, loadingIntegrations]);

  useEffect(() => {
    if (!auth) return;

    observeOnlineStatus(auth.uid);
  }, [auth]);

  useEffect(() => {
    const isNotLoading = !loadingAuth && !loadingStore && !loadingBrands && !loadingPlatforms;
    const hasAllResources = !!auth && !!claim && !!store && !!brands && !!platforms;

    setLoggedIn(isNotLoading && hasAllResources);
  }, [loadingAuth, loadingStore, loadingBrands, loadingPlatforms, auth, claim, store, brands, platforms]);

  useEffect(() => {
    if (!claim) return;
    updateStoreActivatedStatus(claim);
  }, [claim]);

  useEffect(() => {
    if (auth && claim) {
      logger.set(auth, claim.accountId, claim.storeId);
    } else {
      logger.set(undefined, undefined, undefined);
    }
  }, [auth, claim]);

  return {
    loggedIn,
    logout,
    store,
    brands: activeBrands,
    platforms,
    integrations,
    loading: loadingAuth || loading || loadingStore || loadingBrands || loadingPlatforms || loadingIntegrations,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: (errorAuth as any) || errorStore || errorBrands || errorPlatforms || errorIntegrations || error
  };
};

const updateStoreActivatedStatus = (claim: Claim) =>
  firebase.firestore()
    .doc(`accounts/${claim.accountId}/stores/${claim.storeId}`)
    .update({ activated: true });

const getStoreUserClaims = () =>
  getAuth().currentUser?.getIdTokenResult(true)
    .then(result => {
      if (!result.claims.store || !result.claims.accountId || !result.claims.storeId) return undefined;

      return result.claims as Claim;
    });

const observeOnlineStatus = (uid: string) =>
  firebase.database().ref('.info/connected').on('value', snapshot => {
    if (snapshot.val() == false) return;
    const ref = firebase.database().ref('/status/' + uid);

    ref.onDisconnect().set({ state: 'offline', lastChanged: firebase.database.ServerValue.TIMESTAMP })
      .then(() => { // TODO: Is this right? It can't be online
        ref.set({ state: 'online', lastChanged: firebase.database.ServerValue.TIMESTAMP });
      });
  });
