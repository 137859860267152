import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Order } from '../../../../../../domain';
import { Color } from '../../../../../constants';
import { Formatter } from '../../../../../utils';
import { AppText } from '../../../../AppText';
import { If } from '../../../../If';
import { OptionGroupView } from './OptionGroupView';

interface Props {
  item: Item;
  optionGroups: OptionGroupViewModel[];
}

interface OptionGroupViewModel {
  option: Option;
  isHideCategory?: boolean;
  isHideBorder?: boolean;
  optionGroups?: OptionGroupViewModel[];
}

type Item = Order['items'][number];
type Option = Order['items'][number]['options'][number];

export const ItemView = (props: Props): React.ReactElement =>
  <View style={styles.view}>
    <View style={[styles.top, !!props.item.options.length && styles.border]}>
      <View style={[styles.badge, props.item.quantity > 1 && styles.badgeRed]}>
        <AppText style={styles.quantity}>{props.item.quantity}</AppText>
      </View>
      <View style={styles.titleView}>
        <AppText style={styles.title}>{props.item.title}</AppText>
        <AppText style={styles.price}>{Formatter.price(props.item.price)}</AppText>
      </View>
    </View>
    {props.optionGroups.map((optionGroup, i) => (
      <View style={styles.optionView} key={`itemView-optionGroups-${optionGroup.option.id}-${i}`}>
        <OptionGroupView {...optionGroup} />
      </View>
    ))}
    <If condition={(props.item.comment?.length ?? 0) > 0}>
      <View style={styles.commentView}>
        <Image
          source={require('../../../../../assets/icon/comment.png')}
          resizeMode="contain"
          style={styles.commentIcon}
        />
        <AppText style={styles.comment}>商品へのコメント：{props.item.comment}</AppText>
      </View>
    </If>
  </View>;

const styles = StyleSheet.create({
  view: {
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
    paddingVertical: 12
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: Color.gray10,
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 60,
    paddingVertical: 12
  },
  badge: {
    display: 'flex',
    width: 24,
    height: 24,
    backgroundColor: Color.gray100,
    borderRadius: 4,
    marginRight: 10,
    marginTop: 8
  },
  badgeRed: {
    backgroundColor: Color.red,
  },
  quantity: {
    color: Color.white,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 24
  },
  titleView: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 20,
    marginTop: 4
  },
  title: {
    flex: 1,
    fontSize: 20,
    fontWeight: 'bold',
    color: Color.gray100,
    marginRight: 24,
  },
  price: {
    fontSize: 18,
    fontWeight: '500',
    color: Color.gray100,
    textAlign: 'right'
  },
  optionView: {
    marginLeft: 58,
  },
  commentView: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 4,
    marginBottom: 20,
    marginLeft: 64,
    paddingVertical: 16,
    paddingHorizontal: 16,
    backgroundColor: 'rgba(247, 78, 47, 0.05)',
    borderRadius: 4,
  },
  commentIcon: {
    width: 28,
    height: 20,
    marginLeft: 1,
    marginRight: 9,
  },
  comment: {
    fontSize: 16,
    color: Color.red,
    paddingRight: 16,
  },
});
