import firebase from 'firebase/compat/app';
import { useState } from 'react';
import { NetworkError, ResourceInvalidError, UnknownError } from '../../../domain';

type UseAuth = {
  loading: boolean
  error: Error | undefined
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

export const useAuth = (): UseAuth => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const login = async (email: string, password: string) => {
    if (loading) return;
    setLoading(true);
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => { setError(mapError(error)); })
      .finally(() => { setLoading(false); });
  };

  const logout = async () => {
    if (loading) return;
    setLoading(true);
    await firebase
      .auth()
      .signOut()
      .catch((error) => { setError(mapError(error)); })
      .finally(() => { setLoading(false); });
  };

  return { loading, error, login, logout };
};

const mapError = (error: Error | undefined) => {
  if (!error) return undefined;

  if (!('code' in error) || typeof error['code'] !== 'string')
    return new UnknownError();

  switch (error['code']) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
    case 'auth/invalid-email':
    case 'auth/user-disabled':
      return new ResourceInvalidError('Identity');
    case 'auth/network-request-failed':
      return new NetworkError();
    default:
      return new UnknownError();
  }
};
