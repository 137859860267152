import { User } from 'firebase/auth';
import { logger } from 'react-native-logs';
import { colorConsoleAfterInteractions } from 'react-native-logs/dist/transports/colorConsoleAfterInteractions';
import { Adapter } from './';

const log = logger.createLogger({
  transport: colorConsoleAfterInteractions,
  severity: __DEV__ ? 'debug' : 'error',
});

export class ConsoleAdapter implements Adapter {
  set(user: User | undefined, accountId: string | undefined, storeId: string | undefined): Promise<void> {
    log.info(`👨🏻 ${user?.uid} ${accountId} ${storeId}`);
    return Promise.resolve();
  }

  screen(name: string): void {
    log.info(`👣 ${name}`);
  }

  action(name: string, id?: string): void {
    if (id) {
      log.info(`👆🏻 ${name} - ${id}`);
    } else {
      log.info(`👆🏻 ${name}`);
    }
  }

  info(name: string, params: Record<string, unknown> | undefined): void {
    log.info(`${name} ${JSON.stringify(params, undefined, 2)}`);
  }

  error(error: Error): void {
    const { name, message, ...rest } = error;
    log.error(`🚨 ${name} - ${message}${Object.keys(rest).length > 0 ? ` - ${JSON.stringify(rest)}` : ''}`);
  }
}
