import { User } from 'firebase/auth';
import * as SentryNative from '@sentry/react-native';
import * as SentryBrowser from '@sentry/browser';
import { Environment } from '../../../Environment';
import { Adapter } from './';

const sentry = Environment.isNative ? SentryNative : SentryBrowser;

if (Environment.runningMode === 'production')
  sentry.init({
    dsn: Environment.dependencies.sentry.dsn,
    environment: Environment.projectEnvironment,
    enabled: Environment.runningMode === 'production',
    release: Environment.platform.build?.revision,
    enableNative: Environment.isNative,
    debug: Environment.runningMode !== 'production'
  });

export class SentryAdapter implements Adapter {
  currentScreen = 'Launch';

  async set(user: User | undefined, accountId: string | undefined, storeId: string | undefined): Promise<void> {
    if (user) {
      await sentry.setUser({
        id: user.uid,
        accountId,
        storeId,
        email: user.email ?? undefined,
        username: user.displayName ?? '',
        revisionId: Environment.platform.build?.revision,
      });
    } else {
      await sentry.setUser({});
    }
  }

  screen(name: string): void {
    this.currentScreen = name;
    sentry.addBreadcrumb({
      category: 'SCREEN',
      message: name,
    });
  }

  action(name: string, id?: string): void {
    sentry.addBreadcrumb({
      category: 'ACTION',
      message: name,
      data: {
        id,
        screen: this.currentScreen,
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  info(): void {}

  error(error: Error): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userInfo = ('userInfo' in error && (error as any).userInfo) ? (error as any).userInfo : {};

    sentry.captureException(error, userInfo);
  }
}
