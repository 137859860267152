import { documentDirectory, writeAsStringAsync } from 'expo-file-system';
import { requestPermissionsAsync, saveToLibraryAsync } from 'expo-media-library';
import { alert } from '../../../infrastructure/alert';
import { now } from '../../../utils';
import { Printer } from './';

export class CamerarollPrinter implements Printer {
  readonly identifier = 'cameraroll-printer';
  readonly maker = 'Orderly' as const
  readonly modelName = 'デバッグ用カメラロールプリンター' as const;
  readonly supportedImageWidth = 576;
  readonly shouldPrintUpSideDown = false;
  readonly shouldSelectPrinterModelManually = false;

  static load(dictionary: { [key: string]: unknown }): Printer | null {
    if (!dictionary) return null;
    if (dictionary.device_type !== 'Orderly') return null;

    return new CamerarollPrinter();
  }

  print(base64Image: string): Promise<void> {
    return requestPermissionsAsync(true)
      .then(() => {
        const filename = documentDirectory + `${now().unix()}.png`;
        return [
          writeAsStringAsync(filename, base64Image, { encoding: 'base64' }),
          saveToLibraryAsync(filename)
        ];
      })
      .then(promises => Promise.allSettled(promises))
      .then(() => alert('印刷結果をカメラロールに保存しました'));
  }

  dispose(): Promise<void> {
    return Promise.resolve();
  }

  toJSON(): { [key: string]: unknown; } {
    return { device_type: this.maker, model_name: this.modelName };
  }

  isEqual(other: unknown): boolean {
    return other instanceof CamerarollPrinter;
  }
}
