import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';

interface Props {
  title?: string;
}

export const PrinterListSectionHeader = (props: Props): React.ReactElement =>
  <View style={styles.view}>
    <AppText style={styles.title}>{props.title}</AppText>
  </View>;

const styles = StyleSheet.create({
  view: {
    paddingTop: 40,
    paddingBottom: 16,
    paddingHorizontal: 12,
  },
  title: {
    fontSize: 14,
    color: Color.gray50,
  },
});
