import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { BusinessDayTime } from '../../../domain';

interface Params {
  accountId: string
  storeId: string
}

export const useBusinessDayTimes = (
  params: Params | undefined
): [BusinessDayTime[] | undefined, boolean, Error | undefined] =>
  useCollectionData<BusinessDayTime>(params
    && firebase
      .firestore()
      .collectionGroup('businessDayTimes')
      .where('accountId', '==', params.accountId)
      .where('storeId', '==', params.storeId)
      .where('disabled', '==', false)
  );
