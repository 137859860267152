import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Printer } from '../../../../domain';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { Clickable } from '../../Clickable';

interface Props {
  printer: Printer;
  checked: boolean;
  buttonTitle?: string;
  positionInTheList: PrinterListItemPositionInTheList;

  onPressButton: (printer: Printer) => void;
}

export type PrinterListItemPositionInTheList = 'top' | 'middle' | 'bottom' | 'single';

export const PrinterListItem = (
  { printer, checked, buttonTitle, positionInTheList, onPressButton }: Props
): React.ReactElement => {
  const rootStyle = {
    top: [styles.view, styles.withTopBorder, styles.withTopRadius],
    middle: [styles.view, styles.withMiddleBorder],
    bottom: [styles.view, styles.withBottomBorder, styles.withBottomRadius],
    single: [styles.view, styles.withBorder, styles.withRadius],
  }[positionInTheList] ?? [styles.view];

  const onPress = useCallback(() => {
    onPressButton(printer);
  }, [onPressButton, printer]);

  return <View style={rootStyle}>
    <View style={styles.content}>
      <View style={styles.checkAndModel}>
        {checked
          ? <Image source={require('../../../assets/icon/check.png')} style={styles.check} resizeMode="contain" />
          : <View style={styles.check} />
        }
        <AppText style={styles.model}>{printer.modelName}</AppText>
      </View>
      <AppText style={styles.identifier}>{printer.identifier}</AppText>
    </View>
    <Clickable name="printer_tap" style={styles.button} onPress={onPress}>
      <AppText style={styles.buttonTitle}>{buttonTitle}</AppText>
    </Clickable>
  </View>;
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 104,
    paddingStart: 16,
    paddingEnd: 32,
    paddingVertical: 24,
    backgroundColor: Color.white,
  },
  withTopBorder: {
    borderWidth: 1,
    borderColor: Color.gray10,
  },
  withMiddleBorder: {
    borderBottomWidth: 1,
    borderStartWidth: 1,
    borderEndWidth: 1,
    borderColor: Color.gray10,
  },
  withBottomBorder: {
    borderBottomWidth: 1,
    borderStartWidth: 1,
    borderEndWidth: 1,
    borderColor: Color.gray10,
  },
  withBorder: {
    borderWidth: 1,
    borderColor: Color.gray10,
  },
  withTopRadius: {
    borderTopStartRadius: 12,
    borderTopEndRadius: 12,
  },
  withBottomRadius: {
    borderBottomStartRadius: 12,
    borderBottomEndRadius: 12,
  },
  withRadius: {
    borderRadius: 12
  },
  content: {
    flex: 1,
  },
  checkAndModel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  check: {
    width: 14,
    height: 11,
  },
  model: {
    fontSize: 18,
    color: Color.gray100,
    marginStart: 12,
  },
  identifier: {
    fontSize: 14,
    color: Color.gray100,
    marginTop: 12,
    marginStart: 26,
  },
  button: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Color.gray10,
  },
  buttonTitle: {
    fontSize: 14,
    color: Color.gray100,
    marginHorizontal: 16,
    marginVertical: 11,
  }
});
