import { Picker } from '@react-native-picker/picker';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { COOKING_TIMES, CookingTime, platformToRelation } from '../../domain';
import { AppText, Clickable, NavigationBar, PrimaryButton, WithModal, } from '../components';
import { Color, Route } from '../constants';
import { useAppContext, useGetMaxCookingTime, useOrderOperation } from '../hooks';

export const AcceptWithCookingTimeScreen = (
  { navigation, route }: StackScreenProps<Route, 'AcceptWithCookingTime'>
): React.ReactElement => {
  const { order, onComplete } = route.params;
  const { platforms } = useAppContext();
  const [cookingTime, setCookingTime] = useState<CookingTime>(30);
  const [maxCookingMinute, setMaxCookingMinute] = useState<number>();

  const { call: operation, error: errorOperation } = useOrderOperation();
  const { call: getMaxCookingTime, loading, error } = useGetMaxCookingTime();

  const cookingTimes = useMemo(() =>
    maxCookingMinute ? COOKING_TIMES.filter(time => time <= maxCookingMinute) : []
  , [maxCookingMinute]);

  useEffect(() => {
    const platform = platforms?.find(({ brandId, type }) => brandId === order.brandId && type === order.platform);
    if (!platform) return;

    getMaxCookingTime(platformToRelation(platform))
      .then(({ maxPreparationMinute }) => setMaxCookingMinute(maxPreparationMinute));
  }, [order, platforms, getMaxCookingTime]);

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPressConfirm = useCallback(() =>
    operation({ operation: { type: 'ACCEPT', cookingTime }, orderId: order.id })
      .then(() => {
        navigation.goBack();
        onComplete?.();
      })
  , [cookingTime, navigation, onComplete, operation, order.id]);

  const onPressMinus = useCallback(() => {
    setCookingTime(Math.max(cookingTime - 5, Math.min(...cookingTimes)) as CookingTime);
  }, [cookingTime, cookingTimes]);

  const onPressPlus = useCallback(() => {
    setCookingTime(Math.min(cookingTime + 5, Math.max(...cookingTimes)) as CookingTime);
  }, [cookingTime, cookingTimes]);

  const onValueChange = useCallback((value: string) => setCookingTime(Number(value) as CookingTime), []);

  const Header = <NavigationBar title="調理時間を設定する" onPressBack={onPressBack} isForMenu={true} />;
  const Body = <View style={styles.body}>
    <View style={styles.pickerArea}>
      <Clickable name="button_tap" onPress={onPressMinus}>
        <AppText style={styles.updown}>-</AppText>
      </Clickable>
      <Picker
        style={styles.picker}
        itemStyle={styles.pickerItem}
        selectedValue={cookingTime.toString()}
        onValueChange={onValueChange}>
        {cookingTimes.map(minute =>
          <Picker.Item
            key={`set-cooking-time-and-confirm-screen-minutes-${minute}`}
            label={`${minute}分`}
            value={minute.toString()} />
        )}
      </Picker>
      <Clickable name="button_tap" onPress={onPressPlus}>
        <AppText style={styles.updown}>+</AppText>
      </Clickable>
    </View>
    <View style={styles.buttonArea}>
      <PrimaryButton size='large' title="この調理時間で受注する" onPress={onPressConfirm} />
    </View>
  </View>;

  return <WithModal {...{ Header, Body, loading, error: error || errorOperation }}/>;
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 56,
    paddingVertical: 40,
  },
  pickerArea: {
    flex: 1,
    backgroundColor: Color.white,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40
  },
  picker: {
    width: 180,
    color: Color.gray100,
    fontSize: 32,
    padding: 8,
  },
  pickerItem: {
    color: Color.gray100,
    fontSize: 32,
  },
  updown: {
    color: Color.gray100,
    fontSize: 72,
    padding: 28,
  },
  buttonArea: {
    alignItems: 'center'
  }
});
