import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo, StyleSheet, useWindowDimensions } from 'react-native';
import { CanceledOrderNotification, Notification, OrdersNotification } from '../../../../../domain';
import { NotificationMessage } from './NotificationMessage';
import { OrderCancelMessage } from './OrderCancelMessage';

interface Props {
  notifications: readonly Notification[];
  onPress?: (notification: Notification) => void;
  onPressClose?: (notification: Notification) => void;
  onAutoClose?: (notification: Notification) => void;
}

export const Messages = ({ notifications, onPress, onPressClose, onAutoClose }: Props): React.ReactElement => {
  const { height: screenHeight } = useWindowDimensions();

  const keyExtractor = useCallback((notification: Notification, index: number) =>
    `Messages-MessageItem-${notification instanceof OrdersNotification ? 'orders' : 'canceled-orders'}-${index}`
  , []);

  const renderItem = useCallback(({ item }: ListRenderItemInfo<Notification>) =>
    <MessageItem {...{ item, onPress, onPressClose, onAutoClose }}/>
  , [onAutoClose, onPress, onPressClose]);

  return <FlatList
    style={[styles.view, { maxHeight: screenHeight - 24 }]}
    contentContainerStyle={{ paddingVertical: 12 }}
    bounces={false}
    data={notifications}
    renderItem={renderItem}
    keyExtractor={keyExtractor} />;
};

type ItemProps = {
  item: Notification;
  onPress?: (notification: Notification) => void;
  onPressClose?: (notification: Notification) => void;
  onAutoClose?: (notification: Notification) => void;
}

const MessageItem = (props: ItemProps) => {
  const { item } = props;
  const { onPress: propsOnPress, onPressClose: propsOnPressClose, onAutoClose: propsOnAutoClose } = props;

  const onPress = useCallback(() => propsOnPress?.(item), [item, propsOnPress]);
  const onPressClose = useCallback(() => propsOnPressClose?.(item), [item, propsOnPressClose]);
  const onAutoClose = useCallback(() => propsOnAutoClose?.(item), [item, propsOnAutoClose]);

  if (item instanceof OrdersNotification) {
    return <NotificationMessage
      message={item.message}
      count={item.count}
      backgroundColor={item.color}
      onPress={onPress}
      onPressClose={onPressClose} />;
  } else if (item instanceof CanceledOrderNotification) {
    return <OrderCancelMessage {...{ order: item.order, onPress, onPressClose, onAutoClose }}/>;
  } else {
    return <></>;
  }
};

const styles = StyleSheet.create({
  view: {
    width:'82%',
    bottom: 0,
    position: 'absolute',
    alignSelf: 'center',
  },
});
