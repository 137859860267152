import { StackScreenProps } from '@react-navigation/stack';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { AppText, WithModal } from '../components';
import { Color, Route } from '../constants';

export const OptionCategoryScreen = (
  { route: { params: { optionCategoryName, items } } }: StackScreenProps<Route, 'OptionCategory'>
): React.ReactElement =>{
  const Header = <><AppText style={styles.titleText}>このオプションカテゴリが使用されている商品</AppText><View style={styles.divider} /></>;
  const Body = <View style={styles.body}>
    <View style={[styles.section, styles.sectionBorder]}>
      <AppText style={styles.sectionTitle}>オプションカテゴリ</AppText>
      <AppText style={styles.sectionContent}>{optionCategoryName}</AppText>
    </View>
    <View style={styles.section}>
      <AppText style={styles.sectionTitle}>使用されている商品</AppText>
      {items.map(item => (
        <AppText key={`option-category-item-${item.id}`} style={styles.sectionContent}>・{item.title}</AppText>
      ))}
    </View>
  </View>;

  return <WithModal {...{ Header, Body, loading: false, error: undefined }} />;
};

const styles = StyleSheet.create({
  body: {
    flex: 1
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: Color.gray10,
  },
  titleText: {
    height: 72,
    fontSize: 19,
    lineHeight: 72,
    textAlign: 'center',
  },
  section: {
    paddingTop: 40,
    paddingLeft: 40,
    paddingBottom: 6
  },
  sectionBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Color.gray10,
  },
  sectionTitle: {
    height: 24,
    width: 136,
    padding: 6,
    marginBottom: 32,
    fontSize: 13,
    lineHeight: 12,
    textAlign: 'center',
    backgroundColor: Color.gray5,
    borderRadius: 4,
  },
  sectionContent: {
    height: 19,
    marginBottom: 32,
    fontSize: 19,
    lineHeight: 19
  },
});
