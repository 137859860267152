import React from 'react';
import { Notification, Notifications } from '../../../../domain';
import { If } from '../../If';
import { Messages } from './messages/Messages';
import { NotificationView } from '../NotificationView';

type Props = {
  notifications: Notifications
  fullScreen: boolean
  onPress: (notification: Notification) => void
  onClose: (notification: Notification) => void
};

export const OrderNotificationView = ({ notifications, fullScreen, onPress, onClose }: Props): React.ReactElement => {
  const orderNotification = [notifications.scheduledOrders, notifications.newOrders, notifications.prepareOrders]
    .filter(n => n.count > 0)[0];

  return fullScreen
    ? <>
      <If condition={notifications.canceledOrders.length > 0}>
        <Messages {...{
          notifications: notifications.canceledOrders,
          onPress,
          onPressClose: onClose,
          onAutoClose: onClose }} />
      </If>
      {orderNotification
        ? <NotificationView {...{ notification: orderNotification, onPress, onClose }} />
        : <></>
      }
    </>
    : <If condition={notifications.hasAny}>
      <Messages {...{ notifications: notifications.all, onPress, onPressClose: onClose, onAutoClose: onClose }} />
    </If>;
};
