import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Image } from 'react-native';
import { Route } from '../constants';
import {
  AcceptanceMethodScreen,
  BrandsScreen,
  BusinessDayTimesScreen,
  DeliverySettingScreen,
  HistoryScreen,
  MenuScreen,
  NewsItemScreen,
  NewsScreen,
  OptionCategoryScreen,
  PrinterModelScreen,
  SalesScreen,
  SettingsScreen,
  SoldOutScreen,
} from '../screens';

const Stack = createStackNavigator<Route>();

export const MenuNavigator = (): React.ReactElement => (
  <Stack.Navigator screenOptions={{ headerBackImage: HeaderBackImage, presentation: 'transparentModal' }}>
    <Stack.Screen name="Menu" component={MenuScreen} options={optionForScreen} />
    <Stack.Screen name="AcceptanceMethod" component={AcceptanceMethodScreen} options={optionForModalScreen} />
    <Stack.Screen name="BusinessDayTimes" component={BusinessDayTimesScreen} options={optionForScreen} />
    <Stack.Screen name="Sales" component={SalesScreen} options={optionForScreen} />
    <Stack.Screen name="SoldOut" component={SoldOutScreen} options={optionForScreen} />
    <Stack.Screen name="OptionCategory" component={OptionCategoryScreen} options={optionForModalScreen} />
    <Stack.Screen name="History" component={HistoryScreen} options={optionForScreen} />
    <Stack.Screen name="Settings" component={SettingsScreen} options={optionForScreen} />
    <Stack.Screen name="Brands" component={BrandsScreen} options={optionForScreen} />
    <Stack.Screen name="PrinterModel" component={PrinterModelScreen} options={optionForModalScreen} />
    <Stack.Screen name="DeliverySetting" component={DeliverySettingScreen} options={optionForModalScreen} />
    <Stack.Screen name="News" component={NewsScreen} options={optionForScreen} />
    <Stack.Screen name="NewsItem" component={NewsItemScreen} options={optionForScreen}/>
  </Stack.Navigator>
);

const optionForScreen = {
  headerShown: false,
  animationEnabled: false
};

const optionForModalScreen = {
  headerShown: false,
  cardStyle: { backgroundColor: 'transparent', opacity: 1 },
  animationEnabled: false,
};

const HeaderBackImage = () =>
  <Image
    source={require('../assets/icon/arrow_left.png')}
    style={{ width: 18, height: 18, marginLeft: 12, marginRight: 30 }} />;
