import { Order } from '../Order';

/** キャンセル注文の通知 */
export class CanceledOrderNotification {
  constructor(order: Order) {
    this.order = order;
  }

  readonly order: Order;
}
