import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Brand, Item, Store } from '../../../domain';

export const useBrandItems = (
  store: Store | undefined,
  brand: Brand | undefined
): [Item[] | undefined, boolean, Error | undefined] =>
  useCollectionData<Item>(
    store && brand && firebase
      .firestore()
      .collectionGroup('items')
      .where('accountId', '==', brand.accountId)
      .where('storeId', '==', store.id)
      .where('brandId', '==', brand.id)
      .where('archived', '==', false),
    { idField: 'id' },
  );
