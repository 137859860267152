import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Brand, OrderWithDelivery } from '../../../domain';
import { pickObject } from '../../../utils';
import { Color } from '../../constants';
import { DeliveryDecorator, OrderDecorator, PlatformTypeDecorator } from '../../decorators';
import { useAppContext, useOrderDispatches } from '../../hooks';
import { Formatter } from '../../utils';
import { AppText } from '../AppText';
import { If } from '../If';

interface Props {
  orderWithDelivery: OrderWithDelivery
  brand: Brand | undefined
  onLayout?: () => void
}

export const CancelReceiptView = ({ orderWithDelivery, brand, onLayout }: Props): React.ReactElement => {
  const { integrations } = useAppContext();
  const { order, delivery, deliveryEstimation } = orderWithDelivery;
  const orderDecorator = new OrderDecorator(order);
  const deliveryDecorator = delivery && deliveryEstimation && new DeliveryDecorator(delivery, deliveryEstimation);
  const platformDecorator = new PlatformTypeDecorator(order.platform, integrations);
  const [orderDispatches, loadingOrderDispatches] = useOrderDispatches(
    { ...pickObject(order, ['accountId', 'storeId', 'brandId']), orderId: order.id }
  );

  const orderDispatch = orderDispatches?.[0];

  return loadingOrderDispatches
    ? <></>
    : <ScrollView contentContainerStyle={styles.view} testID='ReceiptView_ScrollView' onLayout={onLayout}>
      <View style={styles.titleView}><AppText style={styles.title}>キャンセルされました</AppText></View>
      <View style={styles.orderId}>
        <AppText style={styles.orderIdText} numberOfLines={1} adjustsFontSizeToFit>
          {platformDecorator.name()} &nbsp; &nbsp; #{order.displayId}
        </AppText>
      </View>
      <If condition={brand}>
        <AppText style={styles.brandText} numberOfLines={1}>{brand?.name}</AppText>
      </If>
      <If condition={orderDispatch && orderDispatch?.execution?.type === 'failed'}>
        <AppText style={styles.posIdText}>店内番号：{orderDispatch?.displayId}</AppText>
      </If>
      <View style={[styles.border, { marginTop: 64 }]} />
      <AppText style={styles.fulfillment}>{orderDecorator.fulfillmentText()}</AppText>
      <AppText style={styles.text}>{`注文時間：${Formatter.dayTime(order.createTime.toDate())}`}</AppText>
      <If condition={order.deliveryTimeType.type === 'scheduled'}>
        <AppText style={styles.text}>{`予約時間：${orderDecorator.scheduledTimeText('absolute')}`}</AppText>
      </If>
      <AppText style={styles.text}>{deliveryDecorator?.fulfillmentTimeText()}</AppText>
      <AppText style={styles.text}>{`${order.customer.name} 様`}</AppText>
      <View style={[styles.border, { marginTop: 20 }]} />
    </ScrollView>;
};

const styles = StyleSheet.create({
  view: {
    paddingVertical: 144
  },
  titleView: {
    borderColor: Color.black,
    borderTopWidth: 15,
    borderBottomWidth: 15,
    paddingVertical: 40,
    marginBottom: 80,
  },
  title: {
    fontSize: 70,
    fontWeight: '500',
    textAlign: 'center',
  },
  orderId: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: Color.black,
  },
  orderIdText: {
    fontSize: 64,
    fontWeight: '900',
    color: Color.white,
  },
  brandText: {
    fontSize: 48,
    fontWeight: '400',
    marginTop: 32,
  },
  posIdText: {
    fontSize: 40,
    fontWeight: '400',
    marginTop: 32,
  },
  fulfillment: {
    fontSize: 60,
    fontWeight: '500',
    textAlign: 'center',
    marginTop: 60,
    marginBottom: 52,
  },
  text: {
    fontSize: 36,
    fontWeight: '300',
    marginTop: 8
  },
  border: {
    width: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
  },
});
