import { CookingTime } from '../../../domain';
import { Callable, useCallable } from '../core/useCallable';

interface AcceptOperation {
  type: 'ACCEPT';
  cookingTime: CookingTime | null;
}

interface CancelOperation {
  type: 'CANCEL';
}

interface CompleteOperation {
  type: 'COMPLETE';
}

interface ReadyOperation {
  type: 'READY';
}

interface DelayOperation {
  type: 'DELAY';
  preparationTime: number; // ミリ秒
}

interface Request {
  operation:
    | AcceptOperation
    | CancelOperation
    | CompleteOperation
    | ReadyOperation
    | DelayOperation;
  orderId: string;
}

type Response = Record<string, never>;

export const useOrderOperation = (): Callable<Request, Response> => useCallable<Request, Response>('onCallOrderOperation');
