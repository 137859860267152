import React from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

export interface IconTextButtonProps {
  title: string;
  icon: ImageSourcePropType;
  onPress: () => void;
}

export const IconTextButton = ({ title, icon, onPress }: IconTextButtonProps): React.ReactElement =>
  <Clickable name="button_tap" id={title} onPress={onPress}>
    <View style={styles.iconTextButton}>
      <Image source={icon} style={styles.icon} resizeMode="contain" />
      <AppText style={styles.title}>{title}</AppText>
    </View>
  </Clickable>;

const styles = StyleSheet.create({
  iconTextButton: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 4
  },
  icon: {
    width: 16,
    height: 16,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    color: Color.blue_light,
    marginHorizontal: 4
  },
});
