import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Order, } from '../../../../domain';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { If } from '../../If';

interface Props {
  order: Order;
  size: 'small' | 'medium'
}

export const CookView = ({ order, size }: Props): React.ReactElement => {
  const styles = useMemo(() => createStyles(size), [size]);
  const remainingPreparationTime = remainingPreparationMinute(order);
  const isOverdue = remainingPreparationTime < 0;

  return order.status === 'READY'
    ? <View style={styles.row}>
      <Image source={require('../../../assets/icon/cook.png')} resizeMode='contain' style={styles.icon} />
      <AppText style={[styles.text, { lineHeight: size === 'small' ? 19 : 21 }]}>準備完了</AppText>
    </View>
    : <View style={styles.row}>
      <Image
        source={require('../../../assets/icon/cook.png')}
        resizeMode='contain'
        style={[styles.icon, isOverdue ? styles.redTint : {}]} />
      <AppText style={styles.textView}>
        <If condition={isOverdue}>
          <AppText style={[styles.numberText, styles.redText]}>{Math.abs(remainingPreparationTime)}</AppText>
          <AppText style={[styles.text, styles.redText]}>分</AppText>
          <AppText style={[styles.text, styles.redText]}>遅れ</AppText>
        </If>
        <If condition={!isOverdue}>
          <AppText style={[styles.numberText, styles.blackText]}>{Math.abs(remainingPreparationTime)}</AppText>
          <AppText style={[styles.text, styles.blackText]}>分</AppText>
        </If>
      </AppText>
    </View>;
};

const remainingPreparationMinute = ({ preparationTime }: Order): number =>
  dayjs(preparationTime.toDate()).diff(dayjs(), 'minute');

const createStyles = (size: Props['size']) => StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textView: {
    lineHeight: size === 'small' ? 25 : 27
  },
  text: {
    fontSize: size === 'small' ? 14 : 16,
    fontWeight: '500',
  },
  numberText: {
    fontSize: size === 'small' ? 20 : 22,
    fontWeight: '500',
  },
  icon: {
    width: size === 'small' ? 20 : 24,
    height: size === 'small' ? 20 : 24,
    marginRight: 5,
  },
  blackText: {
    color: Color.gray100
  },
  redText: {
    color: Color.red
  },
  redTint: {
    tintColor: Color.red
  },
});
