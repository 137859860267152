import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Color } from '../../../../constants';
import { AppText } from '../../../AppText';
import { Clickable } from '../../../Clickable';

interface Props {
  message: string;
  count: number;
  backgroundColor?: string;
  onPress?: () => void;
  onPressClose?: () => void;
}

export const NotificationMessage = (
  { message, count, backgroundColor, onPress, onPressClose }: Props
): React.ReactElement =>
  <Clickable
    name="notification_tap"
    style={[styles.view, { backgroundColor: backgroundColor ?? Color.brand }]}
    onPress={onPress}>
    <View style={styles.textView}>
      {count !== undefined
        ? (<View style={styles.circle}><AppText style={styles.count}>{count}</AppText></View>)
        : (<></>)
      }
      <AppText style={styles.text}>{message}</AppText>
    </View>
    <AppText style={styles.subText}>タップして注文内容を確認</AppText>
    <Clickable name="notification_close_tap" style={styles.closeView} onPress={onPressClose}>
      <Image style={styles.closeIcon} source={require('../../../../assets/icon/close_white.png')} />
    </Clickable>
  </Clickable>;

const styles = StyleSheet.create({
  view: {
    padding: 24,
    flexDirection: 'column',
    width: '100%',
    height: 152,
    justifyContent: 'space-between',
    backgroundColor: Color.blue_light,
    marginBottom: 4,
  },
  textView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 16,
  },
  count: {
    fontSize: 26,
    color: Color.white,
    fontWeight: '500',
  },
  text: {
    flex: 1,
    fontSize: 28,
    fontWeight: '500',
    color: Color.white,
  },
  subText: {
    fontSize: 18,
    fontWeight: '500',
    marginTop: 30,
    marginStart: 64,
    color: Color.white,
  },
  closeView: {
    position: 'absolute',
    top: 8,
    end: 8,
    width: 52,
    height: 52,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
});
