import { Integration } from './Integration';

export type AcceptanceMethod = {
  automated: boolean
  cookingTimeCalculation:
    { type: 'automatic' }
    | { type: 'manual' }
    | { type: 'fixed', minutes: CookingTime }
};

export const COOKING_TIMES = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60] as const;
export type CookingTime = typeof COOKING_TIMES[number];

export type CalculationMethod =
  'automatic-automatic'
  | 'automatic-fixed'
  | 'manual-automatic'
  | 'manual-fixed'
  | 'manual-manual';

export const mapAcceptanceMethodToCookingTime = (acceptanceMethod: AcceptanceMethod): CookingTime | null=>
  acceptanceMethod.cookingTimeCalculation.type === 'fixed'
    ? acceptanceMethod.cookingTimeCalculation.minutes
    : null;

export const mapAcceptanceMethodToCalculationMethod = (acceptanceMethod: AcceptanceMethod): CalculationMethod =>
  [
    acceptanceMethod.automated ? 'automatic' : 'manual',
    '-',
    acceptanceMethod.cookingTimeCalculation.type
  ].join('') as CalculationMethod;

export const buildAcceptanceMethod = (
  cookingTime: CookingTime,
  calculationMethod: CalculationMethod
): AcceptanceMethod => {
  const [automatedType, cookingTimeCalculationType] = calculationMethod?.split('-');
  const automated = automatedType === 'automatic';
  const cookingTimeCalculation = (() => {
    switch (cookingTimeCalculationType) {
      case 'fixed': return { type: 'fixed' as const, minutes: cookingTime };
      default: return { type: cookingTimeCalculationType as 'automatic' | 'manual' };
    }
  })() ;

  return { automated, cookingTimeCalculation };
};

export const localizedAcceptanceMethod = (acceptanceMethod: AcceptanceMethod): string => {
  const cookingTime = mapAcceptanceMethodToCookingTime(acceptanceMethod);
  const calculationMethod = mapAcceptanceMethodToCalculationMethod(acceptanceMethod);

  return `${localizedCalculationMethod(calculationMethod)}${cookingTime ? `(${cookingTime}分)` : ''}`;
};

export const localizedCalculationMethod = (calculationMethod: CalculationMethod): string => {
  switch (calculationMethod) {
    case 'automatic-automatic': return 'オート受注・アルゴリズムで時間指定';
    case 'automatic-fixed': return 'オート受注・時間指定';
    case 'manual-automatic': return '手動受注・アルゴリズムで時間指定';
    case 'manual-fixed': return '手動受注・時間指定';
    case 'manual-manual': return '手動受注・注文毎に時間指定';
  }
};

export const calculationMethodsFor = (integration: Integration | undefined): CalculationMethod[] => {
  if (!integration) return [];
  const { autoAccept, manualAccept, acceptWithCookingTime, acceptWithoutCookingTime } = integration.features.orders;

  return [
    autoAccept.supported && acceptWithoutCookingTime.supported ? 'automatic-automatic' as const : undefined,
    autoAccept.supported && acceptWithCookingTime.supported ? 'automatic-fixed' as const : undefined,
    manualAccept.supported && acceptWithoutCookingTime.supported ? 'manual-automatic' : undefined,
    manualAccept.supported && acceptWithCookingTime.supported ? 'manual-fixed' : undefined,
    manualAccept.supported && acceptWithCookingTime.supported ? 'manual-manual' as const : undefined
  ].filter((value): value is CalculationMethod => !!value);
};
