import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { getDeliveryMethodChangeablePlatforms, Platform } from '../../../domain';
import { AppText, WithScreen } from '../../components';
import { Route } from '../../constants';
import { useAppContext } from '../../hooks';
import { DeliverySettingCardItemView } from './DeliverySettingCardItemView';

export const SettingsDeliveryView = (): React.ReactElement => {
  const { brands, platforms, integrations } = useAppContext();
  const navigation = useNavigation<NavigationProp<Route>>();

  const platformsByBrand = useMemo(
    () => {
      const supportedPlatforms = getDeliveryMethodChangeablePlatforms(platforms ?? [], integrations ?? []);
      return brands
        ?.map(brand => ({ brand, platforms: supportedPlatforms.filter(platform => platform.brandId === brand.id) }))
        .filter(({ platforms }) => platforms.length > 0)
      ?? [];
    },
    [platforms, integrations, brands]
  );

  const onPressChange = useCallback((platform: Platform) => {
    navigation.navigate('DeliverySetting', { platform });
  }, [navigation]);

  return <WithScreen loading={false} error={undefined}>
    <ScrollView style={styles.view} contentContainerStyle={styles.container}>
      {platformsByBrand.length > 0
        ? platformsByBrand.map(({ brand, platforms }) =>
          <DeliverySettingCardItemView
            {...{ brand, platforms, onPressChange }}
            key={`delivery-setting-card-${brand.id}`} />
        )
        : <AppText style={styles.unavailableText}>変更可能なプラットフォームがありません。</AppText>
      }
    </ScrollView>
  </WithScreen>;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  container: {
    paddingVertical: 32,
    paddingLeft: 32,
    paddingRight: 56
  },
  unavailableText: {
    fontSize: 18,
    marginStart: 'auto',
    marginEnd: 'auto',
    marginTop: 48,
  },
});
