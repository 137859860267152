import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Item } from '../../../../domain';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';

interface Props {
  item: Item;
  extraView?: React.ReactNode;
}

export const ItemListItem = (props: Props): React.ReactElement => {
  const soldOut = props.item.soldOutStatus !== 'sale';

  return <View style={[styles.view, soldOut && styles.viewSold]}>
    <View style={styles.innerView}>
      <AppText numberOfLines={1} style={styles.title}>{props.item.title}</AppText>
      {props.extraView ? (
        <View>
          <View style={styles.extraView}>{props.extraView}</View>
        </View>
      ) : null}
    </View>
  </View>;
};

const styles = StyleSheet.create({
  view: {
    height: 88,
    paddingHorizontal: 32,
    backgroundColor: Color.white,
  },
  innerView: {
    height: 88,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: Color.gray10,
  },
  viewSold: {
    backgroundColor: Color.red5,
  },
  title: {
    flex: 1,
    fontSize: 18,
    lineHeight: 92,
  },
  extraView: {
    marginLeft: 'auto',
    marginRight: 0,
    zIndex: 1,
  }
});
