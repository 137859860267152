import { hideAsync } from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Environment } from '../../Environment';
import { logger } from '../../infrastructure';
import { AppText, Input, PrimaryButton, WithScreen } from '../components';
import { Color } from '../constants';
import { useAuth } from '../hooks';

type FormData = {
  id: string;
  password: string;
};

const defaultValue = Environment.runningMode === 'debug' && Environment.projectEnvironment === 'development'
  ? { id: 'store_Epsfw', password: 'N7cH3Nkz' }
  : { id: null, password: null };

export const LoginScreen = (): React.ReactElement => {
  const { login, loading, error } = useAuth();
  const { control, handleSubmit, formState } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

  const renderIdInput = useCallback(({ onChange, value }: ControllerRenderProps) =>
    <View style={styles.input}>
      <AppText style={styles.inputTitle}>ID</AppText>
      <Input
        theme='dark'
        value={value ?? ''}
        placeholder="store_xxxx"
        secureTextEntry={false}
        autoCapitalize={'none'}
        onChange={onChange}/>
    </View>
  , []);

  const renderPasswordInput = useCallback(({ onChange, value }: ControllerRenderProps) =>
    <View style={styles.input}>
      <AppText style={styles.inputTitle}>パスワード</AppText>
      <Input theme='dark' value={value ?? ''} placeholder="半角英数字" secureTextEntry={true} onChange={onChange}/>
    </View>
  , []);

  const onSubmit = useCallback((data: FormData) =>
    login(`${data.id}@orderly.jp`, data.password)
  , [login]);

  const onLayout = useCallback(() => {
    hideAsync()
      .catch(error => { logger.info('error', error.message); });
  }, []);

  return <WithScreen loading={loading} error={error}>
    <View style={styles.view} onLayout={onLayout}>
      <KeyboardAwareScrollView style={styles.scrollView}>
        <StatusBar style='light' />
        <View style={styles.container}>
          <Image source={require('../assets/image/logo_white.png')} style={styles.logo} resizeMode="contain" />
          <Controller
            control={control}
            name="id"
            rules={{ required: true }}
            defaultValue={defaultValue.id}
            render={renderIdInput} />
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            defaultValue={defaultValue.password}
            render={renderPasswordInput} />
          <View style={styles.loginButton}>
            <PrimaryButton
              disabled={Environment.projectEnvironment !== 'development' && (!formState.isValid || !formState.isDirty)}
              size='large'
              title="ログインする"
              onPress={handleSubmit(onSubmit)} />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <AppText style={styles.version}>
        Orderly バージョン {Environment.platform.build?.revision ?? Environment.platform.build?.version ?? ''}
      </AppText>
    </View>
  </WithScreen>;
};

const styles = StyleSheet.create({
  view: {
    width: '100%',
    height: '100%',
  },
  scrollView: {
    flex: 1,
    width: '100%',
    backgroundColor: Color.gray100,
  },
  container: {
    height: Dimensions.get('window').height,
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    marginVertical: 32,
    marginHorizontal: 16,
  },
  logo: {
    width: 174,
    height: 40,
    marginBottom: 60,
  },
  input: {
    marginBottom: 44,
  },
  inputTitle: {
    color: Color.white,
    fontSize: 16,
    marginBottom: 12,
  },
  loginButton: {
    marginTop: 16,
  },
  version: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    fontSize: 14,
    color: Color.gray50,
  }
});
