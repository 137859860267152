import { StackScreenProps } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { AppText, NavigationBar, PrimaryButton, Radio, WithModal } from '../components';
import { Color, Route } from '../constants';
import { useOrderOperation } from '../hooks';

enum Interval {
  MIN5 = 5,
  MIN10 = 10,
  MIN20 = 20,
  MIN30 = 30,
}

enum InHouseInterval {
  MIN25 = 25,
  MIN45 = 45,
  MIN65 = 65,
  MIN85 = 85,
}

const InhouseIntervals = [InHouseInterval.MIN25, InHouseInterval.MIN45, InHouseInterval.MIN65, InHouseInterval.MIN85];
const Intervals = [Interval.MIN5, Interval.MIN10, Interval.MIN20, Interval.MIN30];

export const DelayScreen = (
  { navigation, route }: StackScreenProps<Route, 'Delay'>
): React.ReactElement => {
  const { order, onComplete } = route.params;
  const [selectedInterval, setSelectedInterval] = useState<Interval | InHouseInterval>();
  const { call: callOrderOperation, loading: loadingOrderOperation, error: errorOrderOperation }  = useOrderOperation();

  const onPressBack = useCallback(() => {
    navigation.goBack();
    navigation.navigate('Support', { order, onComplete });
  }, [navigation, onComplete, order]);

  const onPressRadio = useCallback((interval: Interval | InHouseInterval) => {
    setSelectedInterval(interval);
  }, []);

  const onPressConfirm = useCallback(() => {
    if (!selectedInterval) return;

    callOrderOperation({
      operation: {
        type: 'DELAY',
        preparationTime: dayjs(order.preparationTime.toDate())
          .add(selectedInterval + 1, 'minute')
          .set('second', 0)
          .toDate()
          .getTime()
      },
      orderId: order.id,
    }).then(() => {
      navigation.goBack();
      onComplete?.();
    });
  }, [callOrderOperation, navigation, onComplete, order.id, order.preparationTime, selectedInterval]);

  const Header = <NavigationBar title="注文を遅らせる" onPressBack={onPressBack} isForMenu={true} />;
  const Body = <View style={styles.body}>
    <View style={styles.radioButtons}>
      <AppText style={styles.text}>
        ピックアップまでの時間を延長します。 一部のサービスでお客様に通知されます。{' '}
      </AppText>
      <View style={{ width: 424 }}>
        {(order.inHouseDelivery ? InhouseIntervals : Intervals).map(interval =>
          <IntervalItem
            key={`DelayScreen-IntervalItem-${interval}`}
            {...{ interval, selected: interval === selectedInterval, onPressRadio }} />
        )}
      </View>
    </View>
    <View style={styles.confirmButtonArea}>
      <PrimaryButton size='large' title="実行する" onPress={onPressConfirm} disabled={!selectedInterval} />
    </View>
  </View>;

  return <WithModal {...{ Header, Body, loading: loadingOrderOperation, error: errorOrderOperation }} />;
};

interface IntervalItemProps {
  interval: number
  selected: boolean

  onPressRadio: (interval: number) => void
}

const IntervalItem = ({ interval, selected, onPressRadio }: IntervalItemProps) => {
  const onPress = useCallback(() => onPressRadio(interval), [interval, onPressRadio]);

  return <Radio {...{ text: `${interval}分延長`, selected, size: 'large', outline: true, onPress, }} />;
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 56,
    paddingVertical: 40,
  },
  radioButtons: {
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    color: Color.gray100,
    marginBottom: 40,
  },
  confirmButtonArea: {
    marginTop: 88,
    marginBottom: 16
  }
});
