import React, { useCallback } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import {
  Brand,
  BusinessDayTime,
  OrderPlatformSuspension,
  Platform,
  platformTypeSortPriority
} from '../../../../domain';
import { Color } from '../../../constants';
import { useAppContext } from '../../../hooks';
import { AppText } from '../../AppText';
import { OutlineButton } from '../../buttons/OutlineButton';
import { OrderPlatformSuspensionListItem } from './OrderPlatformSuspensionListitem';

interface OrderPlatformSuspensionListProps {
  brand: Brand | undefined
  businessDayTimes: BusinessDayTime[] | undefined
  orderPlatformSuspensions: OrderPlatformSuspension[] | undefined

  onPressBulkUpdate: (brandId: string) => void;
  onPressCreateOrderPlatformSuspension: (platform: Platform) => void;
  onPressDeleteOrderPlatformSuspension: (platform: Platform) => void;
}


export const OrderPlatformSuspensionList = (props: OrderPlatformSuspensionListProps): React.ReactElement => {
  const {
    brand,
    businessDayTimes,
    orderPlatformSuspensions,
    onPressBulkUpdate,
    onPressCreateOrderPlatformSuspension,
    onPressDeleteOrderPlatformSuspension
  } = props;

  const { platforms } = useAppContext();

  const latestOrderPlatformSuspension = useCallback((platform: Platform) => orderPlatformSuspensions
    ?.filter(suspension => suspension.brandId === platform.brandId)
    .filter(suspension => suspension.platformType === platform.type)
    ?.sort((a, b) => b.createTime.toMillis() - a.createTime.toMillis())[0]
    ?? null, [orderPlatformSuspensions]);

  const onPressButton = useCallback(() => onPressBulkUpdate(brand?.id ?? ''), [brand?.id, onPressBulkUpdate]);

  return <ScrollView>
    {
      !brand || platforms?.length === 0
        ? <AppText>プラットフォームがありません</AppText>
        : <>
          <View style={styles.itemView}>
            <AppText style={styles.itemText}>{brand?.name}</AppText>
            <OutlineButton title='一括変更' size='medium' onPress={onPressButton}/>
          </View>
          {platforms
            ?.filter((platform) => platform.brandId === brand?.id)
            .sort((a, b) => platformTypeSortPriority(a.type) - platformTypeSortPriority(b.type))
            .map(platform => (
              <OrderPlatformSuspensionListItem
                key={`order-platform-suspension-list-item-${platform.brandId}-${platform.type}`}
                {...{
                  platform,
                  orderPlatformSuspension: latestOrderPlatformSuspension(platform),
                  businessDayTimes,
                  onPressCreateOrderPlatformSuspension,
                  onPressDeleteOrderPlatformSuspension,
                }}/>
            ))}
        </>
    }
  </ScrollView>;
};

const styles = StyleSheet.create({
  itemView: {
    alignItems: 'center',
    backgroundColor: Color.white,
    borderColor: Color.gray10,
    borderWidth: 1,
    borderRadius: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 32,
    marginBottom: 16,
    height: 104
  },
  itemText: {
    color: Color.gray100,
    fontSize: 16,
  }
});
