import { useCallback } from 'react';
import { Order, OrderReference } from '../../../domain';
import { useDocumentUpdate } from '../core/useDocumentUpdate';

type Response = {
  update: <T extends Partial<Order> & OrderReference>(order: T) => Promise<void>
  loading: boolean
  error: Error | undefined
};

export const useOrderUpdate = (): Response => {
  const { update: _update, loading, error } = useDocumentUpdate();

  const update = useCallback(async <T extends Partial<Order> & OrderReference>(order: T) => {
    const { id, accountId, storeId, brandId, ...doc } = { ...order };
    await _update(`accounts/${accountId}/stores/${storeId}/brands/${brandId}/orders/${id}`, doc);
  }, [_update]);

  return { update, loading, error };
};
