import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Block, Inline } from '@contentful/rich-text-types';
import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { NewsItem } from '../../../../domain';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { Clickable } from '../../Clickable';

interface Props {
  newsItem: NewsItem;

  onPressNewsItem: (newsItem: NewsItem) => void
}

export const NewsListItem = ({ newsItem, onPressNewsItem }: Props): React.ReactElement => {
  const timeToString = (time: number) => {
    const date = new Date(time);
    return `${date.getMonth() + 1}月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}`;
  };

  const onPress = useCallback(() => {
    onPressNewsItem(newsItem);
  }, [newsItem, onPressNewsItem]);

  return <Clickable name="news_tap" id={newsItem.id} onPress={onPress}>
    <View style={[styles.view, newsItem.isRead && styles.viewRead]}>
      <View style={styles.titleBody}>
        <View style={styles.title}>
          <AppText style={styles.titleText}>{newsItem.title}</AppText>
          {newsItem.isNew ? (<View style={styles.badge}><AppText style={styles.badgeText}>New</AppText></View>) : null}
        </View>
        <View style={styles.body}>
          <AppText style={styles.bodyText} numberOfLines={1}>{
            documentToPlainTextString(newsItem.body as Block | Inline).replace(/(\r\n|\n|\r)/gm, '')
          }</AppText>
        </View>
      </View>
      <View style={styles.date}>
        <AppText style={styles.dateText}>{timeToString(newsItem.publishedAt)}</AppText>
      </View>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    flex: 1,
    padding: 32,
    backgroundColor: Color.gray5,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
  },
  viewRead: {
    backgroundColor: Color.white,
  },
  titleBody: {
    flex: 1,
  },
  date: {
    marginStart: 16,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    flexShrink: 1,
    color: Color.gray100,
    fontSize: 18,
  },
  badge: {
    alignSelf: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 6,
    backgroundColor: Color.brand,
    borderRadius: 4,
    marginHorizontal: 8,
  },
  badgeText: {
    fontSize: 12,
    color: Color.white,
  },
  body: {
    flex: 1,
    marginTop: 16,
  },
  bodyText: {
    color: Color.gray50,
    fontSize: 14,
  },
  dateText: {
    color: Color.gray50,
    fontSize: 14,
  },
});
