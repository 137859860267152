import dayjs, { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export const stringToDayjs = (dateString: string): Dayjs => dayjs(dateString);

export const unixTimestampToDayjs = (unixTimestamp: number): Dayjs => dayjs.unix(unixTimestamp);
export const dateToDayjs = (date: Date): Dayjs => dayjs(date);

export const now = (): Dayjs => dayjs();

export const startOfHour = (dayjs: Dayjs): Dayjs => dayjs.startOf('hour');
export const endOfHour = (dayjs: Dayjs): Dayjs => dayjs.endOf('hour');
export const startOfDay = (dayjs: Dayjs): Dayjs => dayjs.startOf('day');
export const endOfDay = (dayjs: Dayjs): Dayjs => dayjs.endOf('day');
export const startOfWeek = (dayjs: Dayjs): Dayjs => dayjs.startOf('week');
export const endDayOfWeek = (dayjs: Dayjs): Dayjs => dayjs.endOf('week').startOf('day');
export const startOfMonth = (dayjs: Dayjs): Dayjs => dayjs.startOf('month');
export const endDayOfMonth = (dayjs: Dayjs): Dayjs => dayjs.endOf('month').startOf('day');

export const startOfToday = (): Dayjs => startOfDay(dayjs());
export const endOfToday = (): Dayjs => endOfDay(dayjs());
export const startOfThisHour = (): Dayjs => startOfHour(dayjs());

export const formatTimestamp = (timestamp: Timestamp, format: string): string =>
  dayjs(timestamp.toDate()).format(format);

export const setHourAndMinute = (date: Dayjs, timeString: string): Dayjs => {
  const [hour, minute] = timeString?.split(':') ?? [];

  if (!hour || !minute) throw new Error(`'${timeString}' can't be parsed`);

  return date.set('hour', +hour).set('minute', +minute).set('second', 0).set('millisecond', 0);
};
