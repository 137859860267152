import { StyleSheet } from 'react-native';
import { Color } from '../../constants';

export const styles = StyleSheet.create({
  screen: {
    flex: 1,
    marginTop: 24,
    backgroundColor: Color.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  header: {
    flexDirection: 'row',
    minHeight: 64,
    borderBottomColor: Color.gray10,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    width: 100,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
  },
  headerPrevious: {
    width: 64,
    height: 64,
    marginRight: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerNext: {
    width: 64,
    height: 64,
    marginLeft: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageButton: {
    width: 16,
    height: 16,
  },
  pageButtonBackground: {
    width: 32,
    height: 32,
    borderRadius: 6,
    backgroundColor: Color.gray5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    right: 0,
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  indicatorWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: Color.white,
  },
  indicator: {
    top: '50%',
  },
});
