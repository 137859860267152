import React, { useMemo } from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface Props {
  title: string;
  size: 'large' | 'medium'
  disabled?: boolean;
  icon?: ImageSourcePropType;
  onPress: () => void;
}

export const SecondaryButton = (props: Props): React.ReactElement => {
  const styles = useMemo(() => props.size === 'large'? large : medium, [props.size]);

  return <Clickable name="button_tap" id={props.title} onPress={props.onPress} disabled={props.disabled}>
    <View style={styles.view}>
      {props.icon ? (<Image source={props.icon} style={styles.icon} resizeMode="contain" />) : (<></>)}
      <AppText style={styles.title}>{props.title}</AppText>
    </View>
  </Clickable>;
};

const large = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 48,
    borderRadius: 4,
    backgroundColor: Color.light,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  icon: {
    width: 17,
    height: 17,
    marginRight: 6,
  },
  title: {
    fontSize: 14,
    color: Color.gray70,
  },
});

const medium = StyleSheet.create({
  view: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 48,
    borderRadius: 4,
    backgroundColor: Color.light,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  icon: {
    width: 17,
    height: 17,
    marginRight: 6,
  },
  title: {
    fontSize: 14,
    color: Color.gray70,
  },
});
