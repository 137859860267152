import { pickObject } from '../../utils';
import { Address } from './Address';
import { HasId } from './core/HasId';
import { HasTimestamp } from './core/HasTimestamp';
import { DeliveryPlatformRelation } from './DeliveryPlatform';
import { Interaction, OrderRelation } from './Order';

export type DeliveryReference = OrderRelation & DeliveryPlatformRelation & HasId;
export type DeliveryRelation = OrderRelation & DeliveryPlatformRelation & { deliveryId: DeliveryReference['id'] };

type DeliveryCommon<T extends CourierType> = DeliveryReference & HasTimestamp & {
  id: string
  externalId: string | null

  comment: string | null
  courier: Courier | null
  courierType: T
  customer: Customer

  status: DeliveryStatus
};

export type DeaaSDelivery = DeliveryCommon<'deaas'> & { customerAddress: Address, interaction: Interaction }
export type InhouseDelivery = DeliveryCommon<'inhouse'> & { customerAddress: Address, interaction: Interaction }
export type PlatformDelivery = DeliveryCommon<'platform'> & { customerAddress: null, interaction: null }

export type Delivery =
  | DeaaSDelivery
  | InhouseDelivery
  | PlatformDelivery

export type DeliveryWithoutLocalizedIds = Omit<Delivery, keyof DeliveryReference>
export type DeliveryWithoutReference = Omit<Delivery, keyof HasId>;

export type DeliveryFailedReason =
  | 'delivery_platform_unavailable'
  | 'out_of_service_area'
  | 'too_tight_schedule'
  | 'invalid_address'
  | 'unknown';

export const DeliveryStatuses = [
  'created',
  'assigned',
  'picked',
  'delivered',
  'canceled',
  'failed'
] as const;

export type DeliveryStatus = typeof DeliveryStatuses[number];

type Customer = {
  name: string
  email: string | null
  phoneNumber: string | null
  avatarUrl: string | null
}

type Courier = {
  name: string
  email: string | null
  phoneNumber: string | null
  avatarUrl: string | null
}

export type CourierType = 'inhouse' | 'deaas' | 'platform'

export const deliveryToRelation = (delivery: Delivery): DeliveryRelation => ({
  ...pickObject(delivery, ['accountId', 'storeId', 'brandId', 'platform', 'orderId', 'deliveryPlatformId']),
  deliveryId: delivery.id
});
