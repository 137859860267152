import { dispose, Halftones, Model, Models, printImage } from '@orderlyjp/react-native-epson-epos';
import { Printer } from './';

export class EposPrinter implements Printer {
  readonly identifier: string;
  readonly maker = 'EPSON';
  readonly modelName: string;
  readonly supportedImageWidth: number;
  readonly shouldPrintUpSideDown = false;
  readonly shouldSelectPrinterModelManually = true;

  private readonly printerSeries: Model;

  static load(dictionary: { [key: string]: unknown }): Printer | null {
    if (!dictionary) return null;
    if (dictionary.device_type !== 'epson_epos') return null;

    const { target, deviceName, printerSeries } = dictionary;

    if (!target || typeof target !== 'string') return null;
    if (!deviceName || typeof deviceName !== 'string') return null;

    return new EposPrinter(target, deviceName, (printerSeries ?? Models.TM_T88) as Model);
  }

  constructor(target: string, deviceName: string | null, printerSeries: Model) {
    this.identifier = target;
    this.modelName = deviceName ?? 'Epson ePOS Printer';
    this.printerSeries = printerSeries;
    this.supportedImageWidth = supportedImageWidth(printerSeries);
  }

  print(image: string): Promise<void> {
    return printImage(
      this.identifier,
      this.printerSeries,
      image,
      undefined,
      { halftone: Halftones.THRESHOLD, brightness: 0.1 }
    );
  }

  dispose(): Promise<void> {
    return dispose(this.identifier) ?? Promise.resolve();
  }

  toJSON(): { [key: string]: unknown } {
    return {
      device_type: 'epson_epos',
      target: this.identifier,
      deviceName: this.modelName,
      printerSeries: this.printerSeries,
    };
  }

  isEqual(other: unknown): boolean {
    return other instanceof EposPrinter && this.identifier === other.identifier;
  }

  copy(printerSeries: Model): EposPrinter {
    return new EposPrinter(this.identifier, this.modelName, printerSeries);
  }
}

const supportedImageWidth = (printerSeries: Model): number => ({
  [Models.TM_T60]: 257,
  [Models.TM_T81]: 257,
  [Models.TM_T82]: 257,
  [Models.TM_T83]: 257,
  [Models.TM_U330]: 257,
  [Models.TM_U220]: 257,
  [Models.TM_T83III]: 257,
  [Models.TM_T100]: 257,
  [Models.TS_100]: 257,
  [Models.TM_M50]: 257,
  [Models.TM_P20]: 384,
  [Models.TM_M10]: 420,
  [Models.TM_P60]: 432,
  [Models.TM_P60II]: 432,
  [Models.TM_T88]: 512,
  [Models.TM_H6000]: 512,
  [Models.TM_M30]: 576,
  [Models.TM_P80]: 576,
  [Models.TM_T20]: 576,
  [Models.TM_T70]: 576,
  [Models.TM_T90]: 576,
  [Models.TM_T90KP]: 576,
  [Models.TM_L90]: 576,
  [Models.TM_M30II]: 576,
})[printerSeries] ?? 257;
