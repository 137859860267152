import React, { useCallback, useState } from 'react';
import { Dimensions, Image, ScrollView, StyleSheet, View } from 'react-native';
import { Brand } from '../../../domain';
import { Color } from '../../constants';
import { AppText } from '../AppText';
import { Clickable } from '../Clickable';

interface BrandDropdownProps {
  brands: Brand[] | undefined;
  selectedBrand?: Brand;
  onPressBrand: (brand?: Brand) => void;
}

export const BrandDropdown = ({ brands, selectedBrand, onPressBrand }: BrandDropdownProps): React.ReactElement => {
  const [visible, setVisible] = useState(false);

  const onPress = useCallback(() => {
    setVisible(visible => !visible);
  }, []);

  const onDeselect = useCallback(() => {
    onPressBrand(undefined);
    setVisible(false);
  }, [onPressBrand]);

  const onSelect = useCallback((brand: Brand) => {
    onPressBrand(brand);
    setVisible(false);
  }, [onPressBrand]);

  return <>
    <Clickable name="brand_dropdown_open" onPress={onPress}>
      <View style={styles.view}>
        {selectedBrand
          ? <AppText style={styles.title} numberOfLines={1}>{selectedBrand.name}</AppText>
          : <AppText style={styles.title}>すべてのブランド</AppText>
        }
        <View style={styles.iconView}>
          <Image
            source={visible ? require('../../assets/icon/cursor_up.png') : require('../../assets/icon/cursor_down.png')}
            style={styles.icon}
            resizeMode="contain"/>
        </View>
      </View>
    </Clickable>
    {visible
      ? <View style={selectionStyles.view}>
        <ScrollView contentContainerStyle={selectionStyles.scrollView}>
          <Clickable
            name="brand_dropdown_select"
            id='all'
            useGestureHandlerTouchableOpacity={true}
            style={selectionStyles.list}
            onPress={onDeselect}>
            <View>
              <AppText style={selectionStyles.title} numberOfLines={1}>すべてのブランド</AppText>
            </View>
          </Clickable>
          {brands?.map(brand => <BrandItem {...{ brand, onSelect }} key={`BrandDropwdown-BrandItem-${brand.id}`} />)}
        </ScrollView>
      </View>
      : <></>}
  </>;
};

type BrandItemProps = {
  brand: Brand
  onSelect: (brand: Brand) => void
}

const BrandItem = ({ brand, onSelect }: BrandItemProps) => {
  const onPress = useCallback(() => onSelect(brand), [brand, onSelect]);

  return <Clickable
    name="brand_dropdown_select"
    id={brand.id}
    useGestureHandlerTouchableOpacity={true}
    onPress={onPress}
    style={selectionStyles.list}>
    <View>
      <AppText style={selectionStyles.title} numberOfLines={1}>{brand.name}</AppText>
    </View>
  </Clickable>;
};

const styles = StyleSheet.create({
  view: {
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 6,
    borderColor: Color.gray10,
    borderWidth: 2,
    overflow: 'visible',
  },
  title: {
    maxWidth: 400,
    fontSize: 16,
    color: Color.gray100,
    marginLeft: 6,
  },
  iconView: {
    width: 40,
    height: 40,
    borderRadius: 6,
    backgroundColor: Color.gray5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 16,
    height: 16,
  },
});

const selectionStyles = StyleSheet.create({
  view: {
    width: 380,
    maxHeight: Dimensions.get('window').height - 120,
    position: 'absolute',
    top: 60,
    right: 0,
    borderRadius: 6,
    backgroundColor: Color.white,
    shadowColor: Color.gray100,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 16,
    shadowOpacity: 0.2,
    zIndex: 100
  },
  scrollView: {
    paddingVertical: 8,
  },
  list: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 16,
    color: Color.gray100,
  },
});
