import React, { useCallback } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { Brand, Platform, PlatformType } from '../../../../domain';
import { AcceptanceMethodSettingListItem } from './AcceptanceMethodSettingListItem';

interface Props {
  data: Item[]
  onPress: (brandId: string, platformType: PlatformType) => void
}

type Item = { brand: Brand, platforms: Platform[] };

export const AcceptanceMethodSettingList = ({ data, onPress }: Props): React.ReactElement => {
  const renderItem = useCallback(({ item }: { item: Item }) =>
    <AcceptanceMethodSettingListItem {...{ item, onPress }} />
  , [onPress]);
  const keyExtractor = useCallback(({ brand }: Item) => `acceptance-method-setting-list-item-${brand.id}`, []);

  return <FlatList{...{ data, renderItem, keyExtractor }} contentContainerStyle={styles.container} />;
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 32,
    paddingLeft: 32,
    paddingRight: 56
  },
});
