import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Delivery, DeliveryEstimation, Order } from '../../../../domain';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { If } from '../../If';
import { CookView } from './CookView';
import { FulfillmentView } from './FulfillmentView';

interface Props {
  order: Order
  delivery: Delivery | undefined
  deliveryEstimation: DeliveryEstimation | undefined
  size: 'small' | 'medium'
}

export const FulfillmentAndCookView = ({ order, delivery, deliveryEstimation, size }: Props): React.ReactElement =>
  <If condition={shouldDisplay(order)}>
    <View style={styles.view}>
      <FulfillmentView {...{ order, delivery, deliveryEstimation, size }} />
      <If condition={shouldDisplayCookView(order)}>
        <AppText style={{ ...styles.dotText, fontSize: size === 'small' ? 12 : 14 }}>・</AppText>
        <CookView {...{ order, size }} />
      </If>
    </View>
  </If>;

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  dotText: {
    color: Color.gray100,
    fontWeight: '500',
  },
});

const shouldDisplay = ({ status }: Order) =>
  ['CREATED', 'ACCEPTED', 'READY'].includes(status);

const shouldDisplayCookView = ({ status }: Order) =>
  ['ACCEPTED', 'READY'].includes(status);
