import React, { ReactElement, useCallback, useMemo } from 'react';
import { FlatList, Image, StyleSheet, View } from 'react-native';
import { Environment } from '../../../../Environment';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { MenuListItem } from './MenuListItem';

export enum Menu {
  SOLD_OUT = '商品の売り切れ設定',
  BUSINESS_DAY_TIME = '営業時間管理',
  SALES = '売り上げ',
  HISTORY = '注文履歴',
  SETTINGS = '設定',
  NEWS = '運営からのお知らせ',
  HELP = 'サポートに連絡する',
  LOGOUT = 'ログアウト',
}

interface Props {
  soldOutCount?: number;
  newsCount?: number;
  onPress: (menu: Menu) => void;
}

type Item = { menu: Menu, showDisclosureIndicator?: boolean, badge?: ReactElement, danger?: boolean };

export const MenuList = ({ soldOutCount, newsCount, onPress }: Props): React.ReactElement => {
  const data = useMemo(() => [
    {
      menu: Menu.SOLD_OUT,
      badge: soldOutCount && soldOutCount > 0
        ? <View style={styles.soldOutBadge}>
          <Image style={styles.soldOutBadgeImage} source={require('../../../assets/icon/sold_out.png')} />
        </View>
        : undefined,
      showDisclosureIndicator: true,
    },
    { menu: Menu.BUSINESS_DAY_TIME, showDisclosureIndicator: true },
    { menu: Menu.SALES, showDisclosureIndicator: true },
    { menu: Menu.HISTORY, showDisclosureIndicator: true },
    { menu: Menu.SETTINGS, showDisclosureIndicator: true },
    {
      menu: Menu.NEWS,
      badge: newsCount && newsCount > 0
        ? (<View style={styles.newsBadge}><AppText style={styles.newsBadgeText}>N</AppText></View>)
        : undefined,
      showDisclosureIndicator: true,
    },
    { menu: Menu.HELP },
    { menu: Menu.LOGOUT, danger: true },
  ]
  , [newsCount, soldOutCount]);

  const renderItem = useCallback(({ item }: { item: Item }) =>
    <MenuListItem {...{ ...item, onPressMenu: onPress }} />
  , [onPress]);

  const renderFooter = useCallback(() =>
    <AppText style={styles.version}>
      Orderly バージョン {Environment.platform.build?.revision ?? Environment.platform.build?.version ?? ''}
    </AppText>
  , []);

  const keyExtractor = useCallback(({ menu }: Item) => `MenuList-MenuListItem-${menu}`, []);

  return <FlatList
    data={data}
    renderItem={renderItem}
    ListFooterComponent={renderFooter}
    keyExtractor={keyExtractor}
    style={styles.view} />;
};

const styles = StyleSheet.create({
  view: {
    backgroundColor: Color.gray5,
  },
  version: {
    fontSize: 14,
    color: Color.gray50,
    paddingVertical: 32,
    paddingHorizontal: 32,
  },
  soldOutBadge: {
    backgroundColor: Color.red,
    borderRadius: 4,
  },
  soldOutBadgeImage: {
    width: 24,
    height: 24,
  },
  newsBadge: {
    alignSelf: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 8,
    backgroundColor: Color.brand,
    borderRadius: 4,
  },
  newsBadgeText: {
    fontSize: 12,
    color: Color.white,
  },
});
