import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { CanceledReason, Order, StatusReason } from '../../../../../domain';
import { Color } from '../../../../constants';
import { PlatformTypeDecorator } from '../../../../decorators';
import { useAppContext } from '../../../../hooks';
import { Formatter } from '../../../../utils';
import { AppText } from '../../../AppText';
import { If } from '../../../If';

type Props = { order: Order };

export const CancelReasonView = ({ order }: Props): React.ReactElement => {
  const { integrations } = useAppContext();

  const canceledBy = (canceledReason: CanceledReason) => ({
    customer_canceled: '注文者',
    store_canceled: '店舗',
    driver_canceled: '配達員',
    platform_canceled: new PlatformTypeDecorator(order.platform, integrations).name(),
    unknown: '不明'
  })[canceledReason];

  const canceledReasonIconPath = ({
    customer_canceled: require('../../../../assets/icon/avatar_red.png'),
    store_canceled: require('../../../../assets/icon/shop_red.png'),
    driver_canceled: require('../../../../assets/icon/delivery_red.png'),
    platform_canceled: undefined,
    unknown: undefined
  })[order.statusReason ?? 'unknown'];

  const localizedOrderCanceledReason = (rawCanceledReason: string) => integrations
    ?.find(integration => integration.platformType === order.platform)?.meta.order.status.canceled.reasons
    .find(reason => reason.raw === rawCanceledReason)?.localized ?? '不明';

  return <View style={styles.reasonForOrderCancellationView}>
    <AppText style={styles.title}>キャンセル</AppText>
    <View style={styles.detailsOfCancelation}>
      <If condition={order.statusReason}>
        <View>
          <View style={styles.canceledByView}>
            <AppText style={styles.reasonText}>実行者：</AppText>
            <If condition={canceledReasonIconPath}>
              <Image source={canceledReasonIconPath} style={styles.icon} resizeMode="contain" />
            </If>
            <AppText style={styles.canceledByText}>{canceledBy(order.statusReason as StatusReason)}</AppText>
          </View>
        </View>
      </If>
      <If condition={order.rawStatusReason}>
        <AppText style={styles.reasonText}>理由：{order.rawStatusReason?.split(' ').map(localizedOrderCanceledReason).join('')}</AppText>
      </If>
    </View>
    <View style={styles.timeStamp}>
      <AppText style={styles.time}>
        {Formatter.time(order.statuses?.slice(-1)[0]?.updatedAt.toDate() ?? new Date())}
      </AppText>
      <AppText style={styles.day}>
        {Formatter.day(order.statuses?.slice(-1)[0]?.updatedAt.toDate() ?? new Date())}
      </AppText>
    </View>
  </View>;
};

const styles = StyleSheet.create({
  reasonForOrderCancellationView: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 4
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    color: Color.red500
  },
  detailsOfCancelation: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 8,
    marginTop: 11,
    marginBottom: 10,
    backgroundColor: Color.red25,
  },
  reasonText: {
    color: Color.red500,
    fontSize: 12,
    fontWeight: '400'
  },
  canceledByView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2
  },
  canceledByText: {
    color: Color.red500,
    fontSize: 12,
    fontWeight: '500'
  },
  timeStamp: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  time: {
    fontSize: 14,
    fontWeight: '400'
  },
  day: {
    fontSize: 12,
    fontWeight: '400',
    marginLeft: 8
  }
});
