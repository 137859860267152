import React from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Color } from '../../constants';
import { Clickable } from '../Clickable';

interface Props {
  id: string;
  icon: ImageSourcePropType;
  onPress: () => void;
  children?: React.ReactNode;
}

export const IconButton = (props: Props): React.ReactElement =>
  <Clickable name="button_tap" id={props.id} onPress={props.onPress}>
    <View style={styles.view}>
      <Image source={props.icon} style={styles.icon} resizeMode="contain" />
      {props.children ? props.children : null}
    </View>
  </Clickable>;

const styles = StyleSheet.create({
  view: {
    width: 64,
    height: 64,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 18,
    borderRadius: 4,
    backgroundColor: Color.gray80,
  },
  icon: {
    width: 28,
    height: 28,
  },
});
