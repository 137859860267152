import React, { useCallback, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { PrinterSeries } from '../../../../domain';
import { Color } from '../../../constants';
import { PrimaryButton } from '../../buttons/PrimaryButton';
import { PrinterModelListItem } from './PrinterModelListItem';

interface Props {
  printerSerieses: PrinterSeries[];
  onPressConfirm: (printerSeries: PrinterSeries) => void;
}

export const PrinterModelList = ({ printerSerieses, onPressConfirm }: Props): React.ReactElement => {
  const [selected, setSelected] = useState<PrinterSeries | undefined>();

  const onPressButton = useCallback(() => {
    if (selected) onPressConfirm(selected);
  }, [onPressConfirm, selected]);

  return <View style={styles.view}>
    <ScrollView contentContainerStyle={styles.scrollView}>
      {printerSerieses.map(printerSeries =>
        <PrinterModelListItem
          key={`printerModelListItem-${printerSeries.type}-${printerSeries.nativeValue}`}
          printerSeries={printerSeries}
          selected={selected?.nativeValue === printerSeries.nativeValue}
          onPressPrinterSeries={setSelected}/>
      )}
    </ScrollView>
    <View style={styles.border}/>
    <View style={styles.button}>
      <PrimaryButton
        size='large'
        title="接続する"
        onPress={onPressButton}
        color={Color.brand}
        disabled={selected === undefined}/>
    </View>
  </View>;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: Color.white,
  },
  scrollView: {
    flexGrow: 1,
    paddingVertical: 30,
    paddingHorizontal: 40,
  },
  border: {
    width: '100%',
    height: 1,
    backgroundColor: Color.gray10,
  },
  button: {
    width: '100%',
    alignItems: 'center',
    padding: 32,
  },
});
