import React, { useCallback } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { PrinterSeries } from '../../../../domain';
import { Color } from '../../../constants';
import { AppText } from '../../AppText';
import { Clickable } from '../../Clickable';

interface Props {
  printerSeries: PrinterSeries
  selected: boolean;
  onPressPrinterSeries: (printerSeries: PrinterSeries) => void;
}

export const PrinterModelListItem = ({ printerSeries, selected, onPressPrinterSeries }: Props): React.ReactElement => {
  const onPress = useCallback(() => {
    onPressPrinterSeries(printerSeries);
  }, [onPressPrinterSeries, printerSeries]);

  return <View style={styles.view}>
    <Clickable name="printer_series_tap" onPress={onPress}>
      <View style={selected ? [styles.content, styles.contentSelected] : [styles.content]}>
        <Image
          source={
            selected
              ? require('../../../assets/button/radio_on.png')
              : require('../../../assets/button/radio_off.png')
          }
          style={styles.radio}
          resizeMode="contain"/>
        <AppText style={styles.modelName}>{printerSeries.name}</AppText>
      </View>
    </Clickable>
  </View>;
};

const styles = StyleSheet.create({
  view: {
    paddingVertical: 10,
  },
  content: {
    flexDirection: 'row',
    padding: 24,
    borderColor: Color.gray10,
    borderRadius: 4,
    borderWidth: 2,
    alignItems: 'center',
  },
  contentSelected: {
    borderColor: Color.gray100,
  },
  radio: {
    width: 24,
    height: 24,
  },
  modelName: {
    fontSize: 16,
    color: Color.gray100,
    marginStart: 18,
  },
});
