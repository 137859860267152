import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Order } from '../../../../domain';
import { Formatter } from '../../../utils';
import { AppText } from '../../AppText';
import { If } from '../../If';

type Props = {
  order: Order
};

export const SalesView = ({ order }: Props): React.ReactElement =>
  <>
    <View style={styles.salesView}>
      <View style={styles.item}>
        <AppText style={styles.title}>小計</AppText>
        <AppText style={styles.price}>{Formatter.price(order.price.subtotal)}</AppText>
      </View>
      {order.price.promotions?.map((val, i) => (
        <View style={styles.item} key={`promo-${i}`}>
          <AppText style={styles.title}>プロモーション</AppText>
          <AppText style={styles.price}>{Formatter.price(val)}</AppText>
        </View>
      ))}
      <View style={styles.item}>
        <AppText style={styles.title}>合計</AppText>
        <AppText style={styles.price}>{Formatter.price(order.price.total)}</AppText>
      </View>
      <If condition={order.price.delivery}>
        <View style={styles.item}>
          <AppText style={styles.title}>配達料金</AppText>
          <AppText style={styles.price}>{Formatter.price(order.price.delivery ?? 0)}</AppText>
        </View>
      </If>
      {order.price.deliveryPromotions?.map((val, i) => (
        <View style={styles.item} key={`delivery-promo-${i}`}>
          <AppText style={styles.title}>配達料金の値引き</AppText>
          <AppText style={styles.price}>{Formatter.price(val)}</AppText>
        </View>
      ))}
    </View>
    <View style={styles.border} />
  </>;

const styles = StyleSheet.create({
  salesView: {
    paddingVertical: 28,
  },
  item: {
    flexDirection: 'row',
    marginBottom: 12,
  },
  title: {
    fontSize: 34,
    fontWeight: '300',
    marginEnd: 'auto',
  },
  price: {
    fontSize: 34,
    fontWeight: '300',
  },
  border: {
    width: '100%',
    borderStyle: 'dashed',
    borderWidth: 2,
  },
});
