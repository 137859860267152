import React, { memo, useCallback, useMemo } from 'react';
import { Image, ImageURISource, StyleSheet, View } from 'react-native';
import { Brand, localizedAcceptanceMethod, Platform, PlatformType } from '../../../../domain';
import { Color } from '../../../constants';
import { PlatformTypeDecorator } from '../../../decorators';
import { useAppContext } from '../../../hooks';
import { equalProps } from '../../../utils';
import { AppText } from '../../AppText';
import { OutlineButton } from '../../buttons/OutlineButton';

interface ContainerProps {
  item: { brand: Brand, platforms: Platform[] }
  onPress: (brandId: string, platformType: PlatformType) => void
}

export const AcceptanceMethodSettingListItem = (props: ContainerProps): React.ReactElement => {
  const { item: { brand, platforms }, onPress } = props;
  const { integrations } = useAppContext();

  const items = useMemo(() =>
    platforms.map(({ type, acceptanceMethod }) => ({
      type,
      icon: new PlatformTypeDecorator(type, integrations).icon(),
      name: new PlatformTypeDecorator(type, integrations).name(),
      selectedAcceptanceMethodText: localizedAcceptanceMethod(acceptanceMethod)
    }))
  , [platforms, integrations]);

  return <ViewComponent {...{ brandId: brand.id, brandName: brand.name, items, onPress }} />;
};

type ViewProps = {
  brandId: string
  brandName: string
  items: {
    type: PlatformType
    icon: ImageURISource
    name: string
    selectedAcceptanceMethodText: string
  }[]
  onPress: (brandId: string, platformType: PlatformType) => void
};

/* eslint-disable react/prop-types */
const ViewComponent = memo(function ViewComponent ({ brandId, brandName, items, onPress }: ViewProps) {
  return <View style={styles.view}>
    <AppText style={styles.brandText}>{brandName}</AppText>
    {items.map(item =>
      <PlatformItem
        key={`acceptance-method-setting-list-item-${brandId}-platforms-${item.type}`}
        {...{ brandId, item, onPressButton: onPress }} />
    )}
  </View>;
}, equalProps());

type PlatformItemProps = {
  brandId: string
  item: {
    type: PlatformType
    icon: ImageURISource
    name: string
    selectedAcceptanceMethodText: string
  }
  onPressButton: (brandId: string, type: PlatformType) => void
}

const PlatformItem = (
  { brandId, item: { type, icon, name, selectedAcceptanceMethodText }, onPressButton }: PlatformItemProps
) => {
  const onPress = useCallback(() => onPressButton(brandId, type), [brandId, onPressButton, type]);

  return <View style={styles.platform}>
    <Image source={icon} style={styles.icon} resizeMode="contain"/>
    <AppText style={styles.platformText}>{name}</AppText>
    <AppText style={styles.calculationMethodText}>{selectedAcceptanceMethodText}</AppText>
    <OutlineButton size='large' title="変更" onPress={onPress}/>
  </View>;
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    marginBottom: 24,
    paddingHorizontal: 32,
    paddingBottom: 24,
    borderRadius: 12,
    backgroundColor: Color.white,
    borderWidth: 1,
    borderColor: Color.gray10,
  },
  brandText: {
    height: 104,
    color: Color.gray100,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 104
  },
  platform: {
    minHeight: 88,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  icon: {
    width: 40,
    height: 40,
    marginEnd: 16,
    borderRadius: 20
  },
  platformText: {
    flex: 1,
    color: Color.gray100,
    fontSize: 16,
    fontWeight: '500',
    marginEnd: 16,
  },
  cookingTimePicker: {
    width: 120,
    marginEnd: 8,
    color: Color.gray100,
    fontSize: 14,
    fontWeight: '400',
  },
  orderCalculationMethodPicker: {
    width: 352,
    color: Color.gray100,
    fontSize: 14,
    fontWeight: '400',
  },
  pickerItem: {
    height: 96,
    color: Color.gray100,
    fontSize: 14,
    fontWeight: '400',
  },
  calculationMethodText: {
    marginRight: 24
  }
});
