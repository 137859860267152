import { Environment } from '../../../Environment';

export const NotificationApiHost = Environment.projectEnvironment === 'production'
  ? 'notification-api.orderly.jp'
  : Environment.projectEnvironment === 'staging'
    ? 'staging-notification-api.orderly.jp'
    : 'development-notification-api.orderly.jp';

export * from './endpoints';
export * from './entities';
